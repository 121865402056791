import CustomBtn from "components/fragments/CustomBtn";
import HeaderPos from "components/layouts/HeaderPos";
import NotificationCard from "components/layouts/NotificationCard";
import React, { useState, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { orderState, useFetchAllOrder } from "state/order";
import { notificationState, useFetchAllNotification, useReadNotification } from "state/notfication";
import { posState } from "state/pos";

export default function NotificationPage() {
  const [notiData, setNotiData] = useState([
    // {
    //   id: 1,
    //   orderType: "cancel",
    //   orderNo: "OR202028040007",
    //   placeType: 1,
    //   info: "(001)",
    //   orderItem: "Americano ( Freppe ) X 2",
    //   date: "19/09/2023",
    //   time: "13:30 PM",
    // },
    // {
    //   id: 2,
    //   orderType: "cancel",
    //   orderNo: "OR202028040007",
    //   placeType: 2,
    //   info: "ด้านใน : I05",
    //   orderItem: "Americano ( Freppe ) X 2",
    //   date: "19/09/2023",
    //   time: "13:30 PM",
    // },
    // {
    //   id: 3,
    //   orderType: "cancel",
    //   orderNo: "OR202028040007",
    //   placeType: 3,
    //   info: "Lineman(L001)",
    //   orderItem: "Americano ( Freppe ) X 2",
    //   date: "19/09/2023",
    //   time: "13:30 PM",
    // },
  ]);
  const fetchAllOrder = useFetchAllOrder();
  const fetchAllNotification = useFetchAllNotification();
  const readNotification = useReadNotification();
  const { currentPOS, currentPOSActivity } = useRecoilValue(posState);
  const { orders } = useRecoilValue(orderState);
  const { notifications } = useRecoilValue(notificationState);

  useEffect(() => {
    if (currentPOS && currentPOS.id) {
      // fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
      fetchAllNotification(currentPOS.id, currentPOSActivity.operation_date);
    }
  }, [currentPOS])

  // useEffect(() => {
  //   if(orders && orders.length > 0) {
  //     fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
  //     const parsed = orders
  //       .flatMap(order => {
  //         return order.orderDetail.map(od => {
  //           if(od.status_id == 3) {
  //             return {
  //               id: od.id,
  //               orderType: "cancel",
  //               orderNo: od.main_order_id,
  //               placeType: order.type_id,
  //               info: order.type_id == 2 && order.remark ? order.remark : order.type_id == 3 && order.delivery_remark ? `${order.delivery_remark_channel} (${order.delivery_remark})` : "",
  //               orderItem: `${od.branch_product.product.name} X ${od.cancel_amount}`,
  //               date: new Date(od.updated_at).toLocaleDateString("en-GU"),
  //               time: new Date(od.updated_at).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" }),
  //             };
  //           }
  //         })
  //       })
  //       .filter(pod => pod);
  //     setNotiData(parsed);
  //   }
  // }, [orders])

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      // console.log("🚀 ~ useEffect ~ notifications:", notifications)
      // fetchAllNotification(currentPOS.id, currentPOSActivity.operation_date);
      const parsed = notifications.map(noti => {
        return {
          id: noti.id,
          orderType: noti.is_cancel ?? "cancel",
          orderNo: noti.order_id,
          placeType: noti.order_id,
          info: noti.order_remark,
          orderItem: `${noti.order_detail_name} X ${noti.cancel_amount}`,
          date: new Date(noti.cancel_time).toLocaleDateString("en-GU"),
          time: new Date(noti.cancel_time).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" }),
          is_read: noti.is_read
        };
      })
      setNotiData(parsed);
    }
  }, [notifications])

  useEffect(() => {
    if(notiData && notiData.length > 0) {
      const unread = notiData.filter(noti => !noti.is_read);
      if(unread.length > 0) {
        // console.log("🚀 ~ useEffect ~ unread:", unread.map(noti => noti.id))
        readNotification(unread.map(noti => noti.id), currentPOSActivity.operation_date)
        fetchAllNotification(currentPOS.id, currentPOSActivity.operation_date);
      }
    }
  }, [notiData])

  return (
    <div>
      <HeaderPos />
      <div className="flex flex-col pt-[min(1.72vw,22px)] px-[min(3.13vw,40px)]">
        <div className="text-18 font-[500] text-[#333333]">การแจ้งเตือน</div>
        <CustomBtn
          onClick={() => { }}
          className={`leading-[0.8] mt-[min(2.44vw,25px)] w-fit min-w-[min(11.13vw,114px)] text-14 text-white !font-[500] min-h-[min(3.32vw,34px)] rounded-[min(0.78vw,8px)] btn-main-active`}
          btntxt={"แจ้งเตือนในร้าน"}
        />
        <div className="w-full border-[min(0.20vw,2px)] border-[#D9D9D9] mt-[min(2.05vw,21px)] rounded-[min(1.56vw,16px)]">
          {notiData.map((data, i) => {
            return (
              <NotificationCard
                key={`noti-card-${i}`}
                notiData={data}
                isLast={i === notiData.length - 1}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
