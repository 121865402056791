import { atom, selector, useRecoilState, useSetRecoilState } from 'recoil';
import { getAllEmployee } from 'services/employee';

// State
export const employeeState = atom({
    key: 'employeeState',
    default: {
        employees: []
    },
});

// Action
export const useFetchAllEmployee = () => {
    const setState = useSetRecoilState(employeeState);

    return async (branchId) => {
        try {
            const fetch = await getAllEmployee(branchId);
            const parsed = fetch.employees.map(e => ({
                ...e,
                fullName: `${e.first_name_th} ${e.last_name_th}`,
            }))
            // setState((state => ({ ...state, employees: fetch.employees })));
            setState((state => ({ ...state, employees: parsed })));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};