import { Button } from "@mui/material";
import React from "react";

export default function TableBtnDetail({ content, isActive = false, onClickBtn, ...props }) {
  return (
    <Button
      {...props}
      sx={{
        "&:hover": {
          boxShadow: "inherit",
        },
      }}
      className={`px-0 sarabun rounded-[min(0.78vw,8px)] ${
        isActive ? "" : "btn-table-not-active"
      } ${props.className}`}
      variant="contained"
      onClick={onClickBtn}
    >
      {content}
    </Button>
  );
}
