import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import OperatorBtn from "components/fragments/OperatorBtn";
import CustomBtn from "components/fragments/CustomBtn";
import { useNavigate } from "react-router-dom";

export default function ModalKitchenCancelUnit({
  modalOpenObj,
  seletedUnitsObj,
  onConfirm,
  ...props
}) {
  const [info, setInfo] = useState({
    maxUnit: 0,
    unit: 0,
    name: "",
  });

  useEffect(() => {
    if (seletedUnitsObj) {
      const { foodList, selectIndex } = seletedUnitsObj;
      // const food = foodList[selectIndex];
      const food = foodList[0];
      setInfo({
        ...info,
        maxUnit: food.remain_amount,
        // unit: food.unit,
        unit: food.remain_amount,
        name: food.name,
      });
    }
  }, [seletedUnitsObj]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => modalOpenObj.setModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          <div className="bg-white min-w-[min(38.09vw,390px)] p-[min(2.44vw,25px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-18 text-[var(--color-black-2)] text-center">
            <div className="text-18 font-[700]">ยืนยันการยกเลิกเมนู</div>
            <div className="mt-[min(1.95vw,20px)] text-24 font-[700] text-[var(--color-green)]">
              {info.name}
            </div>
            <div className="flex justify-center items-center mt-[min(2.05vw,21px)]">
              <div className="text-16 mr-[min(4.00vw,41px)]">จำนวน</div>
              <div className="flex items-center gap-[min(2.44vw,12px)]">
                <OperatorBtn
                  onClick={() =>
                    setInfo((prev) => {
                      return { ...prev, unit: prev.unit - 1 };
                    })
                  }
                  className={`aspect-square min-w-[min(3.13vw,32px)] h-fit btn-grey-5`}
                  content={
                    <div>
                      <img
                        className="object-contain aspect-square w-[min(0.78vw,8px)]"
                        src="/img/icons/icon-minus-black.svg"
                        alt=""
                      />
                    </div>
                  }
                  isActive={info.unit > 0}
                />
                {/* <div className="poppins text-20 font-[600]">{info.unit}</div> */}
                  <input
                    type="number"
                    className="w-[44px] text-center text-20 font-[600]"
                    defaultValue={info.unit}
                    value={info.unit}
                    onClick={(e) => {
                      e.target.select()
                    }}
                    onKeyDown={(e) => {
                      if(e.key == ".") {
                        e.preventDefault()
                      }
                    }}
                    onChange={(e) => {
                      let newUnit = Number(e.target.value);
                      if(newUnit <= info.maxUnit) {
                        setInfo((prev) => {
                          return { ...prev, unit: newUnit };
                        })
                      }
                    }}
                  />
                <OperatorBtn
                  onClick={() =>
                    setInfo((prev) => {
                      return { ...prev, unit: prev.unit + 1 };
                    })
                  }
                  className={`aspect-square min-w-[min(3.13vw,32px)] h-fit btn-grey-5`}
                  content={
                    <div>
                      <img
                        className="object-contain aspect-square w-[min(0.78vw,8px)]"
                        src="/img/icons/icon-plus-black.svg"
                        alt=""
                      />
                    </div>
                  }
                  isActive={info.unit < info.maxUnit}
                />
              </div>
            </div>
            <div className="flex justify-center gap-[min(2.44vw,25px)] mt-[min(1.95vw,20px)]">
              <CustomBtn
                onClick={() => {
                  modalOpenObj.setModalOpen(false);
                }}
                className={`leading-[0.8] w-fit min-w-[min(11.13vw,114px)] h-[min(4.88vw,50px)] text-18 text-[#6D788D] font-[600] rounded-[min(0.78vw,8px)] btn-second-2-active shadow-none`}
                btntxt={"ยกเลิก"}
              />
              <CustomBtn
                onClick={() => {
                  if (!seletedUnitsObj) {
                    return modalOpenObj.setModalOpen(false);
                  }
                  const { setFoodList, selectIndex, setSelectedList, foodList } = seletedUnitsObj;
                  // setFoodList(prev => {
                  //   return prev.map((item, index) => {
                  //     if (index === selectIndex) {
                  //       return { ...item, unit: info.unit === 0 ? info.maxUnit : info.unit, prevUnit: info.unit === 0 ? null : info.maxUnit, isCancel: info.unit === 0 ? true : false };
                  //     }
                  //     return item;
                  //   });
                  // });
                  onConfirm(foodList[selectIndex], info.unit, seletedUnitsObj)
                  // setSelectedList([]);
                  modalOpenObj.setModalOpen(false);
                }}
                className={`leading-[0.8] w-fit min-w-[min(11.13vw,114px)] h-[min(4.88vw,50px)] text-18 text-white font-[600] rounded-[min(0.78vw,8px)] ${ info.unit === 0 ? 'disabled-btn' : 'btn-main-active'} shadow-none`}
                btntxt={"ยืนยัน"}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
