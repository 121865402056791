
import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Form from "react-bootstrap/Form";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import { nanoid } from 'nanoid'

export default function  ModalTableSelect({
    modalOpenObj,
    handleTableSelect,
    tables,
    tableZone,
    ...props
  }) {

    const newReservedTables = Array.from({ length: 6 }, () => ({
        currentPerson: 0,
        maxPerson: 2,
        time: "00:00 AM",
        timeUp: "",
        remark: "เอกการช่างมา 6 มาเยอะมากๆๆๆๆ",
        type: "reserved",
        tableNo: "",
        tableID:"",
        mergeList: "",
        moveFrom: "",
    }));
    
    const newOvertimeTables = Array.from({ length: 6 }, () => ({
        currentPerson: 3,
        maxPerson: 2,
        time: "00:00 AM",
        timeUp: "",
        remark: "",
        type: "overtime",
        tableNo: "",
        tableID:"",
        mergeList: "",
        moveFrom: "",
    }));
    
    const newUsingTables = Array.from({ length: 6 }, () => ({
        currentPerson: 3,
        maxPerson: 2,
        time: "00:00 AM",
        timeUp: "",
        remark: "",
        type: "using",
        tableNo: "",
        tableID:"",
        mergeList: "",
        moveFrom: "",
    }));
    
    const newEmptyTables = Array.from({ length: 6 }, () => ({
        currentPerson: 0,
        maxPerson: 2,
        time: "00:00 AM",
        timeUp: "",
        remark: "",
        type: "empty",
        tableNo: "",
        tableID:"",
        mergeList: "",
        moveFrom: "",
    }));
    
    // const updatedTableCardList = [
    //     ...newReservedTables,
    //     ...newOvertimeTables,
    //     ...newUsingTables,
    //     ...newEmptyTables,
    // ];

    // const [tableCardList, setTableCardList] = useState(updatedTableCardList);
    const [tableCardList, setTableCardList] = useState([]);
    const [zoneSelected, setZoneSelected] = useState(0)
    const [tableSelected, setTableSelected] = useState(null)
    const [checkTime, setCheckTime] = useState(false)
    const [selectedZone, setSelectedZone] = useState(null) // This component scope

    const [time, setTime] = useState({
        "hour":'00',
        "minute":'00'
    })

    // const [zone, setZone] = useState(
    //     [
    //       {"zoneID":1,"zoneName":"VIP"},
    //       {"zoneID":2,"zoneName":"ในร้าน"},
    //       {"zoneID":3,"zoneName":"นอกร้าน"},
    //       {"zoneID":4,"zoneName":"สวนหน้าร้าน"},
    //       {"zoneID":5,"zoneName":"สวนหลังร้าน"}
    //     ]
    // );

    const [zone, setZone] = useState([]);

    const filterTable = (tableZoneId) => {
        if (tables?.length > 0 && tableZoneId) {
            let filtered = [...tables].filter(t => t.zone_id == tableZoneId);
            let activeTable = 0;
            let guest = 0;
            return filtered.map((t,i) => {
              // TODO: Optimize these merge value from API side instead
              let merge = filtered.filter(f => f.activity.mainTableId == t.id);
              let childList = merge.filter(m => m.id !== t.id && m.activity.tableId != t.id);
              let isChild = t.activity.tableId != t.activity.mainTableId;
              let isMain = merge.length > 1;
      
              let mergeText = "";
              if(isMain) {
                mergeText = childList.reduce((a,b) => a + ", " + b.no, "");
                mergeText = mergeText.replace(mergeText[0], "");
              }
              if(isChild) {
                let main = filtered.filter(f => f.id == t.activity.mainTableId)[0];
                mergeText = main.no;
              }
      
              if(t.activity.statusId == 2 || t.activity.statusId == 3 || t.activity.statusId == 4) {
                activeTable += 1;
                guest += t.current_guest;
              }
      
              return {
                id: t.id,
                tableID: t.id,
                zoneId: t.zone_id,
                currentPerson: t.current_guest ?? 0,
                maxPerson: t.volume ? t.volume : t.max_guest ? t.max_guest : null,
                time: "00:00 AM",
                timeUp: "",
                remark: "",
                // type: t.is_active ? "using" : "empty",
                type: t.activity ? t.activity.statusId === 2 ? "using" : t.activity.statusId === 3 ? "reserved" : "empty" : "empty",
                tableNo: t.no,
                mergeList: t.activity.statusId === 2 ? mergeText : "",
                moveFrom: "",
                tableActivity: t.activity,
                label: `${t.no}`,
                // label: `${t.no} ${selectedZone?.alias ?? "(" + selectedZone.alias + ""}`
              }
            });
          }
    }

    useEffect(() => {
      return () => {
        setTableSelected(null)
      }
    }, [])

    // Reflect table zone data change
    useEffect(() => {
        if(tableZone?.length > 0) {
            let parsed = tableZone.map(tbz => ({
                ...tbz,
                zoneID: tbz.id,
                zoneName: tbz.alias
            }));
            setZone(parsed);
            if(zoneSelected == 0) {
                setZoneSelected(parsed[0].zoneID)
            }
        }
    }, [tableZone])

    // Reflect table data change
    useEffect(() => {
        if (tables?.length > 0) {
          let filtered = [...tables].filter(t => t.zone_id == zoneSelected);
          let activeTable = 0;
          let guest = 0;
          let parsed = filtered.map((t,i) => {
            // console.log('t: ', t)
            // TODO: Optimize these merge value from API side instead
            let merge = filtered.filter(f => f.activity.mainTableId == t.id);
            let childList = merge.filter(m => m.id !== t.id && m.activity.tableId != t.id);
            let isChild = t.activity.tableId != t.activity.mainTableId;
            let isMain = merge.length > 1;
    
            let mergeText = "";
            if(isMain) {
              mergeText = childList.reduce((a,b) => a + ", " + b.no, "");
              mergeText = mergeText.replace(mergeText[0], "");
            }
            if(isChild) {
              let main = filtered.filter(f => f.id == t.activity.mainTableId)[0];
              mergeText = main.no;
            }
    
            if(t.activity.statusId == 2 || t.activity.statusId == 3 || t.activity.statusId == 4) {
              activeTable += 1;
              guest += t.current_guest;
            }
    
            return {
              id: t.id,
              tableID: t.id,
              zoneId: t.zone_id,
            //   currentPerson: t.current_guest,
              currentPerson: t.current_guest ?? 0,
              maxPerson: t.volume ? t.volume : t.max_guest ? t.max_guest : null,
              time: "00:00 AM",
              timeUp: "",
              remark: "",
              // type: t.is_active ? "using" : "empty",
              type: t.activity ? t.activity.statusId === 2 ? "using" : t.activity.statusId === 3 ? "reserved" : "empty" : "empty",
              tableNo: t.no,
              mergeList: t.activity.statusId === 2 ? mergeText : "",
              moveFrom: "",
              tableActivity: t.activity,
              label: `${t.no}`,
            //   label: `${t.no} ${selectedZone?.alias ?? "(" + selectedZone.alias + ""}`
            }
          });
          setTableCardList(parsed);
        //   setCurrentActiveTable(activeTable);
        //   setCurrentGuest(guest);
        }
      }, [tables]);

    useEffect(() => {
        // setTableSelected(null)
        setTime({
            "hour":'00',
            "minute":'00'
        })
        setCheckTime(false)
    }, [modalOpenObj.modalOpen]);

    useEffect(() => {
        // setTableCardList(
        //     tableCardList.map((v, index) => {
        //         return {
        //         ...v,
        //         tableNo: `A${index + 1 < 10 ? "0" + (index + 1) : index + 1}`,
        //         tableID: index + 1,
        //         };
        //     })
        // );
        const selectedZone = tableZone.filter(tbz => tbz.id == zoneSelected)[0];
        setSelectedZone(selectedZone)
        const parsed = filterTable(zoneSelected)
        setTableCardList(parsed);
    }, [zoneSelected]);

    return (
        <div>
          <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={modalOpenObj.modalOpen}
              onClose={() => {
                setTableSelected(null)
                modalOpenObj.setModalOpen(false)
              }}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
          >
            <Fade in={modalOpenObj.modalOpen}>
              <div className="bg-white focus-visible-none w-[min(90.11vw,785px)] pt-1  absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-16 text-[var(--color-black-2)]" >
                  
                <div className="text-end mb-2">
                    <div className="d-flex  align-items-center mb-3 px-4 pt-4 pb-1">
                        <div className="icon-desk fs-24 tc-2 fw-5 me-auto">เลือกโต๊ะ</div>

                        <div className=" d-flex align-items-center pe-0">
                            <div className="form-check form-check-st-2 me-3">
                                <input 
                                    className="form-check-input timecheck" 
                                    type="checkbox" 
                                    value="" 
                                    id="timecheck" 
                                    style={{marginTop:"2.5px"}}
                                    onChange={(e) => {
                                        setCheckTime(e.target.checked) 
                                    }}
                                />
                                <label className="form-check-label fs-18 fw-5 tc-2 ps-3" >กำหนดเวลา</label>
                            </div>
                            <div className="d-flex align-items-center">
                                <div>
                                    <input 
                                        type="number" 
                                        className="form-control form-time" 
                                        readOnly={!checkTime} 
                                        value={time.hour}
                                        onChange={(e) => {
                                            setTime( (prev) => ({  ...prev,hour:e.target.value }) )  
                                            setTableSelected( (prev) => ({  
                                                ...prev,
                                                    timeUp: checkTime === true ?  `${e.target.value}:${time.minute}` : "",
                                            })) 
                                        }}
                                    />
                                </div>
                                <div className="fs-18 tc-14 mx-1"> : </div>
                                <div>
                                    <input 
                                        type="number" 
                                        className="form-control form-time " 
                                        readOnly={!checkTime} 
                                        value={time.minute}
                                        onChange={(e) => {
                                            setTime( (prev) => ({  ...prev,minute:e.target.value }) )  
                                            setTableSelected( (prev) => ({  
                                                ...prev,
                                                    timeUp: checkTime === true ?  `${time.hour}:${e.target.value}` : "",
                                            })) 
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="px-5"></div>
                        </div>
                        <button type="button" className=" btn btn-close-modal" onClick={() => { setTableSelected(null); modalOpenObj.setModalOpen(false) }}></button>
                    </div>
                    <div className="px-3 py-3" style={{background: '#FAFAFA'}}>
                        <div className="mb-3 px-5" >
                            <Splide
                                hasTrack={false}
                                options={{
                                    autoWidth: true,
                                    pagination: false,
                                }}
                                >
                                <div className="custom-wrapper ">
                                    <SplideTrack>
                                        {zone.map((item, index) =>
                                            <SplideSlide key={index}>
                                                <button
                                                className={  item.zoneID == zoneSelected ?  "btn btn-category category btn-c1 active"  : "btn btn-category category btn-c1" }
                                                onClick={() =>  setZoneSelected(item.zoneID) }
                                                style={{minWidth:"100px"}}
                                                >
                                                {item.zoneName}
                                                </button>
                                            </SplideSlide>
                                        )}
                                        </SplideTrack>
                                        <div className="splide__arrows">
                                        <button className="splide__arrow btn-scroll-prev splide__arrow--prev"></button>
                                        <button className="splide__arrow btn-scroll-next splide__arrow--next"></button>
                                        </div>
                                    </div>
                            </Splide>
                        </div>
                        <div className="bg-white p-3 ps-4">
                            <div className="d-flex align-items-center mb-2">
                            <div className={`bx-alert-a1 me-auto ${tableSelected && "invisible"}`}>กรุณาเลือกโต๊ะ</div>
                            <div>
                                <span className="tc-8 fw-light u-off me-3">ไม่สามารถเปิดได้</span>
                                <span className="tc-8 fw-light u-on me-3">เปิดได้</span>
                            </div>
                            </div>
                            <div className="bx-list-desk-m ">
                                {
                                    tableCardList && tableCardList.map((item, index) => 
                                        <button  
                                            key={index}
                                            className={`bx-desk ${tableSelected && tableSelected.tableID == item.tableID ? 'active' : ''}` }
                                            onClick={() => {
                                                    setTableSelected( (prev) => ({  
                                                        ...prev,
                                                        ...item,
                                                        // currentPerson: item.maxPerson,
                                                        currentPerson: item?.currentPerson > 0 ? item?.currentPerson : 2,
                                                        timeUp: checkTime === true ?  `${time.hour}:${time.minute}` : "",
                                                        // maxPerson: item.maxPerson,
                                                        maxPerson: item.maxPerson,
                                                        time: item.time,
                                                        // type: "using",
                                                        type: item.type,
                                                        tableNo: item.tableNo,
                                                        tableID: item.tableID,
                                                        mergeList: item.mergeList,
                                                        moveFrom: item.moveFrom,
                                                        tableActivity: item.tableActivity ?? null,
                                                        label: item.label
                                                    }) ) 
                                                } 
                                            }
                                                
                                            disabled={item.type != 'empty' && item.type != 'reserved'}
                                        >
                                            <label>
                                                <div className="fs-10 mb-auto d-flex align-items-center justify-content-between tx1">
                                                    <span className="icustomer">{item.currentPerson}/{item.maxPerson}</span>
                                                    <span className="iclock">{item.time}</span>
                                                </div>
                                                <div className="text-end">
                                                    <div className="tx2">{ item.type == 'reserved' ? 'reserved' : '' }</div>
                                                    <div className="tx3">{item.tableNo}</div>
                                                </div>
                                            </label>
                                        </button>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                    <div className="px-3 pt-3 pb-3">
                        <div className="d-flex ps-3">
                            <div className="me-auto d-flex align-items-center">
                                <div className="me-4 tc-2">จำนวนคน</div>
                                <div className="d-flex align-items-center">
                                <button className="btn  btn-minus" 
                                    disabled={ (tableSelected && tableSelected.currentPerson == 1) || !tableSelected }
                                    onClick={ () => { 
                                        setTableSelected( (prev) => ({  ...prev, currentPerson:(prev.currentPerson - 1) }) ) 
                                    }}
                                ></button>
                                <input type="number" name="" 
                                    className="form-control-plaintext plaintext1 mx-4" 
                                    readOnly=""
                                    value={tableSelected?.currentPerson ?? 2 }
                                    onChange={(e) => {
                                        setTableSelected( (prev) => ({  ...prev, currentPerson: e.target.value }) ) 
                                    }}
                                />
                                <button className="btn btn-plus" 
                                    onClick={ () => { 
                                        setTableSelected( (prev) => ({  ...prev,currentPerson:(prev.currentPerson + 1) }) )
                                    }}
                                    disabled={!tableSelected}
                                ></button>
                                </div>
                            </div>
                            <div className="pe-3">
                                <button
                                    className="btn btn-m2 me-2"
                                    onClick={() => {
                                        setTableSelected(null)
                                        modalOpenObj.setModalOpen(false)
                                    }}
                                >
                                    ยกเลิก
                                </button>
                                <button 
                                    className="btn btn-m3 btn-select-table"
                                    disabled={!tableSelected}
                                    onClick={() =>  handleTableSelect(tableSelected, checkTime, time) }
                                >
                                    เปิดโต๊ะ
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </Fade>
          </Modal>
  
        </div>
  
      )

}
