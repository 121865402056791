import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomBtn from "components/fragments/CustomBtn";
import { useNavigate } from "react-router-dom";
import { useClosePOSActivity } from "state/pos";
import { numberWithTwoDecimals } from "utils/numberFormat";

export default function ModalCloseBalanceCf({
  selectedUserIdObj,
  modalOpenObj,
  info,
  onConfirmBtnHandler,
  ...props
}) {
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => modalOpenObj.setModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          <div className="bg-white min-w-[min(37.11vw,380px)] p-[min(2.44vw,25px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-18 text-[var(--color-black-2)] text-center leading-[1.8] font-[500]">
            <div className="flex flex-col items-center">
              <div className="leading-[2.2] font-[500] text-center text-[#061126]">
                คุณต้องการยืนยันการปิดยอด
              </div>
              <div className="leading-[1.2] mt-[min(1.46vw,15px)]">
                <div className="text-[var(--color-grey-2)]">
                  เงินสด Diff มูลค่า
                </div>
                <div
                  className={
                    info && info.diffTotal && info.diffTotal < 0
                      ? "text-[var(--color-red)]"
                      : "text-[var(--color-green)]"
                  }
                >
                  {info && info.diffTotal && info.diffTotal ? numberWithTwoDecimals(info.diffTotal) : '-'}
                </div>
              </div>
              <div className="flex justify-center gap-[min(2.01vw,20.63px)] mt-[min(2.44vw,25px)]">
              <CustomBtn
                onClick={() => {
                  modalOpenObj.setModalOpen(false);
                }}
                className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-[#6D788D] font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-second-2-active`}
                btntxt={"ยกเลิก"}
              />
              <CustomBtn
                onClick={onConfirmBtnHandler}
                className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-white font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-main-active`}
                btntxt={"ยืนยัน"}
              />
            </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
