export const onChangeHandleHour = (e) => {
  if(!e.currentTarget.value) {
    return "";
  }
  let inputValue = parseInt(e.currentTarget.value);
  if (isNaN(inputValue)) {
    return "";
  }
  if (inputValue > 24) {
    inputValue = 24;
  }
  if (inputValue < 0) {
    inputValue = 0;
  }

  return inputValue < 10 ? "0" + inputValue : "" + inputValue;
};

export const onChangeHandleMinute = (e) => {
  if(!e.currentTarget.value) {
    return "";
  }

  let inputValue = parseInt(e.currentTarget.value);
  if (isNaN(inputValue)) {
    return "";
  }
  if (inputValue > 59) {
    inputValue = 59;
  }
  if (inputValue < 0) {
    inputValue = 0;
  }

  return inputValue < 10 ? "0" + inputValue : "" + inputValue;
};
