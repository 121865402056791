import { atom, useSetRecoilState } from 'recoil';
import { getAllMember, createMember } from '../services/member';

// State
export const memberState = atom({
    key: 'memberState',
    default: {
        members: []
    },
});

// Action
export const useFetchAllMember = () => {
    const setState = useSetRecoilState(memberState);

    return async ({ businessId }) => {
        try {
            const fetch = await getAllMember({ businessId });
            setState(state => ({ ...state, members: fetch.members }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
};

// export const useFetchAllMemberCategory = () => {
//     const setState = useSetRecoilState(memberState);

//     return async (businessId) => {
//         try {
//             const fetch = await getAllMemberCategory(businessId);
//             setState(state => ({ ...state }));
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };
// };

// export const useFetchAllBranchMember = () => {
//     const setState = useSetRecoilState(memberState);

//     return async (branchId) => {
//         try {
//             const fetch = await getAllBranchMember(branchId);
//             // console.log("🚀 ~ file: member.js:46 ~ return ~ fetch:", fetch.members)
//             setState(state => ({ ...state, members: fetch.members }));
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };
// };

export const useCreateMember = () => {
    const setState = useSetRecoilState(memberState);

    return async (memberDetail) => {
        // console.log("🚀 ~ file: member.js:57 ~ return ~ memberDetail:", memberDetail)
        try {
            const create = await createMember(memberDetail);
            const fetch = await getAllMember(memberDetail.businessId);
            // console.log("🚀 ~ file: member.js:61 ~ return ~ fetch:", fetch)
            setState(state => ({ ...state, members: fetch.members }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
};