import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomBtn from "components/fragments/CustomBtn";
import CustomInput from "components/fragments/CustomInput";
import SelectStaffCard from "./SelectStaffCard";
import { useRecoilValue } from "recoil";
import { employeeState } from "state/employee";

export default function ModalChangeStaff({
  modalPinOpenObj,
  modalOpenObj,
  selectedUserIdObj,
  staffList,
  ...props
}) {
  // const [userList, setUserList] = useState([
  //   { id: 1, fullName: "มุนิน มุตา", code: "EMP-001" },
  //   { id: 2, fullName: "มุนิน มุตา", code: "EMP-002" },
  //   { id: 3, fullName: "มุนิน มุตา", code: "EMP-003" },
  // ]);
  const { employees } = useRecoilValue(employeeState)
  // const [userList, setUserList] = useState(staffList ? staffList : employees);
  const [userList, setUserList] = useState(staffList);
  const [isError, setIsError] = useState();
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [keyword, setKeyword] = useState("");

  const handleSubmit = () => {
    modalOpenObj.setModalOpen(false);
    modalPinOpenObj.setModalOpen(true);
  };

  useEffect(() => {
    if(staffList) {
      setUserList(staffList)
    } else {
      setUserList(employees)
    }
  }, [staffList]);

  useEffect(() => {
    if (!keyword) {
      return setFilteredUserList([]);
    }
    const filteredList = userList.filter(
      (user) =>
        user.fullName.toLowerCase().includes(keyword.toLowerCase()) ||
        user.code.toLowerCase().includes(keyword.toLowerCase())
    );
    setFilteredUserList(filteredList);
  }, [userList, keyword]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => modalOpenObj.setModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          <div className="bg-white w-[min(38.67vw,396px)] p-[min(2.44vw,25px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-18 text-[var(--color-black-2)] text-center leading-[1.8] font-[700]">
            <div className="leading-[2.2] font-[700] text-center">
              เปลี่ยนพนักงาน
            </div>
            <CustomInput
              className="mt-[min(0.98vw,10px)] w-full"
              inputprops={{
                className:
                  "rounded-[min(0.78vw,8px)] border-[min(0.10vw,1px)] border-[#b6bbc6] py-[min(1.03vw,10.5px)] px-[min(1.46vw,15px)] font-[400] text-16",
                placeholder: "พิมพ์ชื่อ/รหัสพนักงาน",
                onChange: (e) => setKeyword(e.currentTarget.value),
                value: keyword,
                error: isError,
              }}
            />
            {filteredUserList && filteredUserList.length > 0 && (
              <div className="mt-[min(1.46vw,15px)] mb-[min(1.46vw,15px)] flex flex-col gap-[min(1.27vw,13px)]">
                {filteredUserList.map((value, index) => {
                  return (
                    <SelectStaffCard
                      key={index}
                      onClick={() =>
                        selectedUserIdObj.setSelectedUserId(value.id)
                      }
                      userInfo={{
                        id: value.id,
                        fullName: value.fullName,
                        code: value.code,
                      }}
                      selected={value.id === selectedUserIdObj.selectedUserId}
                    />
                  );
                })}
              </div>
            )}

            <div className="flex justify-center gap-[min(2.01vw,20.63px)] mt-[min(0.98vw,10px)]">
              <CustomBtn
                onClick={() => {
                  modalOpenObj.setModalOpen(false);
                }}
                className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-[#6D788D] font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-second-2-active`}
                btntxt={"ยกเลิก"}
              />
              <CustomBtn
                onClick={handleSubmit}
                className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-white font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] ${
                  selectedUserIdObj.selectedUserId
                    ? "btn-main-active"
                    : "disabled-btn"
                } `}
                btntxt={"ยืนยัน"}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
