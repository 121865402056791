import CustomBtn from "components/fragments/CustomBtn";
import DiscountTag from "components/fragments/DiscountTag";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";

const mockDiscount = [
  {
    id: 1,
    name: "ส่วนลด 5 บาท",
    discountTypeId: 1,
    value: 5,
  },
  {
    id: 2,
    name: "ส่วนลด 15 บาท",
    discountTypeId: 1,
    value: 15,
  },
  {
    id: 3,
    name: "ส่วนลด 20 บาท",
    discountTypeId: 1,
    value: 20,
  },
  {
    id: 4,
    name: "ส่วนลด 25 บาท",
    discountTypeId: 1,
    value: 25,
  },
  {
    id: 5,
    name: "ส่วนลด 10%",
    discountTypeId: 2,
    value: 10,
  },
  {
    id: 6,
    name: "ส่วนลดแลกคะแนน June Partner 15%",
    discountTypeId: 2,
    value: 15,
  },
  // {
  //   id: 7,
  //   name: "ลด 5",
  //   discountTypeId: 2,
  //   value: 5,
  // },
  {
    id: 8,
    name: "ส่วนลดพิเศษวันเกิด 20% ",
    discountTypeId: 2,
    value: 20,
  },
]

export default function Discount({ endBillDiscountObj, setPaymentSelect, orderPrice, setPaymentObj, discountObj, PaymentDetailObj }) {
  console.log("🚀 ~ Discount ~ PaymentDetailObj:", PaymentDetailObj)
  const [tagList, setTagList] = useState([
    {
      id: 1,
      name: "พนักงานลด 30%",
      discountTypeId: 2,
      value: 30,
    },
    {
      id: 2,
      name: "ลูกค้า VIP ลด 25%",
      discountTypeId: 2,
      value: 25,
    },
    {
      id: 3,
      name: "ส่วนลดวันเกิด 25%",
      discountTypeId: 2,
      value: 25,
    },
    {
      id: 4,
      name: "ลูกค้าพิเศษชั้นดี 10%",
      discountTypeId: 2,
      value: 10,
    },
    {
      id: 5,
      name: "ส่วนลดครึ่งหนึ่ง 50%",
      discountTypeId: 2,
      value: 50,
    },
    {
      id: 6,
      name: "ส่วนลดรับรอง 100%",
      discountTypeId: 2,
      value: 100,
    },
  ]);
  const [selectedTagId, setSetselectedTagId] = useState(endBillDiscountObj.endBillDiscount.id);
  const [discount, setDiscount] = useState({
    id: endBillDiscountObj.endBillDiscount.id,
    name: endBillDiscountObj.endBillDiscount.name,
    discountTypeId: endBillDiscountObj.endBillDiscount.discountTypeId,
    value: endBillDiscountObj.endBillDiscount.value,
    isPercent: endBillDiscountObj.endBillDiscount.isPercent,
    amount: endBillDiscountObj.endBillDiscount.amount,
  });

  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        <div className="flex gap-[min(2.01vw,20.63px)] mt-[min(2.44vw,25px)]">
          <CustomBtn
            onClick={() => {}}
            className={`leading-[0.8] w-fit min-w-[min(9.96vw,102px)] text-14 text-white font-[500] min-h-[min(3.32vw,34px)] rounded-[min(0.39vw,4px)] btn-main-active`}
            btntxt={"ส่วนลด"}
          />
          <CustomBtn
            onClick={() => {}}
            className={`leading-[0.8] w-fit min-w-[min(9.96vw,102px)] text-14 text-[#6D788D] font-[500] min-h-[min(3.32vw,34px)] rounded-[min(0.39vw,4px)] border border-[rgba(109,120,141,0.5)] btn-white`}
            btntxt={"คูปอง"}
            disabled
          />
        </div>

        <div className="mt-[min(2.25vw,23px)] text-18">เลือกส่วนลด</div>

        <div className="w-full mt-[min(2.44vw,25px)] border-b-[0.5px] border-[rgba(182,187,198,0.3)] min-h-[166px] max-h-[166px] overflow-y-auto flex gap-x-[min(1.07vw,11px)] gap-y-[min(1.46vw,15px)] flex-wrap pb-[min(3.32vw,34px)]">
          {tagList.map((v) => {
            return (
              <DiscountTag
                isActive={v.id === selectedTagId}
                handleOnClick={() => {
                  setSetselectedTagId(v.id);
                  // endBillDiscountObj.setEndBillDiscount({
                  //   ...endBillDiscountObj.endBillDiscount,
                  //   discountTypeId: v.discountTypeId,
                  //   value: v.value,
                  // });
                  setDiscount((prev) => ({
                    ...prev,
                    id: v.id,
                    name: v.name,
                    discountTypeId: v.discountTypeId,
                    isPercent: v.discountTypeId == 1 ? false : true,
                    amount: v.value,
                    value: v.discountTypeId == 1 ? v.value : (v.value / 100) * orderPrice,
                  }));
                }}
                key={`tag-${v.id}`}
                data={v}
              />
            );
          })}
        </div>
        <div className="mt-[min(2.93vw,30px)] max-w-[min(30.96vw,317px)]">
          <div className="mb-2">
            <div className="mb-3 flex justify-between">
              <div className="inline-block">
                <div className="text-16 flex items-center">
                  <img
                    className="object-contain aspect-[20/19] mr-[min(0.59vw,6px)] min-w-[min(1.95vw,20px)] h-fit"
                    src="/img/icons/icon-discount-orange.svg"
                    alt=""
                  />
                  <div>ส่วนลด</div>
                </div>
              </div>
              <div>
                <Form.Check
                  inline
                  label="บาท"
                  name="discount_baht"
                  type="radio"
                  id="discount-1"
                  className="form-check-st-5 tc-7 me-3"
                  value={1}
                  onChange={() => {
                    // endBillDiscountObj.setEndBillDiscount({
                    //   ...endBillDiscountObj.endBillDiscount,
                    //   discountTypeId: 1,
                    // })
                    var existTag = tagList.find(tag => tag.discountTypeId == 1 && tag.value == discount.amount);
                    if (existTag) {
                      setSetselectedTagId(existTag.id);
                      return setDiscount((prev) => ({
                        ...prev,
                        id: existTag.id,
                        name: existTag.name,
                        discountTypeId: 1,
                        isPercent: false
                      }))
                    } else {
                      return setDiscount((prev) => ({
                        ...prev,
                        id: null,
                        name: null,
                        discountTypeId: 1,
                        isPercent: false
                      }))
                    }
                  }}
                  checked={
                    // endBillDiscountObj.endBillDiscount.discountTypeId == 1
                    discount.discountTypeId == 1
                  }
                />
                <Form.Check
                  inline
                  label="เปอร์เซ็นต์(%)"
                  name="discount_percent"
                  type="radio"
                  id="discount-2"
                  className="form-check-st-5 tc-7 me-0"
                  value={2}
                  onChange={() => {
                    // endBillDiscountObj.setEndBillDiscount({
                    //   ...endBillDiscountObj.endBillDiscount,
                    //   discountTypeId: 2,
                    // })
                    var existTag = tagList.find(tag => tag.discountTypeId == 2 && tag.value == discount.amount);
                    if (existTag) {
                      setSetselectedTagId(existTag.id);
                      return setDiscount((prev) => ({
                        ...prev,
                        id: existTag.id,
                        name: existTag.name,
                        discountTypeId: 2,
                        isPercent: true
                      }))
                    } else {
                      return setDiscount((prev) => ({
                        ...prev,
                        id: null,
                        name: null,
                        discountTypeId: 2,
                        isPercent: true
                      }))
                    }
                  }}
                  checked={
                    // endBillDiscountObj.endBillDiscount.discountTypeId == 2
                    discount.discountTypeId == 2
                  }
                />
              </div>
            </div>
            <div className="">
              <input
                className="form-control text-end border-0 fs-20 pe-4 form-st-5"
                type="number"
                onClick={(e) => {
                  const input = e.target;
                  input.select();
                }}
                style={{
                  background: "#FFF",
                  borderRadius: "8px",
                  filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14))",
                }}
                // disabled={
                //   // endBillDiscountObj.endBillDiscount.discountTypeId == undefined ||
                //   // !discount.id || !discount.discountTypeId
                // }
                onChange={(e) => {
                  // endBillDiscountObj.setEndBillDiscount({
                  //   ...endBillDiscountObj.endBillDiscount,
                  //   value: e.currentTarget.value,
                  // })
                  // let discountValue = discount.discountTypeId == 2 ? ( discount.percent / 100) * orderPrice : 0;
 
                  // Limit decimal to 2 points
                  var decimal = e.target.value.split('.');
                  if(decimal.length > 1 && decimal[1].length > 2) {
                    e.preventDefault();
                    return null;
                  }

                  // Check if value is match to predefined discount
                  var existTag = tagList.find(tag => tag.discountTypeId == discount.discountTypeId && tag.value == Number(e.target.value));
                  if(existTag) {
                    setSetselectedTagId(existTag.id);
                    return setDiscount((prev) => ({
                      ...prev,
                      id: existTag.id,
                      name: existTag.name,
                      amount: Number(e.target.value),
                    }))
                  } else {
                    setSetselectedTagId(null);
                    if(discount.discountTypeId == 1 && e.target.value <= orderPrice) {
                      return setDiscount((prev) => ({
                        ...prev,
                        // value: e.currentTarget.value,
                        id: null,
                        amount: Number(e.target.value),
                      }))
                    } else if (discount.discountTypeId == 2 && e.target.value <= 100) {
                      return setDiscount((prev) => ({
                        ...prev,
                        // value: e.currentTarget.value,
                        id: null,
                        amount: Number(e.target.value),
                      }))
                    }
                  }
                }}
                // value={endBillDiscountObj.endBillDiscount.value}
                value={discount.amount}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="ml-auto">
        <CustomBtn
          onClick={async () => {
            let newDiscount = 0;
            let newDiscountRound = 0;
            if (discount.discountTypeId == 1) {
              newDiscount = discount.amount;
            } else if (discount.discountTypeId == 2 || discount.isPercent) {
              newDiscount = orderPrice * ( discount.amount / 100);
            }

            const discountDecimal = newDiscount % 1;
            if(discountDecimal > 0) {
              newDiscountRound = Math.ceil(newDiscount);
            } else {
              newDiscountRound = newDiscount;
            }

            if (setPaymentSelect !== undefined) {
              await setPaymentObj([]);
              await endBillDiscountObj.setEndBillDiscount({
                ...endBillDiscountObj.endBillDiscount,
                discountTypeId: discount.discountTypeId,
                isPercent: discount.isPercent,
                amount: discount.amount,
                // value: discount.value,
                value: newDiscount,
                id: discount.id,
                name: discount.name,
                valueRound: newDiscountRound
              });
              var discounts = [...discountObj.discount];
              var exist = discounts.findIndex(dc => dc.type =='คูปอง/ส่วนลด');
              if(exist >= 0) {
                discounts[exist] = {
                  type: 'คูปอง/ส่วนลด',
                  discounts: [
                    {
                      campaign: discount.isPercent ? `ส่วนลด ${discount.amount} %` : `ส่วนลด ${newDiscountRound} บาท`,
                      desc: '',
                      option: [],
                      amount: newDiscountRound
                    }
                  ]
                };
              } else {
                discounts.push({
                  type: 'คูปอง/ส่วนลด',
                  discounts: [
                    {
                      campaign: discount.isPercent ? `ส่วนลด ${discount.amount} %` : `ส่วนลด ${newDiscountRound} บาท`,
                      desc: '',
                      option: [],
                      amount: newDiscountRound
                    }
                  ]
                });
              }
              discountObj.setDiscount(discounts);
              await setPaymentSelect((v) => ({
                ...v,
                paymentChannelID: 0,
              }));
            }
          }}
          className={`leading-[0.8] w-fit min-w-[min(12.66vw,129.6px)] text-18 text-white font-[700] min-h-[min(5.47vw,56px)] rounded-[min(0.39vw,4px)] btn-main-active`}
          btntxt={"ยืนยันส่วนลด"}
          disabled={
            // endBillDiscountObj.endBillDiscount.discountTypeId == undefined ||
            setPaymentSelect == undefined || 
            discount.amount <= 0 ||
            (discount.discountTypeId == 2 && discount.amount > 100) ||
            (discount.discountTypeId == 1 && discount.amount > orderPrice) ||
            PaymentDetailObj?.PaymentDetail?.paymentRemaining && Math.abs(PaymentDetailObj?.PaymentDetail?.paymentRemaining) == 0
          }
        />
      </div>
    </div>
  );
}
