import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomBtn from "components/fragments/CustomBtn";
import CustomInput from "components/fragments/CustomInput";

export default function ModalOrderRemark({
  modalOpenObj,
  orderRemarkObj,
  onConfirm,
  ...props
}) {
  const [isError, setIsError] = useState();
  const [remark, setRemark] = useState(orderRemarkObj.orderRemark ?? "");

  // useEffect(() => {
  //   return () => {
  //     setRemark("")
  //   }
  // }, [])

  // useEffect(() => {
  //   if(modalOpenObj && modalOpenObj.modalOpen) {
  //     console.log("orderRemarkObj.orderRemark: ", orderRemarkObj.orderRemark)
  //     if(orderRemarkObj.orderRemark) {
  //       setRemark(orderRemarkObj.orderRemark)
  //     } else {
  //       setRemark("")
  //     }
  //   }
  // }, [modalOpenObj])

  const handleSubmit = async () => {
    // console.log("remark: ", remark)
    await onConfirm(remark);
    modalOpenObj.setModalOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => {
          setRemark("")
          modalOpenObj.setModalOpen(false)
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          <div className="bg-white w-[min(38.67vw,396px)] p-[min(2.44vw,25px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-18 text-[var(--color-black-2)] text-center leading-[1.8] font-[700]">
            <div className="leading-[2.2] font-[700] text-center">
              เพิ่ม Remark
            </div>
            <CustomInput
              className="mt-[min(0.98vw,10px)] w-full"
              inputprops={{
                className:
                  "rounded-[min(0.78vw,8px)] border-[min(0.10vw,1px)] border-[#b6bbc6] py-[min(1.03vw,10.5px)] px-[min(1.46vw,15px)] font-[400] text-16",
                placeholder: "",
                onChange: (e) => {
                  setRemark(e.currentTarget.value);
                  // orderRemarkObj.setOrderRemark(e.currentTarget.value);
                },
                value: remark,
                error: isError,
                maxLength: 10
              }}
            />

            <div className="flex justify-center gap-[min(2.01vw,20.63px)] mt-[min(0.98vw,10px)]">
              <CustomBtn
                onClick={() => {
                  setRemark("")
                  modalOpenObj.setModalOpen(false);
                }}
                className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-[#6D788D] font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-second-2-active`}
                btntxt={"ยกเลิก"}
              />
              <CustomBtn
                onClick={handleSubmit}
                className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-white font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-main-active`}
                btntxt={"ยืนยัน"}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
