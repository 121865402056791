import CustomBtn from "components/fragments/CustomBtn";
import React from "react";
import TableTile from "components/fragments/TableTile";

export default function FormTableMove({
  tableSeletected,
  tableMoveSelectedObj,
  tableCardList,
  onConfirm,
  onCancel,
}) {

  return (
    <div className="flex pl-[min(2.44vw,25px)] pb-[min(0.98vw,10px)] rounded-[min(1.46vw,15px)]">
      <div className="bg-[#FFF] px-[min(3.13vw,32px)] py-[min(1.27vw,13px)] shadow-table-reserved-panel w-full flex justify-between items-center">
        <div>
          <div className="text-18 text-[--color-grey-2] font-[300]">
            โต๊ะปัจจุบัน (จะเป็นโต๊ะหลัก)
          </div>
          <div className="flex items-center mt-[min(0.68vw,7px)] gap-[min(1.07vw,11px)]">
            {tableSeletected !== undefined && (
              <TableTile
                info={{
                  index: tableSeletected,
                  tableNo: tableCardList[tableSeletected].tableNo,
                }}
              />
            )}

            <div className="mx-[min(1.27vw,13px)] text-14 text-[--color-grey-2] font-[700]">
              ย้ายไป
            </div>
            <div className="w-[40vw] lg:w-[50vw] overflow-y-visible overflow-x-auto flex flex-nowrap gap-[min(1.07vw,11px)] py-[10px]">
              {tableMoveSelectedObj.selected !== undefined && (
                <TableTile
                  info={{
                    tableNo:
                      tableCardList[tableMoveSelectedObj.selected].tableNo,
                  }}
                  isMoveMode={true}
                  isCanClose={true}
                  onClose={() => tableMoveSelectedObj.setSelected(undefined)}
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-center gap-[min(0.98vw,10px)] mt-[min(2.44vw,25px)]">
          <CustomBtn
            onClick={onCancel}
            sx={{
              "&:hover": {
                boxShadow: "inherit",
              },
            }}
            className={`shadow-table-btn-2 leading-[0.8] w-fit min-w-[min(9.18vw,94px)] text-14 text-[#6D788D] font-[600] h-[min(3.32vw,34px)] rounded-[min(0.78vw,8px)] btn-second-2-active`}
            btntxt={"ยกเลิก"}
          />
          <CustomBtn
            onClick={onConfirm}
            sx={{
              "&:hover": {
                boxShadow: "inherit",
              },
            }}
            className={`shadow-table-btn-2 leading-[0.8] w-fit min-w-[min(9.18vw,94px)] text-14 text-white font-[600] h-[min(3.32vw,34px)] rounded-[min(0.78vw,8px)] ${
              tableMoveSelectedObj && (tableMoveSelectedObj.selected || tableMoveSelectedObj.selected == 0)
                ? "btn-main-active"
                : "disabled-btn"
            }`}
            btntxt={"บันทึก"}
          />
        </div>
      </div>
    </div>
  );
}
