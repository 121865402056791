import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MoneyCountController from "./MoneyCountController";
import CustomBtn from "components/fragments/CustomBtn";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { selectedPosSettingState } from "state/pos";

export default function ModalPosSettingStart({
  modalPosSettingStart,
  modalPosStartConfirm,
  startValueObj,
  details
}) {
  const handleOnSubmit = () => {
    setSelectedPosSetting(state => ({ ...state, startTime: new Date()}))
    modalPosSettingStart.setModalPosSettingStartOpen(false);
    modalPosStartConfirm.setModalOpen(true);
  };
  
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [isResetMoneyController, setIsResetMoneyController] = useState(false);
  const setSelectedPosSetting = useSetRecoilState(selectedPosSettingState);
  const resetSelectedPosSettingState = useResetRecoilState(selectedPosSettingState);

  useEffect(() => {
    if (startValueObj.startValue > 0) {
      setSelectedPosSetting(state => ({ ...state, initialAmount: Number(startValueObj.startValue)}))
      return setIsBtnActive(true);
    }
    setIsBtnActive(false);
  }, [startValueObj.startValue]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalPosSettingStart.modalPosSettingStartOpen}
        onClose={() => modalPosSettingStart.setModalPosSettingStartOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalPosSettingStart.modalPosSettingStartOpen}>
          <div className="bg-white w-[min(58.98vw,604px)] px-[min(2.44vw,25px)] pt-[min(2.44vw,25px)] pb-[min(1.46vw,15px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-20 text-[var(--color-black-2)]">
            <div className="relative">
              <button
                onClick={() => {
                  resetSelectedPosSettingState();
                  return modalPosSettingStart.setModalPosSettingStartOpen(false)
                }}
                className="absolute top-0 right-0 aspect-square w-[min(3.90vw,39.97px)] rounded-full bg-[#F5F5F5] flex justify-center items-center"
              >
                <img
                  className="aspect-square object-contain w-[min(0.98vw,10px)]"
                  src="/img/icons/icon-close.svg"
                  alt=""
                />
              </button>
            </div>
            <div className="font-[500] leading-[2]">เริ่มต้นขาย</div>
            <div>
              <MoneyCountController
                startValueObj={{
                  ...startValueObj,
                }}
                info={{
                  cashierName: `${details.posName}`,
                  staffName: `${details.userName}`,
                  calTxt: "ตั้งต้น",
                }}
                isResetObj={{
                  isReset: isResetMoneyController,
                  setIsReset: setIsResetMoneyController,
                }}
                setSelectedPosSetting={setSelectedPosSetting}
              />
              <div className="bg-[#EDEDED] w-full h-[min(0.10vw,1px)] mt-[min(1.96vw,20.12px)]"></div>
              <div className="flex justify-center">
                <CustomBtn
                  onClick={handleOnSubmit}
                  className={`leading-[0.8] min-w-[min(24.71vw,253px)] text-white font-[400] text-16 min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] mt-[min(1.56vw,16px)] mb-[min(0.98vw,10px)] ${
                    isBtnActive ? "btn-main-active" : "disabled-btn"
                  }`}
                  btntxt={"เริ่มต้นขาย"}
                />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
