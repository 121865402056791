import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import OperatorBtn from "components/fragments/OperatorBtn";
import CustomInput from "components/fragments/CustomInput";
import CustomBtn from "components/fragments/CustomBtn";
import { useNavigate } from "react-router-dom";
import { useOpenTable } from "state/table";

export default function ModalTableOpenConfirm({
  info,
  selectedUserIdObj,
  modalOpenObj,
  onSubmitHandler,
  ...props
}) {
  const navigate = useNavigate();
  const [person, setPerson] = useState(2);
  const initTime = {
    hour: "00",
    min: "00",
  };
  const [time, setTime] = useState(initTime);
  const [isSetTime, setIsSetTime] = useState(false);

  const openTable = useOpenTable();

  const onOkayBtnHandler = async () => {
    const updateDetail = {
      is_active: true,
      isActive: true,
      guest: person,
      isLimitTime: isSetTime,
      limitHours: time.hour,
      limitMinutes: time.min,
    };
    // updateTable(updateDetail);
    if(info.table) {
      await openTable(updateDetail, info.table);
      setIsSetTime(false)
    } else {
      await openTable(updateDetail);
      setIsSetTime(false)
    }
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => modalOpenObj.setModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          <div className="bg-white min-w-[min(38.09vw,390px)] p-[min(2.44vw,25px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-18 text-[var(--color-black-2)] text-center">
            <div className="text-22 font-[700]">ยืนยันการเปิดโต๊ะ</div>
            <div className="mt-[min(1.95vw,20px)] text-24 font-[700] text-[var(--color-green)]">
              โต๊ะ <span className="prompt">{info.tableNo}</span>
            </div>
            <div className="flex justify-center items-center mt-[min(2.05vw,21px)]">
              <div className="text-16 mr-[min(4.00vw,41px)]">จำนวนคน</div>
              <div className="flex items-center gap-[min(2.44vw,25px)]">
                <OperatorBtn
                  onClick={() => setPerson((prev) => prev - 1)}
                  className={`aspect-square min-w-[min(3.13vw,32px)] h-fit btn-grey-5`}
                  content={
                    <div>
                      <img
                        className="object-contain aspect-square w-[min(0.78vw,8px)]"
                        src="/img/icons/icon-minus-black.svg"
                        alt=""
                      />
                    </div>
                  }
                  isActive={person > 0}
                />
                <div className="poppins text-20 font-[600]">{person}</div>
                <OperatorBtn
                  onClick={() => setPerson((prev) => prev + 1)}
                  className={`aspect-square min-w-[min(3.13vw,32px)] h-fit btn-grey-5`}
                  content={
                    <div>
                      <img
                        className="object-contain aspect-square w-[min(0.78vw,8px)]"
                        src="/img/icons/icon-plus-black.svg"
                        alt=""
                      />
                    </div>
                  }
                  isActive={person < info.maxPerson}
                />
              </div>
            </div>
            <div className="flex justify-center mt-[min(1.56vw,16px)] gap-[min(2.83vw,29px)]">
              <div className="custom-input-checkbox">
                <input
                  type="checkbox"
                  value={isSetTime}
                  onChange={() => setIsSetTime(!isSetTime)}
                />
                <label className="gap-[min(2.83vw,29px)] text-18 font-[500]">
                  กำหนดเวลา
                </label>
              </div>
              <div className="flex items-center gap-[min(0.29vw,3px)]">
                <div className="w-[min(6.54vw,67px)]">
                  <CustomInput
                    inputprops={{
                      type: "text",
                      className: `modal-table-input text-center no-spinner text-18 ${
                        isSetTime ? "" : "disabled-input pointer-events-none"
                      }`,
                      value: time.hour,
                      onChange: (e) => {
                        if (!e.currentTarget.value) {
                          inputValue = 0;
                        }
                        let inputValue = parseInt(e.currentTarget.value);
                        if (isNaN(inputValue)) {
                          inputValue = 0;
                        }
                        if (inputValue > 24) {
                          inputValue = 24;
                        }
                        if (inputValue < 0) {
                          inputValue = 0;
                        }
                        setTime({
                          ...time,
                          hour: inputValue < 10 ? "0" + inputValue : inputValue,
                        });
                      },
                      onClick: (e) => {
                        const input = e.target;
                        input.select();
                      },
                    }}
                  />
                </div>
                <div className="poppins text-16">:</div>
                <div className="w-[min(6.54vw,67px)]">
                  <CustomInput
                    inputprops={{
                      type: "text",
                      className: `modal-table-input text-center no-spinner text-18 ${
                        isSetTime ? "" : "disabled-input pointer-events-none"
                      }`,
                      value: time.min,
                      onChange: (e) => {
                        if (!e.currentTarget.value) {
                          inputValue = 0;
                        }
                        let inputValue = parseInt(e.currentTarget.value);
                        if (isNaN(inputValue)) {
                          inputValue = 0;
                        }
                        if (inputValue > 59) {
                          inputValue = 59;
                        }
                        if (inputValue < 0) {
                          inputValue = 0;
                        }
                        setTime({
                          ...time,
                          min: inputValue < 10 ? "0" + inputValue : inputValue,
                        });
                      },
                      onClick: (e) => {
                        const input = e.target;
                        input.select();
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center gap-[min(2.44vw,25px)] mt-[min(1.46vw,15px)]">
              <CustomBtn
                onClick={() => {
                  modalOpenObj.setModalOpen(false);
                }}
                className={`leading-[0.8] w-full text-[#6D788D] font-[600] min-h-[min(5.57vw,57px)] rounded-[min(0.78vw,8px)] btn-second-2-active text-18`}
                btntxt={"ยกเลิก"}
              />
              <CustomBtn
                onClick={async () => {
                  // if(onSubmitHandler) {
                  //   onSubmitHandler
                  // }
                  await onOkayBtnHandler();
                  modalOpenObj.setModalOpen(false);
                  setPerson(2);
                }}
                className={`leading-[0.8] w-full text-white font-[600] min-h-[min(5.57vw,57px)] rounded-[min(0.78vw,8px)] btn-main-active text-18`}
                btntxt={"ตกลง"}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
