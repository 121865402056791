import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import faker from "faker";
import { numberNoDecimals } from "utils/numberFormat";

export default function SumDoughnutCard({ info }) {
  // console.log("🚀 ~ SumDoughnutCard ~ info:", info)
  ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    cutout: "90%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        display: false,
      },
    },
    spacing: 3,
    borderRadius: 9999,
  };

  const data = {
    labels: info.labels,
    datasets: [
      {
        labels: info.labels,
        // data: info.labels.map(() =>
        //   faker.datatype.number({ min: 1000, max: 3000 })
        // ),
        data: info.data?.map((d) => d.total) ?? [],
        borderColor: ["#249F57", "#1B7741", "#124F2B"],
        backgroundColor: ["#249F57", "#1B7741", "#124F2B"],
      },
    ],
  };

  const [doughnutLabel, setdoughnutLabel] = useState({
    id: "doughnutLabel",
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;
      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0]?.x;
      const yCoor = chart.getDatasetMeta(0).data[0]?.y;
      ctx.font = "bold min(1.37vw,14px) sans-serif";
      ctx.fillStyle = "#FFFFF";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        numberNoDecimals(
          // chartData.datasets[0].data.reduce(
          //   (accumulator, currentValue) => accumulator + currentValue,
          //   0
          // )
          info.data.reduce((accumulator, currentValue) =>  accumulator + currentValue.total, 0)
        ),
        xCoor,
        yCoor - 8
      );

      ctx.font = "min(0.78vw,8px) sans-serif";
      ctx.fillStyle = "#696D6E";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(info.unitTxt, xCoor, yCoor + 8);
      // ctx.save();
    },
  });

  const [chartData, setChartData] = useState(data);
  // console.log("🚀 ~ SumDoughnutCard ~ chartData:", chartData)

  useEffect(() => {
    if (info.data && info.data.length > 0) {
      setChartData({
        labels: info.labels,
        datasets: [
          {
            labels: info.labels,
            // data: info.labels.map(() =>
            //   faker.datatype.number({ min: 1000, max: 3000 })
            // ),
            // data: info.labels.map((d, i) => info.data[i].total),
            data: info.labels.map((l, i) => info.data.find(d => d.name == l).total),
            borderColor: ["#249F57", "#1B7741", "#124F2B"],
            backgroundColor: ["#249F57", "#1B7741", "#124F2B"],
          },
        ],
      });
    }
  }, [info])

  useEffect(() => {
    if (chartData) {
      if (chartData.datasets.length > 0) {
        setdoughnutLabel({
          id: "doughnutLabel",
          beforeDatasetsDraw(chart, args, pluginOptions) {
            const { ctx, data } = chart;
            ctx.save();
            const xCoor = chart.getDatasetMeta(0).data[0].x;
            const yCoor = chart.getDatasetMeta(0).data[0].y;
            ctx.font = "bold min(1.37vw,14px) sans-serif";
            ctx.fillStyle = "#FFFFF";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(
              numberNoDecimals(
                // chartData.datasets[0].data.reduce(
                //   (accumulator, currentValue) => accumulator + currentValue,
                //   0
                // )
                info.data.reduce((accumulator, currentValue) =>  accumulator + currentValue.total, 0)
              ),
              xCoor,
              yCoor - 8
            );

            ctx.font = "min(0.78vw,8px) sans-serif";
            ctx.fillStyle = "#696D6E";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(info.unitTxt, xCoor, yCoor + 8);
          },
        })
      }
    }
  }, [chartData])

  return (
    <div className="shadow-3-3 bg-[#FFF] aspect-[289/148] w-[min(28.22vw,289px)] h-fit rounded-[min(0.62vw,6.31px)] py-[min(1.50vw,15.31px)] px-[min(1.93vw,19.77px)]">
      <div className="text-14 font-[700]">{info.headline}</div>
      <div className="flex gap-[min(1.66vw,17px)] mt-[min(1.83vw,18.69px)] items-center">
        <div className="w-[min(7.81vw,80px)] h-[min(7.81vw,80px)] relative">
          <Doughnut
            options={options}
            data={chartData}
            plugins={[doughnutLabel]}
            redraw
          />
          {/* <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center">
            <div className="text-14 font-[700]">
              {numberNoDecimals(
                chartData.datasets[0].data.reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )
              )}
            </div>
            <div className="text-8 text-[#696D6E]">{info.unitTxt}</div>
          </div> */}
        </div>
        <div className="flex-grow-1">
          {chartData?.datasets[0]?.data?.map((v, i) => {
            return (
              <div key={i} className="flex justify-between">
                <div className="flex items-center gap-[min(0.30vw,3.05px)]">
                  <div
                    style={{
                      backgroundColor: chartData.datasets[0].backgroundColor[i],
                    }}
                    className={`rounded-full aspect-square w-[min(0.68vw,7px)]`}
                  ></div>
                  <div className="text-14 font-[400]">
                    {chartData.datasets[0].labels[i]}
                  </div>
                </div>
                <div className="text-[var(--color-green)] text-14 font-[700]">
                  {numberNoDecimals(chartData.datasets[0].data[i])}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
