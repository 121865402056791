import React from "react";

export default function MemberSelectedCard({ info, handleClear }) {
  return (
    <div
      className={`bg-[#FFF] pl-[min(1.50vw,15.35px)] pr-[min(1.46vw,15px)] py-[min(0.59vw,6px)] flex items-center justify-between rounded-[min(1.41vw,14.39px)] border-[var(--color-green)] border-[min(0.10vw,1px)]`}
    >
      <div className="flex items-center">
        <div className="flex items-center">
          <div className="flex flex-col items-center mr-[min(1.27vw,13px)]">
            <img
              className="object-cover aspect-square w-[min(2.44vw,25px)] h-fit rounded-full"
              src={info.avatar}
              alt=""
            />
            <div className="flex items-center gap-[min(0.20vw,2.09px)]">
              <img
                className="object-contain aspect-[6.91/6.62] w-[min(0.67vw,6.91px)] h-fit"
                src={"/img/icons/icon-star-yellow.png"}
                alt=""
              />
              <div className="text-10 font-[400] leading-[1.4] tracking-[0.04em] text-[rgba(76,78,100,0.6)]">
                {info.point}
              </div>
            </div>
          </div>

          <div className="text-15 w-[min(14.84vw,152px)] pr-[min(0.49vw,5px)]">
            <div className="text-13 text-[rgba(76,78,100,0.87)] tracking-[0.015em]">
              {info.name}
            </div>
            <div className="flex gap-[min(0.39vw,4px)]">
              <div className="text-12 text-[rgba(76,78,100,0.6)] tracking-[0.04em]">
                {info.phoneNumber}
              </div>
              <div className="flex items-center justify-center rounded-full prompt font-[300] bg-[var(--color-blue-2)] min-w-[min(4.88vw,50px)] h-[min(1.58vw,16.17px)] text-[#FFF] text-12 uppercase">
                {info.position}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div onClick={handleClear} className="cursor-pointer">
        <img
          className="object-contain aspect-square w-[min(2.34vw,24px)] h-fit"
          src="/img/icons/icon-not-found-red.svg"
          alt=""
        />
      </div>
    </div>
  );
}
