import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomBtn from "components/fragments/CustomBtn";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { posState, selectedPosSettingState } from "state/pos";
import { useCreatePosActivity } from "state/pos";
import PosStartPrintPage from "components/pages/PosStartPrintPage";
import { numberWithTwoDecimals } from "utils/numberFormat";
import { appState } from "state/app";

export default function ModalPosStartConfirm({
  modalOpenObj,
  details,
  logMachine,
  ...props
}) {

  const navigate = useNavigate();
  const { slug, slugBranch } = useRecoilValue(appState);
  const [isPrint, setIsPrint] = useState(false);
  const selectedPosSetting = useRecoilValue(selectedPosSettingState);
  const { isPosStarted } = useRecoilValue(posState);
  const createPosActivity = useCreatePosActivity();

  const onSubmitHandler = async () => {
    await createPosActivity(selectedPosSetting, logMachine);
    navigate(`/${slug}/${slugBranch}/`);
  };

  const onPrintHandler = () => {
    setIsPrint(true);
  };

  useEffect(() => {
    if (isPosStarted) {
      navigate("/");
    }
  }, [isPosStarted]);

  useEffect(() => {
    if(isPrint) {
      window.print();
    }
  }, [isPrint])

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => modalOpenObj.setModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          {!isPrint ? (
            <div className="bg-white w-[min(37.11vw,380px)] p-[min(2.44vw,25px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-16 text-[var(--color-black-2)]">
              <div className="text-18 font-[700] leading-[2.2]">
                คุณต้องการยืนยันการเข้าใช้งาน
              </div>
              <div className="mt-[min(1.46vw,15px)] flex flex-col gap-[min(1.17vw,12px)]">
                <div className="flex justify-between gap-[min(0.98vw,10px)]">
                  <div className="whitespace-nowrap text-[var(--color-grey-2)]">
                    ชื่อธุรกิจ :
                  </div>
                  <div className="truncate">{details.businessName}</div>
                </div>
                <div className="flex justify-between gap-[min(0.98vw,10px)]">
                  <div className="whitespace-nowrap text-[var(--color-grey-2)]">
                    ชื่อสาขา :
                  </div>
                  <div className="truncate">{details.branch}</div>
                </div>
                <div className="flex justify-between gap-[min(0.98vw,10px)]">
                  <div className="whitespace-nowrap text-[var(--color-grey-2)]">
                    พนักงาน :
                  </div>
                  <div className="truncate">{details.userName}</div>
                </div>
                <div className="flex justify-between gap-[min(0.98vw,10px)]">
                  <div className="whitespace-nowrap text-[var(--color-grey-2)]">
                    วันที่/เวลา :
                  </div>
                  <div className="truncate">{details.startTime}</div>
                </div>
                <div className="flex justify-between gap-[min(0.98vw,10px)]">
                  <div className="whitespace-nowrap text-[var(--color-grey-2)]">
                    POS :
                  </div>
                  <div className="truncate">{details.posName}</div>
                </div>
                <div className="flex justify-between gap-[min(0.98vw,10px)]">
                  <div className="whitespace-nowrap text-[var(--color-grey-2)]">
                    ยอดเงินสดตั้งต้น :
                  </div>
                  <div className="truncate">
                    {numberWithTwoDecimals(selectedPosSetting.amountTotal)}
                  </div>
                </div>
              </div>
              <div className="bg-[#EDEDED] w-full h-[min(0.10vw,1px)] mt-[min(1.46vw,15px)] mb-[min(2.44vw,25px)] leading-[1]"></div>
              <div className="flex justify-center gap-[min(2.01vw,20.63px)]">
                <CustomBtn
                  onClick={() => {
                    modalOpenObj.setModalOpen(false);
                  }}
                  className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-[#6D788D] font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-second-2-active`}
                  btntxt={"ยกเลิก"}
                />
                <CustomBtn
                  onClick={() => {
                    // navigate("/");
                    onPrintHandler();
                  }}
                  className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-white font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-main-active`}
                  btntxt={"ยืนยัน"}
                />
              </div>
            </div>
          ) : (
            <div className="bg-white w-[min(37.11vw,480px)] p-[min(2.44vw,25px)] absolute left-[50%] translate-x-[-50%] rounded-[min(1.46vw,15px)] text-16 text-[var(--color-black-2)]">
              <div className="bg-[#EFEFEF]  pt-[4.00vw] ">
                <div className="flex justify-center text-[0.78vw] w-full">
                  <div
                    id={"section-to-print"}
                    className="w-[21.48vw] bg-[#FFF] px-[1.70vw] py-[1.31vw] flex flex-col gap-[0.59vw] "
                  >
                    <div className="text-center font-[600]">
                      <div>{details.businessName}</div>
                      <div>{details.branch}</div>
                      {/* <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div> */}
                    </div>
                    <div className="flex flex-col gap-[0.59vw]">
                      <div className="flex justify-between gap-[0.98vw] ">
                        <div className="whitespace-nowrap">
                          วันที่ :
                          {" "}{details.startTime}
                        </div>
                        <div className="text-right">POS#: {selectedPosSetting.posCode}</div>
                      </div>
                      <div className="flex justify-between gap-[0.98vw] ">
                        <div className="whitespace-nowrap">
                          POS NO :{" "}
                          {selectedPosSetting.posOrder}
                        </div>
                        <div className="text-right">พนักงาน : {details.userName}</div>
                      </div>
                      <div className="flex justify-between gap-[0.98vw] ">
                        <div className="whitespace-nowrap">เวลาเริ่มขาย</div>
                        <div className="text-right">{details.startTime}</div>
                      </div>
                      <div className="border-dashed border-t-[0.04vw] border-black h-0 "></div>
                    </div>

                    <div className="flex flex-col gap-[0.59vw] text-center">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap">เงินสดตั้งต้น</div>
                        <div>จำนวน</div>
                        <div>รวม</div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap">1,000</div>
                        <div>{selectedPosSetting.amount1000}</div>
                        <div>{numberWithTwoDecimals(Number(selectedPosSetting.amount1000 * 1000))}</div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap">500</div>
                        <div>{selectedPosSetting.amount500}</div>
                        <div>{numberWithTwoDecimals(Number(selectedPosSetting.amount500 * 500))}</div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap">100</div>
                        <div>{selectedPosSetting.amount100}</div>
                        <div>{numberWithTwoDecimals(Number(selectedPosSetting.amount100 * 100))}</div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap">50</div>
                        <div>{selectedPosSetting.amount50}</div>
                        <div>{numberWithTwoDecimals(Number(selectedPosSetting.amount50 * 50))}</div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap">20</div>
                        <div>{selectedPosSetting.amount20}</div>
                        <div>{numberWithTwoDecimals(Number(selectedPosSetting.amount20 * 20))}</div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap">10</div>
                        <div>{selectedPosSetting.amount10}</div>
                        <div>{numberWithTwoDecimals(Number(selectedPosSetting.amount10 * 10))}</div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap">5</div>
                        <div>{selectedPosSetting.amount5}</div>
                        <div>{numberWithTwoDecimals(Number(selectedPosSetting.amount5 * 5))}</div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap">1</div>
                        <div>{selectedPosSetting.amount1}</div>
                        <div>{numberWithTwoDecimals(Number(selectedPosSetting.amount1 * 1))}</div>
                      </div>

                      <div className="flex justify-between gap-[0.98vw] ">
                        <div className="whitespace-nowrap">
                          ยอดเงินสดตั้งต้น :
                        </div>
                        <div className="text-right font-[700]">{numberWithTwoDecimals(selectedPosSetting.amountTotal)}</div>
                      </div>
                      <div className="border-dashed border-t-[0.04vw] border-black h-0 "></div>

                    </div>
                  </div>
                </div>
                <div className="flex justify-center py-[min(3.42vw,35px)] gap-[min(2.01vw,20.63px)]">
                  <CustomBtn
                    onClick={() => {
                      onSubmitHandler();
                    }}
                    className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-[#6D788D] font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-second-2-active`}
                    btntxt={"ต่อไป"}
                  />
                  <CustomBtn
                    onClick={() => window.print()}
                    className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-white font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-main-active`}
                    btntxt={"Print"}
                  />
                </div>
              </div>
            </div>
          )}
          {/* <div className="mt-[min(1.46vw,15px)] flex flex-col gap-[min(1.17vw,12px)]">
              <div className="flex justify-between gap-[min(0.98vw,10px)]">
                <div className="whitespace-nowrap text-[var(--color-grey-2)]">
                  ชื่อธุรกิจ :
                </div>
                <div className="truncate">{details.businessName}</div>
              </div>
              <div className="flex justify-between gap-[min(0.98vw,10px)]">
                <div className="whitespace-nowrap text-[var(--color-grey-2)]">
                  ชื่อสาขา :
                </div>
                <div className="truncate">{details.branch}</div>
              </div>
              <div className="flex justify-between gap-[min(0.98vw,10px)]">
                <div className="whitespace-nowrap text-[var(--color-grey-2)]">
                  พนักงาน :
                </div>
                <div className="truncate">{details.userName}</div>
              </div>
              <div className="flex justify-between gap-[min(0.98vw,10px)]">
                <div className="whitespace-nowrap text-[var(--color-grey-2)]">
                  วันที่/เวลา :
                </div>
                <div className="truncate">{details.startTime}</div>
              </div>
              <div className="flex justify-between gap-[min(0.98vw,10px)]">
                <div className="whitespace-nowrap text-[var(--color-grey-2)]">
                  POS :
                </div>
                <div className="truncate">{details.posName}</div>
              </div>
              <div className="flex justify-between gap-[min(0.98vw,10px)]">
                <div className="whitespace-nowrap text-[var(--color-grey-2)]">
                  ยอดเงินสดตั้งต้น :
                </div>
                <div className="truncate">{selectedPosSetting.amountTotal}</div>
              </div>
            </div>
            <div className="bg-[#EDEDED] w-full h-[min(0.10vw,1px)] mt-[min(1.46vw,15px)] mb-[min(2.44vw,25px)] leading-[1]"></div>
            <div className="flex justify-center gap-[min(2.01vw,20.63px)]">
              <CustomBtn
                onClick={() => {
                  modalOpenObj.setModalOpen(false);
                }}
                className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-[#6D788D] font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-second-2-active`}
                btntxt={"ยกเลิก"}
              />
              <CustomBtn
                onClick={() => {
                  // navigate("/");
                  onSubmitHandler();
                }}
                className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-white font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-main-active`}
                btntxt={"ยืนยัน"}
              />
            </div> */}
        </Fade>

      </Modal>
    </div>
  );
}
