import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

function Arrow({ disabled, onClick, ...props }) {
  React.useContext(VisibilityContext);
  return (
    <button
      {...props}
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        opacity: disabled ? "0" : "1",
        userSelect: "none",
        height: "100%",
      }}
    ></button>
  );
}

export function LeftArrow() {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    if(visibleElements.length === 1 || visibleElements.length === 0) {
      return setDisabled(true);
    }
    setDisabled(isFirstItemVisible);
  }, [isFirstItemVisible, visibleElements]);

  return (
    <Arrow
      className={"custom-scroll-prev"}
      disabled={disabled}
      onClick={() => scrollPrev()}
    />
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !visibleElements.length && isLastItemVisible
  );

  React.useEffect(() => {
    if(visibleElements.length === 1 || visibleElements.length === 0) {
      return setDisabled(true);
    }
    setDisabled(isLastItemVisible);
  }, [isLastItemVisible, visibleElements]);

  return (
    <Arrow
      className={"custom-scroll-next"}
      disabled={disabled}
      onClick={() => scrollNext()}
    />
  );
}
