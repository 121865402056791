import React, { useEffect, useState } from "react";

export default function TableTile({
  info,
  isMergeMode = false,
  isMoveMode = false,
  isCanClose = false,
  onClose,
}) {
  const initTheme = {
    bgColor: "bg-[var(--color-blue-2)] ",
    color: "text-[#FFF]",
  };

  const [theme, setTheme] = useState(initTheme);
  const orangeModeTheme = () =>
    setTheme({
      bgColor: "bg-[var(--color-orange)] ",
      color: "text-[#FFF]",
    });

  useEffect(() => {
    if (isMergeMode || isMoveMode) {
      orangeModeTheme();
    }
  }, [isMergeMode, isMoveMode, isCanClose]);

  return (
    <div
      className={`relative ${theme.bgColor} ${theme.color} prompt text-26 font-[500] min-w-[min(7.62vw,78px)] min-h-[min(4.10vw,42px)] w-fit py-[min(0.15vw,1.5px)] rounded-[min(0.78vw,8px)] flex justify-center items-center shadow-table-btn-2`}
      style={{userSelect: 'none'}}
    >
      {isCanClose && (
        <div
          onClick={onClose}
          className="z-1 absolute top-[-20%] right-[15%] w-0 h-0"
        >
          <div className="aspect-square w-[min(1.86vw,19.06px)] bg-[#F00] rounded-full flex justify-center items-center cursor-pointer">
            <img
              className="aspect-square h-fit object-contain w-[min(0.63vw,6.48px)]"
              src="/img/icons/icon-close-white.svg"
              alt=""
            />
          </div>
        </div>
      )}

      <div>{info.tableNo}</div>
    </div>
  );
}
