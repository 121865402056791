import CustomPlainBtn from "components/fragments/CustomPlainBtn";
import KitchenTable from "components/layouts/KitchenTable";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import { posState } from "state/pos";
import { orderState, useFetchAllOrder } from "state/order";

export default function KitchenHistoryPage() {
  const [tabList, setTabList] = useState([
    "ออเดอร์ที่สำเร็จ",
    "ออเดอร์ที่ยกเลิก",
  ]);
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();

  const { user } = useRecoilValue(authState);
  const fetchAllOrder = useFetchAllOrder();
  const { orders } = useRecoilValue(orderState);
  const { currentPOS, currentPOSActivity } = useRecoilValue(posState);

  const [orderDetailHistories, setOrderDetailHistories] = useState([])

  useEffect(() => {
    if(user.branchId && currentPOS.id) {
      // fetchAllOrder();
      fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date, true)
    }
  }, [user,currentPOS])

  useEffect(() => {
    if(orders && orders.length > 0) {
      const parsed = orders
        .filter(
          // order => order.table_activity.table.zone.branch_id == user.branchId && 
          order => 
            // order.orderDetail[0].branch_product.branch_id == user.branchId &&
            order.orderDetail?.filter(od => od.branch_product.branch_id == user.branchId).length > 0 &&
            order.status_id != 4
        )
        .map(order => {
          const newOrderDetail = [...order.orderDetail]
            .filter(od => od.kitchenId == currentPOS.kitchen.id && od.status_id == 2)
            .map(od => ({
            ...od,
            unit: od.cancel_amount && od.cancel_amount > 0 ? od.amount - od.cancel_amount : od.amount,
            name: od.branch_product.product.name,
            addOnList: od.OrderDetailoptionBinding.length > 0 ? od.OrderDetailoptionBinding.map(odb => odb.branch_product_option_detail_binding.product_option_detail.name) : [], 
            ignoreList: [],
            isCancel: od.status_id == 3 ? true : false,
            isServed: od.status_id == 2 ? true : false,
            isCompleted: od.status_id == 2 ? true : false,
            tableNo: order.table_activity?.table?.no ?? "-",
            timeServ: {
              pos: order.pos ? order.pos.code : "",
              orderNo: order.orderNo,
              time: od.updated_at ? new Date(od.updated_at).toLocaleTimeString("th-TH", { hourCycle: "h23", hour:"numeric", minute: "numeric" }).replace(":", ".") + " น." : "",
              isCancel: od.status_id == 3 ? true : false,
            },
            details: {
              addOnList: od.OrderDetailoptionBinding.length > 0 ? od.OrderDetailoptionBinding.map(odb => ({ name: odb.branch_product_option_detail_binding.product_option_detail.name, amount: odb.amount })) : [], 
              ignoreList: [],
            },
            delivery: order.delivery ? order.delivery : order.type_id,
            remark: order.remark,
            delivery_remark: order.delivery_remark,
            delivery_remark_channel: order.delivery_remark_channel,
          }));
          const newOrderDetailCancel = [...order.orderDetail]
            .filter(od => od.kitchenId == currentPOS.kitchen.id && (od.cancel_amount > 0 || od.status_id == 3))
            .map(od => ({
            ...od,
            unit: od.cancel_amount,
            name: od.branch_product.product.name,
            addOnList: od.OrderDetailoptionBinding.length > 0 ? od.OrderDetailoptionBinding.map(odb => odb.branch_product_option_detail_binding.product_option_detail.name) : [], 
            ignoreList: [],
            isCancel: true,
            isServed: false,
            isCompleted: false,
            tableNo: order.table_activity?.table?.no ?? "-",
            timeServ: {
              pos: order.pos ? order.pos.code : "",
              orderNo: order.orderNo,
              time: od.updated_at ? new Date(od.updated_at).toLocaleTimeString("th-TH", { hourCycle: "h23", hour:"numeric", minute: "numeric" }).replace(":", ".") + " น." : "",
              isCancel: true,
            },
            details: {
              addOnList: od.OrderDetailoptionBinding.length > 0 ? od.OrderDetailoptionBinding.map(odb => ({ name: odb.branch_product_option_detail_binding.product_option_detail.name, amount: odb.amount })) : [], 
              ignoreList: [],
            },
            delivery: order.delivery ? order.delivery : order.type_id,
            remark: order.remark,
            delivery_remark: order.delivery_remark,
            delivery_remark_channel: order.delivery_remark_channel,
          }));
          return [newOrderDetail, newOrderDetailCancel]
        });
      setOrderDetailHistories([...parsed.flat().flat()]);
    }
  }, [orders])

  return (
    <div>
      {/* header */}
      <div className="py-[min(2.60vw,26.62px)] px-[min(2.26vw,23.12px)] flex">
        <button onClick={() => navigate(-1)} className="flex justify-center items-center aspect-square w-[min(3.01vw,30.8px)] h-fit border-[black] border-[min(0.10vw,1px)] rounded-[min(0.39vw,4px)] mr-[min(6.65vw,68.12px)]">
          <img
            className="object-contain aspect-[15.4/8.79] w-[min(1.50vw,15.4px)] h-fit"
            src="/img/icons/icon-arrow-left-black.svg"
            alt=""
          />
        </button>
        <div className="flex gap-[min(1.95vw,20px)] mt-[min(2.28vw,23.38px)]">
          {tabList.map((value, index) => {
            return (
              <CustomPlainBtn
                key={index}
                onClick={() => {
                  if (tabIndex !== index) {
                    setTabIndex(index);
                  }
                }}
                className={`px-[min(2.47vw,25.25px)] h-[min(4.98vw,51px)] rounded-[min(0.78vw,8px)] text-18 ${
                  tabIndex === index
                    ? "btn-tab-green-active"
                    : "btn-tab-green-none-active "
                }`}
                btntxt={value}
              />
            );
          })}
        </div>
      </div>

      {/* body */}
      <KitchenTable tabIndex={tabIndex} orderHistories={orderDetailHistories}/>
    </div>
  );
}
