import React from "react";

export default function TableCountDisplay({ info }) {
  return (
    <>
      <div className="shadow-table-btn px-[min(1.07vw,11px)] bg-[#FFF] rounded-[min(0.49vw,5px)] h-[min(4.79vw,49px)] flex items-center">
        <div>
          <div className="text-10 text-[var(--color-grey-2)] tracking-[0.4px] whitespace-nowrap">
            {info.headTxt}
          </div>
          <div className="flex mt-[min(0.59vw,6px)] gap-[min(0.88vw,9px)]">
            <div
              className={`h-fit ${info.iconClassColor} aspect-square w-[min(1.86vw,19px)] flex justify-center items-center rounded-[min(0.29vw,3px)]`}
            >
              <img
                className="aspect-[13/10] w-[min(1.27vw,13px)] object-contain"
                src={info.iconPath}
                alt=""
              />
            </div>
            <div className="text-20 leading-[1.12]">{info.countTxt}</div>
          </div>
        </div>
      </div>
    </>
  );
}
