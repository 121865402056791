import axiosInstance from './axios';
import checkImage from 'utils/checkImage';

export const getAllPaymentChannel = async (branchId) => {
    try {
        const queryParams = {
            branch_id: branchId
        }
        const response = await axiosInstance.get('/pos-payment', { params: queryParams });
        // custom response for paymentChannels
        response.data = response.data.map((item) => {
            return {
                ...item,
                delivery_image: checkImage.checkURL(item.delivery_image)
            }
        })

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            paymentChannels: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const createPaymentHistory = async (paymentHistory) => {
    try {
        const payload = {
            bill_id: paymentHistory.billId,
            paymentchannel_id: paymentHistory.paymentChannelID,
            paymentstatus_id: paymentHistory.paymentStatusID,
            paid_amount: paymentHistory.paidAmount,
            change_amount: paymentHistory.changeAmount,
            credit_charge_percent: paymentHistory.feePercent,
            credit_charge_amount: paymentHistory.feeAmount,
            is_via_delivery: paymentHistory.isViaDelivery ?? false,
            delivery_id: paymentHistory.deliveryId ?? null
        }
        const response = await axiosInstance.post('/payment-histories', payload);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            paymentChannels: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}