// import { de } from "faker/lib/locales";
import React, {useState, useEffect, useMemo} from "react";
import { numberWithTwoDecimals } from "utils/numberFormat";

const baseAPI = "https://uat-api.allder.co/";

export const BillPaperToPrint = React.forwardRef((props, ref) => {
  const { type, pos, order, bill, branch, user, copy, endBillDiscount } = props;

  const [orderToDisplay, setOrderToDisplay] = useState(null);

  // const countNotIncludeVat = (o) => {
  //   console.log("🚀 ~ countNot IncludeVat ~ o:", o)
  //   const detail = o.orderDetail;
  //   let total = 0;

  //   if (detail?.length > 0) {
  //     detail.map((d, di) => {
  //       const product = d.branch_product.product;
  //       const price = d.sumPrice;

  //       if (!product.is_include_vat) {
  //         total = total + price;
  //       }
  //     });
  //   }
  //   return Number(total).toFixed(2);
  // };

  const encryptNum = (num) => {
    num = num.replace(/[-,\s]/g, "");
    return `${num.substring(0, 3)}-${num.substring(3, 4)}XX-XXXX`;
  };

  useMemo(() => {
    if(props.order) {
      if(order.product?.length > 0) {
        const filtered = order.product.filter(prod => prod.orderID == order.id || prod.orderID == order.orderID || prod.orderID == order.orderId);
        setOrderToDisplay({
          product: filtered
        });
      }
    }
  }, [props.order])

  return (
    <div ref={ref} className="flex justify-center text-[0.78vw] w-full">
      <div
        id={"section-to-print"}
        className="w-[21.48vw] bg-[#FFF] px-[1.70vw] py-[1.31vw] flex flex-col gap-[0.59vw] "
      >
        <div className="text-center font-[600]">
          {type == "invoice" && (
            <>
              {branch?.business && (
                <div className="flex justify-center">
                  <img
                    src={`${baseAPI}${branch.business.businessdetail.img_url}`}
                  ></img>
                </div>
              )}
              <div>ใบแจ้งยอด</div>
              <div className="flex justify-between gap-[0.98vw] ">
                <div className="whitespace-nowrap">
                  วันที่ :
                  {
                    order?.start_time ?
                      ` ${new Date(order.start_time).toLocaleDateString("en-GB")} ${new Date(order.start_time).toLocaleTimeString()}` :
                    order?.operation_date ?
                      ` ${new Date(order.operation_date).toLocaleDateString("en-GB")} ${new Date(order.operation_date).toLocaleTimeString()}` : 
                    ""
                  }
                </div>
                <div className="text-right">POS#: {bill?.pos && bill.pos.code}</div>
              </div>
              <div className="flex justify-between gap-[0.98vw] ">
                <div className="whitespace-nowrap">
                  พนักงานขาย :{" "}
                  {
                    order?.employee_id && order?.employee  ?
                      `${order.employee.first_name_th}` :
                    // user.firstNameTH ?
                    //   `${user.firstNameTH} ${user.lastNameTH}` :
                    "-"
                  }
                </div>
                <div className="text-right">
                  โต๊ะ:
                  {order?.delivery == 1 ?
                    order?.table_activity ? ` ${order?.table_activity?.table.zone.alias} / ${order?.table_activity?.table.no}` : '-' : null}
                  {order?.delivery == 2 && " กลับบ้าน "}
                  {order?.delivery == 3 && " เดลิเวอรี่ "}
                </div>
              </div>
            </>
          )}

          {type == "receipt" && (
            <>
              {branch?.business && (
                <div className="flex justify-center">
                  <img
                    className="max-w-150px max-h-150px"
                    src={`${baseAPI}${branch.business.businessdetail.img_url}`}
                    width={150}
                    height={150}
                  ></img>
                </div>
              )}
              <div className="flex justify-center gap-[1.07vw] w-[90%]">
                <div className="text-left  w-full">
                  <div className="flex justify-between">
                    {/* บริษัท อินเตอร์ คอฟฟี่เทรด จำกัด */}
                    {branch?.business && (
                      <div className="whitespace-nowrap">
                        {branch.business.businessdetail.prefix}{" "}
                        {branch.business.businessdetail.name}{" "}
                        {branch.business.businessdetail.suffix}
                      </div>
                    )}
                    {!copy && (
                      <span className="w-[40%]">
                        {branch?.name} ({branch?.code})
                      </span>
                    )}
                  </div>
                  <div className="flex justify-between">
                    TAX ID :{" "}
                    {branch?.business?.businessdetail?.taxpaper_id_number}
                    {(copy && order?.billStatus == 2) && (
                      <span className="font-[500] w-[40%]">
                        (สำเนาครั้งที่ {order?.receiptArchiveLogNo ? order.receiptArchiveLogNo + 1 : order?.receipt_acrhive_no1 ? order.receipt_acrhive_no : 1})
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div>ใบเสร็จรับเงิน</div>
              <div className="flex justify-between gap-[0.98vw] ">
                <div className="whitespace-nowrap">
                  วันที่ :
                  {bill?.created_at &&
                    ` ${new Date(bill?.created_at).toLocaleDateString("en-GB")} ${new Date(bill?.created_at).toLocaleTimeString()}`}
                </div>
                <div className="text-right">POS#: {bill?.pos ? bill.pos.code : pos?.code ? pos.code : ""}</div>
              </div>
              <div className="flex justify-between gap-[0.98vw] ">
                <div className="whitespace-nowrap">เลขที่ : {order?.receiptNo && order?.receiptNo != "-" ? order.receiptNo : order?.receipt_no && order?.receipt_no != "-" ? order.receipt_no : bill?.receiptNo && bill?.receiptNo != "-" ? bill.receiptNo : "-"}</div>
                <div className="text-right">
                  พนักงานขาย :{" "}
                  {
                    // user.firstNameTH ? `${user.firstNameTH} ${user.lastNameTH}` :
                    order?.employee_id && order?.employee  ?
                      `${order.employee.first_name_th}` :
                    "-"
                  }
                </div>
              </div>
              <div className="flex justify-between gap-[0.98vw] ">
                <div className="whitespace-nowrap">RD# </div>
                <div className="text-right">
                  โต๊ะ:
                  {order?.delivery == 1 &&
                    ` ${order?.zone} / ${order?.tableName}`}
                  {order?.delivery == 2 && " กลับบ้าน "}
                  {order?.delivery == 3 && " เดลิเวอรี่ "}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>
        <div className="flex">
          <div className="!w-[15%] whitespace-nowrap text-center">จำนวน</div>
          <div className="!w-[45%] text-center">รายการ</div>
          <div className="!w-[15%]">หน่วยละ</div>
          <div className="!w-[25%] !text-right">รวมเงิน</div>
        </div>
        <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>

        <div>
          <div className="font-bold">
            จำนวนสินค้า: {orderToDisplay?.product?.length > 0 ? orderToDisplay.product.filter(p => p.productStatus != 3).length : 0}
          </div>
          {orderToDisplay?.product?.filter(pd => pd.productStatus != 3).map((p, pi) => (
            <div key={`product${pi}`} className="flex gap-[0.98vw] ">
              <div className="!w-[15%]">
                <p className="text-center">{p.productQty}</p>
              </div>
              <div className="!w-[45%]">
                {p.productName}
                <div className="pl-[0.78vw]">
                  {p.optionstandard?.map((o, oi) => (
                    <div key={`product${pi}${oi}`} className="text-small text-[2.5vw]">
                      {" "}
                      - {o.listName} {o.optionQty > 1 && `x ${o.optionQty}`}
                    </div>
                  ))}
                  {p.optionproduct?.map((o, oi) => (
                    <div className="text-small text-[2.5vw]">
                      {" "}
                      - {o.listName} {o.optionQty > 1 && `x ${o.optionQty}`}
                    </div>
                  ))}
                  {/* {p.optionnote?.map((o, oi) => (
                    <div className="text-small text-[2.5vw]">
                      {" "}
                      - {o.listName}
                    </div>
                  ))} */}
                </div>
              </div>
              <div className="!w-[15%] text-center">
                {numberWithTwoDecimals(Number(p.productPrice))}
              </div>
              <div className="w-25 text-end">
                {numberWithTwoDecimals(Number(p.productPrice) * Number(p.productQty))}
                <div className="pl-[0.78vw]">
                  {p.optionstandard?.map((o, oi) => (
                    <div key={`optionstandard${oi}`} className="text-small text-[2.5vw]">
                      {" "}
                      + {o.optionQty > 1 ? numberWithTwoDecimals(Number(o.listPrice * o.optionQty * p.productQty)) : numberWithTwoDecimals(Number(o.listPrice * p.productQty))}
                    </div>
                  ))}
                  {p.optionproduct?.map((o, oi) => (
                    <div className="text-small text-[2.5vw]">
                      {" "}
                      + {o.optionQty > 1 ? numberWithTwoDecimals(Number(o.listPrice * o.optionQty * p.productQty)) : numberWithTwoDecimals(Number(o.listPrice * p.productQty))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>

        <div className="flex flex-col gap-[0.59vw]">
          <div className="flex justify-between gap-[0.98vw] ">
            <div className="whitespace-nowrap">ราคารวม</div>
            <div className="text-right">
              {bill?.total
                ? numberWithTwoDecimals(Number(bill.total))
                : bill?.orderPrice
                  ? numberWithTwoDecimals(Number(bill.orderPrice.sumprice))
                  : "0.00"}
            </div>
          </div>
          <div className="flex justify-between gap-[0.98vw] ">
            <div className="whitespace-nowrap">ส่วนลด {(endBillDiscount?.isPercent && endBillDiscount?.value > 0) ? `(${endBillDiscount.amount}%)` : bill?.discount_percent > 0 ? `(${bill.discount_percent}%)` : ""}</div>
            <div className="text-right">
              {endBillDiscount?.value > 0 ? numberWithTwoDecimals(endBillDiscount.value) : bill?.discount ? numberWithTwoDecimals(bill.discount) : "0.00"}
            </div>
          </div>
          <div className="flex justify-between gap-[0.98vw] ">
            <div className="whitespace-nowrap">ส่วนลดหลังปัดเศษ</div>
            <div className="text-right">
              {endBillDiscount?.valueRound > 0 ? numberWithTwoDecimals(endBillDiscount.valueRound) : bill?.discount_round ? numberWithTwoDecimals(bill.discount_round) : "0.00"}
            </div>
          </div>
          <div className="flex justify-between gap-[0.98vw] ">
            <div className="whitespace-nowrap">ส่วนลดคะแนน</div>
            <div className="text-right">
              {endBillDiscount?.isUsePoint ? numberWithTwoDecimals(endBillDiscount.discountFromPoint) : bill?.discount_from_point ? numberWithTwoDecimals(bill.discount_from_point) : "0.00"}
            </div>
          </div>
          <div className="flex justify-between gap-[0.98vw] ">
            <div className="whitespace-nowrap">
              ค่าบริการ ({branch?.financial && branch.financial.service_charge}
              %)
            </div>
            <div className="text-right">
              {branch?.financial.is_include_service_charge
                ? numberWithTwoDecimals(Number(
                  bill?.total *
                  ((100 + Number(branch.financial.service_charge)) / 100)
                ))
                : "0.00"}
            </div>
          </div>
          <div className="flex justify-between gap-[0.98vw] ">
            <div className="whitespace-nowrap">
              ค่าธรรมเนียมบัตรเครดิต {bill?.credit_charge_percent ? `(${bill.credit_charge_percent}%)` : ""}
            </div>
            <div className="text-right">
              {bill?.credit_charge_amount ? numberWithTwoDecimals(bill.credit_charge_amount) : "0.00"}
            </div>
          </div>
          <div className="flex justify-between gap-[0.98vw] text-5em text-large">
            <div className="whitespace-nowrap text-5em text-large ">
              ราคาสุทธิ :
            </div>
            <div className="text-right font-[700] text-5em text-large">
              {
                (endBillDiscount?.valueRound > 0 && order?.orderPrice?.sumprice && bill?.credit_charge_amount) ?
                  numberWithTwoDecimals(Number(order.orderPrice.sumprice) - (endBillDiscount.valueRound + endBillDiscount.discountFromPoint) + bill.credit_charge_amount) :
                endBillDiscount?.valueRound > 0 && order?.orderPrice?.sumprice ?
                  numberWithTwoDecimals(Number(order.orderPrice.sumprice) - (endBillDiscount.valueRound + endBillDiscount.discountFromPoint)) :
                bill?.net && bill?.credit_charge_amount ?
                  numberWithTwoDecimals(Number(bill.net + bill.credit_charge_amount)) :
                bill?.net || bill?.net != null || bill?.net != undefined?
                  numberWithTwoDecimals(bill.net) :
                bill?.total && bill?.credit_charge_amount ?
                  numberWithTwoDecimals(Number(bill.total + bill.credit_charge_amount)) :
                bill?.total ?
                  numberWithTwoDecimals(Number(bill?.total)) :
                order?.orderPrice?.sumprice ?
                  numberWithTwoDecimals(Number(order.orderPrice.sumprice)) :
                  "0.00"
              }
            </div>
          </div>
          <div className="flex justify-between gap-[0.98vw] ">
            <div className="whitespace-nowrap">มูลค่าสินค้าไม่มีภาษี</div>
            <div className="text-right">
              {/* {countNotIncludeVat(order)} */}
              0.00
            </div>
          </div>
          <div className="flex justify-between gap-[0.98vw] ">
            <div className="whitespace-nowrap">มูลค่าสินค้ามีภาษี</div>
            <div className="text-right">
              {
                ((endBillDiscount?.value > 0 || endBillDiscount?.discountFromPoint > 0) && bill?.total && bill?.credit_charge_amount) ?
                  numberWithTwoDecimals(Number((bill.total - (Math.ceil(endBillDiscount.value) + Math.ceil(endBillDiscount.discountFromPoint)) + bill.credit_charge_amount) * (100 / 107))) :
                ((endBillDiscount?.value > 0 || endBillDiscount?.discountFromPoint > 0) && bill?.total) ?
                  numberWithTwoDecimals(Number((bill.total - (Math.ceil(endBillDiscount.value) + Math.ceil(endBillDiscount.discountFromPoint))) * (100 / 107))) :
                ((bill?.discount_round > 0 || bill?.discount_from_point > 0) && bill?.total && bill?.credit_charge_amount) ?
                  numberWithTwoDecimals(Number((bill.total - (bill.discount_round + bill.discount_from_point) + bill.credit_charge_amount) * (100 / 107))) :
                ((bill?.discount > 0 || bill?.discount_from_point > 0) && bill?.total && bill?.credit_charge_amount) ?
                  numberWithTwoDecimals(Number((bill.total - (bill.discount + bill.discount_from_point) + bill.credit_charge_amount) * (100 / 107))) :
                (bill?.total && bill?.credit_charge_amount) ?
                  numberWithTwoDecimals(Number((bill.total + bill.credit_charge_amount) * (100 / 107))) :
                ((bill?.total && bill?.discount_round) || (bill?.total && bill?.discount_from_point)) ?
                  numberWithTwoDecimals(Number((bill.total - bill.discount_round - bill.discount_from_point) * (100 / 107))) :
                ((bill?.total && bill?.discount) || (bill?.total && bill?.discount_from_point)) ?
                  numberWithTwoDecimals(Number((bill.total - bill.discount - bill.discount_from_point) * (100 / 107))) :
                bill?.total ? 
                  numberWithTwoDecimals(Number(bill?.net - (bill?.net * (7 / 107)))) : 
                "0.00"
              }
            </div>
          </div>
          {/* <div className="flex justify-between gap-[0.98vw] ">
            <div className="whitespace-nowrap">ยอดก่อนภาษี</div>
            <div className="text-right">
              {bill?.total
                ? Number(bill.total * 1.1).toFixed(2)
                : bill?.orderPrice
                ? Number(bill.orderPrice.sumprice * 1.1).toFixed(2)
                : "0.00"}
            </div>
          </div> */}
          <div className="flex justify-between gap-[0.98vw] ">
            <div className="whitespace-nowrap">ภาษีมูลค่าเพิ่ม 7%</div>
            <div className="text-right">
              {
                bill?.total == bill?.discount ? 
                  "0.00" : 
                (endBillDiscount?.value > 0 || endBillDiscount?.discountFromPoint > 0 ) && bill?.total && bill?.credit_charge_amount ?
                  numberWithTwoDecimals(Number((bill.total - (Math.ceil(endBillDiscount.value) + endBillDiscount.discountFromPoint) + bill.credit_charge_amount) * (7 / 107))) :
                (endBillDiscount?.value > 0 || endBillDiscount?.discountFromPoint > 0 ) && bill?.total ?
                  numberWithTwoDecimals(Number((bill.total - (Math.ceil(endBillDiscount.value) + endBillDiscount.discountFromPoint)) * (7 / 107))) :
                (bill?.discount_round > 0 || bill?.discount_from_point > 0) && bill?.total && bill?.credit_charge_amount ?
                  numberWithTwoDecimals(Number((bill.total - bill.discount_round - bill.discount_from_point + bill.credit_charge_amount) * (7 / 107))) :
                (bill?.discount_round > 0 || bill?.discount_from_point > 0) && bill?.total ?
                  numberWithTwoDecimals(Number((bill.total - bill.discount_round - bill.discount_from_point) * (7 / 107))) :
                (bill?.discount > 0 || bill?.discount_from_point > 0) && bill?.total && bill?.credit_charge_amount ?
                  numberWithTwoDecimals(Number((bill.total - bill.discount - bill.discount_from_point + bill.credit_charge_amount) * (7 / 107))) :
                (bill?.discount > 0 || bill?.discount_from_point > 0) && bill?.total ?
                  numberWithTwoDecimals(Number((bill.total - bill.discount - bill.discount_from_point) * (7 / 107))) :
                bill?.total && bill?.credit_charge_amount ?
                  numberWithTwoDecimals(Number((bill.total + bill.credit_charge_amount) * (7 / 107))) :
                bill?.net ?
                  numberWithTwoDecimals(Number(bill.net * (7 / 107))) :
                bill?.total ?
                  numberWithTwoDecimals(Number(bill?.total * (7 / 107))) :
                "0.00"
              }
            </div>
          </div>
        </div>

        <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>
        <div className="text-center">
          <div>VAT Included</div>
          {type == "receipt" && (
            <>
              {bill?.paymentsReceipt && bill.paymentsReceipt.length > 0 && bill.paymentsReceipt[0].total > 0 &&
                <div className="flex justify-between gap-[0.98vw] ">
                  <div className="whitespace-nowrap">เงินสด</div>
                  <div className="text-right">
                    {/* {Number(bill?.paid_amount ? bill?.paid_amount - bill.change_amount - bill.credit_charge_amount : bill?.paid ? bill?.paid : 0).toFixed(2)} */}
                    {numberWithTwoDecimals(Number(bill.paymentsReceipt[0].total))}
                  </div>
                </div>
              }
              {bill?.paymentsReceipt?.map((payment, pi) => {
                if (pi > 0 && payment.total > 0) {
                  return (
                    <div key={`payments-${pi}`}>
                      <div className="gap-[0.98vw] flex justify-between">
                        <div>{payment.type == "Delivery" ? payment.name : payment.text}</div>
                        {
                          payment.type == "Cash" || payment.type == "Delivery" &&
                          <div>
                            {numberWithTwoDecimals(Number(payment.total))}
                          </div>
                        }
                      </div>
                      {payment.channels.map(channel => (
                        <div className="flex justify-between gap-[0.98vw] ">
                          <div className="whitespace-nowrap pl-8">- {channel.name}</div>
                          <div className="text-right">
                            {numberWithTwoDecimals(Number(channel.total))}
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                } else if (payment.type == "Delivery" && payment.channels.length > 0) {
                  return (
                    <div key={`payments-${pi}`}>
                      <div className="gap-[0.98vw] flex justify-between">
                        <div>{payment.name}</div>
                      </div>
                      {payment.channels.map(channel => (
                        <div className="flex justify-between gap-[0.98vw] ">
                          <div className="whitespace-nowrap pl-8">- {channel.name}</div>
                          <div className="text-right">
                            {numberWithTwoDecimals(Number(channel.total))}
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                }
              })
              }
              <div className="flex justify-between gap-[0.98vw] ">
                <div className="whitespace-nowrap">เงินทอน</div>
                <div className="text-right">
                  {/* {(
                    Number(copy ? bill?.paid_amount : bill?.paid).toFixed(2) - Number(copy
                    ? (Number(bill.total) + Number(summaryVat(bill, branch?.financial)))
                    :countTotal(order, branch?.financial)).toFixed(2)
                  ).toFixed(2)} */}
                  {
                    numberWithTwoDecimals(
                      Number(
                        bill?.change ? Math.max(0, Number(bill.change)) : 
                        bill?.changes ? Math.max(0, Number(bill.changes)) : 
                        (bill?.change_amount != null || bill?.change_amount != undefined) ? Math.max(0, Number(bill.change_amount)) :
                        // (bill?.paid_amount && bill?.net) ? (bill.paid_amount - bill.net) :
                        // (bill?.paid_amount && bill?.total) ? (bill.paid_amount - bill.total) :
                        0
                      )
                    )
                  }
                </div>
              </div>
              {/* <div className="flex justify-between gap-[0.98vw] ">
                <div className="whitespace-nowrap">ค่าธรรมเนียม</div>
                <div className="text-right">
                  {bill?.credit_charge_amount ? bill.credit_charge_amount.toFixed(2) : "0.00"}
                </div>
              </div> */}
              {/* line star */}
              <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>
            </>
          )}

          {type == "receipt" && order?.member ?
            <>
              <div className="text-center">Member : {order.member?.code}</div>
              <div className="flex justify-between gap-[0.98vw] mt-[1.66vw]">
                <div className="whitespace-nowrap">
                  คุณ : {order.member?.firstname} {order.member?.lastname}
                </div>
                <div className="text-right">
                  เบอร์ : {encryptNum(order.member?.phone_number)}
                </div>
              </div>
              <table className="w-full">
                <thead>
                  <tr className="mt-[0.78vw]">
                    <th className="w-[7.23vw] th-col-lg text-center py-[0.39vw] font-[400]"></th>
                    <th className="text-right py-[0.39vw] font-[400]">
                      ได้รับ
                    </th>
                    <th className="py-[0.39vw] text-right font-[400]">ใช้ไป</th>
                    <th className="py-[0.39vw] text-right font-[400]">
                      คงเหลือ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>คะแนน</td>
                    <td className="text-right">{bill?.point_receive ? bill.point_receive : '-'}</td>
                    <td className="text-right">{bill?.point_usage ? bill.point_usage : bill?.point_discount_usage ? bill.point_discount_usage  : '-'}</td>
                    <td className="text-right">{bill?.member_net_point ? bill.member_net_point : '-'}</td>
                  </tr>
                </tbody>
              </table>
              <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>
            </>
          :
          type == "receipt" && bill?.member?.id ? (
            <>
              <div className="text-center">Member : {bill.member?.code}</div>
              <div className="flex justify-between gap-[0.98vw] mt-[1.66vw]">
                <div className="whitespace-nowrap">
                  คุณ : {bill.member?.firstname} {bill.member?.lastname}
                </div>
                <div className="text-right">
                  เบอร์ : {encryptNum(bill.member?.phone_number)}
                </div>
              </div>
              <table className="w-full">
                <thead>
                  <tr className="mt-[0.78vw]">
                    <th className="w-[7.23vw] th-col-lg text-center py-[0.39vw] font-[400]"></th>
                    <th className="text-right py-[0.39vw] font-[400]">
                      ได้รับ
                    </th>
                    <th className="py-[0.39vw] text-right font-[400]">ใช้ไป</th>
                    <th className="py-[0.39vw] text-right font-[400]">
                      คงเหลือ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>คะแนน</td>
                    <td className="text-right">{bill?.point_receive ? bill.point_receive : '-'}</td>
                    <td className="text-right">-</td>
                    <td className="text-right">{bill?.member_net_point ? bill.member_net_point : '-'}</td>
                  </tr>
                </tbody>
              </table>
              <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>
            </>
          ) : <></>}
          <div>THANK YOU</div>
          <div>ขอบคุณที่ใช้บริการ</div>
          {order?.remark &&
            <div className="text-5xl">({order.remark})</div>
          }
        </div>

        {/* <div className="flex flex-col gap-[0.59vw]">
          <div className="flex justify-between gap-[0.98vw] ">
            <div className="whitespace-nowrap">POS :</div>
            <div className="text-right">POS 1</div>
          </div>
          <div className="flex justify-between gap-[0.98vw] ">
            <div className="whitespace-nowrap">วันที่ :</div>
            <div className="text-right">05/19/2020 12:20</div>
          </div>
          <div className="flex justify-between gap-[0.98vw] ">
            <div className="whitespace-nowrap">พนักงาน :</div>
            <div className="text-right">วัสมนต์ จิตรานุพงศ</div>
          </div>
          <div className="flex justify-between gap-[0.98vw] ">
            <div className="whitespace-nowrap">ยอดเงินสดตั้งต้น :</div>
            <div className="text-right font-[700]">2,500.00</div>
          </div>
        </div> */}
      </div>
    </div>
  );
});