import { atom, selector, useRecoilState, useSetRecoilState } from 'recoil';
import { getAllBill, createBill } from '../services/bill';

// State
export const billState = atom({
    key: 'billState',
    default: {
        bills: [],
        currentBill: {}
    },
});

// Action
export const useFetchAllBill = () => {
    const setState = useSetRecoilState(billState);

    return async () => {
        try {
            const fetch = await getAllBill();
            setState(({ bills: fetch.bill }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useFetchBill = () => {
    const setState = useSetRecoilState(billState);

    return async () => {
        try {
            const fetch = await getAllBill();
            setState(({ bill: fetch.bill }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useUpdateBill = () => {
    const setState = useSetRecoilState(billState);

    return async () => {
        try {
            const fetch = await getAllBill();
            setState(({ bill: fetch.bill }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useCreateBill = () => {
    const setState = useSetRecoilState(billState);

    return async (order, posId, selectedTable) => {
        // console.log("🚀 ~ file: bill.js:60 ~ return ~ posId:", posId)
        // console.log("🚀 ~ file: bill.js:60 ~ return ~ selectedTable:", selectedTable)
        // console.log("🚀 ~ file: bill.js:60 ~ return ~ order:", order)
        try {
            const create = await createBill(order, posId, selectedTable);
            // console.log("🚀 ~ file: bill.js:62 ~ return ~ create:", create)
            // const fetch = await getAllBill();
            // console.log("🚀 ~ file: bill.js:64 ~ return ~ fetch:", fetch)
            setState(state => ({ 
                ...state,
                // bills: fetch.bill,
                currentBill: create.bill 
            }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};