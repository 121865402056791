import React, { useEffect, useState } from "react";

export default function NotificationCard({ notiData, isLast = false }) {
  const [theme, setTheme] = useState({
    color: "",
    placeText: "",
    iconSrc: "",
  });

  useEffect(() => {
    switch (notiData.placeType) {
      case 1:
        setTheme({
          ...theme,
          color: "text-[var(--color-orange)]",
          placeText: "ทานที่ร้าน",
          iconSrc: "/images/notification/icon-store-orange.svg",
        });
        break;
      case 3:
        setTheme({
          ...theme,
          color: "text-[var(--color-green)]",
          placeText: "เดลิเวอรี่",
          iconSrc: "/images/notification/icon-delivery-green.svg",
        });
        break;
      default:
        setTheme({
          ...theme,
          color: "text-[var(--color-blue)]",
          placeText: "กลับบ้าน",
          iconSrc: "/images/notification/icon-take-home-blue.svg",
        });
        break;
    }
  }, []);

  return (
    <div
      className={`flex p-[min(1.17vw,12px)] ${
        isLast ? "" : "border-b-[min(0.20vw,2px)]"
      } border-[#D9D9D9] relative`}
    >
      <div className="flex gap-[min(2.05vw,21px)] items-center pr-[min(6.84vw,70px)]">
        <div className="aspect-square w-[min(5.08vw,52px)] h-fit bg-[#FF0000] bg-opacity-[20%] rounded-full flex justify-center items-center">
          <img
            className="aspect-[6/33] w-[min(0.59vw,6px)] h-fit object-contain"
            src="/images/notification/icon-alert-red.png"
            alt=""
          />
        </div>
        <div className="flex flex-col gap-[min(0.78vw,8px)]">
          <div className="text-16">
            <span className="text-[var(--color-red)] mr-[min(1.46vw,15px)]">
              ยกเลิกรายการ
            </span>
            {notiData.orderNo}
          </div>

          <div className={`${theme.color} text-12 flex items-center`}>
            <img
              className="aspect-square w-[min(1.27vw,13px)] h-fit object-contain mr-[min(0.49vw,5px)]"
              src={theme.iconSrc}
              alt=""
            />
            <div>
              {theme.placeText}
              <span className="ml-[min(0.68vw,7px)]">{notiData.info}</span>
            </div>
          </div>

          <div className="text-16">{notiData.orderItem}</div>
        </div>
      </div>

      <div className="leading-[1.2] text-10 text-[#8C8C8C] text-right absolute bottom-[min(0.29vw,3px)] right-[min(0.98vw,10px)]">
        <div>{notiData.date}</div>
        <div>{notiData.time}</div>
      </div>
    </div>
  );
}
