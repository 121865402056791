import React from "react";

export default function AvartarHeader({userInfo}) {
  return (
    <div>
      <img
        className="aspect-square w-full rounded-full object-cover"
        src={userInfo.avartar ?? "-"}
        alt=""
      />
      <div className="relative">
        <div className="absolute aspect-square w-1/4 bg-[#72E128] border-[min(0.05vw,0.5px)] border-white rounded-full bottom-0 right-0"></div>
      </div>
    </div>
  );
}
