import React, { useState } from "react";

export default function MoneyDisplay({isBankNote, ...props}) {
  const [displayInfo, setDisplayInfo] = useState({
    icon: isBankNote ? "/img/icons/icon-banknote.svg" : "/img/icons/icon-coin.svg",
  });
  return (
    <div className="flex justify-between">
      <div
        className={`aspect-[107/24] w-[min(10.45vw,107px)] bg-[var(--color-grey-2)] flex justify-between items-center text-white rounded-[min(0.20vw,2px)] leading-[0.5] px-[min(1.27vw,13px)] ${props.className}`}
      >
          <img
            className="aspect-[24/14] w-[min(2.34vw,24px)] object-contain"
            src={displayInfo.icon}
            alt=""
          />

        <div className="text-18">{props.value}</div>
      </div>
    </div>
  );
}
