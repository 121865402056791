import CustomBtn from "components/fragments/CustomBtn";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { posState, useCreatePosActivity } from "state/pos";
import { appState } from "state/app";

export default function PosSettingCard({
  modalPosDubStartConfirm,
  modalPosSettingStart,
  isStarted,
  isKitchen,
  posInfo,
  userInfo,
  ...props
}) {
  const navigate = useNavigate();
  const { slug, slugBranch } = useRecoilValue(appState);
  const [theme, setTheme] = useState({
    btnTxt: "เข้าใช้งาน",
    color: "bg-[var(--color-grey-2)]",
    btnColor: "btn-second-active",
  });

  // const [first, setfirst] = useState(second);

  const createPosActivity = useCreatePosActivity();

  const redTheme = () => {
    setTheme({
      ...theme,
      btnTxt: isStarted ? "เข้าใช้งาน" : "เริ่มต้นการใช้งาน",
      color: isStarted ? "bg-[var(--color-grey-2)]" : "bg-[var(--color-red)]",
      btnColor: isStarted ? "btn-second-active" : "btn-table-zone-active",
    });
  };

  const greenTheme = () => {
    setTheme({
      ...theme,
      btnTxt: isStarted ? "เข้าใช้งาน" : "เริ่มต้นการใช้งาน",
      color: isStarted ? "bg-[var(--color-grey-2)]" : "bg-[var(--color-green)]",
      btnColor: isStarted ? "btn-second-active" : "btn-main-active",
    });
  };

  useEffect(() => {
    if (isKitchen) {
      return redTheme();
    }
    greenTheme();
  }, [isStarted]);

  const onKitchenHandler = async () => {
    await createPosActivity({
      posId: posInfo.posId,
      employeeBranchAccountId: userInfo.employeeBranchAccountId,
      employeeId: userInfo.employeeId,
      activityTypeId: 1,
      startTime: new Date(),
      type: "kitchen"
    });
    navigate(`/${slug}/${slugBranch}/kitchen`);
  }

  return (
    <div className="w-[min(31.15vw,319px)] rounded-[min(0.78vw,8px)]  shadow-pos-setting-card text-18 overflow-hidden">
      <div className="w-full">
        <div
          className={`text-white font-[500] text-24 ${theme.color} px-[min(1.46vw,15px)] py-[min(0.49vw,5px)]`}
        >
          {posInfo && posInfo.posName ? posInfo.posName : "-"}
        </div>
        <div className="pt-[min(1.32vw,13.5px)] pb-[min(1.07vw,11px)]">
          <div className="px-[min(1.46vw,15px)] flex flex-col gap-[min(1.46vw,15px)]">
            <div className="flex justify-between gap-[min(0.98vw,10px)] ">
              <div className="whitespace-nowrap">พนักงาน :</div>
              <div className="truncate">
                {userInfo && userInfo.fullName ? userInfo.fullName : "-"}
              </div>
            </div>
            <div className="flex justify-between gap-[min(0.98vw,10px)]">
              <div className="whitespace-nowrap">เวลาเริ่มขาย :</div>
              <div className="truncate">
                {userInfo && userInfo.startTime ? userInfo.startTime : "-"}
              </div>
            </div>
            <div className="flex justify-between gap-[min(0.98vw,10px)]">
              <div className="whitespace-nowrap">เวลาสิ้นสุด :</div>
              <div className="truncate">
                {userInfo && userInfo.endTime ? userInfo.endTime : "-"}
              </div>
            </div>
            <div className="flex justify-between gap-[min(0.98vw,10px)]">
              <div className="whitespace-nowrap">ระยะเวลาขาย :</div>
              <div className="truncate">
                {userInfo && userInfo.duration ? userInfo.duration : "-"}
              </div>
            </div>
          </div>

          <div className="px-[min(1.46vw,15px)] mt-[min(2.51vw,25.72px)]">
            <div className="w-full h-[min(0.10vw,1px)] bg-[#EDEDED]"></div>
          </div>

          <div className="px-[min(3.13vw,32px)]">
            <CustomBtn
              sx={{
                "& .MuiButton-startIcon": {
                  margin: 0,
                  marginRight: `calc(${Math.min(
                    1.46 * 0.01 * window.innerWidth,
                    15
                  )}px)`,
                },
              }}
              startIcon={
                <img
                  className="aspect-[27/28.92] w-[min(2.64vw,27px)] object-contain"
                  src="/img/icons/icon-pos-login.svg"
                />
              }
              className={`text-18 mt-[min(1.27vw,13px)] py-[min(0.88vw,9px)] w-full ${theme.btnColor} font-[700] rounded-[min(0.78vw,8px)]`}
              btntxt={theme.btnTxt}
              onClick={() => {
                if (isKitchen && !isStarted) {
                  return onKitchenHandler();
                }
                props.setSelectedPosSetting(state => ({
                  ...state,
                  ...posInfo,
                  posId: posInfo.posId,
                  employeeId: userInfo.employeeId,
                  employeeBranchAccountId: userInfo.employeeBranchAccountId,
                  posName: posInfo && posInfo.posName ? posInfo.posName : null,
                  posCode: posInfo && posInfo.code ? posInfo.code : null,
                  posOrder: posInfo && posInfo.order ? posInfo.order : null,
                }))
                if (isStarted) {
                  return modalPosDubStartConfirm.setModalOpen(
                    !modalPosDubStartConfirm.modalOpen
                  );
                }
                modalPosSettingStart.setModalPosSettingStartOpen(
                  !modalPosSettingStart.modalPosSettingStartOpen
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
