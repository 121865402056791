import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";

import ModalChangeStaff from "components/layouts/ModalChangeStaff";
import ModalPin from "components/layouts/ModalPin";
import ModalSuccess from "components/layouts/ModalSuccess";
import HeaderPos from "components/layouts/HeaderPos";
import Collapse from "react-bootstrap/Collapse";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import { useReactToPrint } from 'react-to-print';

import ModalPayment from "components/layouts/ModalPayment";
import ModalCallBill from "components/layouts/ModalCallBill";
import ModalHoldBillConfirm from "components/layouts/ModalHoldBillConfirm";
import ModalPaymentSuccess from "components/layouts/ModalPaymentSuccess";
import ModalProductOption from "components/layouts/ModalProductOption";
import ModalOrderRemark from "components/layouts/ModalOrderRemark";

import CategorySlide from "components/layouts/CategorySlide";
import CategorySubSlide from "components/layouts/CategorySubSlide";
import ProductPaginate from "components/layouts/ProductPaginate";
import ProductOrderDetail from "components/layouts/ProductOrderDetail";

import { nanoid } from "nanoid";
import * as _ from "lodash";
import ModalSearchMember from "components/layouts/ModalSearchMember";
import ModalRegisterMember from "components/layouts/ModalRegisterMember";
import ModalMemberRegisterSuccess from "components/layouts/ModalMemberRegisterSuccess";
import MemberSelectedCard from "components/layouts/MemberSelectedCard";

import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { selectedTableState, useFetchAllTable, tableState, useFetchAllTableZone, useOpenTable } from "state/table";
import { useFetchAllOrder, useCreateOrder, useAddOrder, useFetchLatestOrderNo, useUpdateOrderDetail } from "state/order";

import { orderState, useUpdateOrder, useDeleteOrder, useGetOrder, useUpdateOrderEmployee, useUpdateOrderRemark, useUpdateOrderMember } from "state/order";
import { posState } from "state/pos";
import { productState, useFetchAllProduct, useFetchAllProductCategory, useFetchAllBranchProduct } from "state/product";
import { useCreateBill } from "state/bill";
import { authState, useFetchBranch } from "state/auth";
import { useFetchAllPaymentChannel } from "state/payment";
import { paymentState, useResetCurrentPayment } from "state/payment";
import { useFetchAllEmployee } from "state/employee";
import { employeeState } from "state/employee";
import { billState } from "state/bill";
import ModalTableSelect from "components/layouts/ModalTableSelect";
import { useFetchAllMember, useCreateMember } from "state/member";
import { memberState } from "state/member";
import { BillPaperToPrint } from "components/fragments/BillPaper";
import { socket } from '../../socket';
import LoadingSpinner from 'components/fragments/LoadingSpinner';
import { appState, useSetRequestLoading } from "state/app";

// TODO: Remove due to no use
const defaultOrders = {
  id: null,
  orderNo: "",
  // orderDate: null,
  orderDate: new Date().toLocaleString('en-GB').replace(',',''),
  tableID: "",
  zone: "",
  delivery: 1,
  product: [],
  orderPrice: {
    sumprice: 0.0,
    discount: 0.0,
    vat: 0.0,
    net: 0.0,
  },
  payment: [],
}

export function Home() {

  const [modalTableSelect, setModalTableSelect] = useState(false);
  const [modalPaymentOpen, setModalPaymentOpen] = useState(false);
  const [modalPaymentSuccessOpen, setModalPaymentSuccessOpen] = useState(false);
  const [modalCallBillOpen, setModalCallBillOpen] = useState(false);
  const [modalHoldBillConfirmOpen, setModalHoldBillConfirmOpen] = useState(false);
  const [modalChangeStaffOpen, setModalChangeStaffOpen] = useState(false);
  const [modalPinOpen, setModalPinOpen] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [optionmodal, setOptionmodal] = useState(false);

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [productselect, setProductselect] = useState({});
  const [productOrder, setProductOrder] = useState(false);
  const prevProductOrder = usePrevious(productOrder);
  const [categoryID, setcategoryID] = useState(0);
  const [subcategoryID, setsubcategoryID] = useState(0);
  const [collapseShow, setCollapseShow] = useState(false);
  const [alerttable, setAlerttable] = useState(false);
  const [alertproduct, setAlertproduct] = useState(false);
  const [edit, setEdit] = useState(0);
  const [products, setProducts] = useState([]);
  const [categorys, setCategorys] = useState([
    {
      category_id: 0,
      category_name: "ทั้งหมด"
    }
  ]);
  const [subcategorys, setSubcategorys] = useState([
    {
      category_id: 0,
      subcategory_id: 0,
      subcategory_name: "ทั้งหมด"
    }
  ]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [modalSearchMemberOpen, setmodalSearchMemberOpen] = useState(false);
  const [modalRegisterMemberOpen, setmodalRegisterMemberOpen] = useState(false);
  const [modalRegisterSuccess, setmodalRegisterSuccess] = useState(false);
  const [productfilter, setProductfilter] = useState([]);
  const [enableSendOrderBtn, setEnableSendOrderBtn] = useState(false);
  const [disablePayOrderBtn, setDisablePayOrderBtn] = useState(false);

  // const fetchAllProduct = useFetchAllProduct();
  const fetchAllBranchProduct = useFetchAllBranchProduct();
  const fetchAllProductCategory = useFetchAllProductCategory();
  const fetchAllTable = useFetchAllTable();
  const fetchAllOrder = useFetchAllOrder();
  const fetchAllPaymentChannel = useFetchAllPaymentChannel();
  const createOrder = useCreateOrder();
  const createBill = useCreateBill();
  // const addOrder = useAddOrder();
  const fetchAllEmpolyee = useFetchAllEmployee();
  const fetchLatestOrderNo = useFetchLatestOrderNo();
  const fetchAllTableZone = useFetchAllTableZone();
  // const selectedPosSetting = useRecoilValue(selectedPosSettingState);
  const { user, branch } = useRecoilValue(authState);
  const { tables, tableZone, isTableOrderCheckBill } = useRecoilValue(tableState);
  const [tableOptions, setTableOptions] = useState([]);
  const [fromSelectedTable, setFromSelectedTable] = useRecoilState(selectedTableState); // From Table page
  const [selectedTable, setSelectedTable] = useState(fromSelectedTable) // This page scope
  const { orders: orderList, selectedOrder, latestOrderNo, isLoading } = useRecoilValue(orderState);
  const setOrderState = useSetRecoilState(orderState);
  const { currentPOS, currentPOSActivity } = useRecoilValue(posState);
  const { products: productList, productCategory } = useRecoilValue(productState);
  const { paymentChannels, currentPayment } = useRecoilValue(paymentState);
  const setPaymentState = useSetRecoilState(paymentState);
  const { employees } = useRecoilValue(employeeState);
  const { currentBill } = useRecoilValue(billState);
  const setBillState = useSetRecoilState(billState);
  // console.log("🚀 ~ currentBill:", currentBill)
  const resetCurrentPayment = useResetCurrentPayment();
  const fetchAllMember = useFetchAllMember();
  const { members } = useRecoilValue(memberState);
  // console.log("🚀 ~ members:", members)
  const createMember = useCreateMember();
  const [memberCode, setMemberCode] = useState("");
  const [currentMember, setCurrentMember] = useState(null);
  // console.log("🚀 ~ currentMember:", currentMember)
  const fetchBranch = useFetchBranch();
  const openTable = useOpenTable();
  const [currentBillPayments, setCurrentBillPayments] = useState("")
  const updateOrder = useUpdateOrder();
  const updateOrderRemark = useUpdateOrderRemark();
  const [successText, setSuccessText] = useState(null)
  const [newProduct, setNewProduct] = useState(false)
  const deleteOrder = useDeleteOrder();
  const updateOrderDetail = useUpdateOrderDetail();
  const setTableState = useSetRecoilState(tableState);
  const getOrder = useGetOrder();
  const [isPaymentMode, setIsPaymentMode] = useState(false);
  const updateOrderEmployee = useUpdateOrderEmployee();
  const { isRequestLoading } = useRecoilValue(appState);
  const setRequestLoading = useSetRequestLoading();
  const updateOrderMember = useUpdateOrderMember();

  const billRef = useRef();
  const billPrintFullRef = useRef();

  const handlePrintBill = useReactToPrint({
    content: () => billRef.current,
  });

  const handlePrintBillFull = useReactToPrint({
    content: () => billPrintFullRef.current,
  });

  const handlePrintBillFullSuccess = useReactToPrint({
    content: () => billPrintFullRef.current,
    onAfterPrint: () => {
      setModalPaymentSuccessOpen(true);
    }
  });

  const [customerList, setCustomerList] = useState([]);
  const [custSeletedIdCf, setCustSeletedIdCf] = useState();
  // console.log("🚀 ~ file: Home.jsx:164 ~ custSeletedIdCf:", custSeletedIdCf)

  const [orders, setOrders] = useState({
    id: selectedOrder?.id ? selectedOrder.id : null,
    orderId: null,
    // orderNo: selectedOrder?.orderNo ? selectedOrder.orderNo : latestOrderNo,
    // orderNo: "",
    orderDate: new Date().toLocaleString('en-GB').replace(',',''),
    tableID: "",
    zone: "",
    delivery: 2,
    product: [],
    orderPrice: {
      sumprice: 0.0,
      discount: 0.0,
      vat: 0.0,
      net: 0.0,
    },
    payment: [],
    member_id: null,
    member: null,
    ...selectedOrder,
    orderNo: selectedOrder && selectedOrder.orderNo != "NA" ? selectedOrder.orderNo : latestOrderNo,
    // remark: selectedOrder && selectedOrder.remark ? selectedOrder.remark : null,
  });
  // console.log("🚀 ~ file: Home.jsx:222 ~ Home ~ orders:", orders)
  const prevOrders = usePrevious(orders);
  const [modalOrderRemark, setModalOrderRemark] = useState(false);
  const [endBillDiscount, setEndBillDiscount] = useState({
    id: null,
    name: null,
    discountTypeId: 1,
    isPercent: false,
    amount: 0,
    value: 0,
    valueRound: 0,
    isUsePoint: false,
    pointUsage: 0,
    discountFromPoint: 0
  });

  const optionsDelivery = [
    { value: 2, label: "กลับบ้าน" },
    { value: 1, label: "ทานที่ร้าน" },
    // { value: 2, label: "กลับบ้าน" },
    { value: 3, label: "เดลิเวอรี่" },
  ];

  const [selectedOptionDelivery, setSelectedOptionDelivery] = useState(optionsDelivery[0])

  // const productfilter = products.filter(
  //   (item) =>
  //     item.productName &&
  //     item.productName.toLowerCase().includes(filterText.toLowerCase())
  // );

  useEffect(() => {
    // fetchAllTable(user.branchId);
    // fetchAllOrder();
    // fetchAllProduct();

    // // realtime order list sync
    // const intervalFetchOrder = setInterval(async () => {
    //   // Fetch all orders
    //   if(user.id && currentPOS.id && currentPOSActivity.operation_date) {
    //     await fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
    //   }
    // }, 1500);

    async function onOrdersChange (value) {
      if(user.id && currentPOS.id && currentPOSActivity.operation_date) {
        await fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
        await fetchAllTable(user.branchId);
        await fetchAllTableZone(user.branchId);
      }
      // if(orders && orders.id && !isPaymentMode) {
      //   await onOrderDetailChange()
      // }
    }

    if(user.id && currentPOS.id && currentPOSActivity.operation_date) {
      socket.on(`POS_${user.branchId}_ORDER`, onOrdersChange);
      // socket.on(`POS_${user.branchId}_ORDER_DETAIL`, onOrderDetailChange);
    }

    return () => {
      // console.log('clear interval')
      // clearInterval(intervalFetchOrder);
      socket.off(`POS_${user.branchId}_ORDER`);
    }
  }, []);

  useEffect(() => {
    // console.log('user: ', user)
    // console.log('currentPOS: ', currentPOS)
    if(user.branchId) {
      fetchAllTable(user.branchId);
      fetchAllTableZone(user.branchId);
      fetchAllBranchProduct(user.branchId);
      fetchAllPaymentChannel(user.branchId);
      fetchBranch(user.branchId)
    }
    if(user.businessId) {
      fetchAllProductCategory(user.businessId, user.branchId);
      fetchAllEmpolyee(user.businessId);
      fetchAllMember({
        businessId: user.businessId
      });
    }
    // if(user.id) {
    // fetchAllMember();
    // }
  }, [user]);

  useEffect(() => {
    if(productCategory && productCategory.length > 0) {
      const newProductCategory = [];
      const newProductSubCategory = [];
      for (let i = 0; i < productCategory.length; i++) {
        newProductCategory.push({
          category_id: productCategory[i].id,
          category_name: productCategory[i].name,
        });
        for (let j = 0; j < productCategory[i].subCategory.length; j++) {
          newProductSubCategory.push({
            category_id: productCategory[i].id,
            subcategory_id: productCategory[i].subCategory[j].id,
            subcategory_name: productCategory[i].subCategory[j].name
          })
        }
      }
      setCategorys(newProductCategory);
      setcategoryID(newProductCategory[0].category_id)
      setSubcategorys(newProductSubCategory);
      setsubcategoryID(newProductSubCategory[0].subcategory_id)
    }
  }, [productCategory])
  

  useEffect(() => {
    // getCategoryData(categoryID);
    // getsubCategoryData(subcategoryID);
    // getFoodData(categoryID, subcategoryID);
    // if (orders.orderNo === "") {
      // getOrderNo();
    // }

    if(productCategory.length > 0 && categoryID != 0 && productCategory.length > 0 && categorys.length > 0) {
      let filteredSubcategory = [...subcategorys].filter(sc => sc.category_id == categoryID);
      setFilteredSubCategories(filteredSubcategory);
      setsubcategoryID(filteredSubcategory[0].subcategory_id);
    } else {
      setFilteredSubCategories([subcategorys[0]]);
      setsubcategoryID(0);
    }
  }, [categoryID]);

  useEffect(() => {
    // if (orders.orderNo === "") {
      // getOrderNo();
    // }

    // if(productList.length > 0 && subcategoryID != 0 && productCategory.length > 0 && subcategorys.length > 0) {
    //   let filteredProducts = [...productList].filter(p => p.subCategoryId == subcategoryID);
    //   console.log("🚀 ~ useEffect ~ filteredProducts:", filteredProducts)
    //   setProductfilter(filteredProducts);
    // } else {
    //   setProductfilter([...productList]);
    // }

    if(productList.length > 0 && subcategoryID != 0 && productCategory.length > 0 && subcategorys.length > 0) {
      let filteredProducts = [...productList].filter(p => p.subCategoryId == subcategoryID);
      setProductfilter(filteredProducts);
    } else if(productList.length > 0 && subcategoryID == 0 && productCategory.length > 0 && subcategorys.length > 0 && categoryID != 0) {

      let filteredProducts = [...productList].filter(p => p.categoryId == categoryID);
      setProductfilter(filteredProducts);
    }else {
      setProductfilter([...productList]);
    }
  }, [subcategoryID, categoryID]);

  async function onOrderDetailChange (value) {
    if(orders && orders.id && !isPaymentMode) {
      const order = await getOrder(orders.id);
      if ((order.status_id == 2 || order.status_id == 3) && !isPaymentMode) {
        await clearOrders();
        await fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
      } else if (order.status_id == 1 && !isPaymentMode) {
        const newOrderProducts = orders.product.filter(p => typeof(p.id) == "string");
        // setOrders(prev => ({ ...prev, product: order.product }))
        setOrders(prev => ({ ...prev, product: [...order.product, ...newOrderProducts] }))
      }
    }
  }

  useEffect(() => {

    if (!_.isEqual(orders, prevOrders)) {
      if (orders.product.length > 0) {
        sumOrders(orders);
      }
    }

    // Send order button handler
    const newOrders = orders.product.filter((i) => i.productStatus == 0).length > 0;

    if((selectedOptionDelivery.value === 1 || orders.delivery == 1) && selectedTable && newOrders) {
      // enable send order btn
      setEnableSendOrderBtn(true)
    } else if ((selectedOptionDelivery.value !== 1 || orders.delivery != 1) && newOrders) {
      // enable send order btn
      setEnableSendOrderBtn(true)
    } else {
      // disable send order btn
      setEnableSendOrderBtn(false)
    }

    if(orders.id) {
      // If an existed order is DineIn, find table to be display
      if (orders.delivery == 1) {
        const table = [...tables].filter(t => t.id == orders.tableID)[0]
        if(table) {
          setSelectedTable(({ value: table.id, label: `${table.no} (${table.zone.alias})`, ...table }))
        }
      }

      if(orders.delivery != selectedOptionDelivery.value) {
        setSelectedOptionDelivery(optionsDelivery.filter(o => o.value == orders.delivery)[0]);
      }
    }

    if(orders.member_id) {
      setCustSeletedIdCf(orders.member_id);
    } else if (currentMember?.id) {
      if(orders.id && (!orders.member || !orders.member_id)) {
        setOrders(prev => ({ ...prev, member: null, member_id: null }));
        setCustSeletedIdCf(null);
        setCurrentMember(null);
      }
      else  {
        setOrders(prev => ({ ...prev, member_id: currentMember.id, member: currentMember }));
      }
    }
    // else {
    //   setCustSeletedIdCf(undefined);
    // }

    if(!orders.pos_id) {
      let newOrders = {...orders};
      newOrders["pos_id"] = currentPOS.id;
      newOrders["pos"] = currentPOS;
      setOrders(newOrders);
    }

    if(orders.orderNo == "NA") {
      if(orders.id) {
        fetchLatestOrderNo(currentPOS.id)
        setOrders(prev => ({ ...prev, orderNo: latestOrderNo }))
      } else {
        setOrders(prev => ({ ...prev, orderNo: latestOrderNo }))
      }
    } else {
      // Handle for order of selected table from table page
      if(orders.id && orders.order_id && orders.orderNo != "NA" && orders.orderNo != orders.order_id) {
        setOrders(prev => ({ ...prev, orderNo: orders.order_id }))
      }
    }

    // hotfix cancel all order products
    if(prevOrders?.id && !orders.id) {
      fetchLatestOrderNo(currentPOS.id)
    }

    // if(orders.orderNo != orders.orderId) {
    //   clearOrders()
    // }

    // if(orders.id) {
    //   if(!orders.member || !orders.member_id) {
    //     setOrders(prev => ({ ...prev, member: null, member_id: null }));
    //     setCustSeletedIdCf(null);
    //     setCurrentMember(null);
    //   }
    // }

    setAlertproduct(false);

    // // realtime order status/action sync
    // const intervalCheckOrderStatus = setInterval(async () => {
    //   // Fetch current select order
    //   if(orders && orders.id && !isPaymentMode) {
    //     const order = await getOrder(orders.id);
    //     if ((order.status_id == 2 || order.status_id == 3) && !isPaymentMode) {
    //       await clearOrders();
    //       await fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
    //     } else if (order.status_id == 1 && !isPaymentMode) {
    //       const newOrderProducts = orders.product.filter(p => typeof(p.id) == "string");
    //       // setOrders(prev => ({ ...prev, product: order.product }))
    //       setOrders(prev => ({ ...prev, product: [...order.product, ...newOrderProducts] }))
    //     }
    //   }
    // }, 1500);

    if(orders && orders.id && !isPaymentMode) {
      socket.on(`POS_${user.branchId}_ORDER_DETAIL`, onOrderDetailChange);
    } else {
      socket.off(`POS_${user.branchId}_ORDER_DETAIL`);
    }

    return () => {
      // console.log('clear interval')
      // clearInterval(intervalCheckOrderStatus);
      socket.off(`POS_${user.branchId}_ORDER_DETAIL`);
    }

  }, [orders, isPaymentMode]);

  useEffect(() => {
    if (tables && tables.length > 0) {
      const parsed = tables.map(table => ({
        ...table,
        value: table.id,
        label: `${table.no} (${table.zone.alias})`
      }));
      // if (parsed.length > 0) {
      //   // setTableOptions(state => ({ ...state, tables: parsed }));
      setTableOptions(parsed);
      // }
    }
  }, [tables]);

  // useEffect(() => {
    // if(orders.delivery === 1) {
    //   let currentYear = new Date();
    //   let year = currentYear.getFullYear().toString().substr(-2);
    //   let month = currentYear.getMonth();
    //   let orderId = null;
    //   // TODO: implement API filter
    //   if (orderList && orderList.length > 0) {
    //     // orderId = `OR${year}${month}`;
    //     orderId = `OR${year}${month+1}000000${orderList.length + 1}P${currentPOS ? currentPOS.order : ""}`;
    //   } else {
    //     let count = 1;
    //     orderId = `OR${year}${month+1}000000${count}P${currentPOS ? currentPOS.order : ""}`;
    //   }
    //   setLatestOrderId(orderId);
    // }

  //   if(orderList.length > 0 && orders.orderNo != "") {
  //     const order = orderList.filter(od => od.orderNo == orders.orderNo);
  //     if(order.length > 0) {
  //       setOrders(prev => ({ ...order[0]}))
  //     }
  //   }
  // }, [orderList]);

  useEffect(() => {
    if(currentPOS && currentPOS.id) {
      fetchLatestOrderNo(currentPOS.id)
      fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
      fetchAllTable(user.branchId);
      fetchAllTableZone(user.branchId);
    }
  }, [currentPOS])

  useEffect(() => {
    if (productList && productList.length > 0) {
      setProducts(productList)
      let filtered = productList.filter(
        (item) =>
          item.productName &&
          item.productName.toLowerCase().includes(filterText.toLowerCase())
      );
      filtered = filtered.filter(f => f.branchId == user.branchId)
      if(subcategoryID > 0) {
        filtered = filtered.filter(f => f.subCategoryId == subcategoryID)
      }
      setProductfilter(filtered);
    }
  }, [productList]);

  const sumOrders = (orders) => {
    // console.log("🚀 ~ file: Home.jsx:333 ~ sumOrders ~ orders:", orders)
    const sum =
      (orders.product.length > 0 && orders.product.filter(pd => pd.productStatus != 3).length > 0 )
        ? orders.product
          .filter(pd => pd.productStatus != 3)
          .map((pd) => parseFloat(pd.productSumPrice))
          .reduce((prev, curr) => prev + curr)
        : 0;

    setOrders((prev) => ({
      ...prev,
      orderPrice: {
        ...prev.orderPrice,
        // sumprice: (prev.orderPrice.sumprice = sum),
        sumprice: sum,
        // net: (prev.orderPrice.net = sum),
        net: sum,
      },
    }));
  };

  // first about to add product to order
  const handleSelectProduct = (item) => {
    // console.log("🚀 ~ handleSelectProduct ~ item:", item)
    setProductselect(item);
    setProductOrder({
      id: nanoid(),
      // id: null,
      productID: item.productID,
      productImage: item.productImage,
      productName: item.productName,
      productPrice: item.productPrice,
      productSumPrice: item.productPrice,
      productQty: 1,
      productStatus: 0,
      optionnote: [],
      optionproduct: [],
      optionstandard: [],
      note: "",
      branchProductId: item.branchProductId
    });
    setOptionmodal(true);
  };

  const handleaddProducttoOrder = async () => {
    const checkproduct = orders.product.filter(
      // (i) => i.productID === productOrder.productID
      (i) => i.id === productOrder.id
    );

    const checkSameProduct = orders.product.filter(
      (i) => i.productID === productOrder.productID
    );

    if (edit === 1 && checkproduct.length > 0) {
      const changed = checkproduct.map((item, index) => {
        var checkNote = item.note === productOrder.note;

        var checkoptionnote = _.isEqual(
          item.optionnote,
          productOrder.optionnote
        );

        var checkoptionproduct = _.isEqual(
          item.optionproduct,
          productOrder.optionproduct
        );

        var checkoptionstandard = _.isEqual(
          item.optionstandard,
          productOrder.optionstandard
        );
        
        return checkNote || checkoptionnote || checkoptionproduct || checkoptionstandard
      })

      // Handle for cancel an exists order product
      if(typeof(productOrder.id) == "number" && productOrder.productStatus == 1) {
        // if(productOrder.productQty != productOrder.productRemain || productOrder.productQty == productOrder.productAmount) {
          const updated = await updateOrderDetail({
            id: productOrder.id,
            order_id: productOrder.orderID,
            branch_product_id: productOrder.productID,
            amount: productOrder.productAmount,
            cancel_amount: productOrder.productQty,
            orderNo: productOrder.orderNo
          });
          // console.log("🚀 ~ handleaddProducttoOrder ~ updated:", updated)
        // }

        setOrders((prev) => ({
          ...prev,
          product: prev.product.map((el) =>
            el.id === productOrder.id
              ? {
                ...el,
                productStatus: productOrder.productStatus,
                // productQty: productOrder.productQty,
                productQty: updated.orderDetail.remain_amount,
                productSumPrice: el.productSumPrice - productOrder.productSumPrice,
                // productSumPrice: productOrder.productPrice * productOrder.productQty,
                optionnote: productOrder.optionnote,
                optionproduct: productOrder.optionproduct,
                optionstandard: productOrder.optionstandard,
                note: productOrder.note,
                productAmount: updated.orderDetail.amount,
                productCancel: updated.orderDetail.cancel_amount,
                productRemain: updated.orderDetail.remain_amount,
                productStatus: updated.orderDetail.status_id
              }
              : el
          ),
        }));
      } else {
        setOrders((prev) => ({
          ...prev,
          product: prev.product.map((el) =>
            el.id === productOrder.id
              ? {
                ...el,
                productStatus: changed ? 0 : productOrder.productStatus,
                productQty: productOrder.productQty,
                productSumPrice: productOrder.productSumPrice,
                // productSumPrice: productOrder.productPrice * productOrder.productQty,
                optionnote: productOrder.optionnote,
                optionproduct: productOrder.optionproduct,
                optionstandard: productOrder.optionstandard,
                note: productOrder.note,
              }
              : el
          ),
        }));
      }
    } else {
      if (checkproduct.length > 0) {
        checkproduct.map((item, index) => {
          var checkNote = item.note === productOrder.note;
          var checkoptionnote = _.isEqual(
            item.optionnote,
            productOrder.optionnote
          );

          var checkoptionproduct = _.isEqual(
            item.optionproduct,
            productOrder.optionproduct
          );

          var checkoptionstandard = _.isEqual(
            item.optionstandard,
            productOrder.optionstandard
          );

          if (
            checkNote &&
            checkoptionnote &&
            checkoptionproduct &&
            checkoptionstandard
          ) {
            setOrders((prev) => ({
              ...prev,
              orderNo: (prev.orderNo != "" || prev.orderNo != "NA") ? prev.orderNo : latestOrderNo,
              product: prev.product.map((el) =>
                el.productID === productOrder.productID
                  ? {
                    ...el,
                    productQty: el.productQty + productOrder.productQty,
                    // productSumPrice: el.productSumPrice + (productOrder.productPrice * productOrder.productQty),
                    productSumPrice: el.productSumPrice + productOrder.productSumPrice,
                  }
                  : el
              ),
              employeeID: prev.employeeID ? prev.employeeID : user.employeeId,
            }));
          } else {
            setOrders((prev) => ({
              ...prev,
              orderNo: (prev.orderNo != "" || prev.orderNo != "NA") ? prev.orderNo : latestOrderNo,
              product: [...prev.product, productOrder],
              employeeID: prev.employeeID ? prev.employeeID : user.employeeId,
            }));
          }
        });
      } else {
        if(checkSameProduct.length > 0) {
          var productMenu = null;
          const same = checkSameProduct.map((item, index) => {
            var checkNote = item.note === productOrder.note;

            var checkoptionnote = _.isEqual(
              item.optionnote.map(o => o.listID),
              productOrder.optionnote.map(o => o.listID)
            );

            var checkoptionproduct = _.isEqual(
              item.optionproduct.map(o => o.listID),
              productOrder.optionproduct.map(o => o.listID)
            );

            var checkoptionproductAmount = _.isEqual(
              item.optionproduct.map(o => o.optionQty),
              productOrder.optionproduct.map(o => o.optionQty)
            );

            var checkoptionstandard = _.isEqual(
              item.optionstandard.map(o => o.listID),
              productOrder.optionstandard.map(o => o.listID)
            );

            var checkoptionstandardAmount = _.isEqual(
              item.optionstandard.map(o => o.optionQty),
              productOrder.optionstandard.map(o => o.optionQty)
            );

            if(checkNote && checkoptionnote && checkoptionproduct && checkoptionstandard && checkoptionproductAmount && checkoptionstandardAmount) {
              productMenu = item;
            }
            
            return checkNote && checkoptionnote && checkoptionproduct && checkoptionstandard && checkoptionproductAmount && checkoptionstandardAmount
          }).includes(true);
          // console.log("🚀 ~ handleaddProducttoOrder ~ productMenu:", productMenu)
          if(same) {
            if(typeof(productOrder.id) == "string") {
              setOrders((prev) => ({
                ...prev,
                orderNo: (prev.orderNo != "" || prev.orderNo != "NA") ? prev.orderNo : latestOrderNo,
                // product: [...prev.product, {...productOrder, productSumPrice: productOrder.productPrice * productOrder.productQty}],
                product: [...prev.product, {...productOrder, productSumPrice: productOrder.productSumPrice}],
                employeeID: prev.employeeID ? prev.employeeID : user.employeeId,
              }));
            } else {
              let newOrders = JSON.parse(JSON.stringify(orders));
              let newProducts = newOrders.product.map(p => p);
              let sameIndex = newProducts.findIndex(p => p.id == productMenu.id)
              newProducts[sameIndex].productQty += productOrder.productQty;
              let optionnotePrice = productOrder.optionnote.reduce((a,b) => a + b.listPrice, 0);
              let optionproductPrice = productOrder.optionproduct.reduce((a,b) => a + b.listPrice, 0);
              let optionstandardPrice = productOrder.optionstandard.reduce((a,b) => a + b.listPrice, 0);
              let price = productOrder.productPrice + optionstandardPrice + optionproductPrice + optionnotePrice;
              newProducts[sameIndex].productSumPrice += productOrder.productQty * price;
              // newProducts[sameIndex].productStatus = 0;
              setOrders((prev) => ({
                ...prev,
                orderNo: (prev.orderNo != "" || prev.orderNo != "NA") ? prev.orderNo : latestOrderNo,
                // product: [...prev.product, {...productOrder, productSumPrice: productOrder.productPrice * productOrder.productQty}],
                product: newProducts,
                employeeID: prev.employeeID ? prev.employeeID : user.employeeId,
              }));
              // setNewProduct(true);
            }
          } else {
            setOrders((prev) => ({
              ...prev,
              orderNo: (prev.orderNo != "" || prev.orderNo != "NA") ? prev.orderNo : latestOrderNo,
              // product: [...prev.product, {...productOrder, productSumPrice: productOrder.productPrice * productOrder.productQty}],
              product: [...prev.product, {...productOrder, productSumPrice: productOrder.productSumPrice}],
              employeeID: prev.employeeID ? prev.employeeID : user.employeeId,
            }));
          }
        } else {
          setOrders((prev) => ({
            ...prev,
            orderNo: (prev.orderNo != "" || prev.orderNo != "NA") ? prev.orderNo : latestOrderNo,
            // product: [...prev.product, {...productOrder, productSumPrice: productOrder.productPrice * productOrder.productQty}],
            product: [...prev.product, {...productOrder, productSumPrice: productOrder.productSumPrice}],
            employeeID: prev.employeeID ? prev.employeeID : user.employeeId,
          }));
        }
      }
    }

    setOptionmodal(false);
    clearoptionProductChecked();
    setAlertproduct(false);
  };

  const [optionProductChecked, setOptionProductChecked] = useState({
    optionstandard: [],
    optionproduct: [],
    optionnote: [],
  });
  const clearoptionProductChecked = () => {
    setOptionProductChecked({
      optionstandard: [],
      optionproduct: [],
      optionnote: [],
    });
  };

  const handleEditProduct = (item) => {
    // console.log("🚀 ~ handleEditProduct ~ item:", item)
    // if (item.productStatus == 1) {
    //   return false;
    // }
    // const checkproduct = products.filter((i) => i.productID === item.productID);
    // if (checkproduct.length > 0) {

    // setProductselect(prev => ({ ...prev, ...item }));
    // console.log("products: ", products)
    const checkproduct = products.filter(i => i.productID === item.productID);
    // console.log("🚀 ~ handleEditProduct ~ checkproduct:", checkproduct)
    if(checkproduct.length > 0){
      setProductselect(checkproduct[0]); // TODO: Recheck
      // setProductselect(item);
    } else {
      setProductselect(item);
    }

    item.optionstandard &&
      item.optionstandard.map((item) => {
        setOptionProductChecked((prev) => ({
          ...prev,
          optionstandard: [...prev.optionstandard, item.listID],
        }));
      });

    item.optionproduct &&
      item.optionproduct.map((item) => {
        setOptionProductChecked((prev) => ({
          ...prev,
          optionproduct: [...prev.optionproduct, item.listID],
        }));
      });

    item.optionnote &&
      item.optionnote.map((item) => {
        setOptionProductChecked((prev) => ({
          ...prev,
          optionnote: [...prev.optionnote, item.listID],
        }));
      });

    setProductOrder(item);
    setEdit(1);
    setOptionmodal(true);
  };

  // ส่งออเดอร์
  const sendOrders = async () => {
    setDisablePayOrderBtn(true);
    setOrders((prev) => ({
      ...prev,
      product: prev.product.map((el) => ({
        ...el,
        productStatus: el.productStatus > 1 ? el.productStatus : 1,
      })),
    }));
    const orderNo = orders.orderNo && orders.orderNo != "" ? orders.orderNo : selectedOrder.orderNo ? selectedOrder.orderNo : latestOrderNo;
    // console.log("🚀 ~ sendOrders ~ orderNo:", orderNo)
    const customer = members.find(member => member.id == custSeletedIdCf);
    // console.log("🚀 ~ sendOrders ~ customer:", customer)
    // console.log("644 - orders: ", orders)
    // console.log("645 - selectedTable: ", selectedTable)
    // console.log("646 - currentPOSActivity: ", currentPOSActivity)
    // const orderStatusId = orders.status_id == 4 ? 4 : 1;
    const orderStatusId = 1;
    const createdOrder = await createOrder(orders.id, orderNo, orders.product, orders.delivery, selectedTable, currentPOSActivity.operation_date, customer, user.employeeId, currentPOS.id, orderStatusId, orders);
    if(orders.delivery == 1) {
      // Reset Orders due to client feedback
      // setOrderState(prev => ({ ...prev, selectedOrder: {}}))
      // setOrders({...defaultOrders});
      // await fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
      await fetchLatestOrderNo(currentPOS.id);
      setOrders(prev => ({
        // ...prev,
        id: null,
        orderId: null,
        orderDate: new Date().toLocaleString('en-GB').replace(',',''),
        product: [],
        tableID: "",
        zone: "",
        delivery: 2,
        orderPrice: {
          sumprice: 0.0,
          discount: 0.0,
          vat: 0.0,
          net: 0.0,
        },
        member: null,
        member_id: null,
        payment: [],
        orderNo: latestOrderNo
      }));
      // setTimeout(() => {
      //   clearOrders();
      // }, 100);
      // await clearOrders();
      // Reset Selected Table
      setSelectedTable(null);
      resetCurrentPayment();
      setOrderState(prev => ({ ...prev, selectedOrder: {}}))
      setCustSeletedIdCf(null)
      setCurrentMember(null)
      fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
    } else {
      //  fetchAllOrder();
      // fetchLatestOrderNo(currentPOS.id)
    }
    await fetchAllTable(user.branchId);
    await fetchAllTableZone(user.branchId);
    setDisablePayOrderBtn(false);
  };

  const clearOrders = async () => {
    setOrders({
      id: null,
      orderId: null,
      orderNo: latestOrderNo,
      // orderDate: null,
      orderDate: new Date().toLocaleString('en-GB').replace(',',''),
      tableID: "",
      zone: "",
      delivery: selectedOptionDelivery?.value != 2 ? selectedOptionDelivery.value : 2,
      product: [],
      orderPrice: {
        sumprice: 0.0,
        discount: 0.0,
        vat: 0.0,
        net: 0.0,
      },
      payment: [],
      member_id: null,
      member: null,
      remark: null
    });
    // getOrderNo();
    resetCurrentPayment();
    setSelectedTable(null);
    setOrderState(prev => ({ ...prev, selectedOrder: {}}))
    setCustSeletedIdCf(null)
    setCurrentMember(null)
    // await fetchLatestOrderNo(currentPOS.id)

    if(modalPaymentSuccessOpen) {
      // Reset Order and table
      setOrderState(prev => ({ ...prev, selectedOrder: {}}))
      // setOrders({...defaultOrders});
      setSelectedTable(null);
    }
  };

  // พักบิล
  const handleBill = () => {
    // console.log("handleBill: ", orders)
    // setModalHoldBillConfirmOpen(true);
    // if (orders.table != "" && orders.product.length > 0) {
    //   setModalHoldBillConfirmOpen();
    // } else {
      // if (orders.table == "" || orders.tableID == "") {
      //   setAlerttable(true);
      // }
      // if (orders.product.length <= 0) {
      //   setAlertproduct(true);
      // }
    // }
    // if (orders.tableID == "" || !orders.table?.id) {
    //   setAlerttable(true);
    // }
    if(orders.product.length <= 0) {
      setAlertproduct(true);
    } 
    // else if (orders.tableID == "" || !orders.table?.id || !selectedTable || !selectedTable.id || !selectedTable.tableActivity) {
    //   setAlerttable(true);
    // } 
    else {
      setModalHoldBillConfirmOpen(true);
    }
  };

  // ชำระเงิน เพื่อขึ้น Modal จ่ายเงิน
  const handlePayment = async () => {
    // TODO: Recheck and mapping bill data check
    if (orders.product.length > 0) {
      await setRequestLoading({
        isRequestLoading: true
      });
      // Update order in case of Member binding
      // const selectedTableActivity = selectedTable?.activity?.id ? selectedTable.activity.id : selectedTable?.tableActivity?.id ? selectedTable.tableActivity.id : null;
      // await updateOrder(orders, { ...orders, member: currentMember }, selectedTableActivity, currentPOSActivity.operation_date,)
      // Create Bill
      // if(!selectedOrder || Object.keys(selectedOrder).length !== 0) {
      //   await createBill(selectedOrder, currentPOS.id, selectedTable);
      // } else {
      //   await createBill(orders, currentPOS.id, selectedTable);
      // }
      await createBill(orders, currentPOS.id, selectedTable);
      await setRequestLoading({
        isRequestLoading: false
      });
      // Open payment Modal
      // added timeout to prevent payment data not display in payment modal
      setIsPaymentMode(true);
      setModalPaymentOpen(true);
      // setTimeout(() => {
      //   setIsPaymentMode(true);
      //   setModalPaymentOpen(true);
      // }, 2000)
    } else {
      if (orders.table == "") {
        setAlerttable(true);
      }
      if (orders.product.length <= 0) {
        setAlertproduct(true);
      }
    }
  };

  const onCalbillBtnHandler = async () => {
    setModalCallBillOpen(true);
  }

  // พักบิล handler
  const onConfirmBtnHandler = async () => {
    setModalHoldBillConfirmOpen(false);
    await setRequestLoading({
      isRequestLoading: true
    });
    const updateStatus = {
      status_id: 4
    }
    const orderToBeUpdate = {
      ...orders,
      orderNo: "NA"
    }
    const selectedTableActivity = selectedTable?.activity?.id ? selectedTable.activity.id : selectedTable?.tableActivity?.id ? selectedTable.tableActivity.id : null;
    await updateOrder(orderToBeUpdate, updateStatus, selectedTableActivity, currentPOSActivity.operation_date, currentPOS.id, user.employeeId, currentMember);

    await fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
    await fetchLatestOrderNo(currentPOS.id);
    clearOrders();
    setSelectedTable(null);
    setCustSeletedIdCf(null);
    setCurrentMember(null);
    setSelectedOptionDelivery(optionsDelivery[0])
    setOrders(prev => ({ ...prev, delivery: 2 }));
    setBillState(state => ({ ...state, currentBill: {}}))
    // modalSuccessOpenObj.setModalOpen(true);
    // navigate("/");
    await fetchAllTable(user.branchId);
    await fetchAllTableZone(user.branchId);
    await setRequestLoading({
      isRequestLoading: false
    });
    setSuccessText("พักบิลสำเร็จ");
    setModalSuccessOpen(true);
  }

  useEffect(() => {
    // if(order)
    // console.log("🚀 ~ file: Home.jsx:608 ~ useEffect ~ orders:", orders)
    // console.log("selectedOptionDelivery: ", selectedOptionDelivery)
    // console.log("selectedTable: ", selectedTable)

    // If it's an existed order
    if(orders.id && orders.id != '' && orders.orderNo != '') {

      // If an existed order is DineIn, find table to be display
      if (orders.delivery == 1) {
        const table = [...tables].filter(t => t.id == orders.tableID)[0]
        // console.log("🚀 ~ file: Home.jsx:621 ~ useEffect ~ table:", table)
        if(table) {
          setSelectedTable(({ value: table.id, label: `${table.no} (${table.zone.alias})`, ...table }))
        }
      }

      // If swith delivery option along the way
      if(selectedOptionDelivery.value != orders.delivery) {
        clearOrders();
      }
    } else {
      // If newly order
    }

  }, [selectedOptionDelivery])

  // Handle selected table's order
  useEffect(() => {
    if (
        selectedTable && 
        (selectedTable.activity || selectedTable.tableActivity) && 
        orderList && 
        orderList.length > 0
      ) {
      const tableOrder = [...orderList]
        .filter(order => 
          (order.table_activity || order.table_activity?.id) &&
          (order.table_activity_id == selectedTable.activity?.id || order.table_activity_id == selectedTable.tableActivity?.id) && 
          (order.status_id == 1 || order.status_id == 4) &&
          order.operation_date == currentPOSActivity.operation_date && order.type_id == 1
        )[0];
      
      if(tableOrder) {
        if(!orders?.id || orders?.id == '' || !orders.orderNo || orders.orderNo == '' || orders.tableID != selectedTable?.id) {
          const newOrders = {
            ...orders, 
            ...tableOrder,
            orderNo: tableOrder.order_id && tableOrder.order_id != "NA" ? tableOrder.order_id : latestOrderNo ? latestOrderNo : "",
            // orderDate: null,
            orderDate: new Date().toLocaleString('en-GB').replace(',',''),
            tableID: tableOrder?.table_activity?.table_id ? tableOrder.table_activity.table_id : "",
            zone: tableOrder?.table_activity?.table?.zone ? tableOrder.table_activity.table.zone : "",
            delivery: tableOrder.type_id ?? 1,
            // product: [],
            orderPrice: {
              sumprice: tableOrder.sumPrice,
              discount: 0.0,
              vat: 0.0,
              net: 0.0,
            },
            payment: [],
          }

          if (newOrders.delivery == 1) {
            setOrders(newOrders);
            sumOrders(orders);
          } else if(!orders.id){
            // setOrders(...prev => ({
            //   ...prev,
            //   zone: tableOrder?.table_activity?.table?.zone ? tableOrder.table_activity.table.zone : "",
            // }));
          } else {
            setOrders({
              id: null,
              orderNo: latestOrderNo,
              // orderDate: null,
              orderDate: new Date().toLocaleString('en-GB').replace(',',''),
              tableID: "",
              zone: "",
              delivery: 2,
              product: [],
              orderPrice: {
                sumprice: 0.0,
                discount: 0.0,
                vat: 0.0,
                net: 0.0,
              },
            });
          }
        }
      }
      else {
        if ( 
            ( 
              selectedTable.activity?.id !== orders.table_activity_id ||
              selectedTable.activity?.id !== orders.table_activity?.id ||
              selectedTable.tableActivity?.id !== orders.table_activity?.id ||
              selectedTable.tableActivity?.id !== orders.table_activity?.id ||
              selectedTable.id !== orders.tableID
            )
          )
        {
          if(orders.id) {
            if(orders.status_id == 4 || orders.billStatus == 4 || orders.orderNo == "NA") {
              setOrders(prev => ({
                ...prev,
                tableID: selectedTable.id ?? "",
                zone: selectedTable.zoneId ?? "",
              }));
            } else {
              setOrders({
                id: null,
                orderNo: latestOrderNo,
                // orderDate: null,
                orderDate: new Date().toLocaleString('en-GB').replace(',',''),
                tableID: "",
                zone: "",
                delivery: 2,
                product: [],
                orderPrice: {
                  sumprice: 0.0,
                  discount: 0.0,
                  vat: 0.0,
                  net: 0.0,
                },
                tableID: selectedTable.id ?? "",
                zone: selectedTable.zoneId ?? "",
              });
            }
          } else {
            setOrders(prev => ({
              ...prev,
              tableID: selectedTable.id ?? "",
              zone: selectedTable.zoneId ?? "",
            }));
          }
        }
      }
    } 
  }, [selectedTable])

  // refresh icon
  const refreshOrderDetail = async () => {
    const order = await getOrder(orders.id);
    // const newOrderProducts = orders.product.filter(p => typeof(p.id) == "string");
    setOrders(prev => ({ ...prev, product: [...order.product] }))
  }

  useEffect(() => {
    if(selectedOrder && Object.keys(selectedOrder).length > 0) {
      setOrders(prev => ({ 
        ...prev,
        ...selectedOrder,
        orderId: selectedOrder.orderId ? selectedOrder.orderId : selectedOrder.id ? selectedOrder.id : null,
        orderNo: !orders.id ? latestOrderNo : (selectedOrder.orderNo && selectedOrder.orderNo != "NA") ? selectedOrder.orderNo : latestOrderNo,
      }))
    
      if(orders && orders.id && !isPaymentMode && (selectedOrder.status_id != 4 || orders.status_id != 4)) {
        refreshOrderDetail()
      }
    }
  }, [selectedOrder])

  // useEffect(() => {
    // if(modalPaymentSuccessOpen) {
      // Reset Order and table
      // setOrderState(prev => ({ ...prev, selectedOrder: {}}))
      // setOrders({...defaultOrders});
      // setSelectedTable(null);
    // }
  // }, [modalPaymentSuccessOpen])

  useEffect(() => {
    // CM2011040001
    // TODO: Handle this code generato from API side
    if(members && members.length > 0) {
      const currentDate = new Date();
      const count = String(members.length + 1).padStart(6, '0');
      const code = `CM${currentDate.getFullYear().toString().substring(2)}${count}`;
      setMemberCode(code);

      let parsed = [...members.map(member => ({
        id: member.id,
        name: member.name ? member.name : `${member.firtname} ${member.lastname}`,
        phoneNumber: member.phone_number,
        position: member.member_level,
        customerId: member.code,
        customerType: member.customerType,
        birthdate: new Date(member.birthdate).toLocaleDateString('th-TH', { year: 'numeric', month: "2-digit", day: "2-digit"}),
        // point: parseInt(Math.random() * 10000),
        point: member.point ? member.point : 0,
        avatar:
          Math.random() > 0.5
            ? "/img/ex-avatar-male-purple.png"
            : "/img/ex-avatar-female-green.png",
      }))]
      setCustomerList(parsed);
    } else {
      const currentDate = new Date();
      const count = String(1).padStart(6, '0');
      const code = `CM${currentDate.getFullYear().toString().substring(2)}${count}`;
      setMemberCode(code);
    }
  }, [members])

  useEffect(() => {
    if(!orders.orderNo || orders.orderNo == "" || !orders.id || orders.orderNo == "NA" || !orders.orderId) {
      setOrders(prev => ({ ...prev, orderNo: latestOrderNo }))
    }
  }, [latestOrderNo])
  
  // ค้นหาเมนูอาหาร
  useEffect(() => {
    if(filterText != "") {
      if (productList.length > 0) {
        let filtered = productList.filter(
          (item) =>
            item.productName &&
            item.productName.toLowerCase().includes(filterText.toLowerCase())
        );
        filtered = filtered.filter(f => f.branchId == user.branchId)
        if(subcategoryID > 0) {
          filtered = filtered.filter(f => f.subCategoryId == subcategoryID)
        }
        setProductfilter(filtered);
      }
    } else {
      let filtered = productList.filter(f => f.branchId == user.branchId)
      if(subcategoryID > 0) {
        filtered = filtered.filter(f => f.subCategoryId == subcategoryID)
      }
      setProductfilter(filtered);
    }
  }, [filterText])

  useEffect(() => {
    if(custSeletedIdCf) {
      if(members.length > 0) {
        const member = members.filter(m => m.id == custSeletedIdCf)[0];
        // console.log("🚀 ~ useEffect ~ member:", member)
        setCurrentMember(member);
        setOrders(prev => ({ ...prev, member_id: member.id, member: member }))
      }
    }
    // else {
    //   setOrders(prev => ({ ...prev, member_id: null, member: null }))
    // }
  }, [custSeletedIdCf])
  
  useEffect(() => {
    if(!modalSuccessOpen && successText) {
      setTimeout(() => {
        setSuccessText(null);
      }, 500)
    }
  }, [modalSuccessOpen])
  
  // Check bill btn from Table page
  useEffect(() => {
    if(currentBill && currentBill.id) {
      const billOrder = orderList.find(order => order.orderNo == currentBill.order_id)
      if(billOrder && billOrder.id && isTableOrderCheckBill) {
        setOrders(billOrder)
        setModalPaymentOpen(true)
        setTableState(prevState => ({ ...prevState, isTableOrderCheckBill: false }))
      }
    }
  }, [currentBill])

  const onRegisterSubmitHandler = async (registerInfo) => {
    if(registerInfo) {
      registerInfo["businessId"] = branch?.business_id ?? null;
      registerInfo["branchId"] = branch?.id ?? null;
    }
    await createMember(registerInfo);
  }

  const onPrintBillPaperHandler = async () => {
    handlePrintBill()
  }

  const clearMember = async () => {
    if(orders.id) {
      await setRequestLoading({
        isRequestLoading: true
      });
      const update = await updateOrderMember({
        orderId: orders.id,
        memberId: null
      });
      await setRequestLoading({
        isRequestLoading: false
      });
    }
    setCustSeletedIdCf(undefined);
    setCurrentMember(null);
    setOrders(prev => ({ ...prev, member: null, member_id: null }))
    setBillState(state => ({ ...state, member: {} }))
  }

  const cancelHoldOrder = async (order) => {
    await deleteOrder(order);
    await fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
  }

  const onUpdateOrderStaffHandler = async (staff) => {
    if(staff) {
    const employee = employees.find(e => e.code == staff.code);
      const update = await updateOrderEmployee({
        orderId: orders.id,
        employeeId: employee.id
      })
      setOrders(prev => ({ ...prev, employee_id: update.employee_id, employee: update.employee }))
    } else {
      const update = await updateOrderEmployee({
        orderId: orders.id,
        employeeId: null
      })
      setOrders(prev => ({ ...prev, employee_id: null, employee: null }))
    }
    await fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
  }

  const setOrderRemark = async (remark) => {
    setOrders(prev => ({
      ...prev,
      remark: remark
    }));
  }

  const onConfirmOrderRemarkHandler = async (remark) => {
    setOrders(prev => ({
      ...prev,
      remark: remark
    }));

    if(orders.id) {
      const updateOrder = await updateOrderRemark({
        id: orders.id,
        remark: remark
      })
    }
  }

  const addMemberToOrder = async (customerId) => {
    if(customerId && orders.id) {
      await setRequestLoading({
        isRequestLoading: true
      });
      const update = await updateOrderMember({
        orderId: orders.id,
        memberId: customerId
      });
      await setRequestLoading({
        isRequestLoading: false
      });
    }
  }

  return (
    <div className="h-100" id="ccvv">
      <div className="block-2">
        <HeaderPos isSearch={true} filterText={filterText} setFilterText={setFilterText} />
        <div className="px-3 pt-3 pb-2 d-flex flex-column bx-content">
          <CategorySlide
            categoryObj={{
              categoryID: categoryID,
              setcategoryID: setcategoryID,
            }}
            handleClick={(id) => {
              setcategoryID(id);
              // setsubcategoryID("");
            }}
            categorys={categorys}
          />
          <CategorySubSlide
            categoryObj={{
              categoryID: subcategoryID,
              setcategoryID: setsubcategoryID,
            }}
            handleClick={(id) => setsubcategoryID(id)}
            // categorys={subcategorys}
            categorys={filteredSubCategories}
          />
          <div className=" d-flex flex-column" id="foodlist">
            <ProductPaginate
              items={productfilter}
              handleoptionShow={(item) => {
                handleSelectProduct(item);
                setEdit(0);
                clearoptionProductChecked();
              }}
              categoryID={categoryID}
            />
          </div>
        </div>
      </div>
      <div className="block-3">
        <div className="customShow min-h-[100vh]">
          <div className="ordertop py-2 px-2">
            <div className="fw-5 tc-8">
              ORDER NO. <span className="tc-6 ms-3">
                {
                  orders.orderNo && (orders.orderNo != "" || orders.orderNo != "NA") ? orders.orderNo :
                  selectedOrder.orderNo && selectedOrder.orderNo != "NA" ? selectedOrder.orderNo : latestOrderNo
                }
                </span>
            </div>
            <div className="mb-2">
              <span className="i-calendar me-2"></span>
              <span className="tc-8 fs-14 fw-light">{orders.orderDate}</span>
            </div>
            {custSeletedIdCf && customerList && (
              <MemberSelectedCard
                info={{ ...customerList.find((v) => v.id === custSeletedIdCf) }}
                handleClear={clearMember}
              />
            )}
            {!custSeletedIdCf && (
              <button
                onClick={() => {
                  setmodalSearchMemberOpen(true);
                }}
                className="btn btn-add-member"
              >
                เพิ่มสมาชิก
              </button>
            )}
          </div>
          <div className="flex align-items-center px-2 justify-start">
            <div className="form-select-image">
              <Select
                // className={` selectedimg  w-100 select-arrow  selectedimg-${orders.delivery} `}
                className={` selectedimg  w-100 select-arrow  selectedimg-${selectedOptionDelivery.value} `}
                // defaultValue={optionsDelivery[0]}
                defaultValue={selectedOptionDelivery}
                options={optionsDelivery}
                onChange={(e) => {
                  if(!orders.id || orders.id == "") {
                    setOrders((prev) => ({ ...prev, delivery: e.value }));
                  }
                  setSelectedOptionDelivery(optionsDelivery.filter(o => o.value == e.value)[0]);
                }}
                // value={optionsDelivery.filter(
                //   (item) => item.value === orders.delivery
                // )}
                value={selectedOptionDelivery}
                placeholder=""
                isSearchable={false}
                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
              />
            </div>

            {
              selectedOptionDelivery.value === 1 &&
              <button
                className="btn btn-desk mx-1"
                data-bs-toggle="modal"
                data-bs-target="#deskModal"
                onClick={() => setModalTableSelect(true) }
              >
                โต๊ะ
              </button>
            }

            {
              (selectedOptionDelivery.value === 1) &&
              // <div className="w-100 form-select-st-2">
              //   <Select
              //     className="w-100 select-arrow"
              //     options={tableOptions}
              //     placeholder="ยังไม่เลือกโต๊ะ"
              //     onChange={(e) => {
              //       setOrders((prev) => ({ ...prev, tableID: e.value }));
              //       setAlerttable(false);
              //       setSelectedTable(({ value: e.value, label: e.label, ...tables.filter(t => e.value == t.id)[0] }))
              //     }}
              //     defaultValue={selectedTable}
              //     value={selectedTable}
              //   />
              // </div>
              <div className="w-100 p-2 rounded-lg text-center bg-[#FDF0F0]">
                <p className="text-[#F95057] text-sm">{selectedTable?.label ?? "เลือกโต๊ะ"}</p>
              </div>
            }

            {
              selectedOptionDelivery.value === 2 &&
              <button
                className="btn btn-pencil mx-2"
                data-bs-toggle="modal"
                data-bs-target="#deskModal"
                onClick={() => setModalOrderRemark(true) }
              >
              </button>
            }

            {
              selectedOptionDelivery.value === 2 &&
              <div className="pl-2 text-md" >{orders.remark}</div>
            }
            
          </div>
          <div>
            <div className=" py-2">
              <div className="px-2">
                <div className="row gx-2 tc-17 fs-14">
                  <div className="col-7">รายการ</div>
                  <div className="col-2 text-center">จำนวน</div>
                  <div className="col-3 text-end">ราคา</div>
                </div>
              </div>
              <hr className="mt-1 mb-0" />
              <div className="bx-main-order-list ">
                <ul className="p-0 m-0  w-100 ">
                  {alerttable || alertproduct ? (
                    <li
                      className="fs-18 d-flex align-items-center justify-content-center"
                      style={{
                        color: "#F95057",
                        fontWeight: "600",
                        height: "calc(100vh - 363px)",
                      }}
                    >
                      {alerttable
                        ? "ไม่พบโต๊ะที่ต้องการชำระเงิน"
                        : "ไม่พบรายการอาหาร"}
                    </li>
                  ) : !modalPaymentSuccessOpen && (
                    <ProductOrderDetail
                      products={orders.product}
                      orders={orders}
                      handleClick={(id) => handleEditProduct(id)}
                    />
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="orderbottom pb-2 px-0">
            <Collapse in={collapseShow}>
              <div id="collapse1">
                <div className="card card-body border-0 bg-c1 px-3 py-2">
                  <div className="d-flex fs-14 tc-10 px-1">
                    <div className="me-auto">ยอดรวม</div>
                    <div className="" id="txsum">
                      {orders.orderPrice.sumprice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}฿
                    </div>
                  </div>
                  <div className="d-flex fs-12 tc-10 px-1">
                    <div className="me-auto">ส่วนลด</div>
                    <div id="txdiscount">
                      {orders.orderPrice.discount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}฿
                    </div>
                  </div>
                  <div className="d-flex fs-12 tc-10 px-1">
                    <div className="me-auto">ภาษี</div>
                    <div id="txtax">{orders.orderPrice.vat.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}฿</div>
                  </div>
                </div>
              </div>
            </Collapse>
            <div className="text-center">
              {
                enableSendOrderBtn ?
              (
                <button
                  className="btn btn-st-10 btn-send-order mt-3 mb-3"
                  onClick={async () => {
                    setEnableSendOrderBtn(false);
                    await sendOrders();
                  }}
                >
                  ส่งออเดอร์
                </button>
              ) : ( "" )}
            </div>
            <button
              className="btn collapse-total collapsed mb-3"
              type="button"
              onClick={() => setCollapseShow(!collapseShow)}
              aria-controls="collapse1"
              aria-expanded={collapseShow}
            >
              <div className="d-flex align-items-center  ">
                <div className="me-auto">ยอดรวมทั้งหมด </div>
                <div id="txnet">{orders.orderPrice.net.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}฿</div>
              </div>
            </button>

            <div className="d-flex align-items-center justify-content-between px-2 mb-1">
              <Dropdown as={ButtonGroup}>
                <button 
                  className="btn btn-st-6 text-white" 
                  onClick={() => onCalbillBtnHandler()}
                  // disabled={orders.id && (orders.orderNo != "" || orders.orderNo != "NA") || (orders.delivery == 1 && (!orders.tableID || orders.tableID == "" || !selectedTable || !selectedTable.id))}
                >
                  เรียกบิล
                </button>
                <Dropdown.Toggle
                  split
                  className="i-ar"
                  id="dropdown-split-basic"
                />
                <Dropdown.Menu>
                  <div
                    className="d-flex align-items-center"
                    style={{ marginLeft: "50px" }}
                  >
                    <button
                      disabled={orders.id && (orders.orderNo != "" || orders.orderNo != "NA")}
                      className="btn btn-pause-bill text-white px-2"
                      onClick={handleBill}
                    >
                      พักบิล
                    </button>
                    <button
                      className="btn btn-st-7  ms-1 "
                      onClick={async () => {
                        await setRequestLoading({
                          isRequestLoading: true
                        });
                        await clearOrders()
                        setBillState(state => ({ ...state, currentBill: {}}))
                        await fetchLatestOrderNo(currentPOS.id)
                        await setRequestLoading({
                          isRequestLoading: false
                        });
                      }}
                    >
                      ล้างรายการ
                    </button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>

              <div className="ps-2 flex-fill">
                <button
                  className="btn btn-pay btn-payment w-100"
                  onClick={handlePayment}
                  disabled={!orders.id || orders.product.filter(p => p.productStatus == 0).length > 0 || disablePayOrderBtn}
                >
                  ชำระเงิน
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
              
      <ModalProductOption
        show={optionmodal}
        orders={orders}
        onHide={() => setOptionmodal(false)}
        productselect={productselect}
        productOrder={productOrder}
        prevProductOrder={prevProductOrder}
        edit={edit}
        setProductOrder={setProductOrder}
        optionProductChecked={optionProductChecked}
        addProductOrders={() => {
          handleaddProducttoOrder();
        }}
        removeProductOrders={() => {
          setOptionmodal(false);
          setOrders((prev) => ({
            ...prev,
            product: prev.product.filter(
              // (item) => item.productID !== productOrder.productID
              (item) => item.id !== productOrder.id
            ),
          }));
          // setOptionmodal(false)
          // setOrders(prev => ({
          //     ...prev,
          //       product:prev.product.filter(item => item.id !== productOrder.id) 
          //   }) 
          // )
        }}
      />

      <ModalPayment
        modalOpenObj={{
          modalOpen: modalPaymentOpen,
          setModalOpen: setModalPaymentOpen,
        }}
        modalSuccessOpenObj={{
          modalOpen: modalPaymentSuccessOpen,
          setModalOpen: setModalPaymentSuccessOpen,
        }}
        paymentChannels={paymentChannels}
        order={orders}
        user={user}
        bill={currentBill}
        currentPos={currentPOS}
        currentBranch={branch}
        // onPrintBtn={onPrintBillPaperHandler}
        onPrintBtn={handlePrintBill}
        setCurrentPayment={ async (payments) => {
          // console.log("🚀 ~ handleEditProduct ~ payments:", payments);
          // TODO: Set payment success channel via Delivery here using payments'element isViaDelivery field
          const paymentNames = payments.map(p => p.channel.paymentChannelName);
          const onePaymentName = paymentNames.every(pn => pn == paymentNames[0]);
          if(onePaymentName) {
            setCurrentBillPayments(paymentNames[0])
          } else {
            // console.log("🚀 ~ handleEditProduct ~ paymentNames:", paymentNames)
            setCurrentBillPayments("หลายช่องทาง")
          }
        }}
        member={currentMember}
        staffList={employees}
        setIsPaymentMode={setIsPaymentMode}
        currentPOS={currentPOS}
        updateOrderStaff={onUpdateOrderStaffHandler}
        updateOrderRemark={updateOrderRemark}
        endBillDiscount={endBillDiscount}
        setEndBillDiscount={setEndBillDiscount}
        setRequestLoading={setRequestLoading}
        printBillFull={handlePrintBillFull}
        printBillFullSuccess={handlePrintBillFullSuccess}
        members={members}
      />

      <ModalPaymentSuccess
        modalOpenObj={{
          modalOpen: modalPaymentSuccessOpen,
          setModalOpen: setModalPaymentSuccessOpen,
        }}
        orders={orders}
        handleClick={async () => {
          // เริ่มต้นขายใหม่
          await setRequestLoading({
            isRequestLoading: true
          });
          await clearOrders();
          setCurrentBillPayments("");
          setBillState(state => ({ ...state, currentBill: {}}))
          setPaymentState(state => ({ 
            ...state,
            currentPayment: {
              billTotalAmount: 0,
              paidAmount: 0,
              remainingAmount: 0,
              changes: 0
            }
          }))
          // setCurrentBill
          setModalPaymentSuccessOpen(false);
          setIsPaymentMode(false);
          setCurrentMember(null);
          setCustSeletedIdCf(null)
          await fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
          // setSelectedOptionDelivery(optionsDelivery[0])
          await fetchAllMember({
            businessId: user?.businessId ? user.businessId : branch?.business_id ?? null
          })
          setOrderState(prev => ({ ...prev, selectedOrder: {}}))
          await fetchLatestOrderNo(currentPOS.id)
          await fetchAllTable(user.branchId);
          await fetchAllTableZone(user.branchId);
          await setRequestLoading({
            isRequestLoading: false
          });
        }}
        order={orders}
        bill={currentBill}
        payment={currentPayment}
        currentBillPayments={currentBillPayments}
        onPrintBtn={handlePrintBillFull}
        setRequestLoading={setRequestLoading}
      />

      {
        modalCallBillOpen &&
        <ModalCallBill
          modalOpenObj={{
            modalOpen: modalCallBillOpen,
            setModalOpen: setModalCallBillOpen,
          }}
          modalSuccessOpenObj={{
            modalOpen: modalSuccessOpen,
            setModalOpen: setModalSuccessOpen,
          }}
          setOrders={setOrders}
          bills={orderList}
          staffName={`${user.firstNameTH} ${user.lastNameTH}`}
          pos={currentPOS}
          cancelOrder={cancelHoldOrder}
        />
      }

      <ModalHoldBillConfirm
        modalOpenObj={{
          modalOpen: modalHoldBillConfirmOpen,
          setModalOpen: setModalHoldBillConfirmOpen,
        }}
        modalSuccessOpenObj={{
          modalOpen: modalSuccessOpen,
          setModalOpen: setModalSuccessOpen,
        }}
        order={orders}
        setSelectedTable={setSelectedTable}
        setOrders={setOrders}
        selectedTable={selectedTable}
        currentPOSActivity={currentPOSActivity}
        currentPOS={currentPOS}
        onConfirm={onConfirmBtnHandler}
        isLoading={isLoading}
      />

      <ModalChangeStaff
        modalOpenObj={{
          modalOpen: modalChangeStaffOpen,
          setModalOpen: setModalChangeStaffOpen,
        }}
        modalPinOpenObj={{
          modalOpen: modalPinOpen,
          setModalOpen: setModalPinOpen,
        }}
        selectedUserIdObj={{
          selectedUserId,
          setSelectedUserId,
        }}
        staffList={employees}
      />

      <ModalPin
        modalOpenObj={{
          modalOpen: modalPinOpen,
          setModalOpen: setModalPinOpen,
        }}
        modalSuccessOpenObj={{
          modalOpen: modalSuccessOpen,
          setModalOpen: setModalSuccessOpen,
        }}
      />

      <ModalSuccess
        modalOpenObj={{
          modalOpen: modalSuccessOpen,
          setModalOpen: setModalSuccessOpen,
        }}
        successText={successText}
      />

      <ModalSearchMember
        customerList={customerList}
        setSelectIdCf={setCustSeletedIdCf}
        modalOpenObj={{
          modalOpen: modalSearchMemberOpen,
          setModalOpen: setmodalSearchMemberOpen,
        }}
        modalRegisterMemberOpenObj={{
          modalOpen: modalRegisterMemberOpen,
          setModalOpen: setmodalRegisterMemberOpen,
        }}
        addMemberToOrder={addMemberToOrder}
      />

      <ModalRegisterMember
        modalOpenObj={{
          modalOpen: modalRegisterMemberOpen,
          setModalOpen: setmodalRegisterMemberOpen,
        }}
        modalSuccessOpenObj={{
          modalOpen: modalRegisterSuccess,
          setModalOpen: setmodalRegisterSuccess,
        }}
        onSubmit={onRegisterSubmitHandler}
        memberCode={memberCode}
      />

      <ModalMemberRegisterSuccess
        className={"min-w-[min(31.74vw,325px)] text-22"}
        modalOpenObj={{
          modalOpen: modalRegisterSuccess,
          setModalOpen: setmodalRegisterSuccess,
        }}
        setModalMemberSearch={setmodalSearchMemberOpen}
        contentText="สมัครสมาชิกสำเร็จ"
      />

      <ModalTableSelect
        modalOpenObj={{
          modalOpen: modalTableSelect,
          setModalOpen: setModalTableSelect,
        }}
        handleTableSelect={async (tableSelected, isLimit, limitTime) => {
          // console.log("🚀 ~ handleEditProduct ~ tableSelected:", tableSelected)
          setSelectedTable(tableSelected)
          setFromSelectedTable(tableSelected)

          if(!orders.id) {
            setOrders( (prev) => ({ ...prev, tableID: tableSelected.tableID }) )
          }

          if(tableSelected?.type == "empty" || tableSelected?.type == "reserved") {
            const updateDetail = {
              is_active: true,
              isActive: true,
              guest: tableSelected?.currentPerson ?? 1,
              isLimitTime: isLimit ?? null,
              limitHours: limitTime?.hour ?? "0",
              limitMinutes: limitTime?.minute ?? "0",
            };
            // updateTable(updateDetail);
            await openTable(updateDetail, tableSelected);
          }
          // setSelectedTable(tableSelected)
          // fetchAllTable()
          setModalTableSelect(false)
        }}
        tables={tables}
        tableZone={tableZone}
      />

      {modalOrderRemark &&
        <ModalOrderRemark
          modalOpenObj={{
            modalOpen: modalOrderRemark,
            setModalOpen: setModalOrderRemark,
          }}
          orderRemarkObj={{
            orderRemark: orders.remark,
            setOrderRemark: setOrderRemark,
          }}
          onConfirm={onConfirmOrderRemarkHandler}
        />
      }

      <div style={{ display: "none" }}>
        <BillPaperToPrint 
          ref={billRef} 
          order={orders}
          user={user}
          bill={currentBill}
          pos={currentPOS}
          branch={branch}
          type="invoice"
          endBillDiscount={endBillDiscount}
        />

        <BillPaperToPrint
          ref={billPrintFullRef}
          order={orders}
          user={user}
          bill={currentBill}
          pos={currentPOS}
          branch={branch}
          type="receipt"
          copy={false}
        />
      </div>

      <LoadingSpinner 
        modalOpenObj={{
          modalOpen: isRequestLoading,
          setModalOpen: null
        }}
      />

    </div>
  );
}

const usePrevious = (state) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  }, [state]);
  return ref.current;
};
