import axiosInstance from './axios';
import { jwtDecode } from 'jwt-decode';

export const checkPOSSlug = async ({ slug, slugBranch }) => {
    try {
        const queryParams = {
            slug: slug,
            slugBranch: slugBranch
        }

        const response = await axiosInstance.get('/auth/check-slug', { params: queryParams });
        // console.log("🚀 ~ checkPOSSlug ~ response:", response)

        if (response.status === 200) {
            if (response.data) {
                return {
                    business: response.data.business,
                    isValidSlug: true,
                    slugBranch:  response.data.slugBranch,
                    branch: response.data.branch
                }
            } else {
                return {
                    business: null,
                    isValidSlug: false
                }
            }
        }
        else if (response.status === 404) {
            return false
        }
        else {
            console.error('Unexpected status code:', response.status);
            return false
        }

    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}