import { Button } from "@mui/material";
import React from "react";

export default function TableBtn({ content, isActive = false, ...props }) {
  return (
    <Button
      {...props}
      sx={{
        "&:hover": {
          boxShadow: "inherit",
        },
      }}
      className={`px-0 sarabun min-w-[min(9.18vw,94px)] h-[min(3.32vw,34px)] rounded-[min(0.78vw,8px)] ${
        isActive ? "" : "btn-table-not-active"
      } ${props.className}`}
      variant="contained"
    >
      {content}
    </Button>
  );
}