import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { posState, selectedPosSettingState } from "state/pos";
import { authState } from "state/auth";
import { branchState } from "state/branch";
import { useFetchBranch } from "state/branch";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomBtn from "components/fragments/CustomBtn";
import { numberWithTwoDecimals } from "utils/numberFormat";

export default function ModalSuccessMoneyDrawer({
  selectedUserIdObj,
  modalOpenObj,
  startValueObj,
  isResetMoneyControllerObj,
  info,
  ...props
}) {
  // console.log("🚀 ~ info:", info);
  const [value, setValue] = useState(startValueObj.startValue);
  const selectedPosSetting = useRecoilValue(selectedPosSettingState);
  const { user } = useRecoilValue(authState);
  const { branch } = useRecoilValue(branchState);
  const fetchBranch = useFetchBranch();
  const { currentPOS, summary } = useRecoilValue(posState);
  const [isPrint, setIsPrint] = useState(false);

  useEffect(() => {
    if (modalOpenObj.modalOpen) {
      setValue(startValueObj.startValue);
      isResetMoneyControllerObj.setReset(true);
    }
  }, [modalOpenObj.modalOpen]);

  useEffect(() => {
    if (user.branchId && user.branchId !== 0) {
      fetchBranch(user.branchId);
    }
  }, [user]);

  


  const onPrintHandler = () => {
    setIsPrint(true);
  };

  const summaryTotal = (currentState, total, current) => {
    let sum = 0;
    if (currentState == 0) {
      sum =
        Number(total) + Number(current);
    } else {
      sum =
        Number(total) - Number(current);
    }
    return sum.toFixed(2);
  }

  const onClostBtnHandler = async () => {
    startValueObj.setStartValue("0.00");
    // isResetMoneyControllerObj.setReset(true);
    modalOpenObj.setModalOpen(false);
    window.location.reload(false);  
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => modalOpenObj.setModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          {isPrint ? (
            <div className="bg-white w-[min(37.11vw,380px)] p-[min(2.44vw,25px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-24 text-[var(--color-black-2)] text-center leading-[1.8] font-[500]">
              <div className="flex flex-col items-center text-18 font-[500]">
                <div className="font-[700] leading-[2.2]">
                  {info.headModalTxt}
                </div>
                <div className="leading-[1.2] text-[var(--color-grey-2)] mt-[min(1.46vw,15px)]">
                  เงินสด
                </div>
                <div className="leading-[1.2] text-[var(--color-green)]">
                  {numberWithTwoDecimals(value)} บาท
                </div>
                <div className="mt-[min(2.44vw,25px)]">
                  <CustomBtn
                    onClick={onClostBtnHandler}
                    className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-white font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-main-active`}
                    btntxt={"ปิด"}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-white w-[min(37.11vw,480px)] p-[min(2.44vw,25px)] absolute top-[5%] left-[50%] translate-x-[-50%] translate-y-[-5%] rounded-[min(1.46vw,15px)] text-16 text-[var(--color-black-2)]">
              <div className="bg-[#EFEFEF]  pt-[4.00vw] ">
                <div className="flex justify-center text-[0.78vw] w-full">
                  <div
                    id={"section-to-print"}
                    className="w-[21.48vw] bg-[#FFF] px-[1.70vw] py-[1.31vw] flex flex-col gap-[0.59vw] "
                  >
                    <div className="text-center font-[600]">
                      <div>
                        {branch && branch.business
                          ? branch.business.businessdetail.name
                          : null}
                      </div>
                      <div>{branch.name}</div>
                    </div>

                    <div className="flex flex-col gap-[0.59vw]">
                      <div className="flex justify-between gap-[0.98vw] ">
                        <div className="whitespace-nowrap">
                          วันที่ :{" "}
                          {new Date().toLocaleDateString("en-GB", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })}{" "}
                          {new Date().toLocaleTimeString()}
                        </div>
                        <div className="text-right">
                          POS#: {currentPOS.code}
                        </div>
                      </div>
                      <div className="flex justify-between gap-[0.98vw] ">
                        <div className="whitespace-nowrap">
                          POS NO : {currentPOS.order}
                        </div>
                        <div className="text-right">
                          พนักงาน : {user.firstNameTH} {user.lastNameTH}
                        </div>
                      </div>
                      <div className="flex justify-between gap-[0.98vw] ">
                        <div className="whitespace-nowrap">
                          RD#
                        </div>
                        <div className="text-right">
                          {info.billCountTxt} : {info.currentMenu == 0 ? info.drawerCount.deposit : info.drawerCount.withdraw}
                        </div>
                      </div>
                      <div className="flex justify-between gap-[0.98vw] ">
                        <div className="whitespace-nowrap">{info.billCreateAtTxt}</div>
                        <div className="text-right">
                          {new Date().toLocaleDateString("en-GB", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })}{" "}
                          {new Date().toLocaleTimeString()}
                        </div>
                      </div>
                      <div className="border-dashed border-t-[0.04vw] border-black h-0 "></div>
                    </div>

                    <div className="flex flex-col gap-[0.59vw] text-center">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap">เงินสดตั้งต้น</div>
                        <div>จำนวน</div>
                        <div>รวม</div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap"> 1000</div>
                        <div>{selectedPosSetting.amount1000}</div>
                        <div>
                          {Number(selectedPosSetting.amount1000 * 1000).toFixed(
                            2
                          )}
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap"> 500</div>
                        <div>{selectedPosSetting.amount500}</div>
                        <div>
                          {Number(selectedPosSetting.amount500 * 500).toFixed(
                            2
                          )}
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap"> 100</div>
                        <div>{selectedPosSetting.amount100}</div>
                        <div>
                          {Number(selectedPosSetting.amount100 * 100).toFixed(
                            2
                          )}
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap"> 50</div>
                        <div>{selectedPosSetting.amount50}</div>
                        <div>
                          {Number(selectedPosSetting.amount50 * 50).toFixed(2)}
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap"> 20</div>
                        <div>{selectedPosSetting.amount20}</div>
                        <div>
                          {Number(selectedPosSetting.amount20 * 20).toFixed(2)}
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap"> 10</div>
                        <div>{selectedPosSetting.amount10}</div>
                        <div>
                          {Number(selectedPosSetting.amount10 * 10).toFixed(2)}
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap"> 5</div>
                        <div>{selectedPosSetting.amount5}</div>
                        <div>
                          {Number(selectedPosSetting.amount5 * 5).toFixed(2)}
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <div className="whitespace-nowrap"> 1</div>
                        <div>{selectedPosSetting.amount1}</div>
                        <div>
                          {Number(selectedPosSetting.amount1 * 1).toFixed(2)}
                        </div>
                      </div>

                      <div className="flex justify-between gap-[0.98vw] ">
                        <div className="whitespace-nowrap">{info.billTotalTxt} :</div>
                        <div className="text-right font-[700]">
                          {Number(selectedPosSetting?.amountTotal * 1).toFixed(
                            2
                          )}
                        </div>
                      </div>
                      <div className="border-dashed border-t-[0.04vw] border-black h-0 "></div>
                      <div className="flex justify-between gap-[0.98vw] ">
                        <div className="whitespace-nowrap">โน๊ต :</div>
                        <div className="text-right font-[700]"></div>
                      </div>
                      <div className="flex justify-between gap-[0.98vw] ">
                        <div className="whitespace-nowrap font-[700]">
                          {info.billTotalNoteTxt} :
                        </div>
                        <div className="text-right font-[700]">
                          {/* {summary &&
                            (
                              Number(summary.amountTotal) +
                              Number(selectedPosSetting.amountTotal * 1)
                            ).toFixed(2)} */}
                            {Number(summaryTotal(info.currentMenu, summary?.amountTotal, selectedPosSetting?.amountTotal * 1)).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center py-[min(3.42vw,35px)] gap-[min(2.01vw,20.63px)]">
                  <CustomBtn
                    onClick={() => {
                      onPrintHandler();
                    }}
                    className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-[#6D788D] font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-second-2-active`}
                    btntxt={"ต่อไป"}
                  />
                  <CustomBtn
                    onClick={() => window.print()}
                    className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-white font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-main-active`}
                    btntxt={"Print"}
                  />
                </div>
              </div>
            </div>
          )}
        </Fade>
      </Modal>
    </div>
  );
}
