const checkURL = (url) => {
  const prefix = process.env.REACT_APP_URL_UPLOAD_FILE_PATH;
  const baseAPI = "https://dev-api.allder.co/";
  if (!url) return "";
  const exists = url.startsWith(prefix);

  const result = exists
    ? url
    : `${baseAPI}cdn-cgi/image/fit=scale-down,width=150,quality=40/${url}`;
  return result;
};

export default {
  checkURL,
};
