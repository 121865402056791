import { Button } from "@mui/material";
import React from "react";

export default function CustomBtn({btntxt, disabled = false, ...props}) {
  return (
    <Button
      {...props}
      className={`px-0 h-[auto] sarabun font-[700] ${props.className}`}
      variant="contained"
      disabled={disabled}
    >
      {btntxt}
    </Button>
  );
}
