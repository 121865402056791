import React, { useEffect, useState } from "react";

import Calculate from "components/layouts/payment/Calculator";
import Formmoneypay from "components/layouts/payment/Inputmoneypay";
import { nanoid } from 'nanoid'

const bankLogoList = [
    { "id": 1, "name": "ธนาคารกสิกรไทย", "logo": "/img/payment/bank/kbank.png" },
    { "id": 2, "name": "ธนาคารกรุงศรีอยุธยา", "logo": "/img/payment/bank/bay.png" },
    { "id": 3, "name": "ธนาคารกรุงเทพ", "logo": "/img/payment/bank/bbl.png" },
    { "id": 4, "name": "ธนาคารไทยพาณิชย์", "logo": "/img/payment/bank/scb.png" },
    { "id": 5, "name": "ธนาคารกรุงไทย", "logo": "/img/payment/bank/ktb.png" },
]

export default function  PayTranfer(
    {
        PaymentDetailObj,
        modalSuccessOpenObj,
        providerObj,
        Payment,
        eObj,
        moneypayObj,
        paymentBankList,
        btnObj,
        deliveryObj,
        ...props
    }) {
    
    // const [bankList, setBankList] = useState([
    //     { "id": 1, "name": "ธนาคารกสิกรไทย", "logo": "/img/payment/bank/kbank.png" },
    //     { "id": 2, "name": "ธนาคารกรุงศรีอยุธยา", "logo": "/img/payment/bank/bay.png" },
    //     { "id": 3, "name": "ธนาคารกรุงเทพ", "logo": "/img/payment/bank/bbl.png" },
    //     { "id": 4, "name": "ธนาคารไทยพานิชย์", "logo": "/img/payment/bank/scb.png" },
    //     { "id": 5, "name": "ธนาคารกรุงไทย", "logo": "/img/payment/bank/ktb.png" },
    // ]);

    const [bankList, setBankList] = useState([]);

    const [bankSelect, setBankSelect] = useState(null);
    const [step, setStep] = useState(1);
    
    useEffect(() => {
        if(paymentBankList && paymentBankList.length > 0) {
            const newBankList = [];
            for (const pb of paymentBankList) {
                const bankLogo = bankLogoList.filter(blg => blg.name == pb.bank_title)[0];
                newBankList.push({
                    ...pb,
                    logo: bankLogo.logo
                });
            }
            setBankList(newBankList);
        }
    }, [paymentBankList])

    useEffect(() => {
        if(bankList && bankList.length > 0 && !bankSelect) {
            setBankSelect(bankList[0])
        }
    }, [bankList])
    
    return (
        <div className="d-flex flex-column h-100">
            {
                step == 2 && bankSelect ? (
                    <AddAmount 
                        paymentObj={Payment}
                        moneypayObj={moneypayObj}
                        eObj={eObj}
                        btnObj={btnObj}
                        PaymentDetailObj={PaymentDetailObj}
                        modalSuccessOpenObj={modalSuccessOpenObj}
                        deliveryObj={deliveryObj}
                        handleClick={() => {
                            eObj.handleAddPayment()
                        } }
                    />
                ) : (
                    <SelectBank 
                        bankList={bankList}
                        deliveryObj={deliveryObj}
                        bankObj={{
                            bankSelect:bankSelect,
                            setBankSelect:setBankSelect
                        }}
                        handleClick={() => {
                            providerObj.setProvider(bankSelect)
                            setStep(2) 
                        } }
                    />
                )
            }
            

            
            
        
        </div>
    )

}

const AddAmount = ({moneypayObj,eObj,PaymentDetailObj,modalSuccessOpenObj,btnObj,handleClick,deliveryObj,paymentObj,...props}) => {
    return (
        <>
            <div>
                {
                    deliveryObj.deliverySelect ? (
                        <button 
                            type="button" 
                            className="fs-12 mb-1" 
                            style={{color:'#0760FC'}}
                            onClick={() => { 
                                deliveryObj.setDeliverySelect(null)
                                paymentObj.setPaymentObj([])
                            }}
                        >{`<<`} ล้างรายการ</button>
                    ) : ''
                }
            </div>
            <div className="d-flex align-items-center text-[#061126] mb-1">
                <div className="me-auto">
                <span className="fs-18 fw-500">Order NO.</span>
                <span className="fs-14 fw-500 ms-2">{PaymentDetailObj.PaymentDetail.orderNo}</span>
                </div>
                <div className="fs-18 fw-bold text-[#2DC76D]">โอนเงิน</div>
            </div>
            {
                deliveryObj.deliverySelect ? (
                    <div className="fs-14 fw-500 mb-1 d-flex align-items-center"> 
                        <span className="me-2"> กรุณาระบุยอดเงินรับชำระของ </span> 
                        <img src={deliveryObj.deliverySelect.logo} style={{width: '26px',height: '26px',objectFit:'cover',borderRadius:'5px'}} />
                    </div>
                ) : (
                    <div className="fs-14 fw-500 mb-3">กรุณาระบุยอดเงินรับชำระ</div>     
                )
            }
            <div className="py-2">
                <Formmoneypay 
                moneypay={moneypayObj.moneypay}
                setMoneypay={moneypayObj.setMoneypay}
                />
            </div>

            <Calculate 
                setMoneypay={moneypayObj.setMoneypay}
                moneypay={moneypayObj.moneypay}
                PaymentDetail={PaymentDetailObj.PaymentDetail}
            />

            <div className="d-flex align-items-center pt-2">
                <button className="me-auto btn btn-st-q1 px-5"
                onClick={handleClick}
                disabled={btnObj.BtnenterDisabled || Number(moneypayObj.moneypay) == 0}
                >Enter
                </button>
                <button className="btn btn-st-q1"
                onClick={eObj.handlePayment}
                disabled={btnObj.BtnpaymentDisabled}
                >ชำระเงิน</button>
            </div>
        </>
    )
}

const SelectBank = ({bankList,bankObj,handleClick,deliveryObj,paymentObj,...props}) => {


    return (
        <>
            <div>
                {
                    deliveryObj.deliverySelect ? (
                        <button 
                            type="button" 
                            className="fs-12 mb-2" 
                            style={{color:'#0760FC'}}
                            onClick={() => { 
                                deliveryObj.setDeliverySelect(null)
                                paymentObj.setPaymentObj([])
                            }}
                        >{`<<`} ล้างรายการ</button>
                    ) : ''
                }
            </div>
            <div className="fs-18 fw-500 text-[#061126] mb-2">กรุณาเลือกธนาคารรับเงิน</div>
            {
                deliveryObj.deliverySelect ? (
                    <div className="fs-14 fw-500 mb-3 d-flex align-items-center"> 
                        <span className="me-2"> กรุณาเลือกช่องทางชำระเงินของ </span> 
                        <img src={deliveryObj.deliverySelect.logo} style={{width: '26px',height: '26px',objectFit:'cover',borderRadius:'5px'}} />
                    </div>
                ) : ''
            }
            
            
            <div className="bx-paylist p-3 overflow-auto mb-auto" style={{ background: '#FFF',filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14))',borderRadius:'8px',height:'calc(100vh - 500px)' }}>
                <div className="row gx-3">
                    {
                        bankList && bankList.map((item, index) => 

                        <div className="col-6" key={item.id}>
                            <div className={`d-flex align-items-center card-bank ${ bankObj.bankSelect && item.id == bankObj.bankSelect.id ? 'active' : ''  } mb-3`} style={{cursor:'pointer'}}
                            onClick={() => bankObj.setBankSelect(item) }
                            >
                                <img src={item.logo} style={{width: '32px',height: '32px',objectFit:'cover',borderRadius:'50%'}} />
                                <div className="text-[#061126] fs-14 ms-3">{item.bank_title}</div>
                            </div>
                        </div>
                        
                        )
                    }
                    
                </div>

            </div>
            
            <div className="d-flex align-items-center justify-content-end pt-2">
                <button className=" btn btn-st-q1 px-5"
                onClick={() => handleClick() }
                disabled={ !bankObj.bankSelect }
                >ยืนยัน
                </button>
            </div>
        </>
    )
}

