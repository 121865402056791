import React, { useRef } from "react";
import CustomInput from "./CustomInput";

export default function CustomDatePicker({ inputprops, dateObj }) {
  const inputRef = useRef(null);

  const handleOnClick = () => {
    inputRef.current.focus();
  };
  return (
    <div className="relative custom-input-datepicker-container">
      <CustomInput inputprops={{ ...inputprops, ref: inputRef, readOnly: true }} />
      <input
        type="date"
        name=""
        className="custom-datepicker absolute top-0 left-0 opacity-0 w-full h-full"
        onClick={handleOnClick}
        onChange={(e) => {
          const syntheticEvent = {
            target: {
              name: inputprops.name,
              value: e.currentTarget.value,
            },
          };
          dateObj.setDate(syntheticEvent);
        }}
      />
    </div>
  );
}
