import React from "react";
import DefaultProductImg from 'img/product/default.png';

export default function SumBestSellerCard({info, number}) {
  return (
    <div className="flex justify-between mt-[min(1.33vw,13.67px)]">
      <div className="flex gap-[min(1.33vw,13.67px)]">
        <div className="text-8 font-[600] text-[rgba(0,0,0,0.5)]">{number}</div>
        <img
          className="object-contain aspect-[35.53/25.28] w-[min(3.47vw,35.53px)] h-fit rounded-[min(0.53vw,5.46px)]"
          // src="/img/ex-food-2.png"
          src={info.img_url}
          alt=""
          onError={(e) => e.target.src = DefaultProductImg}
        />
        <div className="flex flex-col justify-between">
          <div className="text-10 font-[600]">{info.name}</div>
          <div className="text-8 font-[300]">฿{info.price}</div>
        </div>
      </div>
      <div className="text-[var(--color-green)] text-8 font-[500] pr-[min(0.98vw,10px)]">
        ขายแล้ว {info.sales}
      </div>
    </div>
  );
}
