import axiosInstance from './axios';

const endPoint = "/branch";

export const getAllBranch = async () => {
    try {
        const response = await axiosInstance.get('/branch');

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            branch: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getBranch = async (id) => {
    try {
        const response = await axiosInstance.get(`/branch/${id}`);
        
        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            branch: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

// export const updateBranch = async (id) => {
//     try {
//         const response = await axiosInstance.patch(`/branch/${id}`);
        
//         // TODO:: Add/Update hanlder for HTTP status
//         // if (response.status === 200) {
//         //     console.log('Successful request! Data:', response.data);
//         // } else {
//         //     console.error('Unexpected status code:', response.status);
//         // }

//         return {
//             branch: response.data
//         }
//     } catch (error) {
//         console.error('Error fetching data:', error);
//         return error
//     }
// }