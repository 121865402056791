import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

export default function ModalSuccess({
  selectedUserIdObj,
  modalOpenObj,
  successText,
  ...props
}) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => modalOpenObj.setModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          <div className="bg-white w-fit p-[min(2.44vw,25px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-24 text-[var(--color-black-2)] text-center leading-[1.8] font-[500]">
            <div className="flex flex-col items-center ">
              <img className="w-[min(8.11vw,83px)] aspect-square object-contain rounded-full" src="/img/icons/icon-success.png" alt="" />
              <div className="leading-[1.6] font-[500] text-center text-[#5A5A5A] mt-[min(2.44vw,25px)]">
                { successText ? successText : modalOpenObj?.modalTextSuccess ? modalOpenObj?.modalTextSuccess : "เปลี่ยนพนักงานสำเร็จ" }
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
