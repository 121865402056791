import React from "react";

export default function AvartarLogoutBtn({
  signOutHandler
}) {
  return (
    <button 
      className="text-18 font-[500] text-[#4A4A4A] rounded-[min(0.78vw,8px)] w-full pl-[min(1.37vw,14px)] py-[min(0.88vw,9px)] shadow-logout-avartar bg-white flex items-center gap-[min(1.07vw,11px)] logout-avartar"
      onClick={signOutHandler ?? null}
    >
      <img
        className="aspect-square w-[min(1.95vw,20px)] object-contain"
        src="/img/icons/icon-logout.svg"
        alt=""
      />
      <div>ออกจากระบบ</div>
    </button>
  );
}
