import AvartarHeader from "components/fragments/AvartarHeader";
import AvartarLogoutBtn from "components/fragments/AvartarLogoutBtn";
import ModalKitchenCancelUnit from "components/layouts/ModalKitchenCancelUnit";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { orderState, useKitchenClearOrder } from "state/order";
import { posState, selectedPosSettingState, useCreateStaffLogin, useClosePOSActivity } from 'state/pos';
import { authState, useAuthCheckActive } from "state/auth";
import { useFetchAllOrder, useUpdateOrderDetail, useUpdateMultipleOrderDetail } from "state/order";
import { appState, useSetRequestLoading } from "state/app";
import useSound from 'use-sound';
import kitchenOrderSound from '../../assets/audio/kitchen-order.mp3';
import { socket } from '../../socket';
import LoadingSpinner from 'components/fragments/LoadingSpinner';

export default function KitchenPage() {
  const navigate = useNavigate();
  const { slug, isRequestLoading, slugBranch } = useRecoilValue(appState);
  const [isLogoutOpen, setIsLogoutOpen] = useState(false);

  const [testFoodList, setTestFoodList] = useState([
    { unit: 1, name: "ข้าวผัดกุ้ง" },
    {
      unit: 1,
      prevUnit: 3,
      name: "ข้าวผัดกุ้ง",
      addOnList: ["เพิ่มข้าว"],
      ignoreList: ["ไม่ใส่ผัก"],
    },
    {
      unit: 1,
      name: "ข้าวผัดกุ้ง",
      addOnList: ["เพิ่มข้าว"],
      ignoreList: ["ไม่ใส่ผัก"],
    },
    {
      unit: 1,
      name: "ข้าวผัดกุ้ง",
      addOnList: ["เพิ่มข้าว"],
      ignoreList: ["ไม่ใส่ผัก"],
    },
    {
      unit: 1,
      name: "ข้าวผัดกุ้ง",
      addOnList: ["เพิ่มข้าว"],
      ignoreList: ["ไม่ใส่ผัก"],
    },
    { unit: 1, name: "ต้มยำกุ้ง" },
    { unit: 3, name: "สปาเก็ตตี้" },
    {
      unit: 3,
      name: "สลัดอกไก่",
      addOnList: ["ซอสซีซาร์ x 3"],
      ignoreList: ["ไม่ใส่งา"],
    },
    {
      unit: 1,
      name: "Vegan พิซซ่า",
      addOnList: ["เพิ่มเบค่อน x 2"],
      ignoreList: ["ไม่ใส่สับปะรด"],
    },
    {
      unit: 1,
      name: "ต้มยำกุ้งน้ำข้น",
      addOnList: ["เพิ่มกุ้ง"],
      ignoreList: ["ไม่เผ็ด"],
      isCancel: true,
    },
  ]);

  const [testFoodList2, setTestFoodList2] = useState([
    { unit: 1, name: "ข้าวผัดกุ้ง" },
    {
      unit: 1,
      name: "ข้าวผัดกุ้ง",
      addOnList: ["เพิ่มข้าว"],
      ignoreList: ["ไม่ใส่ผัก"],
    },
    {
      unit: 1,
      name: "ข้าวผัดกุ้ง",
      addOnList: ["เพิ่มข้าว"],
      ignoreList: ["ไม่ใส่ผัก"],
    },
    {
      unit: 1,
      name: "ข้าวผัดกุ้ง",
      addOnList: ["เพิ่มข้าว"],
      ignoreList: ["ไม่ใส่ผัก"],
    },
    {
      unit: 1,
      name: "ข้าวผัดกุ้ง",
      addOnList: ["เพิ่มข้าว"],
      ignoreList: ["ไม่ใส่ผัก"],
    },
    { unit: 1, name: "ต้มยำกุ้ง" },
    { unit: 3, name: "สปาเก็ตตี้" },
    { unit: 1, name: "ต้มยำกุ้ง" },
    { unit: 3, name: "สปาเก็ตตี้" },
    {
      unit: 1,
      name: "สลัดอกไก่",
      addOnList: ["ซอสซีซาร์ x 3"],
      ignoreList: ["ไม่ใส่งา"],
    },
    {
      unit: 1,
      name: "Vegan พิซซ่า",
      addOnList: ["เพิ่มเบค่อน x 2"],
      ignoreList: ["ไม่ใส่สับปะรด"],
    },
    {
      unit: 1,
      name: "ต้มยำกุ้งน้ำข้น",
      addOnList: ["เพิ่มกุ้ง"],
      ignoreList: ["ไม่เผ็ด"],
      isCancel: true,
    },
  ]);

  const [testFoodList3, setTestFoodList3] = useState([
    { unit: 1, name: "ข้าวผัดกุ้ง" },
    {
      unit: 1,
      name: "ข้าวผัดกุ้ง",
      addOnList: ["เพิ่มข้าว"],
      ignoreList: ["ไม่ใส่ผัก"],
    },
    {
      unit: 1,
      name: "ข้าวผัดกุ้ง",
      addOnList: ["เพิ่มข้าว"],
      ignoreList: ["ไม่ใส่ผัก"],
    },
    {
      unit: 1,
      name: "ข้าวผัดกุ้ง",
      addOnList: ["เพิ่มข้าว"],
      ignoreList: ["ไม่ใส่ผัก"],
    },
    {
      unit: 1,
      name: "ข้าวผัดกุ้ง",
      addOnList: ["เพิ่มข้าว"],
      ignoreList: ["ไม่ใส่ผัก"],
    },
    { unit: 1, name: "ต้มยำกุ้ง" },
    { unit: 3, name: "สปาเก็ตตี้" },
    { unit: 3, name: "สปาเก็ตตี้" },
    {
      unit: 1,
      name: "สลัดอกไก่",
      addOnList: ["ซอสซีซาร์ x 3"],
      ignoreList: ["ไม่ใส่งา"],
    },
    {
      unit: 1,
      name: "Vegan พิซซ่า",
      addOnList: ["เพิ่มเบค่อน x 2"],
      ignoreList: ["ไม่ใส่สับปะรด"],
    },
    {
      unit: 1,
      name: "ต้มยำกุ้งน้ำข้น",
      addOnList: ["เพิ่มกุ้ง"],
      ignoreList: ["ไม่เผ็ด"],
      isCancel: true,
    },
  ]);

  const [onSiteIndexList, setOnSiteIndexList] = useState([]);
  const [takeHomeIndexList, setTakeHomeIndexList] = useState([]);
  const [deliveryIndexList, setDeliveryIndexList] = useState([]);

  const { user, logMachine } = useRecoilValue(authState);
  const fetchAllOrder = useFetchAllOrder();
  const { orders } = useRecoilValue(orderState);
  // console.log("🚀 ~ orders:", orders)
  const [kitchenOrders, setKitchenOrders] = useState([]);
  const [waitingOrdersCount, setWaitingOrdersCount] = useState(0);
  const { currentPOS, currentPOSActivity } = useRecoilValue(posState);
  // console.log("🚀 ~ currentPOS:", currentPOS)
  // console.log("🚀 ~ currentPOSActivity:", currentPOSActivity)
  // const [foodIndexList, setFoodIndexList] = useState(Array.from({ length: kitchenOrders.length }, () => []));
  const [foodIndexList, setFoodIndexList] = useState([]);
  // console.log("🚀 ~ foodIndexList:", foodIndexList)
  const [selectedPosSetting, setSelectedPosSetting] = useRecoilState(selectedPosSettingState);
  // console.log("🚀 ~ selectedPosSetting:", selectedPosSetting)
  const createStaffLogin = useCreateStaffLogin();
  const closePOSActivity = useClosePOSActivity();
  const [modalKitchenCancelOpen, setModalKitchenCancelOpen] = useState(false);
  const [seletedUnitsObj, setSeletedUnitsObj] = useState();
  const authCheckActive = useAuthCheckActive();
  const updateOrderDetail = useUpdateOrderDetail();
  const kitchenClearOrder = useKitchenClearOrder();
  const setRequestLoading = useSetRequestLoading();
  const updateMultipleOrderDetail = useUpdateMultipleOrderDetail();

  const checkDisabled = (indexList, foodList, unit) => {
    if (indexList.length === 0) {
      return false;
    }

    if (unit === 1 && checkIsSelectMutiple(indexList, foodList)) {
      return false;
    }

    if (unit > 1 && checkIsSelectMutiple(indexList, foodList)) {
      return true;
    }

    if (unit === 1 && !checkIsSelectMutiple(indexList, foodList)) {
      return true;
    }

    if (unit > 1 && checkIsSelectMutiple(indexList, foodList)) {
      return false;
    }
  };

  const checkIsSelectMutiple = (indexList, foodList) => {
    var isSelectMultiple = false;

    for (let v of indexList) {
      if (foodList[v].unit === 1) {
        return true;
      }
    }

    return isSelectMultiple;
  };

  const [ playKitchenOrderSound ] = useSound(kitchenOrderSound);

  // const checkClearAllTakeHome = () => {
  //   return (
  //     takeHomeIndexList.length ===
  //     testFoodList2.filter((v) => !v.isCancel && !v.isServ).length
  //   );
  // };

  // const clearAllTakeHome = () => {
  //   setTestFoodList2((prev) => {
  //     const newList = [...prev];
  //     newList.map((v) => (v.isServ = true));
  //     return newList;
  //   });
  //   setTakeHomeIndexList([]);
  // };

  useEffect(() => {
    function onPosLogin (value) {
      if(user.employeeId && user.branchId && currentPOS.id) {
        authCheckActive(currentPOS.id, user.employeeId, currentPOSActivity.operation_date, logMachine, true, slug, slugBranch)
      }
    }

    function onPosOrder (value) {
      if(user.employeeId && user.branchId && currentPOS.id) {
        fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date, true)
      }
    }

    if (user.employeeId && user.branchId && currentPOS.id) {
      // Set interval call
      // const intervalFetchAllOrder = setInterval(() => {
        // fetchAllOrder()
        // fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date, true)
        // authCheckActive(currentPOS.id, user.employeeId, currentPOSActivity.operation_date, logMachine, true, slug)
      // }, 2000);
      authCheckActive(currentPOS.id, user.employeeId, currentPOSActivity.operation_date, logMachine, true, slug)
      fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date, true)

      socket.on(`POS_${currentPOS.id}_LOGIN`, onPosLogin);
      socket.on(`POS_${user.branchId}_ORDER`, onPosOrder);
      
      // Clean up the interval when the component is unmounted
      // return () => clearInterval(intervalFetchAllOrder); 
    }
    // Clean up the interval when the component is unmounted
    // return () => clearInterval(intervalFetchAllOrder);
  }, [user, currentPOS])

  useEffect(() => {

    const setKitchenOrdersData = async () => {
      // console.log("🚀 ~ useEffect ~ orders:", orders)
      setWaitingOrdersCount(0);
      let queueNo = 0;
      let parsed = [...orders]
        .sort((a,b) => a.id - b.id)
        .filter(
          // o => o.table_activity.table.zone.branch_id == user.branchId && 
          o => 
            // o.orderDetail[0].branch_product.branch_id == user.branchId &&
            o.orderDetail.filter(od => od.branch_product.branch_id == user.branchId).length > 0 &&
            // o.status_id == 1 &&
            o.orderDetail.filter(od => od.status_id == 1).length > 0
        )
        .map(o => {
          let remainFood = [...o.orderDetail].find(od => od.status_id == 1 && od.kitchenId == currentPOS.kitchen.id);

          // if (remainFood) {
          //   setWaitingOrdersCount(prev => prev + 1);
          // }

          let order = []

          for (const batch of Array.from({ length: o.batch }, (_,i) => i + 1) ) {
            let newOrderDetail = [...o.orderDetail]
            .filter(od => od.kitchenId == currentPOS.kitchen.id && od.order_batch == batch && !od.is_kitchen_clear)
            .map(od => ({
              ...od,
              unit: od.remain_amount && od.remain_amount > 0 ? od.remain_amount : od.amount,
              name: od.branch_product.product.name,
              addOnList: 
                od.OrderDetailoptionBinding.length > 0 ? 
                  od.OrderDetailoptionBinding
                    .filter(odb => odb.branch_product_option_detail_binding.product_option_detail.product_option.option_type_id != 3)
                    .map(odb => {
                      if(odb.amount > 1){
                        return `${odb.branch_product_option_detail_binding.product_option_detail.name} x${odb.amount}`
                      } else {
                        return odb.branch_product_option_detail_binding.product_option_detail.name
                      }
                    })
                : [],
              ignoreList: 
                od.OrderDetailoptionBinding.length > 0 ? 
                od.OrderDetailoptionBinding
                  .filter(odb => odb.branch_product_option_detail_binding.product_option_detail.product_option.option_type_id == 3)
                  .map(odb => odb.branch_product_option_detail_binding.product_option_detail.name)
                : [],
              isCancel: od.status_id == 3 ? true : false,
              isServed: od.status_id == 2 ? true : false,
              isCompleted: od.status_id == 2 ? true : false,
              prevUnit: od.cancel_amount && od.cancel_amount > 0 ? od.amount : null,
              cancel_amount: od.cancel_amount && od.cancel_amount > 0 ? od.cancel_amount : 0,
              remain_amount: od.remain_amount && od.remain_amount > 0 ? od.remain_amount : od.amount,
            }));

            if((o.type_id == 2 || o.type_id == 3) && o.status_id != 4) {
              // order.queueNo =  queueNo + 1;
              queueNo += 1;
            }

            if(newOrderDetail.length > 0) {
              order.push({
                ...o,
                orderDetail: newOrderDetail,
                queueNo: ((o.type_id == 2 || o.type_id == 3) && o.status_id != 4) ? queueNo : null,
                batch: batch,
                start_time: batch > 1 ? newOrderDetail[0].created_at : o.start_time
              })
            }
          }

          // let order = [{
          //   ...o,
          //   orderDetail: newOrderDetail,
          //   queueNo: null
          // }]

          // if(o.type_id == 2 || o.type_id == 3) {
          //   order.queueNo =  queueNo + 1;
          //   queueNo += 1;
          // }

          if((o.status_id == 1 || o.status_id == 2) && remainFood) {
            return order
          }
          // return {
          //   ...o,
          //   orderDetail: newOrderDetail,
          //   queueNo: o.type_id == 2 || o.type_id == 3 ? queueNo + 1 : null
          // }
        });
      let filtered = parsed.flat().filter(p => p).sort((a,b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime() );
      setWaitingOrdersCount(filtered.length);
      setKitchenOrders(filtered);
      // setFoodIndexList(Array.from({ length: parsed.length }, () => []))
      // if(filtered.length > foodIndexList.length) {
      //   var newFoodIndexList = [...foodIndexList];
      //   newFoodIndexList.push([]);
      //   // play sound
      //   playKitchenOrderSound();
      //   setFoodIndexList(newFoodIndexList);
      // }

      var newFoodIndexList = [];
      var newOrder = false;
      for (const anOrder of filtered) {
        var existOrderFoodIndex = [...foodIndexList].find(fi => fi.id == anOrder.id && fi.batch == anOrder.batch);
        if(!existOrderFoodIndex) {
          newOrder = true;
          newFoodIndexList.push({
            id: anOrder.id,
            batch: anOrder.batch,
            list: []
          });
        } else {
          newFoodIndexList.push({
            id: existOrderFoodIndex.id,
            batch: existOrderFoodIndex.batch,
            list: existOrderFoodIndex.list
          });
        }
      }
      setFoodIndexList(newFoodIndexList);
      if(newOrder) {
        // play sound
        playKitchenOrderSound();
      }

      if(isRequestLoading) {
        await setRequestLoading({
            isRequestLoading: false
        });
      }
    };

    if (orders && orders.length > 0) {
      setKitchenOrdersData();
    }
  }, [orders])

  const onSignOutBtnHanlder = async () => {
    closePOSActivity(
      {
      ...selectedPosSetting,
      posId: currentPOS.id,
      employeeId: user.employeeId,
      employeeBranchAccountId: user.employeeBranchAccountId,
      activityTypeId: 5
      }, 
      currentPOSActivity.operation_date, 
      true
    )
    // navigate("/")
  }

  const onRanOutConfirmBtnHandler = async (product, cancelAmount, seletedUnitsObj) => {
    // console.log("🚀 ~ onRanOutConfirmBtnHandler ~ product:", product)
    // console.log("seletedUnitsObj: ", seletedUnitsObj)
    // const selectedOrderDetails = selectedList.map(index => foodList[index]);
    // setModalKitchenCancelOpen(true);
    
    for (const orderDetail of seletedUnitsObj.foodList) {
      await updateOrderDetail({
        ...orderDetail,
        status_id: 3,
        cancel_amount: cancelAmount,
        is_from_kitchen: true
      });
    }
    // setSelectedList([])
    // Clear order select list
    let newFoodIndexList = [...foodIndexList];
    // Can be optimized index by order ID for more precise
    newFoodIndexList[seletedUnitsObj.orderIndex].list = [];
    setFoodIndexList(newFoodIndexList);
  }

  // const onRanOutBtnHandler = async (orderIndex) => {
    // // console.log("🚀 ~ onRanOutConfirmBtnHandler ~ product:", product)
    // console.log("seletedUnitsObj: ", seletedUnitsObj)
    // // const selectedOrderDetails = selectedList.map(index => foodList[index]);
    // // setModalKitchenCancelOpen(true);
    // for (const orderDetail of seletedUnitsObj.foodList) {
    //   await updateOrderDetail({
    //     ...orderDetail,
    //     status_id: 3,
    //     cancel_amount: cancelAmount
    //   });
    // }
    // // setSelectedList([])
    // // Clear order select list
    // let newFoodIndexList = [...foodIndexList];
    // // Can be optimized index by order ID for more precise
    // newFoodIndexList[seletedUnitsObj.orderIndex].list = [];
    // setFoodIndexList(newFoodIndexList);
  // }

  const clearOrderAllDetails = async (orderId, orderBatch) => {
    await kitchenClearOrder(orderId, orderBatch);
    if(user.employeeId && user.branchId && currentPOS.id) {
      await fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date, true)
    }
  }

  const serveAll = async (orderId, orderBatch) => {
    await setRequestLoading({
      isRequestLoading: true
    });

    const foodList = foodIndexList.find(fi => fi.id == orderId && fi.batch == orderBatch).list;

    if(foodList) {
      await updateMultipleOrderDetail(foodList);
    }

    // await setRequestLoading({
    //   isRequestLoading: false
    // });
  }

  return (
    <div className="bg-[#F5F5F5] w-full min-h-[100vh]">
      {/* header */}
      <div className="rounded-b-[min(0.39vw,4px)] h-[min(7.91vw,81px)] shadow-4-4 bg-[#FFF] py-[min(1.46vw,15px)] pl-[min(2.05vw,21px)] pr-[min(1.56vw,16px)] flex justify-between">
        <div className="flex">
          <img
            className="object-contain aspect-square h-fit rounded-full w-[min(4.98vw,51px)] mr-[min(4.69vw,48px)]"
            src="/img/logo-3.png"
            alt=""
          />
          <div className="flex gap-[min(1.27vw,13px)]">
            <div className="flex flex-col justify-center gap-[min(0.59vw,6px)] min-w-[min(10.45vw,107px)] h-[min(5.66vw,58px)] shadow-4-12 text-12 rounded-[min(0.49vw,5px)] px-[min(1.17vw,12px)]">
              <div className="text-[var(--color-grey-2)]">รอการจัดเตรียม</div>
              <div className="flex justify-between items-baseline ">
                <div className="flex items-baseline gap-[min(1.27vw,13px)]">
                  <img
                    className="object-contain aspect-[18/20] w-[min(1.76vw,18px)] h-fit"
                    src="/img/icons/icon-bill-orange.svg"
                    alt=""
                  />
                  <div className="prompt text-16">{waitingOrdersCount}</div>
                </div>
                <div className="text-10 text-[var(--color-grey-2)]">บิล</div>
              </div>
            </div>
            <div className="flex flex-col justify-center gap-[min(0.59vw,6px)] min-w-[min(12.11vw,124px)] h-[min(5.66vw,58px)] shadow-4-12 text-12 rounded-[min(0.49vw,5px)] px-[min(0.98vw,10px)]">
              <div className="text-[var(--color-grey-2)]">
                เวลาเฉลี่ยต่อออเดอร์
              </div>
              <div className="flex justify-between items-baseline ">
                <div className="flex items-baseline gap-[min(1.07vw,11px)]">
                  <img
                    className="object-contain aspect-square w-[min(1.76vw,18px)] h-fit"
                    src="/img/icons/icon-time-blue.svg"
                    alt=""
                  />
                  {/* <div className="prompt text-16">08:25</div> */}
                  {/* TODO: Update calculate function */}
                  <div className="prompt text-16">--:--</div>
                </div>
                <div className="text-10 text-[var(--color-grey-2)]">นาที</div>
              </div>
            </div>
            <div className="flex flex-col justify-center gap-[min(0.59vw,6px)] min-w-[min(12.70vw,130px)] h-[min(5.66vw,58px)] shadow-4-12 text-12 rounded-[min(0.49vw,5px)] px-[min(0.68vw,7px)]">
              <div className="text-[var(--color-grey-2)]">
                ออเดอร์ที่ใช้เวลาสูงสุด
              </div>
              <div className="flex justify-between items-baseline ">
                <div className="flex items-baseline gap-[min(1.07vw,11px)]">
                  <img
                    className="object-contain aspect-square w-[min(1.76vw,18px)] h-fit"
                    src="/img/icons/icon-time-red.svg"
                    alt=""
                  />
                  {/* <div className="prompt text-16">76:21</div> */}
                  {/* TODO: Update calculate function */}
                  <div className="prompt text-16">--:--</div>
                </div>
                <div className="text-10 text-[var(--color-grey-2)]">นาที</div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="min-w-[min(20.31vw,208px)] rounded-[min(0.29vw,3px)] bg-[var(--color-light-green)] text-[var(--color-green)] text-24 flex justify-center items-center mr-[min(2.44vw,25px)]">
            {currentPOS.name}
          </div>
          <div
            onClick={() => navigate(`/${slug}/${slugBranch}/kitchen-history`)}
            className="cursor-pointer mr-[min(1.76vw,18px)]"
          >
            <div className="flex justify-center items-center aspect-square h-fit w-[min(3.81vw,39px)] bg-[rgba(217,217,217,0.3)] rounded-[min(0.78vw,8px)]">
              <img
                className="object-contain aspect-square w-[min(2.54vw,26px)] h-fit"
                src="/img/icons/icon-history-grey.svg"
                alt=""
              />
            </div>

            <div className="text-12 text-[var(--color-grey-2)]">ประวัติ</div>
          </div>
          <div
            onClick={() => setIsLogoutOpen(!isLogoutOpen)}
            className="cursor-pointer aspect-square h-fit w-[min(4.10vw,42px)] relative"
          >
            <AvartarHeader
              userInfo={{ avartar: "/img/avatar-male-pink.png" }}
            />
            {isLogoutOpen && (
              <div className="w-[min(19.43vw,199px)] h-0 absolute bottom-[calc(min(1.95vw,20px)*-1)] right-0">
                <AvartarLogoutBtn signOutHandler={onSignOutBtnHanlder} />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* body */}
      <div className="flex h-[min(calc(100vh-81px))] overflow-scroll gap-[min(2.15vw,22px)] px-[min(1.56vw,16px)] pt-[min(1.56vw,16px)] pb-[min(7.13vw,73px)]">
        {kitchenOrders.map((order, orderIndex) => {
          if (order.orderDetail.length > 0 && order.orderDetail.filter(od => od.status_id == 1).length > 0) {
            // if (order.orderDetail.length > 0) {
            return (
              <div
                key={`order-${orderIndex}`}
                className="shadow-2-8 w-[min(28.81vw,295px)] bg-[#FFF] h-fit"
              >
                <div
                  className={
                    order.delivery == 1 ? "rounded-t-[min(0.39vw,4px)] flex justify-between bg-[var(--color-orange)] pl-[min(1.37vw,14px)] pr-[min(0.39vw,4px)] pt-[min(0.39vw,4px)] pb-[min(0.68vw,7px)]" :
                      order.delivery == 2 ? "rounded-t-[min(0.39vw,4px)] flex items-end justify-between bg-[var(--color-blue)] pl-[min(1.37vw,14px)] pr-[min(0.39vw,4px)] pt-[min(0.39vw,4px)] pb-[min(0.68vw,7px)]" :
                        "rounded-t-[min(0.39vw,4px)] flex items-end justify-between bg-[var(--color-green)] pl-[min(1.37vw,14px)] pr-[min(0.39vw,4px)] pt-[min(0.39vw,4px)] pb-[min(0.68vw,7px)]"
                  }
                >
                  <div className="flex items-center gap-[min(1.17vw,12px)]">
                    <img
                      className="object-contain aspect-square w-[min(6.54vw,67px)] h-fit"
                      src={
                        order.delivery == 1 ? "/img/icons/icon-kitchen-onsite.png" :
                          order.delivery == 2 ? "/img/icons/icon-kitchen-take-home.png" :
                            "/img/icons/icon-kitchen-delivery.png"
                      }
                      alt=""
                    />
                    <div className="text-[#FFF] text-20 font-[500]">
                      <div>
                        {
                          order.delivery == 1 ? "ทานที่ร้าน" :
                            order.delivery == 2 ? "ซื้อกลับบ้าน" :
                              "เดลิเวอรี่"
                        }
                      </div>
                      {order.delivery == 1 &&
                        <div>โต๊ะที่ {order.table_activity?.table?.no ?? ""}</div>
                      }
                      {/* TODO: Add take home and delivery order queue */}
                      {(order.delivery == 2 || order.delivery == 3) &&
                        <div>คิวที่ {order.queueNo} {order.delivery == 2 && order.remark ? `(${order.remark})` : ""}</div>
                      }

                      {(order.delivery == 3 && (order.delivery_remark_channel || order.delivery_remark)) &&
                        <div>{order.delivery_remark_channel} ({order.delivery_remark})</div>
                      }
                      <div className="mt-[min(0.34vw,3.5px)]">{new Date(order.start_time).toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" })}</div>
                    </div>
                  </div>
                  {order.delivery == 1 &&
                    <div className="flex flex-col justify-center items-center w-[min(6.04vw,61.8px)] h-[min(4.68vw,47.9px)] bg-[#FFF] border-[min(0.10vw,1px)] border-[var(--color-blue-2)] text-[var(--color-blue-2)] font-[500]">
                      <div className="text-12">ในร้าน</div>
                      <div className="text-18">{order.table_activity?.table?.no}</div>
                    </div>
                  }
                </div>

                <div className="overflow-y-auto flex flex-col gap-[min(1.56vw,16px)] max-h-[min(43.65vw,447px)] px-[min(1.95vw,20px)] pt-[min(1.95vw,20px)] pb-[min(3.32vw,34px)]">
                  {order.orderDetail.map((value, index) => {
                    if (!value.isServed) {
                      return (
                        !value.isServ && (
                          <FoodChild
                            key={index}
                            index={index}
                            info={value}
                            setSelectedList={setFoodIndexList}
                            setFoodList={setTestFoodList}
                            isCancel={value.isCancel}
                            isServed={value.isServed}
                            orderIndex={orderIndex}
                            orderId={order.id}
                            // isDisabled={checkDisabled(
                            //   onSiteIndexList,
                            //   testFoodList,
                            //   value.unit
                            // )}
                            // isSelectOneMode={!checkIsSelectMutiple(onSiteIndexList, testFoodList2)}
                            // isSelectOneMode={!checkIsSelectMutiple(onSiteIndexList, foodIndexList[orderIndex])}
                            setSeletedUnitsObj={setSeletedUnitsObj}
                            foodIndexList={foodIndexList}
                            foodList={order.orderDetail}
                            isChecked={foodIndexList.find(fi => fi.id == order.id && fi.batch == order.batch).list.includes(value.id)}
                            orderDetailId={value.id}
                          />
                        )
                      );
                    }
                  })}
                </div>
                {/* footer */}
                <FooterCard
                  selectedList={foodIndexList.find(fi => fi.id == order.id && fi.batch == order.batch).list}
                  setSelectedList={setFoodIndexList}
                  setFoodList={setTestFoodList}
                  orderNo={order.orderNo}
                  foodList={order.orderDetail}
                  orderIndex={orderIndex}
                  orderId={order.id}
                  checkIsSelectMutiple={checkIsSelectMutiple}
                  setModalKitchenCancelOpen={setModalKitchenCancelOpen}
                  setSeletedUnitsObj={setSeletedUnitsObj}
                  clearOrderHanlder={clearOrderAllDetails}
                  orderBatch={order.batch}
                />
                <div className="flex justify-center pt-2 pb-3">
                <button
                  className="btn btn-pay btn-payment font-normal text-lg p-2"
                  onClick={async () => {
                    await serveAll(order.id, order.batch)
                  }}
                  disabled={foodIndexList.find(fi => fi.id == order.id && fi.batch == order.batch).list.length != order.orderDetail.filter(od => od.status_id == 1).length}
                >
                  เสร็จสิ้นทั้งหมด
                </button>
                </div>
              </div>
            )
          }
        })}
      </div>

      <ModalKitchenCancelUnit
        modalOpenObj={{
          modalOpen: modalKitchenCancelOpen,
          setModalOpen: setModalKitchenCancelOpen,
        }}
        seletedUnitsObj={seletedUnitsObj}
        onConfirm={onRanOutConfirmBtnHandler}
      />

      <LoadingSpinner 
        modalOpenObj={{
          modalOpen: isRequestLoading,
          setModalOpen: null
        }}
      />

    </div>
  );
}

function FoodChild({
  selectedIndexList,
  setSelectedList,
  foodList,
  setFoodList,
  setSeletedUnitsObj,
  index,
  info,
  isCancel = false,
  orderIndex,
  isDisabled = false,
  isSelectOneMode = false,
  foodIndexList,
  orderId,
  isChecked,
  orderDetailId
}) {
  // console.log("🚀 ~ selectedIndexList:", selectedIndexList)
  const [isServ, setIsServ] = useState(false);
  const [icon, setIcon] = useState("/img/icons/icon-serv-grey.svg");
  const { isRequestLoading } = useRecoilValue(appState);
  const setRequestLoading = useSetRequestLoading();

  useEffect(() => {
    if (isCancel) {
      return setIcon("/img/icons/icon-serv-red.svg");
    }
    if (selectedIndexList?.includes(index) || isServ) {
      return setIcon("/img/icons/icon-serv-green.svg");
    }
    setIcon("/img/icons/icon-serv-grey.svg");
  }, [selectedIndexList, isCancel, isServ]);

  useEffect(() => {
    setIsServ(isChecked);
  }, [isChecked])
  

  const updateOrderDetail = useUpdateOrderDetail();

  const onServeBtnHandler = async (orderDetail, orderDetailId) => {
    await setRequestLoading({
      isRequestLoading: true
    });
    await updateOrderDetail({
      ...orderDetail,
      status_id: 2,
      remain_amount: 0
    });
    setIsServ(false)
    setSelectedList(prev => {
      let newSelectedList = [...prev];
      if(newSelectedList.length > 0) {
        if(newSelectedList[orderIndex]) {
          let filteredNewSelectedList = newSelectedList[orderIndex].list.filter(s => s != orderDetailId);
          newSelectedList[orderIndex].list = filteredNewSelectedList;
        }
      }
      return newSelectedList;
    })
    await setRequestLoading({
      isRequestLoading: false
    });
  }

  return (
    <div
      className={`flex justify-between text-[#4A4A4A] text-18 ${isDisabled && !isCancel ? "pointer-events-none opacity-50" : ""
        }`}
    >
      <div className="relative flex leading-[1.2]">
        <div
          className={`kitchen-input-checkbox w-fit mr-[min(1.46vw,15px)] h-fit ${isCancel ? "opacity-0 pointer-events-none" : ""
            }`}
        >
          <input
            type="checkbox"
            // checked={selectedIndexList?.includes(index)}
            checked={isServ}
            onChange={() => {
              setIsServ(!isServ);
              // TODO: Recheck
              // if (isSelectOneMode) {
              //   return setSelectedList((prev) => {
              //     if (prev?.includes(index)) {
              //       return [];
              //     }
              //     setSeletedUnitsObj({
              //       foodList: foodList,
              //       setFoodList: setFoodList,
              //       selectIndex: index,
              //       setSelectedList: setSelectedList,
              //     });
              //     return [index];
              //   });
              // }
              setSelectedList((prev) => {
                const oldList = [...prev][orderIndex].list;
                // console.log("🚀 ~ file: KitchenPage.jsx:611 ~ setSelectedList ~ oldList:", oldList)
                if (oldList?.includes(orderDetailId)) {
                  const newSelectedList = [...prev];
                  newSelectedList[orderIndex].list = oldList.filter((item) => item !== orderDetailId);
                  // console.log("🚀 ~ setSelectedList ~ newSelectedList:", newSelectedList)
                  return newSelectedList
                }
                const newList = [...oldList, orderDetailId];
                // console.log("🚀 ~ file: KitchenPage.jsx:615 ~ setSelectedList ~ newList:", newList)
                const newSelectedList = [...prev];
                newSelectedList[orderIndex].list = newList;
                // console.log("🚀 ~ file: KitchenPage.jsx:617 ~ setSelectedList ~ newSelectedList:", newSelectedList)
                setSeletedUnitsObj({
                  foodList: foodList,
                  setFoodList: setFoodList,
                  selectIndex: index,
                  setSelectedList: setSelectedList,
                  orderIndex: orderIndex,
                  orderId: orderId,
                  orderDetailId: orderDetailId
                });
                return newSelectedList
              });
            }}
          />
          <label></label>
        </div>
        <div
          className={`mr-[min(1.76vw,18px)] text-18 font-[500] ${selectedIndexList?.includes(index) || isCancel ? "line-through" : ""
            } ${info.prevUnit ? 'text-[var(--color-red)]' : ''} relative`}
        >
          {info.unit} x
          {info.prevUnit && (
            <div className="absolute line-through top-[calc(min(0.78vw,8px)*-1)] right-[calc(min(0.59vw,6px)*-1)] text-12 text-black">{info.prevUnit} x</div>
          )}
        </div>
        <div className="flex flex-col gap-[min(0.29vw,3px)]">
          <div
            className={`text-18 font-[500] ${selectedIndexList?.includes(index) || isCancel
                ? "line-through"
                : ""
              }`}
          >
            {info.name}
          </div>
          {info &&
            info.addOnList &&
            info.addOnList.map((value, index) => {
              return (
                <div
                  key={index}
                  className="text-18 p-1 min-w-[min(6.15vw,63px)] min-h-[min(1.76vw,18px)] px-[min(0.29vw,3px)] text-[var(--color-red)] bg-[var(--color-light-red)] rounded-[min(0.20vw,2px)] flex items-center"
                >
                  {value}
                </div>
              );
            })}
          {info &&
            info.ignoreList &&
            info.ignoreList.map((value, index) => {
              return (
                <div
                  key={index}
                  className="text-18 p-1 w-fit min-h-[min(1.76vw,18px)] px-[min(0.29vw,3px)] text-[var(--color-orange)] bg-[var(--color-light-orange)] rounded-[min(0.20vw,2px)] flex items-center"
                >
                  {value}
                </div>
              );
            })}
            { info.note && info.note.replace(" ") != "" &&
              <div
                className="text-18 p-1 min-w-[min(6.15vw,63px)] min-h-[min(1.76vw,18px)] px-[min(0.29vw,3px)] text-[var(--color-orange)] bg-[var(--color-light-orange)] rounded-[min(0.20vw,2px)] flex items-center"
              >
                {info.note}
              </div>
            }
        </div>
      </div>
      <div
        // TODO: recheck
        // className={`${selectedIndexList?.includes(index)
        //     ? "cursor-pointer"
        //     : "pointer-events-none"
        //   }`}
        className={`ml-[min(0.49vw,5px)] ${isServ ? "cursor-pointer" : "pointer-events-none"}`}
        onClick={async () => {
          // setFoodList((prev) => {
          //   const newList = [...prev];
          //   if (newList.length > index) {
          //     newList[index].isServ = true;
          //   }
          //   return newList;
          // });
          // setSelectedList((prev) => {
          //   const newList = [...prev];
          //   if (newList?.includes(index)) {
          //     return newList.filter((v) => v !== index);
          //   }
          //   return newList;
          // });
          await onServeBtnHandler(info, orderDetailId);
        }}
      >
        <img
          className={`object-contain aspect-square min-w-[min(2.13vw,36px)] h-fit`}
          src={icon}
          alt=""
        />
      </div>
    </div>
  );
}

function FooterCard({
  orderNo,
  selectedList,
  setSelectedList,
  setFoodList,
  checkIsSelectMutiple,
  setModalKitchenCancelOpen,
  foodList,
  orderIndex,
  orderId,
  setSeletedUnitsObj,
  clearOrderHanlder,
  orderBatch
}) {
  // console.log("🚀 ~ info.ignoreList.map ~ selectedList:", selectedList)
  const [isClearOpen, setIsClearOpen] = useState(false);

  // const updateOrderDetail = useUpdateOrderDetail();

  const onRanOutBtnHandler = async () => {
    // console.log("selectedList: ", selectedList)
    // const selectedOrderDetails = selectedList.map(index => foodList[index]);
    const selectedOrderDetails = foodList.filter(food => selectedList.includes(food.id));
    // console.log("🚀 ~ file: KitchenPage.jsx:680 ~ onRanOutBtnHandler ~ selectedOrderDetails:", selectedOrderDetails)
    setSeletedUnitsObj(prev => ({
      ...prev,
      foodList: selectedOrderDetails,
      // foodList: foodList,
      orderId: orderId,
      orderIndex: orderIndex
    }));
    setModalKitchenCancelOpen(true);
    // for (const orderDetail of selectedOrderDetails) {
    //   await updateOrderDetail({
    //     ...orderDetail,
    //     status_id: 3
    //   });
    // }
    // setSelectedList([]);
  }

  const onClearBtnHandler = async () => {
    setIsClearOpen(false);
    await clearOrderHanlder(orderId,orderBatch);
  }

  return (
    <div className="px-[min(1.95vw,20px)] rounded-b-[min(0.39vw,4px)]">
      <div className="relative pb-[min(0.98vw,10px)]">
        <div className="h-[min(0.10vw,1px)] w-full bg-[#CECECE]"></div>
        <div className="flex justify-between text-14 font-[500] text-[#B0A9A9]">
          <div className="flex gap-[min(0.59vw,6px)]">
            <div>Order</div>
            <div>{orderNo}</div>
          </div>
          <div className="flex gap-[min(0.88vw,9px)]">
            <div>POS 1</div>
            {/* <div>00:07:35</div> */}
            <div>00:00:00</div>
          </div>
        </div>
        <button
          onClick={async () => {
            // setFoodList((prev) => {
            //   const newFoodList = [...prev];
            //   selectedList.map((index) => {
            //     if (newFoodList[index]) {
            //       newFoodList[index].isCancel = true;
            //     }
            //   });
            //   return newFoodList;
            // });
            // setSelectedList([]);
            await onRanOutBtnHandler();
            // TODO: recheck
            // setFoodList((prev) => {
            //   if (!checkIsSelectMutiple(selectedList, prev)) {
            //     setModalKitchenCancelOpen(true);
            //     return prev;
            //   }
            //   const newFoodList = [...prev];
            //   selectedList.map((index) => {
            //     if (newFoodList[index]) {
            //       newFoodList[index].isCancel = true;
            //     }
            //   });
            //   setSelectedList([]);
            //   return newFoodList;
            // });
          }}
          className={`mt-[min(0.49vw,5px)] flex justify-between items-center rounded-[min(0.49vw,5px)] min-w-[min(6.54vw,67px)] h-[min(2.44vw,25px)] border-[min(0.10vw,1px)] border-[#CECECE] px-[min(0.49vw,5px)] logout-avarta ${selectedList?.length > 0 ? "" : "disabled-btn-grey"
            }`}
        >
          <img
            className="object-contain aspect-square w-[min(1.56vw,16px)] h-fit"
            src="/img/icons/icon-stop-red.svg"
            alt=""
          />
          <div className="text-16">หมด</div>
        </button>
        <div className="absolute bottom-0 right-0">
          <div
            className="cursor-pointer"
            onClick={() => setIsClearOpen(!isClearOpen)}
          >
            <img
              className="logout-avartar object-contain aspect-square w-[min(2.83vw,29px)] h-fit"
              src="/img/icons/icon-three-dot-grey.svg"
              alt=""
            />
          </div>

          {isClearOpen && (
            <div className="relative h-0">
              <ClearAllBtn onBtnClick={onClearBtnHandler}/>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ClearAllBtn({ onBtnClick }) {
  return (
    <button
      style={{ userSelect: "none" }}
      className="absolute right-[calc(min(1.95vw,20px)*-1)] top-[min(0.68vw,7px)] text-18 h-[min(4.10vw,42px)] font-[500] text-[#4A4A4A] rounded-[min(0.78vw,8px)] w-[min(19.43vw,199px)] pl-[min(1.37vw,14px)] py-[min(0.88vw,9px)] shadow-logout-avartar bg-white flex items-center gap-[min(1.07vw,11px)] logout-avartar"
      onClick={onBtnClick}
    >
      <img
        className="aspect-square w-[min(2.34vw,24px)] object-contain"
        src="/img/icons/icon-bin-red.svg"
        alt=""
      />
      <div>ล้างทั้งหมด</div>
    </button>
  );
}
