import React, { useEffect, useState } from "react";
import * as _ from 'lodash';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { nanoid } from 'nanoid'

export default function  ModalProductOption({...props}) {

    const {
      productselect,
      productOrder,
      setProductOrder,
      addProductOrders,
      removeProductOrders,
      optionProductChecked,
      prevProductOrder,
      edit,
      ...rest
    } = props;

    const [productSumPrice, setProductSumPrice] = useState(
      edit && productOrder?.productSumPrice ? 
        productOrder.productSumPrice :
      productselect?.productPrice ? 
        productselect.productPrice :
        0
    )
    const [maxproductQty, setMaxproductQty] = useState(99);

    const [isDisabled, setIsDisabled] = useState(false);
    
    const handleSubmit = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      props.addProductOrders();
    };
  
    const handleSumProduct = () => {
  
      const optionstandardPrice =  productOrder.optionstandard.length > 0 ? productOrder.optionstandard
      .map((pd) => parseFloat(pd.listPrice) * parseInt(pd.optionQty) )
      .reduce((prev, curr) => prev + curr) : 0;
      // console.log("🚀 ~ handleSumProduct ~ optionstandardPrice:", optionstandardPrice)
  
      const optionproductPrice =  productOrder.optionproduct.length > 0 ? productOrder.optionproduct
      .map((pd) => parseFloat(pd.listPrice) * parseInt(pd.optionQty) )
      .reduce((prev, curr) => prev + curr) : 0;
      // console.log("🚀 ~ handleSumProduct ~ optionproductPrice:", optionproductPrice)
  
      // const productPrict = parseFloat(props.productOrder.productPrice) * parseInt(props.productOrder.productQty);
      
      const sumPrice = parseFloat((productOrder.productPrice + optionstandardPrice + optionproductPrice) * productOrder.productQty);
      // console.log("🚀 ~ handleSumProduct ~ sumPrice:", sumPrice)
      setProductSumPrice(sumPrice);
      props.setProductOrder((prev) => ({ ...prev, productSumPrice: sumPrice }));
    }
  
    const handleuncheckOptionNote = (id) => { 
      props.setProductOrder((prev) => ({  ...prev,optionnote:prev.optionnote.filter(dx => dx.listID !== id) }) );
    }

    const handlecheckOptionNote = (item) => {
      props.setProductOrder( (prev) => ({  
        ...prev,
          optionnote:[
            ...prev.optionnote,item
          ]
        }) 
      )
    }

    const handleuncheckOptionStandard = (id) => { 
      props.setProductOrder((prev) => ({  ...prev,optionstandard:prev.optionstandard.filter(dx => dx.listID !== id) }) );
    }

    const handlecheckOptionStandard = (item, amount) => {
      // console.log("🚀 ~ file: ModalProductOption.jsx:55 ~ handlecheckOptionStandard ~ item:", item)
      const option = { ...item, optionQty : amount ? amount : 1 }
      // console.log("🚀 ~ file: ModalProductOption.jsx:57 ~ handlecheckOptionStandard ~ option:", option)

      if(item.isMultiple) {
        props.setProductOrder( (prev) => {
          return {  
            ...prev,
            optionstandard:[
              ...prev.optionstandard,
              option
            ]
          }
        });
      } else {
        props.setProductOrder( (prev) => {
          let newOption = [...prev.optionstandard].filter(o => o.optionType != item.optionType);
          return {  
            ...prev,
            optionstandard:[
              ...newOption, option
            ]
          }
        });
      }
    }

    const handleChangeOptionStandardValue = (id, value) => {
      props.setProductOrder( (prev) => ({  
        ...prev,
          optionstandard: prev.optionstandard.map((el) => 
              el.listID == id
              ? {
                ...el,
                // optionQty: el.optionQty = e.target.value
                optionQty: value
              } : el
          )
        }) 
      )
    }

    const handleuncheckOptionProduct = (id) => { 
      props.setProductOrder((prev) => ({  ...prev,optionproduct:prev.optionproduct.filter(dx => dx.listID !== id) }) );
    }

    const handlecheckOptionProduct = (item, amount) => {
      // console.log("🚀 ~ file: ModalProductOption.jsx:55 ~ handlecheckOptionStandard ~ item:", item)
      const option = { ...item, optionQty : amount ? amount : 1 }
      // console.log("🚀 ~ file: ModalProductOption.jsx:57 ~ handlecheckOptionStandard ~ option:", option)

      if(item.isMultiple) {
        props.setProductOrder( (prev) => {
          return {  
            ...prev,
            optionproduct:[
              ...prev.optionproduct,
              option
            ]
          }
        });
      } else {
        props.setProductOrder( (prev) => {
          let newOption = [...prev.optionproduct].filter(o => o.optionType != item.optionType);
          return {  
            ...prev,
            optionproduct:[
              ...newOption, option
            ]
          }
        });
      }


    }

    const handleChangeOptionProductValue = (id, value) => {
      props.setProductOrder( (prev) => ({  
        ...prev,
          optionproduct: prev.optionproduct.map((el) => 
              el.listID == id
              ? {
                ...el,
                // optionQty: el.optionQty = e.target.value
                optionQty: value
              } : el
          )
        }) 
      )
    }

    useEffect(() => {
      if(!_.isEqual(prevProductOrder, productOrder)){
        if(productOrder){
          handleSumProduct();
        }
      }
    }, [productOrder]);

    // useEffect(() => {
    //   if(productselect || productOrder) {
    //     handleSumProduct();
    //   }
    // }, [productselect, productOrder]);

    useEffect(() => {
      if(props.productOrder.productStatus == 1){
        setMaxproductQty(props.productOrder.productQty)
      }else{
        setMaxproductQty(99)
      }
    }, [props.show]);

    // // Refrect productselect data changes
    // useEffect(() => {
    //   setProductSumPrice(productselect.productPrice)
    // }, [productselect])

    useEffect(() => {
      if(productOrder) {
        if(productOrder.productStatus != 0) {
          setIsDisabled(true)
        } else {
          setIsDisabled(false)
        }
      }
    }, [productOrder])
    
    return (
      <Modal
        {...rest}
          size="lg"
          centered
          dialogClassName="modal-st-1 optionModal "
        >
          <Form noValidate onSubmit={handleSubmit}>
            <div className="modal-header  px-4">
              <div className=" fs-18 fw-6 tc-2 me-auto foodName"> 
                
                {
                  props.productOrder.productStatus == 1 ? (
                    <span className="me-3">ยกเลิกรายการ</span> 
                  ) : ''
                }
               
                {props.productselect.productName}
              
              </div>
              <div className="me-4">
                <span className="fw-light tc-2 fs-16 me-2">ราคา</span>
                <span className="fw-18 fw-6 ">
                  <span className="foodPrice">{productSumPrice.toLocaleString()}</span>.-
                </span>
              </div>
              <div className="d-flex align-items-center">
                <button type="button" 
                className="btn  btn-minus" 
                disabled={productOrder.productQty - 1 == 0}
                onClick={ () => { 
                  props.setProductOrder( (prev) => ({  ...prev,productQty:(prev.productQty - 1) }) )  } 
                }></button>
                <input
                  type="number"
                  name="foodQty"
                  className="form-control  mx-4 text-center lh-1 foodQty"
                  value={productOrder.productQty}
                  onChange={(e) => { 
                    if(e.target.value <= 99) {
                      props.setProductOrder((prev) => ({ 
                        ...prev,
                        productQty: e.target.value
                      }))
                    }
                  }}
                />
                <button 
                type="button" 
                className="btn btn-plus" 
                onClick={ () => { 
                  props.setProductOrder( (prev) => ({  ...prev,productQty:(prev.productQty + 1) }) )  } 
                }
                disabled={ productOrder.productQty >= maxproductQty }
                ></button>
              </div>
            </div>
            <div className="modal-body px-4 py-3 ">
              <div className="foodOption ">
                {props.productselect?.optionstandard?.length > 0 && props.productselect?.optionstandard?.map((item, index) =>

                  item.selecttype == 0 ? (
                    <OptionSigleCheck
                      item={item}
                      key={item.selecttype+index+'optionstandard'}
                      optionObj={{
                        option:props.productselect.optionstandard,
                        optionChecked:props.optionProductChecked.optionstandard
                      }}
                      handleCheck={ (item) =>  handlecheckOptionStandard(item)  }
                      handleunCheck={ (listID) =>  handleuncheckOptionStandard(listID) }
                      isDisabled={isDisabled}
                      optionSelected={productOrder.optionstandard}
                    />
                  ) : (
                    <OptionMultiCheck
                      item={item}
                      key={item.selecttype+index}
                      optionObj={{
                        option:props.productselect.optionstandard,
                        optionChecked:props.optionProductChecked.optionstandard
                      }}
                      productObj={{
                        productOrder:props.productOrder.optionstandard
                      }}
                      handleCheck={ (item, amount) =>  handlecheckOptionStandard(item, amount)  }
                      handleunCheck={ (listID) =>  handleuncheckOptionStandard(listID) }
                      handleChangeValue={(listID,value) => handleChangeOptionStandardValue(listID,value) }
                      isDisabled={isDisabled}
                    />
                  )
                  
                )}
  
                {props.productselect?.optionproduct?.length > 0 && props.productselect?.optionproduct?.map((item, index) =>
                  
                  item.selecttype == 0 ? (
                    <OptionSigleCheck
                      item={item}
                      key={item.selecttype+index+'optionproduct'}
                      optionObj={{
                        option:props.productselect.optionproduct,
                        optionChecked:props.optionProductChecked.optionproduct
                      }}
                      handleCheck={ (item) =>  handlecheckOptionProduct(item)  }
                      handleunCheck={ (listID) =>  handleuncheckOptionProduct(listID) }
                      isDisabled={isDisabled}
                      optionSelected={productOrder.optionproduct}
                    />
                  ) : (
                    <OptionMultiCheck
                      item={item}
                      key={item.selecttype+index}
                      optionObj={{
                        option:props.productselect.optionproduct,
                        optionChecked:props.optionProductChecked.optionproduct
                      }}
                      productObj={{
                        productOrder:props.productOrder.optionproduct
                      }}
                      handleCheck={ (item, amount) =>  handlecheckOptionProduct(item, amount)  }
                      handleunCheck={ (listID) =>  handleuncheckOptionProduct(listID) }
                      handleChangeValue={(listID,value) => handleChangeOptionProductValue(listID,value) }
                      isDisabled={isDisabled}
                    />
                  )
                  
                )}
              </div>
  
              <div className=" pe-4">
                <div className="row gx-5">
                  <div className="col-6">
                    <div className="d-flex align-items-center mb-1">
                      <div className="me-auto fs-14 tc-15">หมายเหตุ</div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-link p-0 text-danger btn-clear fs-14"
                          onClick={(e) => { props.setProductOrder( (prev) => ({  ...prev,note:''}) )  } }
                          disabled={isDisabled}
                        >
                          ล้าง
                        </button>
                      </div>
                    </div>
                    <div className="option-note-container">
                      <textarea
                        id="noteTextArea"
                        className="form-control fs-14"
                        name="foodNote"
                        rows="4"
                        onChange={(e) => { props.setProductOrder( (prev) => ({  ...prev,note:e.target.value}) )  } }
                        required
                        value={productOrder.note}
                        disabled={isDisabled}
                        maxLength={60}
                      ></textarea>
                      {productOrder?.note?.length >= 0 &&
                        <label htmlFor="noteTextArea" className="note-textarea-placeholder text-sm">{productOrder.note.length}/60</label>
                      }
                    </div>
                  </div>
                  <div className="col-6">
                    {
                      props.productselect.optionnote && props.productselect.optionnote.length > 0  ? (
                        <div className="fs-14 tc-15 mb-2">ตัวเลือกหมายเหตุ</div>
                      ) : ''
                    }
                    
                    <div className="d-flex flex-wrap">
  
                    {props.productselect?.optionnote?.length > 0 && props.productselect?.optionnote?.map((item, index) =>   
  
                      
                      <div className="form-check-option me-2 mb-2" key={item.listID}>
                        <input
                          type="checkbox"
                          name="optionnote"
                          className="hide-input"
                          id={ `optionnote-${item.listID} `}
                          defaultChecked={ props.optionProductChecked.optionnote.includes(item.listID) }
                          onChange={(e) => { 
                            if(e.target.checked === true){
                              handlecheckOptionNote(item) 
                            }else{
                              handleuncheckOptionNote(item.listID) 
                            } 
                          } }
                          disabled={isDisabled}
                        />
                        <label htmlFor={ `optionnote-${item.listID} `}>{item.listName}</label>
                      </div>
                                          
                    
                    )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <hr style={{margin: '1rem 0'}} />
  
            <div className="text-end d-flex align-items-center px-4 pb-2  mb-2">
              <div className="me-auto">
                {
                  props.edit === 1 && props.productOrder.productStatus == 0  ? (
                    <button
                      type="button"
                      className="btn btn-st-9 px-4 py-3 lh-1  btn-cancel-food "
                      onClick={props.removeProductOrders}
                    >
                      ยกเลิกรายการ
                    </button>
                  ) : ''
                }
                
              </div>
              <button
                type="button"
                className="btn btn-st-2 px-5 py-3 lh-1 me-3"
                onClick={() => props.onHide() }
              >
                { props.productOrder.productStatus != 0 ? 'ย้อนกลับ' : 'ยกเลิก' }
              </button>
              <button 
                className={`btn ${props.productOrder.productStatus != 0 ? "btn-st-9 btn-cancel-food font-bold text-xl" : "btn-login"} px-5 py-3 lh-1`}
                disabled={productOrder.productStatus == 2 ?? false}
              > 
                { props.productOrder.productStatus != 0 ? 'ยกเลิกรายการ' : 'ยืนยัน' }
              </button>
            </div>
          </Form>
      </Modal>
    )

}

const OptionSigleCheck  = ({ item, optionObj, handleCheck, handleunCheck, isDisabled,optionSelected, ...props}) => {

  
  const inputname = nanoid()
  return (
    <div > 
      <div className="mb-3">
        <div className="fs-18 fw-5 tc-2 mb-3">
          {" "}
          {item.optionName}{" "}
          <span className="fs-14 fw-light tc-16 ms-2">
            {" "}
            (เลือกได้ 1 อย่าง)
          </span>
        </div>
        <div className="">
          {item.optionList.map((item2, index2) => (
            <div className="form-check form-check-inline form-check-st-2 mb-3" key={item.selecttype+index2}>
              <input
                className="form-check-input form-option"
                type="checkbox"
                name={inputname}
                id={item.optionID + item2.listID}
                defaultChecked={ optionObj.optionChecked.includes(item2.listID) }
                checked={ optionSelected.filter( el => el.listID ==  item2.listID && el.optionType == item.optionName  ).length > 0 }
                onClick={(e) => { 
                  if(e.target.checked === true){
                    handleCheck(item2) 
                  }else{
                    handleunCheck(item2.listID) 
                  } 
                } }
                disabled={isDisabled}
              />
              <label
                className="form-check-label ms-2 ps-1 mt-1"
                htmlFor={item.optionID + item2.listID}
              >
                {item2.listName}
                {item2.listPrice > 0 && ` (+${item2.listPrice})`}
              </label>
            </div>
          ))}
        </div>
      </div>
      <hr style={{margin: '1rem 0'}} />
    </div>
  )
}

const OptionMultiCheck  = ({ item, optionObj, productObj, handleCheck, handleunCheck, handleChangeValue, isDisabled, ...props }) => {
  // console.log("🚀 ~ OptionMultiCheck ~ item:", item)
  const inputname = nanoid()

  const [optionAmount, setOptionAmount] = useState([])

  const getOptionValue =  (listID) => {
     var item = productObj.productOrder.filter( el => el.listID ===  listID ) 
     if(item.length > 0){
        return (item[0].optionQty)
     }
     return '';
  }

  useEffect(() => {
    if (item?.optionList?.length > 0) {
      const initAmount = item.optionList.map(ol => 0);
      // console.log("🚀 ~ useEffect ~ initAmount:", initAmount)
      setOptionAmount(initAmount)
    }
  }, [item])

  return (
    <div>
      <div className="mb-3">
        <div className="fs-18 fw-5 tc-2 mb-3">
          {item.optionName}{" "}
          <span className="fs-14 fw-light tc-16 ms-2">
            {" "}
            (เลือกมากกว่า 1 อย่าง)
          </span>
        </div>
        <div className="card card-option">
          <div className="card-body   pe-5">
            <div className="row gx-5 gy-2">
              {item.optionList.map((item2, index2) => (
                <div className="col-6" key={item2+index2}>
                  <div className="form-check form-check-group form-check-st-2 t0 d-flex align-items-center">
                    <input
                      className="form-check-input form-option"
                      type="checkbox"
                      defaultChecked={ optionObj.optionChecked.includes(item2.listID) }
                      onClick={(e) => { 
                        if(e.target.checked === true){
                          handleCheck(item2, getOptionValue(item2.listID) != '' ? getOptionValue(item2.listID) : optionAmount[index2]) 
                        }else{
                          handleunCheck(item2.listID) 
                        } 
                      } }
                      disabled={isDisabled}
                    />
                    <input
                      type="number"
                      name="optionQty"
                      className="form-control validateNumber form-option-number mx-3"
                      defaultValue={ getOptionValue(item2.listID) }
                      onClick={(e) => {
                        e.target.select();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === '.' || e.key === '-' || e.key === '+') {
                          e.preventDefault();
                          return;
                        }
                      }}
                      onChange={(e) => {
                        var inputValue = e.target.value.replace(/[^\d]/g, '');
                        // console.log("🚀 ~ OptionMultiCheck ~ inputValue:", inputValue)
                        // console.log("🚀 ~ OptionMultiCheck ~ inputValue:", Number(inputValue))
                        if(inputValue.length > 0 && inputValue.charAt(0) == '0') {
                          inputValue = inputValue.substring(1);
                        }

                        if(inputValue == "") {
                          inputValue = 0;
                        }

                        if(Number(inputValue) <= 99) {
                          const newAmount = [...optionAmount];
                          newAmount[index2] = inputValue;
                          setOptionAmount(newAmount);
                          handleChangeValue(item2.listID, inputValue);
                        }
                      }}
                      disabled={isDisabled}
                      max={99}
                      value={ getOptionValue(item2.listID) != '' ? getOptionValue(item2.listID) : optionAmount[index2] }
                    />
                    <div className="me-auto">{item2.listName} (+{item2.listPrice})</div>
                    <div>X { getOptionValue(item2.listID)  !== '' ?  (item2.listPrice*getOptionValue(item2.listID)).toFixed(2) : item2.listPrice  }</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <hr style={{margin: '1rem 0'}} />
    </div>
  )
}