import React from "react";

export default function CustomInput({ inputprops,...props }) {

  return (
    <div
      {...props}
      className={`custom-input sarabun text-18 ${props.className ?? ''}`}
    >
      <input
        {...inputprops}
        className={`w-full ${
          inputprops && inputprops.className
            ? inputprops.className
            : ""
        } ${inputprops && inputprops.error ? 'error' : ''}`}
      />
    </div>
  );
}
