import React, { useEffect, useState } from "react";
import CustomInput from "components/fragments/CustomInput";
import SelectStaffCard from "components/layouts/SelectStaffCard";
import ModalConfirm from "components/layouts/ModalConfirm";
import { nanoid } from "nanoid";

export default function Staff({
  staffObj,
  modalSuccessOpenObj,
  selectedUserObj,
  userInfoObj,
  staffList,
  updateOrderStaff,
  resetPayment,
  ...props
}) {
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [userSelect, setUserSelect] = useState(null);
  // const [userList, setUserList] = useState([
  //   { id: 1, fullName: "มุนิน มุตา", code: "EMP-001" },
  //   { id: 2, fullName: "มุนิน มุตา", code: "EMP-002" },
  //   { id: 3, fullName: "มุนิน มุตา", code: "EMP-003" },
  // ]);
  const [userList, setUserList] = useState(staffList ?? []);
  const [isError, setIsError] = useState();
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    if (!keyword) {
      return setFilteredUserList([]);
    }
    const filteredList = userList.filter(
      (user) =>
        user.fullName.toLowerCase().includes(keyword.toLowerCase()) ||
        user.code.toLowerCase().includes(keyword.toLowerCase())
    );
    setFilteredUserList(filteredList);
  }, [userList, keyword]);

  useEffect(() => {
    if (userInfoObj.userInfo && !keyword) {
      setKeyword(userInfoObj.userInfo.fullName);
    }
  }, []);

  return (
    <div className="d-flex flex-column h-100">
      <div className="fs-22 fw-500 mb-2">
        {userInfoObj.userInfo ? "แก้ไขพนักงาน" : "เพิ่มพนักงานขาย"}
      </div>
      <CustomInput
        className="mt-[min(0.98vw,10px)] w-full"
        inputprops={{
          className:
            "rounded-[min(0.78vw,8px)] border-[min(0.10vw,1px)] border-[#b6bbc6] py-[min(1.03vw,10.5px)] px-[min(1.46vw,15px)] font-[400] text-16",
          placeholder: "พิมพ์ชื่อ/รหัสพนักงาน",
          onChange: (e) => setKeyword(e.currentTarget.value),
          value: keyword,
          error: isError,
        }}
      />
      {filteredUserList && filteredUserList.length > 0 && (
        <div
          className="mt-[min(1.46vw,15px)] mb-[min(1.46vw,15px)] flex flex-col gap-[min(1.27vw,13px)]"
          style={{ maxHeight: "450px", overflow: "auto" }}
        >
          {filteredUserList.map((value, index) => {
            return (
              <SelectStaffCard
                key={index}
                onClick={() => selectedUserObj.setSelectedUser(value)}
                userInfo={{
                  id: value.id,
                  fullName: value.fullName,
                  code: value.code,
                }}
                selected={value.id === selectedUserObj?.selectedUser?.id}
              />
            );
          })}
        </div>
      )}

      <div className="d-flex align-items-center pt-2 mt-auto">
        {userInfoObj.userInfo ? (
          <button
            className="me-auto btn btn-st-q1 px-3 bg-[#F95057] text-[#FFF]"
            style={{ width: "70px" }}
            onClick={async () => {
              // const update = await updateOrderStaff();
              // userInfoObj.setUserInfo(null);
              setModalConfirmOpen(true);
            }}
          >
            ลบ
          </button>
        ) : (
          ""
        )}

        <button
          className={` ${
            userInfoObj.userInfo ? "ms-auto me-3" : "me-auto"
          } btn btn-st-q1 px-4 bg-[#E6E6E6] text-[#6D788D] `}
          onClick={() => staffObj.setStaffSelect(false)}
        >
          {userInfoObj.userInfo ? "ยกเลิก" : "ย้อนกลับ"}
        </button>

        <button
          className="btn dp btn-st-q1"
          disabled={!selectedUserObj.selectedUser}
          onClick={async () => {
            const update = await updateOrderStaff(selectedUserObj.selectedUser);
            userInfoObj.setUserInfo(selectedUserObj.selectedUser);
            resetPayment();
            modalSuccessOpenObj.setModalTextSuccess("เพิ่มพนักงานสำเร็จ");
            modalSuccessOpenObj.setModalOpen(true);
            staffObj.setStaffSelect(false);
          }}
        >
          ยืนยัน
        </button>
      </div>

      <ModalConfirm
        textobj={{
          t1: "คุณต้องการยืนยันการลบพนักงาน",
        }}
        modalOpenObj={{
          modalOpen: modalConfirmOpen,
          setModalOpen: setModalConfirmOpen,
        }}
        handleConfirm={async () => {
          const update = await updateOrderStaff();
          userInfoObj.setUserInfo(null);
          setModalConfirmOpen(false);
          modalSuccessOpenObj.setModalTextSuccess("ลบพนักงานสำเร็จ");
          modalSuccessOpenObj.setModalOpen(true);
          // userInfoObj.setUserInfo(null);
          // staffObj.setStaffSelect(false);
        }}
      />
    </div>
  );
}
