import React, { useEffect, useState } from "react";
import MoneyDisplay from "./MoneyDisplay";
import MoneyDisplayCount from "./MoneyDisplayCount";
import CustomBtn from "components/fragments/CustomBtn";
import CustomInput from "components/fragments/CustomInput";
import { numberWithTwoDecimals } from "utils/numberFormat";
import { useRecoilState, useSetRecoilState } from "recoil";
import { selectedPosSettingState } from "state/pos";

const initRowValues = [
  {
    display: {
      value: 1000,
      isBankNote: true,
    },
    displayCount: {
      countValue: 0,
      currentValue: 0,
      isActive: false,
    },
  },
  {
    display: {
      value: 500,
      isBankNote: true,
    },
    displayCount: {
      countValue: 0,
      currentValue: 0,
      isActive: false,
    },
  },
  {
    display: {
      value: 100,
      isBankNote: true,
    },
    displayCount: {
      countValue: 0,
      currentValue: 0,
      isActive: false,
    },
  },
  {
    display: {
      value: 50,
      isBankNote: false,
    },
    displayCount: {
      countValue: 0,
      currentValue: 0,
      isActive: false,
    },
  },
  {
    display: {
      value: 20,
      isBankNote: false,
    },
    displayCount: {
      countValue: 0,
      currentValue: 0,
      isActive: false,
    },
  },
  {
    display: {
      value: 10,
      isBankNote: false,
    },
    displayCount: {
      countValue: 0,
      currentValue: 0,
      isActive: false,
    },
  },
  {
    display: {
      value: 5,
      isBankNote: false,
    },
    displayCount: {
      countValue: 0,
      currentValue: 0,
      isActive: false,
    },
  },
  {
    display: {
      value: 1,
      isBankNote: false,
    },
    displayCount: {
      countValue: 0,
      currentValue: 0,
      isActive: false,
    },
  },
];

export default function MoneyCountController({
  startValueObj,
  info,
  isResetObj,
  viaPaymentInfoObj,
  isCloseBalance = false,
  isCloseBalanceCfObg,
  posClosureSummary,
  currentMenu = 0,
  isPOSClosure = false,
}) {
  // console.log("🚀 ~ isCloseBalance:", isCloseBalance)
  // console.log("isResetObj: ", isResetObj)
  const [sumValue, setSumValue] = useState("0.00");

  const handleInputChange = (index, value) => {
    const updatedRowValues = [...rowValues];
    updatedRowValues[index].displayCount.currentValue = parseInt(value) || 0;
    setRowValues(updatedRowValues);
    isResetObj.setIsReset(false);
  };

  const calculateRowSum = () => {
    return rowValues.reduce((sum, item) => {
      return sum + item.display.value * item.displayCount.currentValue;
    }, 0);
  };

  const [rowValues, setRowValues] = useState([...initRowValues]);

  const resetAllValue = () => {
    setRowValues([...initRowValues]);
    startValueObj.setStartValue("0.00");
    isResetObj.setIsReset(false);
  };

  const setSelectedPosSetting = useSetRecoilState(selectedPosSettingState);

  useEffect(() => {
    setRowValues([...initRowValues]);
  }, []);

  useEffect(() => {
    setSumValue(calculateRowSum());
  }, [rowValues]);

  useEffect(() => {
    setSelectedPosSetting(state => ({
      ...state,
      amount1000: rowValues[0].displayCount.currentValue,
      amount500: rowValues[1].displayCount.currentValue,
      amount100: rowValues[2].displayCount.currentValue,
      amount50: rowValues[3].displayCount.currentValue,
      amount20: rowValues[4].displayCount.currentValue,
      amount10: rowValues[5].displayCount.currentValue,
      amount5: rowValues[6].displayCount.currentValue,
      amount1: rowValues[7].displayCount.currentValue,
      amountTotal: sumValue
    }));
  }, [sumValue]);

  useEffect(() => {
    // console.log(isResetObj)
    if (isResetObj.isReset) {
      resetAllValue();
      // setRowValues([...initRowValues]);
      // isResetObj.setIsReset(false);
    }
  }, [isResetObj.isReset]);

  useEffect(() => {
    if (isCloseBalance) {
      startValueObj.setStartValue(sumValue);
    }
    if (isCloseBalance && viaPaymentInfoObj) {
      viaPaymentInfoObj.setViaPaymentInfo({
        ...viaPaymentInfoObj.viaPaymentInfo,
        diffTotal: sumValue - viaPaymentInfoObj.viaPaymentInfo.cashTotal,
      });
      isCloseBalanceCfObg.setIsCloseBalanceConfirm(false);
    }
  }, [isCloseBalance, sumValue]);

  useEffect(() => {
    if (isCloseBalance) {
      const testRowValues = [
        {
          display: {
            value: 1000,
            isBankNote: true,
          },
          displayCount: {
            countValue: 25000,
            currentValue: 25,
            isActive: false,
          },
        },
        {
          display: {
            value: 500,
            isBankNote: true,
          },
          displayCount: {
            countValue: 5000,
            currentValue: 10,
            isActive: false,
          },
        },
        {
          display: {
            value: 100,
            isBankNote: true,
          },
          displayCount: {
            countValue: 2500,
            currentValue: 25,
            isActive: false,
          },
        },
        {
          display: {
            value: 50,
            isBankNote: false,
          },
          displayCount: {
            countValue: 1000,
            currentValue: 20,
            isActive: false,
          },
        },
        {
          display: {
            value: 20,
            isBankNote: false,
          },
          displayCount: {
            countValue: 400,
            currentValue: 20,
            isActive: false,
          },
        },
        {
          display: {
            value: 10,
            isBankNote: false,
          },
          displayCount: {
            countValue: 450,
            currentValue: 45,
            isActive: false,
          },
        },
        {
          display: {
            value: 5,
            isBankNote: false,
          },
          displayCount: {
            countValue: 500,
            currentValue: 100,
            isActive: false,
          },
        },
        {
          display: {
            value: 1,
            isBankNote: false,
          },
          displayCount: {
            countValue: 100,
            currentValue: 100,
            isActive: false,
          },
        },
      ];
      setRowValues([...initRowValues]);
    }
  }, [isCloseBalance]);

  useEffect(() => {
    // if(isPOSClosure || posClosureSummary) {
    // console.log("🚀 ~ useEffect ~ isPOSClosure:", isPOSClosure)
  //     const newRowValues = [
  //       {
  //         display: {
  //           value: 1000,
  //           isBankNote: true,
  //         },
  //         displayCount: {
  //           countValue: posClosureSummary.amount1000 * 1000,
  //           currentValue: posClosureSummary.amount1000,
  //           isActive: false,
  //         },
  //       },
  //       {
  //         display: {
  //           value: 500,
  //           isBankNote: true,
  //         },
  //         displayCount: {
  //           countValue: posClosureSummary.amount500 * 500,
  //           currentValue: posClosureSummary.amount500,
  //           isActive: false,
  //         },
  //       },
  //       {
  //         display: {
  //           value: 100,
  //           isBankNote: true,
  //         },
  //         displayCount: {
  //           countValue: posClosureSummary.amount100 * 100,
  //           currentValue: posClosureSummary.amount100,
  //           isActive: false,
  //         },
  //       },
  //       {
  //         display: {
  //           value: 50,
  //           isBankNote: false,
  //         },
  //         displayCount: {
  //           countValue: posClosureSummary.amount50 * 50,
  //           currentValue: posClosureSummary.amount50,
  //           isActive: false,
  //         },
  //       },
  //       {
  //         display: {
  //           value: 20,
  //           isBankNote: false,
  //         },
  //         displayCount: {
  //           countValue: posClosureSummary.amount20 * 20,
  //           currentValue: posClosureSummary.amount20,
  //           isActive: false,
  //         },
  //       },
  //       {
  //         display: {
  //           value: 10,
  //           isBankNote: false,
  //         },
  //         displayCount: {
  //           countValue: posClosureSummary.amount10 * 10,
  //           currentValue: posClosureSummary.amount10,
  //           isActive: false,
  //         },
  //       },
  //       {
  //         display: {
  //           value: 5,
  //           isBankNote: false,
  //         },
  //         displayCount: {
  //           countValue: posClosureSummary.amount20 * 5,
  //           currentValue: posClosureSummary.amount5,
  //           isActive: false,
  //         },
  //       },
  //       {
  //         display: {
  //           value: 1,
  //           isBankNote: false,
  //         },
  //         displayCount: {
  //           countValue: posClosureSummary.amount1 * 1,
  //           currentValue: posClosureSummary.amount1,
  //           isActive: false,
  //         },
  //       },
  //     ];
      // setRowValues([...initRowValues]);
    // }
    setRowValues([
      {
        display: {
          value: 1000,
          isBankNote: true,
        },
        displayCount: {
          countValue: 0,
          currentValue: 0,
          isActive: false,
        },
      },
      {
        display: {
          value: 500,
          isBankNote: true,
        },
        displayCount: {
          countValue: 0,
          currentValue: 0,
          isActive: false,
        },
      },
      {
        display: {
          value: 100,
          isBankNote: true,
        },
        displayCount: {
          countValue: 0,
          currentValue: 0,
          isActive: false,
        },
      },
      {
        display: {
          value: 50,
          isBankNote: false,
        },
        displayCount: {
          countValue: 0,
          currentValue: 0,
          isActive: false,
        },
      },
      {
        display: {
          value: 20,
          isBankNote: false,
        },
        displayCount: {
          countValue: 0,
          currentValue: 0,
          isActive: false,
        },
      },
      {
        display: {
          value: 10,
          isBankNote: false,
        },
        displayCount: {
          countValue: 0,
          currentValue: 0,
          isActive: false,
        },
      },
      {
        display: {
          value: 5,
          isBankNote: false,
        },
        displayCount: {
          countValue: 0,
          currentValue: 0,
          isActive: false,
        },
      },
      {
        display: {
          value: 1,
          isBankNote: false,
        },
        displayCount: {
          countValue: 0,
          currentValue: 0,
          isActive: false,
        },
      },
    ]);
  }, [isPOSClosure, posClosureSummary])
  
  useEffect(() => {
    setRowValues([...initRowValues]);
  }, [currentMenu])

  return (
    <div className="w-full">
      <div className="flex justify-between mt-[min(0.98vw,10px)]">
        <div className="w-[min(26.86vw,275px)]">
          <div className="font-[500] leading-[2] text-20">{info.cashierName}</div>
          <div className="bg-[#EDEDED] w-full h-[min(0.10vw,1px)]"></div>
          <div className="mt-[min(1.76vw,18px)] flex flex-col gap-[min(0.78vw,8px)] text-18">
            <div className="flex justify-between gap-[min(0.98vw,10px)] text-18">
              <div className="whitespace-nowrap text-[#474747]">
                ชื่อพนักงาน :
              </div>
              <div className="truncate">{info.staffName}</div>
            </div>
            <div className="flex justify-between gap-[min(0.98vw,10px)]  text-18">
              <div className="whitespace-nowrap text-[#474747]">
                เวลาเริ่มขาย :
              </div>
              <div className="truncate">{info.startTime ?? "-"}</div>
            </div>
            <div className="flex justify-between gap-[min(0.98vw,10px)]  text-18">
              <div className="whitespace-nowrap text-[#474747]">
                เวลาสิ้นสุด :
              </div>
              <div className="truncate">{info.endTime ?? "-"}</div>
            </div>
            <div className="flex justify-between gap-[min(0.98vw,10px)]  text-18">
              <div className="whitespace-nowrap text-[#474747]">
                ระยะเวลาขาย :
              </div>
              <div className="truncate">{info.duration ?? "-"}</div>
            </div>
            {!isCloseBalance && (
              <div className="flex items-center justify-between gap-[min(0.98vw,10px)]">
                <div className="whitespace-nowrap text-[#474747]">
                  {info.eventTxt ?? "เงินสดตั้งต้น"} :
                </div>
                <div className="w-[min(9.77vw,100px)]">
                  <CustomInput
                    inputprops={{
                      type: "text",
                      readOnly: 'readOnly',
                      className: "modal-input no-spinner text-18 disabled-input",
                      value: numberWithTwoDecimals(startValueObj.startValue),
                      min: 0,
                      onChange: (e) => {
                        const inputValue = parseFloat(e.currentTarget.value);
                        const roundedValue = Math.round(inputValue * 100) / 100;
                        startValueObj.setStartValue(roundedValue);
                      },
                      onClick: (e) => {
                        const input = e.target;
                        input.select();
                      },
                    }}
                  />
                </div>
              </div>
            )}

            {isCloseBalance && (
              <>
                <div className="flex justify-between gap-[min(0.98vw,10px)]  text-18">
                  <div className="whitespace-nowrap text-[#474747]">
                    {info.eventTxt ?? "เงินสดตั้งต้น"} :
                  </div>
                  <div className="text-[var(--color-green)] truncate">
                    {info.startValue
                      ? numberWithTwoDecimals(info.startValue)
                      : "-"}
                  </div>
                </div>
                <div className="flex justify-between gap-[min(0.98vw,10px)]  text-18">
                  <div className="whitespace-nowrap text-[#474747]">
                    นำเงินเข้ารวม :
                  </div>
                  <div className="text-[var(--color-green)] truncate">
                    {info.totalDeposit
                      ? numberWithTwoDecimals(info.totalDeposit)
                      : "-"}
                  </div>
                </div>
                <div className="flex justify-between gap-[min(0.98vw,10px)]  text-18">
                  <div className="whitespace-nowrap text-[#474747]">
                    นำเงินออกรวม :
                  </div>
                  <div className="text-[var(--color-red)] truncate">
                    {info.totalWithDraw
                      ? numberWithTwoDecimals(info.totalWithDraw * -1)
                      : "-"}
                  </div>
                </div>
              </>
            )}

            <div className="flex justify-between gap-[min(0.98vw,10px)]  text-18">
              <div className="whitespace-nowrap text-[#474747]">ยอดขาย :</div>
              <div
                className={`${
                  info.grandTotal ? "text-[var(--color-green)]" : ""
                } truncate`}
              >
                {info.grandTotal ? numberWithTwoDecimals(info.grandTotal) : "-"}
              </div>
            </div>

           { isCloseBalance && 
              <div className="flex justify-between gap-[min(0.98vw,10px)]  text-18">
                <div className="whitespace-nowrap text-[#474747]">รายได้ค่าธรรมเนียม :</div>
                <div
                  className={`${
                    info.grandTotal ? "text-[var(--color-green)]" : ""
                  } truncate`}
                >
                  {info.grandTotal ? numberWithTwoDecimals(info.totalFee) : "-"}
                </div>
              </div>
            }

            { isCloseBalance &&
              <div className="flex justify-between gap-[min(0.98vw,10px)]  text-18">
                <div className="whitespace-nowrap text-[#474747]">รวม :</div>
                <div
                  className={`${
                    info.grandTotal ? "text-[var(--color-green)]" : ""
                  } truncate`}
                >
                  {info.grandTotal ? numberWithTwoDecimals(info.grandTotal + info.totalFee) : "-"}
                </div>
              </div>
            }

            {!isCloseBalance && (
              <div className="flex justify-between gap-[min(0.98vw,10px)]  text-18">
                <div className="whitespace-nowrap text-[#474747]">
                  เงินสดปิดยอด :
                </div>
                <div className="truncate">-</div>
              </div>
            )}
          </div>
        </div>
        <div className="w-[min(22.07vw,226px)]">
          <div className="leading-[2] text-20">ตัวช่วยนับเงิน</div>
          <div className="bg-[#EDEDED] w-full h-[min(0.10vw,1px)]"></div>
          <div className="flex flex-col gap-[min(0.91vw,9.28px)] mt-[min(1.76vw,18px)]">
            {rowValues.map((value, index) => {
              return (
                <div key={index} className="flex justify-between">
                  <MoneyDisplay
                    value={value.display.value}
                    isBankNote={value.display.isBankNote}
                  />
                  <MoneyDisplayCount
                    index={index}
                    currentValue={value.displayCount.currentValue}
                    countValue={
                      value.displayCount.currentValue * value.display.value
                    }
                    isActive={
                      (value.displayCount.currentValue * value.display.value) > 0
                    }
                    isCloseBalance={isCloseBalance}
                    handleInputChange={handleInputChange}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex justify-between mt-[min(1.46vw,15px)]">
            <div className="text-18 text-[var(--color-grey-2)]">Total</div>
            <div className="w-[min(9.77vw,100px)] bg-[#F4F4F4] truncate flex justify-center items-center rounded-[min(0.20vw,2px)]">
              {numberWithTwoDecimals(sumValue)}
            </div>
            {!isCloseBalance && (
              <CustomBtn
                onClick={() => 
                  startValueObj.setStartValue(parseFloat(sumValue).toFixed(2))
                }
                className={
                  "btn-main-active min-w-[min(6.74vw,69px)] text-white leading-[0.8] font-[400] text-16 pt-[min(1.27vw,13px)] pb-[min(0.78vw,8px)] rounded-[min(0.20vw,2px)]"
                }
                btntxt={info.calTxt}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
