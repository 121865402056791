import React from "react";

export default function TableOnModeActive({info}) {
  return (
    <div className="flex pl-[min(2.44vw,25px)] justify-between items-center text-16">
      <div className="flex justify-center items-center max-h-[34px] gap-[min(1.28vw,13.07px)] min-w-[min(28.22vw,289px)] rounded-[min(0.78vw,8px)] bg-[--color-light-orange] p-[min(0.98vw,10px)]">
        <img
          className="object-contain aspect-[3.87/15.08] w-[min(0.38vw,3.87px)] h-fit"
          src="/img/icons/icon-warning-2-orange.svg"
          alt=""
        />
        <div className="text-18 text-[var(--color-orange)]">
          {info.headText}
        </div>
      </div>
      <div className="flex items-center gap-[min(0.98vw,10px)]">
        <div className="flex items-baseline gap-[min(0.49vw,5px)]">
          <div className="aspect-square h-fit w-[min(1.76vw,18px)] bg-[#F4F4F4] border-[#b6bbc6] border-[min(0.10vw,1px)] rounded-full"></div>
          <div className="font-[300] text-[var(--color-grey-2)]">
            {info.disabledText}
          </div>
        </div>
        <div className="flex items-baseline gap-[min(0.49vw,5px)]">
          <div className="aspect-square h-fit w-[min(1.76vw,18px)] bg-[var(--color-green)] rounded-full"></div>
          <div className="font-[300] text-[var(--color-grey-2)]">{info.activeText}</div>
        </div>
      </div>
    </div>
  );
}
