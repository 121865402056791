import { atom, useSetRecoilState } from 'recoil';
import { getAllProduct, getAllProductCategory, getAllBranchProduct } from '../services/product';

// State
export const productState = atom({
    key: 'productState',
    default: {
        products: [],
        productCategory: []
    },
});

// Action
export const useFetchAllProduct = () => {
    const setState = useSetRecoilState(productState);

    return async () => {
        try {
            const fetch = await getAllProduct();
            setState(state => ({ ...state, products: fetch.products }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
};

export const useFetchAllProductCategory = () => {
    const setState = useSetRecoilState(productState);

    return async (businessId, branchId) => {
        try {
            const fetch = await getAllProductCategory(businessId, branchId);
            const defaultCategory = {
                id: 0,
                name: "ทั้งหมด",
                subCategory: [
                    {
                        id: 0,
                        name: "ทั้งหมด"
                    }
                ]
            };
            const categories = [defaultCategory, ...fetch.productCategory]
            setState(state => ({ ...state, productCategory: categories }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
};

export const useFetchAllBranchProduct = () => {
    const setState = useSetRecoilState(productState);

    return async (branchId) => {
        try {
            const fetch = await getAllBranchProduct(branchId);
            // const sort = fetch.products.sort((a, b) => {
            //     if (a.productName < b.productName) {
            //         return -1;
            //     }
            //     if (a.productName > b.productName) {
            //         return 1;
            //     }
            //     return 0;
            // });
            setState(state => ({ ...state, products: fetch.products }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
};