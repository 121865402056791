import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomBtn from "components/fragments/CustomBtn";
import { useNavigate } from "react-router-dom";
import { useUpdateOrder } from "state/order";

export default function ModalHoldBillConfirm({
  modalSuccessOpenObj,
  modalOpenObj,
  onConfirm,
  order,
  setOrders,
  setSelectedTable,
  selectedTable,
  currentPOSActivity,
  currentPOS,
  isLoading = false,
  ...props
}) {
  const navigate = useNavigate();
  const updateOrder = useUpdateOrder();

  // const onConfirmBtnHandler = async () => {
  //   const updateStatus = {
  //     status_id: 4
  //   }
  //   const selectedTableActivity = selectedTable?.activity?.id ? selectedTable.activity.id : selectedTable?.tableActivity?.id ? selectedTable.tableActivity.id : null;
  //   await updateOrder(order, updateStatus, selectedTableActivity, currentPOSActivity.operation_date, currentPOS.id);
  //   modalOpenObj.setModalOpen(false);
  //   setOrders({
  //     orderNo: null,
  //     orderDate: null,
  //     tableID: "",
  //     zone: "",
  //     delivery: 1,
  //     product: [],
  //     orderPrice: {
  //       sumprice: 0.0,
  //       discount: 0.0,
  //       vat: 0.0,
  //       net: 0.0,
  //     },
  //   });
  //   setSelectedTable(null)
  //   // modalSuccessOpenObj.setModalOpen(true);
  //   // navigate("/");
  // }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => modalOpenObj.setModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          <div className="bg-white focus-visible-none w-[min(37.11vw,380px)] p-[min(2.44vw,25px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-16 text-[var(--color-black-2)]">
            <div className="mt-3 mb-4 text-22 text-center font-[700] leading-[2.2]">
              คุณต้องการที่จะพักบิลนี้ใช่หรือไม่
            </div>

            <div className=" mb-3 flex justify-center gap-[min(2.01vw,20.63px)]">
              <CustomBtn
                onClick={() => {
                  modalOpenObj.setModalOpen(false);
                }}
                className={` leading-[0.8] w-fit min-w-[min(16.25vw,148px)] text-18 text-[#6D788D] font-[600] min-h-[min(5.51vw,57px)] rounded-[min(0.78vw,8px)] btn-second-2-active shadow-none`}
                btntxt={"ยกเลิก"}
              />
              <CustomBtn
                onClick={onConfirm}
                disabled={isLoading && !modalSuccessOpenObj.modalSuccessOpen}
                className={` leading-[0.8] w-fit min-w-[min(16.25vw,148px)] text-18 text-white font-[600] min-h-[min(5.51vw,57px)] rounded-[min(0.78vw,8px)] btn-main-active shadow-none`}
                btntxt={"ยืนยัน"}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
