import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  gridClasses,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import { useEffect } from "react";
import { useState } from "react";
import { Pagination, PaginationItem } from "@mui/material";

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.columnHeaders}`]: {
    backgroundColor: "var(--color-blue-2)",
  },
  [`& .${gridClasses.columnSeparator}`]: {
    display: "none",
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    fontSize: "min(1.95vw,20px)",
    color: "#FFF",
  },
  [`& .${gridClasses.row}`]: {
    fontSize: "min(1.95vw,20px)",
    fontWeight: 500,
    minHeight: "min(8.01vw,82px) !important",
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: "#F3F7FC",
  },
}));

export default function KitchenTable({ tabIndex, orderHistories }) {
  // console.log("🚀 ~ KitchenTable ~ orderHistories:", orderHistories)
  const { width, height } = useWindowDimensions();
  const columns = [
    {
      field: "tableNo",
      headerName: "โต๊ะ/บิล",
      headerAlign: "center",
      align: "center",
      maxWidth: (width * 10) / 100,
      flex: 1,
      renderCell: ({ row }) => {
        if (row.delivery == 1) {
          return <div className="text-[var(--color-blue-2)]">{row.tableNo}</div>
        } else if (row.delivery == 2) {
          return <div className="text-[var(--color-blue-2)]">กลับบ้าน{row.remark && <br/>}{row.remark && `(${row.remark})`}</div>
        } else if (row.delivery_remark_channel || row.delivery_remark) {
          return <div className="text-[var(--color-blue-2)]">{row.delivery_remark_channel}<br/>({row.delivery_remark})</div>
        } else {
          return <div className="text-[var(--color-blue-2)]">-</div>
        }
      },
      sortable: false,
    },
    {
      field: "unit",
      headerName: "จำนวน",
      headerAlign: "center",
      align: "center",
      maxWidth: (width * 12) / 100,
      flex: 1,
      renderCell: ({ row }) => {
        return <div className="text-[var(--color-blue-2)]">{row.unit} x</div>;
      },
      sortable: false,
    },
    {
      field: "name",
      headerName: "รายการ",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div className="text-[var(--color-blue-2)] truncate">
            {row.name}
          </div>
        );
      },
      sortable: false,
    },
    {
      field: "รายละเอียด",
      headerName: "รายละเอียด",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div className="flex flex-wrap gap-[min(1.46vw,15px)]">
            {row.details &&
              row.details.addOnList &&
              row.details.addOnList.map((v, i) => {
                return (
                  <div
                    className="text-18 bg-[var(--color-light-red)] text-[var(--color-red)] px-[min(1.27vw,13px)] h-[min(3.13vw,32px)] flex items-center"
                    key={i}
                  >
                    {v.name} {v.amount > 1 && `x${v.amount}`}
                  </div>
                );
              })}
            {row.details &&
              row.details.ignoreList &&
              row.details.ignoreList.map((v, i) => {
                return (
                  <div
                    className="text-18 bg-[var(--color-light-orange)] text-[var(--color-orange)] px-[min(1.27vw,13px)] h-[min(3.13vw,32px)] flex items-center"
                    key={i}
                  >
                    {v.name}
                  </div>
                );
              })}
            { row.note && row.note.replace(" ") != "" &&
              <div
                className="text-18 bg-[var(--color-light-orange)] text-[var(--color-orange)] px-[min(1.27vw,13px)] h-[min(3.13vw,32px)] flex items-center"
              >
                {row.note}
              </div>
            }
          </div>
        );
      },
      sortable: false,
    },
    {
      field: "time",
      headerName: tabIndex == 0 ? "เวลาเสริฟ" : "เวลาที่ยกเลิก",
      headerAlign: "center",
      align: "center",
      flex: 1,
      maxWidth: (width * 22) / 100,
      renderCell: ({ row }) => {
        return (
          <div className="flex gap-[min(1.15vw,11.82px)]">
            <img
              className="object-contain aspect-square w-[min(3.61vw,37px)] h-fit"
              src={
                row.timeServ.isCancel
                  ? "/img/icons/icon-serv-red.svg"
                  : "/img/icons/icon-serv-green.svg"
              }
              alt=""
            />
            <div className="flex flex-col items-center">
              <div className="text-14 text-[#BDBDBD] font-[300]">
                {row.timeServ.orderNo} : {row.timeServ.pos}
              </div>
              <div className="text-20 text-[#4A4A4A] font-[300]">
                {row.timeServ.time}
              </div>
            </div>
          </div>
        );
      },
      sortable: false,
    },
  ];

  const [rawData, setRawData] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 5,
  });

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 5,
  });

  useEffect(() => {
    if(orderHistories && orderHistories.length > 0) {
      setRawData((old) => ({ ...old, isLoading: true }));
      setRawData((old) => ({
        ...old,
        isLoading: false,
        data: orderHistories,
        total: orderHistories.length,
      }));
    }
  }, [orderHistories]);

  useEffect(() => {
    // const fetchData = async () => {
    //   setRawData((old) => ({ ...old, isLoading: true }));
    //   const response = await fetch(`json/serv_list.json`);
    //   const json = await response.json();
    //   setRawData((old) => ({
    //     ...old,
    //     isLoading: false,
    //     data: json,
    //     total: json.length,
    //   }));
    // };
    // fetchData();
    if(orderHistories & orderHistories.length > 0) {
      setRawData((old) => ({ ...old, isLoading: true }));
      setRawData((old) => ({
        ...old,
        isLoading: false,
        data: orderHistories,
        total: orderHistories.length,
      }));
    }
  }, [rawData.page, rawData.pageSize]);

  useEffect(() => {
    if (rawData) {
      // console.log("🚀 ~ useEffect ~ rawData:", rawData)
      const filterData = rawData.data.filter((item) =>
        tabIndex === 0 ? !item.timeServ.isCancel : item.timeServ.isCancel
      );
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: filterData,
        total: filterData.length,
      }));
    }
  }, [tabIndex, rawData]);

  return (
    <div className="w-full h-[min(54.00vw,553px)]">
      <StripedDataGrid
        pagination
        pageSizeOptions={[5, 10, 25]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: pageState.pageSize,
            },
          },
        }}
        rows={pageState.data}
        rowCount={
          pageState.total
        }
        loading={pageState.isLoading}
        columns={columns}
        columnHeaderHeight={
          (width * 5.96) / 100 > 61 ? 61 : (width * 5.96) / 100
        }
        getRowHeight={() => "auto"}
        disableColumnMenu={true}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
            py: "min(0.98vw,10px)",
          },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "min(0.98vw,10px)",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "min(0.98vw,10px)",
          },
        }}
        slots={{
          pagination: CustomPagination,
        }}
      />
    </div>
  );
}
function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}
