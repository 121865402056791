import React, { useState } from "react";
import { numberNoDecimals, numberWithTwoDecimals } from "utils/numberFormat";
// import 'print.css';

export default function PosOpenStorePrintPage() {
  const [tableInit, setTableInit] = useState([
    { credit: 1000, unit: 1 },
    { credit: 500, unit: 1 },
    { credit: 100, unit: 4 },
    { credit: 50, unit: 2 },
    { credit: 20, unit: 5 },
    { credit: 10, unit: 10 },
    { credit: 5, unit: 20 },
    { credit: 2, unit: 50 },
    { credit: 1, unit: 100 },
  ]);
  const [prevSum, setPrevSum] = useState(2500);
  const sum = (array) => {
    return array.reduce((sum, item) => sum + item.credit * item.unit, 0);
  };
  return (
    <div className="bg-[#EFEFEF] pt-[4.64%] pb-[7.95%] min-h-[100vh]">
      <div className="flex justify-center text-[0.78vw] w-full">
        <div
          id={"section-general-print"}
          className="w-[29.49vw] text-[0.98vw] font-[300] bg-[#FFF] px-[1.70vw] py-[1.31vw] flex flex-col gap-[0.59vw] "
        >
          <div className="text-center">
            <div>โรงคั่วกาแฟพะเยา</div>
            <div>บริษัท อินเตอร์ คอฟฟี่เทรด จำกัด - สำนักงานใหญ่ (00000)</div>
            <div>TAX ID : 123456789101 (VAT Included)</div>
          </div>
          <div className="flex flex-col gap-[0.59vw]">
            <div className="flex justify-between gap-[0.98vw] pt-[1.66%]">
              <div className="whitespace-nowrap">วันที่ 28/04/2020 16:53</div>
              <div className="text-right">POS# : PO14554870740</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">POS NO : 01</div>
              <div className="text-right">พนักงาน : สุวิมน แสนสิริ</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="">RD#E085700002S2541</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="">เวลาเริ่มขาย</div>
              <div className="">14/12/2023 11:33 น.</div>
            </div>
            {/* line */}
            <div className="border-dashed border-t-[0.04vw] border-black h-0 my-[1.66%]"></div>
            {/* table */}
            <table>
              <thead>
                <tr>
                  <th className="font-[300] w-[33.33%] pb-[2.32%]">
                    เงินสดตั้งต้น
                  </th>
                  <th className="font-[300] text-right pr-[8.94%] pb-[2.32%]">
                    จำนวน
                  </th>
                  <th className="font-[300] w-[33.33%] text-right pb-[2.32%]">
                    รวม
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableInit.map((v, i) => {
                  return (
                    <tr key={`'tr-'${i}`}>
                      <td className="pl-[2.5%]">
                        - {numberNoDecimals(v.credit)}
                      </td>
                      <td className="text-right pr-[12%]">
                        {numberNoDecimals(v.unit)}
                      </td>
                      <td className="text-right">
                        {numberWithTwoDecimals(v.credit * v.unit)}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td
                    colSpan={3}
                    className="text-center pt-[2.98%] text-[1.17vw] text-p-12 font-[600]"
                  >
                    ยอดตั้งต้น
                    <span className="ml-[15.56%]">
                      {numberWithTwoDecimals(sum(tableInit))}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* line */}
            <div className="border-dashed border-t-[0.04vw] border-black h-0 my-[1.66%]"></div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">โน๊ต :</div>
            </div>
            <div className="text-[1.17vw] text-p-12 font-[600] mt-[3.31%] opacity-0">
              note here
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
