import React from "react";
import OTPInput from "react-otp-input";
import CustomInput from "./CustomInput";

export default function PersonIdInput({ personIdObj, inputprops }) {
  const pinCount = 13;
  const separator = [0, 4, 9];
  const handleChange = (value) => {
    const syntheticEvent = {
      target: {
        name: inputprops.name,
        value: value,
      },
    };
    personIdObj.setValue(syntheticEvent);
  };

  return (
    <OTPInput
      value={personIdObj.value}
      onChange={handleChange}
      numInputs={pinCount}
      inputStyle={null}
      //   renderSeparator={<div className="w-[min(1.95vw,20px)]"></div>}
      renderInput={(props, index) => {
        const { style, ...rest } = props;
        return (
          <CustomInput
            className={`w-[min(2.73vw,28px)] aspect-[28/38] ${
              separator.includes(index) ? "mr-[min(1.07vw,11px)]" : ""
            }`}
            inputprops={{
              ...rest,
              className:
                "rounded-[min(0.39vw,4px)] border-[min(0.10vw,1px)] border-[#E5E5E5] font-[400] text-18 text-center no-spinner",
              type: "number",
              error: inputprops.error,
              placeholder: "-",
            }}
          />
        );
      }}
    />
  );
}
