import axiosInstance from './axios';
import moment from "moment";

const endPoint = "/tables";

export const getAllTable = async (branchId) => {
    try {
        const queryParams = {
            branch_id: branchId
        };

        const response = await axiosInstance.get('/tables', { params: queryParams });

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        const table = [];
        if(response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
                table.push({
                    ...response.data[i],
                    activity: response.data[i].activity ? response.data[i].activity : {},
                    tableActivity: response.data[i].activity ? response.data[i].activity : {}
                });
            }
        }

        return {
            // table: response.data
            table: table
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getTable = async (id) => {
    try {
        const response = await axiosInstance.get(`/tables/${id}`);
        
        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            table: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const updateTable = async (table, updateDetail) => {
    try {
        const payload = {};
        if(updateDetail.isActive) {
            payload.isActive = true;
        }
        const response = await axiosInstance.patch(`/tables/${table.id}`,payload);
        
        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            table: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

// Table Activty for table management
export const createTableActivity = async (table, detail) => {
    // console.log("🚀 ~ createTableActivity ~ table:", table)
    try {
        var limitTime = null;
        if(detail.isLimitTime) {
            limitTime = moment();
            limitTime.add(Number(detail.limitHours), 'hours');
            limitTime.add(Number(detail.limitMinutes), 'minutes');
        }
        
        const payload = {
            tableId: table.id,
            mainTableId: table.id,
            previousTableId: table.id,
            statusId: detail.isActive ? 1 : 0,
            currentGuest: detail.guest,
            limitTime: limitTime
        };
        // console.log("🚀 ~ createTableActivity ~ payload:", payload)

        const response = await axiosInstance.post(`/table-activities/`, payload);
        
        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            tableActivity: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const updateTableActivity = async (tableActivity) => {
    try {
        // const payload = {
        //     tableId: table.id,
        //     mainTableId: table.id,
        //     previousTableId: table.id,
        //     statusId: detail.isActive ? 1 : 0,
        // };
        const response = await axiosInstance.patch(`/table-activities/${tableActivity.id}`, tableActivity);
        
        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            tableActivity: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const moveTableActivity = async (fromTable, toTable) => {
    try {
        const payload = {
            fromTable: fromTable.tableActivity,
            toTable: toTable.tableActivity
        };
        const response = await axiosInstance.patch(`/table-activities/move`, payload);
        
        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            tableActivity: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const mergeTableActivity = async (mainTable, mergeTables) => {
    try {
        const payload = {
            mainTable: mainTable.tableActivity,
            mergeTables: mergeTables.map(t => t.tableActivity)
        };
        const response = await axiosInstance.patch(`/table-activities/merge`, payload);
        
        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            tableActivity: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const createTableBooking= async (bookingDetail) => {
    try {
        // const payload = {
        //     mainTable: mainTable.tableActivity,
        //     mergeTables: mergeTables.map(t => t.tableActivity)
        // };
        const response = await axiosInstance.post(`/table-booking`, bookingDetail);
        
        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            tableBooking: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getAllTableZone = async (branchId) => {
    try {
        const queryParams = {
            branch_id: branchId,
        };

        const response = await axiosInstance.get('/table-zone', { params: queryParams });

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            tableZone: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}