import axiosInstance from './axios';

const endPoint = "/staff-login";

export const createStaffLogin = async (posSetting, logMachine) => {
    // console.log("🚀 ~ createStaffLogin ~ posSetting:", posSetting)
    try {
        let isoFormattedDate = null;
        if (posSetting.activityTypeId == 1) {
            // isoFormattedDate = posSetting.startTime.setMilliseconds(0).toISOString();
            let tempOperationDate = null;
            tempOperationDate = new Date(posSetting.startTime);
            tempOperationDate.setMilliseconds(500);
            // tempOperationDate.setSeconds(0, 0);            ;
            isoFormattedDate = tempOperationDate.toISOString();
            // console.log("🚀 ~ createStaffLogin ~ isoFormattedDate:", isoFormattedDate)
        } else if (posSetting.startTime) {
            isoFormattedDate = posSetting.startTime;
        } else {
            isoFormattedDate = new Date().toISOString();
        }

        const payload = {
            pos_id: posSetting.posId,
            employee_id: posSetting.employeeId,
            employee_branch_account_id: posSetting.employeeBranchAccountId,
            is_active: true,
            logout_note: "",
            login_at: isoFormattedDate,
            operation_date: isoFormattedDate,
            login_machine: logMachine
        };
        // console.log("🚀 ~ createStaffLogin ~ payload:", payload)

        const response = await axiosInstance.post(
            `${endPoint}`,
            payload,
        );

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            staffLogin: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const createStaffLogout = async (posSetting) => {
    try {
        let isoFormattedDate = new Date().toISOString();;
        // if(posSetting.activityTypeId == 1) {
        //     isoFormattedDate = posSetting.startTime.toISOString();
        // } else {
        //     isoFormattedDate = new Date().toISOString();
        // }

        const payload = {
            pos_id: posSetting.posId,
            employee_id: posSetting.employeeId,
            employee_branch_account_id: posSetting.employeeBranchAccountId,
            is_active: true,
            logout_note: "",
            // login_at: isoFormattedDate,
            operation_date: posSetting.operation_date ? posSetting.operation_date : isoFormattedDate
        };

        const response = await axiosInstance.post(
            `${endPoint}`,
            payload,
        );

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            staffLogin: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getStaffActive = async (posId, employeeId, operationDate) => {
    try {
        const queryParams = {
            pos_id: parseInt(posId),
            employee_id: parseInt(employeeId),
            operation_date: operationDate
        }
        // console.log("🚀 ~ getStaffActive ~ queryParams:", queryParams)
        const response = await axiosInstance.get(`${endPoint}/check-current-logged-staff`, { params: queryParams });
        // const response = await axiosInstance.get(`${endPoint}/check-active`, { params: queryParams });

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return response.data
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getAllStaffLog = async () => {
    try {
        const response = await axiosInstance.get(`${endPoint}`);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return response.data
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}