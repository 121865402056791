import { useRef } from "react";
import TableBtn from "components/fragments/TableBtn";
import TableCountDisplay from "components/fragments/TableCountDisplay";
import TableOpenBtn from "components/fragments/TableOpenBtn";
import TableZoneBtn from "components/fragments/TableZoneBtn";
import ModalPayment from "components/layouts/ModalPayment";
import ModalTableOpenConfirm from "components/layouts/ModalTableOpenConfirm";
import TableCard from "components/layouts/TableCard";
import React, { useEffect, useState } from "react";
import FormTableReserved from "components/layouts/FormTableReserved";
import TableOnModeActive from "components/layouts/TableOnModeActive";
import FormTableMerge from "components/layouts/FormTableMerge";
import ModalTableEventMode from "components/layouts/ModalTableEventMode";
import FormTableMove from "components/layouts/FormTableMove";
import { useNavigate } from "react-router-dom";
import ShowTableDetail from "components/layouts/ShowTableDetail";
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { tableState, useFetchAllTable, selectedTableState, useFetchAllTableZone } from "state/table";
import { useMoveTable, useMergeTable } from "state/table";
import { paymentState } from "state/payment";
import { authState } from "state/auth";
import { useFetchAllOrder } from "state/order";
import { orderState } from "state/order";
import { posState } from "state/pos";
import { billState, useCreateBill } from "state/bill";
import { BillPaperToPrint } from "components/fragments/BillPaper";
import { useReactToPrint } from 'react-to-print';
import { appState } from "state/app";

export default function TablePage() {
  const navigate = useNavigate();
  const { slug, slugBranch } = useRecoilValue(appState);
  const billRef = useRef();

  const handlePrintBill = useReactToPrint({
    content: () => billRef.current,
  });
  // for test
  const newReservedTables = Array.from({ length: 6 }, () => ({
    currentPerson: 0,
    maxPerson: 2,
    time: "00:00 AM",
    timeUp: "",
    remark: "เอกการช่างมา 6 มาเยอะมากๆๆๆๆ",
    type: "reserved",
    tableNo: "",
    mergeList: "",
    moveFrom: "",
  }));

  const newOvertimeTables = Array.from({ length: 6 }, () => ({
    currentPerson: 3,
    maxPerson: 2,
    time: "00:00 AM",
    timeUp: "",
    remark: "",
    type: "overtime",
    tableNo: "",
    mergeList: "",
    moveFrom: "",
  }));

  const newUsingTables = Array.from({ length: 6 }, () => ({
    currentPerson: 3,
    maxPerson: 2,
    time: "00:00 AM",
    timeUp: "",
    remark: "",
    type: "using",
    tableNo: "",
    mergeList: "",
    moveFrom: "",
  }));

  const newEmptyTables = Array.from({ length: 6 }, () => ({
    currentPerson: 0,
    maxPerson: 2,
    time: "00:00 AM",
    timeUp: "",
    remark: "",
    type: "empty",
    tableNo: "",
    mergeList: "",
    moveFrom: "",
  }));

  const updatedTableCardList = [
    ...newReservedTables,
    ...newOvertimeTables,
    ...newUsingTables,
    ...newEmptyTables,
  ];

  // const [tableZoneBtnList, setTableZoneBtnList] = useState([
  //   "VIP",
  //   "ในร้าน",
  //   "นอกร้าน",
  //   "สวนหน้าร้าน",
  // ]);
  const [tableZoneBtnList, setTableZoneBtnList] = useState([]);
  const [tableZoneSelected, setTableZoneSelected] = useState(0);
  const [tableZoneIdSelected, setTableZoneIdSelected] = useState(0);
  // const [tableCardList, setTableCardList] = useState(updatedTableCardList);
  const [tableCardList, setTableCardList] = useState([]);

  const [tableSeletected, setTableSeletected] = useState();
  const [modalTableOpenCfOpen, setModalTableOpenCfOpen] = useState(false);
  const [modalTableMergeCancelOpen, setModalTableMergeCancelOpen] = useState(false);
  const [modalTableMergeCfOpen, setModalTableMergeCfOpen] = useState(false);
  const [isReservedMode, setIsReservedMode] = useState(false);
  const [isMergeTableMode, setIsMergeTableMode] = useState(false);
  const [tableMergeSelectedList, setTableMergeSelectedList] = useState([]);
  const [isMoveMode, setIsMoveMode] = useState(false);
  const [tableMoveSelected, setTableMoveSelected] = useState();
  const [modalTableMoveCancelOpen, setModalTableMoveCancelOpen] = useState(false);
  const [modalTableMoveCfOpen, setModalTableMoveCfOpen] = useState(false);
  const [isDetailMode, setIsDetailMode] = useState(false);
  const [modalPaymentOpen, setModalPaymentOpen] = useState(false);
  const [modalPaymentSuccessOpen, setModalPaymentSuccessOpen] = useState(false);
  const [currentBillPayments, setCurrentBillPayments] = useState("")
  const [currentMember, setCurrentMember] = useState(null);

  const updateTableCardByIndex = (index) => {
    setTableCardList((prevArray) => {
      const newArray = [...prevArray];
      const tableNo = newArray[index] ? newArray[tableSeletected].tableNo : "";
      newArray[index] = {
        ...newArray[index],
        mergeList: tableNo,
        type: "using",
      };
      return newArray;
    });
  };

  const { tables, tableZone } = useRecoilValue(tableState);
  const [tableList, setTableList] = useState(tables)
  const { user, branch } = useRecoilValue(authState);
  const [selectedTable, setSelectedTable] = useRecoilState(selectedTableState);
  // console.log("🚀 ~ TablePage ~ selectedTable:", selectedTable)
  const fetchAllTableZone = useFetchAllTableZone();
  const fetchAllTable = useFetchAllTable();
  const moveTable = useMoveTable();
  const mergeTable = useMergeTable();
  const fetchAllOrder = useFetchAllOrder();
  const { orders, selectedOrder } = useRecoilValue(orderState);
  const { currentBill } = useRecoilValue(billState);
  const [ tableOrder, setTableOrder] = useState(null) // This page scope
  const [currentActiveTable, setCurrentActiveTable] = useState(0)
  const [currentGuest, setCurrentGuest] = useState(0)
  const { currentPOS, currentPOSActivity } = useRecoilValue(posState);
  const { paymentChannels, currentPayment } = useRecoilValue(paymentState);
  const createBill = useCreateBill();
  const setTableState = useSetRecoilState(tableState);

  const onToOrderBtnHandler = async () => {
    navigate(`/${slug}/${slugBranch}/`);
  }

  // ชำระเงิน เพื่อขึ้น Modal จ่ายเงิน
  const handlePayment = () => {
    // TODO: Recheck and mapping bill data check
    // if (orders.product.length > 0) {
    //   // Update order in case of Member binding
    //   updateOrder(orders, { ...orders, member: currentMember })
    //   // Create Bill
    //   if(!selectedOrder || Object.keys(selectedOrder).length !== 0) {
    //     createBill(selectedOrder, currentPOS.id, selectedTable);
    //   } else {
    //     createBill(orders, currentPOS.id, selectedTable);
    //   }
    //   // Open payment Modal
    //   setModalPaymentOpen(true);
    // } else {
    //   if (orders.table == "") {
    //     setAlerttable(true);
    //   }
    //   if (orders.product.length <= 0) {
    //     setAlertproduct(true);
    //   }
    // }
  };

  // const clearOrders = () => {
    // setOrders({
    //   id: null,
    //   orderId: null,
    //   orderNo: latestOrderNo,
    //   orderDate: null,
    //   tableID: "",
    //   zone: "",
    //   delivery: selectedOptionDelivery?.value != 1 ? selectedOptionDelivery.value : 1,
    //   product: [],
    //   orderPrice: {
    //     sumprice: 0.0,
    //     discount: 0.0,
    //     vat: 0.0,
    //     net: 0.0,
    //   },
    //   payment: [],
    //   member_id: null,
    //   member: null
    // });
    // // getOrderNo();
    // resetCurrentPayment();
    // setSelectedTable(null);
    // fetchLatestOrderNo(currentPOS.id)

    // if(modalPaymentSuccessOpen) {
    //   // Reset Order and table
    //   setOrderState(prev => ({ ...prev, selectedOrder: {}}))
    //   // setOrders({...defaultOrders});
    //   setSelectedTable(null);
    // }
  // };

  useEffect(() => {
    // setTableCardList(
    //   tableCardList.map((v, index) => {
    //     return {
    //       ...v,
    //       tableNo: `A${index + 1 < 10 ? "0" + (index + 1) : index + 1}`,
    //     };
    //   })
    // );
    // fetchAllTableZone(user.branchId)
    // fetchAllTable(user.branchId)
    // fetchAllOrder()
  }, []);

  useEffect(() => {
    if(user.branchId) {
      fetchAllTableZone(user.branchId)
      fetchAllTable(user.branchId)
    }
  }, [user]);

  useEffect(() => {
    if(currentPOS && currentPOS.id) {
      fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
    }
  }, [currentPOS]);

  useEffect(() => {
    setTableList(tables)
  }, [tables]);

  useEffect(() => {
    if (tableZone && tableZone.length > 0) {
      setTableZoneBtnList(tableZone)
    }
  }, [tableZone])

  useEffect(() => {
    if (tableZoneBtnList && tableZoneBtnList.length > 0) {
      setTableZoneIdSelected(tableZone[0].id)
    }
  }, [tableZoneBtnList])

  useEffect(() => {
    if(tables && tables.length > 0) {
      // let filtered = [...tables].filter(t => t.zone_id == tableZoneIdSelected);
      // setTableList(filtered)
      if (!isMoveMode && !isMergeTableMode) {
        setTableSeletected()
      }
    }
  }, [tableZoneIdSelected])

  useEffect(() => {
    setIsDetailMode(false);
    if (tableSeletected >= 0 && tableList?.length > 0) {
      // let filtered = [...tableList].filter(t => t.zone_id == tableZoneIdSelected);
      let filtered = [...tableList];
      setSelectedTable(state => ({ 
        ...state, 
        ...filtered[tableSeletected], 
        value: filtered[tableSeletected].id, 
        label: `${filtered[tableSeletected].no} (${filtered[tableSeletected].zone.alias})`
      }));
      const selected = [...filtered][tableSeletected];
      // const order = orders.find(o => o.tableID == selected.id && (o.status_id == 1 || o.status_id == 4));
      const order = orders.find(o => o.type_id == 1 && o.tableID == selected.id && (o.status_id == 1 || o.status_id == 4) && o.type_id == 1);
      // console.log("🚀 ~ useEffect ~ orders:", order)
      setTableOrder(order);
    }
    // if (tableZone && tableZone.length > 0) {
    //   setTableZoneIdSelected(tableZone[tableZoneSelected].id)
    // }
  }, [tableSeletected]);

  useEffect(() => {
    if (tableZone && tableZone.length > 0) {
      setTableZoneIdSelected(tableZone[tableZoneSelected].id)
    }
  }, [tableZoneSelected]);

  useEffect(() => {
    if (tableList && tableList.length > 0) {
      // console.log("🚀 ~ useEffect ~ tableList:", tableList)
      // let filtered = [...tables].filter(t => t.zone_id == tableZoneIdSelected);
      let filtered = [...tables];
      let activeTable = 0;
      let guest = 0;
      let parsed = filtered.map((t,i) => {
        // console.log('t: ', t)
        // TODO: Optimize these merge value from API side instead
        let merge = filtered.filter(f => f.activity.mainTableId == t.id);
        let childList = merge.filter(m => m.id !== t.id && m.activity.tableId != t.id);
        let isChild = t.activity.tableId != t.activity.mainTableId;
        let isMain = merge.length > 1;

        let mergeText = "";
        if(isMain) {
          mergeText = childList.reduce((a,b) => a + ", " + b.no, "");
          mergeText = mergeText.replace(mergeText[0], "");
        }
        if(isChild) {
          let main = filtered.filter(f => f.id == t.activity.mainTableId)[0];
          mergeText = main.no;
        }

        if(t.activity.statusId == 2 || t.activity.statusId == 3 || t.activity.statusId == 4) {
          activeTable += 1;
          guest += t.current_guest;
        }

        return {
          id: t.id,
          zoneId: t.zone_id,
          currentPerson: t.current_guest,
          maxPerson: t.volume ? t.volume : t.max_guest ? t.max_guest : null,
          time: t.activity.statusId == 2 ? new Date(t.activity.updatedAt).toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" }) : "00:00 AM",
          timeUp: t.isOverTime ? new Date(t.overTime).toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" }) : "",
          remark: "",
          // type: t.is_active ? "using" : "empty",
          type: t.isOverTime ? "overtime" : t.activity ? t.activity.statusId === 2 ? "using" : t.activity.statusId === 3 ? "reserved" : "empty" : "empty",
          tableNo: t.no,
          mergeList: t.activity.statusId === 2 ? mergeText : "",
          moveFrom: "",
          tableActivity: t.activity,
          remark: t.booking ? t.booking.guest_name : ""
        }
      });
      setTableCardList([...parsed]);
      setCurrentActiveTable(activeTable);
      setCurrentGuest(guest);
    }
  }, [tableList]);

  const onSeeOrderBtnHandler = async () => {
    setIsDetailMode(true);
  }

  const onCheckBillHandler = async () => {
    // console.log("tableOrder: ", tableOrder)
    // console.log("selectedTable: ", tableCardList[tableSeletected])
    await createBill(tableOrder, currentPOS.id, tableCardList[tableSeletected])
    setTableState(prevState => ({ ...prevState, isTableOrderCheckBill: true }))
    navigate(`/${slug}/${slugBranch}/`)
  }

  return (
    <div className="">
      {/* header */}
      <div className="flex items-center justify-between bg-[#FFF] pl-[min(2.34vw,24px)] py-[min(0.59vw,6px)] pr-[min(4.00vw,41px)]">
        <div className="flex">
          <TableCountDisplay
            info={{
              headTxt: "จำนวนโต๊ะใช้งาน",
              iconClassColor: "bg-[var(--color-green)]",
              iconPath: "/img/icons/icon-table-display.svg",
              countTxt: currentActiveTable, // TODO: Update with correct data from db
            }}
          />
          <TableCountDisplay
            info={{
              headTxt: "จำนวนลูกค้าในร้าน",
              iconClassColor: "bg-[#FF731D]",
              iconPath: "/img/icons/icon-customer-display.svg",
              countTxt: currentGuest, // TODO: Update with correct data from db
            }}
          />
        </div>
        <div className="flex items-center gap-[min(0.78vw,8px)] pr-[min(0.98vw,10px)]">
          <div className="text-10 whitespace-nowrap">โต๊ะที่เลือก</div>
          <div className="h-[min(4.88vw,50px)] min-w-[min(4.79vw,49px)] bg-[#2dc76d26] flex items-center justify-center rounded-[min(0.29vw,3px)]">
            <div className="text-20 font-[700] text-[var(--color-green)]">
              {tableSeletected !== undefined
                ? tableCardList[tableSeletected].tableNo
                : "-"}
            </div>
          </div>
        </div>
        <div className="flex gap-[min(0.98vw,10px)]">
          <TableBtn
            onClick={() => setIsMergeTableMode(true)}
            className="flex justify-center items-center btn-orange shadow-table-btn-2 text-14"
            content={<div className="">รวมโต๊ะ</div>}
            isActive={
              tableSeletected !== undefined &&
              tableCardList[tableSeletected].type !== "empty" &&
              !tableCardList[tableSeletected].mergeList &&
              !tableCardList[tableSeletected].moveFrom &&
              !isMoveMode
            }
          />
          <TableBtn
            onClick={() => setIsMoveMode(true)}
            className="flex justify-center items-center btn-orange shadow-table-btn-2 text-14"
            content={<div className="">ย้ายโต๊ะ</div>}
            isActive={
              tableSeletected !== undefined &&
              tableCardList[tableSeletected].type !== "empty" &&
              // !tableCardList[tableSeletected].mergeList &&
              // !tableCardList[tableSeletected].moveFrom &&
              !isMergeTableMode &&
              (
                tableCardList[tableSeletected]?.tableActivity?.tableId == tableCardList[tableSeletected]?.tableActivity?.mainTableId ||
                tableCardList[tableSeletected]?.tableActivity?.tableId == tableCardList[tableSeletected]?.tableActivity?.mainTableId
              )
            }
          />
          <TableBtn
            onClick={onToOrderBtnHandler}
            className="flex justify-center items-center btn-main-active shadow-table-btn-2 text-14"
            content={<div className="">สั่งอาหาร</div>}
            isActive={
              tableSeletected !== undefined &&
              tableCardList[tableSeletected].type !== "empty" &&
              !isMergeTableMode &&
              !isMoveMode &&
              (
                tableCardList[tableSeletected]?.tableActivity?.tableId == tableCardList[tableSeletected]?.tableActivity?.mainTableId ||
                tableCardList[tableSeletected]?.tableActivity?.tableId == tableCardList[tableSeletected]?.tableActivity?.mainTableId
              )
            }
          />
          <TableBtn
            onClick={() => onSeeOrderBtnHandler()}
            className="flex justify-center items-center btn-blue-2 shadow-table-btn-2 text-14"
            content={<div className="">ดูรายการ</div>}
            isActive={
              tableSeletected !== undefined &&
              tableCardList[tableSeletected].type !== "empty" &&
              !isMergeTableMode &&
              !isMoveMode &&
              (
                tableCardList[tableSeletected]?.tableActivity?.tableId == tableCardList[tableSeletected]?.tableActivity?.mainTableId ||
                tableCardList[tableSeletected]?.tableActivity?.tableId == tableCardList[tableSeletected]?.tableActivity?.mainTableId
              )
              
            }
          />
        </div>
      </div>
      {/* body */}
      <div className="pr-[min(4.00vw,41px)]">
        {isReservedMode && (
          <FormTableReserved
            isReservedModeObj={{
              isMode: isReservedMode,
              isSetMode: setIsReservedMode,
            }}
            tableId={selectedTable.id}
          />
        )}
        {isMergeTableMode && (
          <FormTableMerge
            tableSeletected={tableSeletected}
            tableMergeSelectedListObj={{
              tableMergeSelectedList: tableMergeSelectedList,
              setTableMergeSelectedList: setTableMergeSelectedList,
            }}
            tableCardList={tableCardList}
            onConfirm={() => setModalTableMergeCfOpen(true)}
            onCancel={() => setModalTableMergeCancelOpen(true)}
          />
        )}
        {isMoveMode && (
          <FormTableMove
            tableSeletected={tableSeletected}
            tableMoveSelectedObj={{
              selected: tableMoveSelected,
              setSelected: setTableSeletected,
            }}
            tableCardList={tableCardList}
            onConfirm={() => setModalTableMoveCfOpen(true)}
            onCancel={() => setModalTableMoveCancelOpen(true)}
          />
        )}
        {!isReservedMode && (
          <div className="flex pl-[min(5.76vw,59px)] pt-[min(1.86vw,19px)] pb-[min(1.56vw,16px)]">
            <div className="w-full flex justify-between pr-[min(2.15vw,22px)]">
              <div className="flex gap-[min(1.17vw,12px)]">
                {tableZoneBtnList.map((value, index) => {
                  return (
                    <TableZoneBtn
                      key={index}
                      onClick={() => setTableZoneSelected(index)}
                      btnTxt={value.alias}
                      isActive={index === tableZoneSelected}
                    />
                  );
                })}
              </div>
              {!isMergeTableMode && (
                <div className="flex gap-[min(1.37vw,14px)]">
                  <TableOpenBtn
                    onClick={() => setModalTableOpenCfOpen(true)}
                    className={"btn-main-active"}
                    btnTxt={"เปิดโต๊ะ"}
                    isActive={
                      tableSeletected !== undefined &&
                      ["reserved", "empty"].includes(
                        tableCardList[tableSeletected].type
                      )
                    }
                  />
                  <TableOpenBtn
                    onClick={() => setIsReservedMode(true)}
                    className={"btn-yellow"}
                    btnTxt={"จองโต๊ะ"}
                    isActive={
                      tableSeletected !== undefined &&
                      tableCardList[tableSeletected].type === "empty"
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <div className="flex pl-[min(2.44vw,25px)]">
          <div className="bg-[#FFF] rounded-[min(1.46vw,15px)] py-[min(2.93vw,30px)] pr-[min(4.39vw,45px)] w-full max-h-[68.75vh] overflow-auto">
            {!isReservedMode && !isMergeTableMode && !isMoveMode && (
              <div className="flex justify-end gap-[min(0.98vw,10px)] text-16">
                <div className="flex items-baseline gap-[min(0.49vw,5px)]">
                  <div className="aspect-square h-fit w-[min(1.76vw,18px)] bg-[#F4F4F4] border-[#b6bbc6] border-[min(0.10vw,1px)] rounded-full"></div>
                  <div className="font-[300] text-[var(--color-grey-2)]">
                    ว่าง
                  </div>
                </div>
                <div className="flex items-baseline gap-[min(0.49vw,5px)]">
                  <div className="aspect-square h-fit w-[min(1.76vw,18px)] bg-[var(--color-yellow)] rounded-full"></div>
                  <div className="font-[300] text-[var(--color-grey-2)]">
                    จอง
                  </div>
                </div>
                <div className="flex items-baseline gap-[min(0.49vw,5px)]">
                  <div className="aspect-square h-fit w-[min(1.76vw,18px)] bg-[var(--color-green)] rounded-full"></div>
                  <div className="font-[300] text-[var(--color-grey-2)]">
                    ไม่ว่าง
                  </div>
                </div>
                <div className="flex items-center gap-[min(0.49vw,5px)]">
                  <div className="aspect-square h-fit w-[min(1.76vw,18px)] bg-[var(--color-red)] rounded-full"></div>
                  <div className="font-[300] text-[var(--color-grey-2)]">
                    เกินเวลา
                  </div>
                </div>
              </div>
            )}
            {isReservedMode && (
              <TableOnModeActive
                info={{
                  headText: "กรุณาเลือกโต๊ะที่ต้องการจอง",
                  disabledText: "ไม่สามารถจองได้",
                  activeText: "จองได้",
                }}
              />
            )}
            {isMergeTableMode && (
              <TableOnModeActive
                info={{
                  headText: "กรุณาเลือกโต๊ะที่ต้องการรวม",
                  disabledText: "ไม่สามารถรวมได้",
                  activeText: "รวมได้",
                }}
              />
            )}
            {isMoveMode && (
              <TableOnModeActive
                info={{
                  headText: "กรุณาเลือกโต๊ะที่ต้องการย้าย",
                  disabledText: "ไม่สามารถย้ายได้",
                  activeText: "ย้ายได้",
                }}
              />
            )}
            <div className="grid grid-cols-6 xl:grid-cols-8 2xl:grid-cols-10 gap-[min(3.13vw,32px)] pl-[min(2.83vw,29px)] py-[min(1.86vw,19px)]">
              {tableCardList.map((value, index) => {
                if(value.zoneId == tableZoneIdSelected) {
                  return (
                    <TableCard
                      onClick={() => {
                        if (!isMergeTableMode && !isMoveMode) {
                          return setTableSeletected(index);
                        }
                        if (isMergeTableMode && index !== tableSeletected) {
                          const isSelected =
                            tableMergeSelectedList.includes(index);

                          return setTableMergeSelectedList((prevArray) =>
                            isSelected
                              ? prevArray.filter((item) => item !== index)
                              : [...prevArray, index]
                          );
                        }
                        if (isMoveMode && index !== tableSeletected) {
                          if (index === tableMoveSelected) {
                            return setTableMoveSelected(undefined);
                          }
                          return setTableMoveSelected(index);
                        }
                      }}
                      key={index}
                      info={{
                        currentPerson: value.currentPerson,
                        maxPerson: value.maxPerson,
                        time: value.time,
                        timeUp: value.timeUp,
                        remark: value.remark,
                        tableNo: value.tableNo,
                        mergeList: value.mergeList,
                        moveFrom: value.moveFrom,
                        remark: value.remark
                      }}
                      type={value.type}
                      isSelected={index === tableSeletected}
                      isReservedMode={isReservedMode}
                      isMergeTableMode={isMergeTableMode}
                      isMergeSelected={
                        index !== tableSeletected &&
                        tableMergeSelectedList.includes(index)
                      }
                      isMoveTableMode={isMoveMode}
                      isMoveSelected={
                        isMoveMode &&
                        index !== tableSeletected &&
                        index === tableMoveSelected
                      }
                    />
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
      {/* open */}
      {/* modal */}
      <ModalTableOpenConfirm
        modalOpenObj={{
          modalOpen: modalTableOpenCfOpen,
          setModalOpen: setModalTableOpenCfOpen,
        }}
        info={{
          tableNo: tableSeletected
            ? tableCardList[tableSeletected].tableNo
            : "",
          maxPerson: tableSeletected ? tableCardList[tableSeletected].maxPerson : 99,
          table: tableSeletected ? tableCardList[tableSeletected] : null
        }}
      // onSubmitHandler={}
      />
      {/* merge */}
      {/* cancel */}
      <ModalTableEventMode
        modalOpenObj={{
          modalOpen: modalTableMergeCancelOpen,
          setModalOpen: setModalTableMergeCancelOpen,
        }}
        info={{
          headText: "คุณต้องการยกเลิกการรวมโต๊ะ",
          selectTableNo: (tableSeletected || tableSeletected == 0)
            ? tableCardList[tableSeletected].tableNo
            : "",
          split: "กับ",
          targetList: tableMergeSelectedList
            .map((v) => tableCardList[v].tableNo)
            .join(", "),
        }}
        tableMergeSelectedListObj={{
          tableMergeSelectedList: tableMergeSelectedList,
          setTableMergeSelectedList: setTableMergeSelectedList,
        }}
        onConfirm={() => {
          setTableMergeSelectedList([]);
          setIsMergeTableMode(false);
          setModalTableMergeCancelOpen(false);
        }}
      />
      {/* confirm */}
      <ModalTableEventMode
        modalOpenObj={{
          modalOpen: modalTableMergeCfOpen,
          setModalOpen: setModalTableMergeCfOpen,
        }}
        info={{
          headText: "คุณต้องการยืนยันการรวมโต๊ะ",
          selectTableNo: (tableSeletected || tableSeletected == 0)
            ? tableCardList[tableSeletected].tableNo
            : "",
          split: "กับ",
          targetList: tableMergeSelectedList
            .map((v) => tableCardList[v].tableNo)
            .join(", "),
        }}
        onConfirm={() => {
          setTableCardList((prevArray) => {
            const newArray = [...prevArray];
            const tableList = tableMergeSelectedList
              .map((v) => tableCardList[v].tableNo)
              .join(", ");
            newArray[tableSeletected] = {
              ...newArray[tableSeletected],
              mergeList: tableList,
              type: "using",
            };
            return newArray;
          });
          tableMergeSelectedList.map((v) => {
            updateTableCardByIndex(v);
          });

          // const mergeSelectedList = tableCardList.filter((_, i) => i in tableMergeSelectedList && tableCardList[i]);
          const mergeSelectedList = tableCardList.filter((_, i) => tableMergeSelectedList.includes(i));
          mergeTable(tableCardList[tableSeletected], mergeSelectedList);

          setTableMergeSelectedList([]);
          setIsMergeTableMode(false);
          setModalTableMergeCfOpen(false);
        }}
      />
      {/* move */}
      {/* cancel */}
      <ModalTableEventMode
        modalOpenObj={{
          modalOpen: modalTableMoveCancelOpen,
          setModalOpen: setModalTableMoveCancelOpen,
        }}
        info={{
          headText: "คุณต้องการยกเลิกการย้ายโต๊ะ",
          selectTableNo: (tableSeletected || tableSeletected == 0)
            ? tableCardList[tableSeletected].tableNo
            : "",
          split: "ย้ายไปที่",
          targetList: (tableMoveSelected || tableMoveSelected == 0)
            ? tableCardList[tableMoveSelected].tableNo
            : "",
        }}
        onConfirm={() => {
          setTableMoveSelected(undefined);
          setIsMoveMode(false);
          setModalTableMoveCancelOpen(false);
        }}
      />
      {/* confirm */}
      <ModalTableEventMode
        modalOpenObj={{
          modalOpen: modalTableMoveCfOpen,
          setModalOpen: setModalTableMoveCfOpen,
        }}
        info={{
          headText: "คุณต้องการยืนยันการย้ายโต๊ะ",
          selectTableNo: (tableSeletected || tableSeletected == 0)
            ? tableCardList[tableSeletected].tableNo
            : "",
          split: "ย้ายไปที่",
          targetList: (tableMoveSelected || tableMoveSelected == 0)
            ? tableCardList[tableMoveSelected].tableNo
            : "",
        }}
        onConfirm={async () => {
          // setTableCardList((prevArray) => {
          //   const newArray = [...prevArray];
          //   newArray[tableMoveSelected] = {
          //     ...newArray[tableMoveSelected],
          //     moveFrom: newArray[tableSeletected].tableNo,
          //     type: "using",
          //   };
          //   newArray[tableSeletected] = {
          //     ...newArray[tableSeletected],
          //     moveFrom: "",
          //     type: "empty",
          //   };
          //   return newArray;
          // });
          await moveTable(tableCardList[tableSeletected], tableCardList[tableMoveSelected]);
          setTableMoveSelected(undefined);
          setIsMoveMode(false);
          setModalTableMoveCfOpen(false);
          await fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
        }}
      />

      {/* detail */}
      {/* {tableOrder && */}
        <ShowTableDetail
          tableSeletected={tableSeletected}
          tableCardList={tableCardList}
          isDetailModeObj={{
            isMode: isDetailMode,
            setIsMode: setIsDetailMode,
          }}
          tableOrder={tableOrder}
          selectedTable={selectedTable}
          onPrintBtn={handlePrintBill}
          onCheckBillBtn={onCheckBillHandler}
        />
      {/* } */}
      {/* // bill */}
      <div style={{ display: "none" }}>
        <BillPaperToPrint
          ref={billRef}
          order={tableOrder}
          user={user}
          bill={currentBill}
          pos={currentPOS}
          branch={branch}
          type="invoice"
        />
      </div>
      {/* // end bill */}

      {/* payment */}
      {/* <ModalPayment
        modalOpenObj={{
          modalOpen: modalPaymentOpen,
          setModalOpen: setModalPaymentOpen,
        }}
        modalSuccessOpenObj={{
          modalOpen: modalPaymentSuccessOpen,
          setModalOpen: setModalPaymentSuccessOpen,
        }}
        paymentChannels={paymentChannels}
        order={tableOrder}
        user={user}
        bill={currentBill}
        currentPos={currentPOS}
        currentBranch={branch}
        // onPrintBtn={onPrintBillPaperHandler}
        onPrintBtn={handlePrintBill}
        setCurrentPayment={(payments) => {
          // console.log("🚀 ~ handleEditProduct ~ payments:", payments);
          const paymentNames = payments.map(p => p.channel.paymentChannelName);
          const onePaymentName = paymentNames.every(pn => pn == paymentNames[0]);
          if(onePaymentName) {
            setCurrentBillPayments(paymentNames[0])
          } else {
            // console.log("🚀 ~ handleEditProduct ~ paymentNames:", paymentNames)
            setCurrentBillPayments("หลายช่องทาง")
          }
        }}
        member={currentMember}
      /> */}
      {/* // end payment */}
    </div>
  );
}
