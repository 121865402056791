import CustomBtn from "components/fragments/CustomBtn";
import React from "react";
import TableTile from "components/fragments/TableTile";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import useDrag from "hooks/useDrag";
import { LeftArrow, RightArrow } from "components/fragments/Arrow";
import "react-horizontal-scrolling-menu/dist/styles.css";

export default function FormTableMerge({
  tableSeletected,
  tableMergeSelectedListObj,
  tableCardList,
  onConfirm,
  onCancel,
}) {
  const { dragStart, dragStop, dragMove, dragging } = useDrag();

  const handleDrag =
    ({ scrollContainer }) =>
    (ev) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  return (
    <div className="flex pl-[min(2.44vw,25px)] pb-[min(0.98vw,10px)] rounded-[min(1.46vw,15px)]">
      <div className="bg-[#FFF] px-[min(3.13vw,32px)] py-[min(1.27vw,13px)] shadow-table-reserved-panel w-full flex justify-between items-center">
        <div>
          <div className="text-18 text-[--color-grey-2] font-[300]">
            โต๊ะปัจจุบัน (จะเป็นโต๊ะหลัก)
          </div>
          <div className="flex items-center mt-[min(0.68vw,7px)] gap-[min(1.07vw,11px)]">
            {tableSeletected !== undefined && (
              <TableTile
                info={{
                  index: tableSeletected,
                  tableNo: tableCardList[tableSeletected].tableNo,
                }}
              />
            )}

            <div className="mx-[min(1.27vw,13px)] text-14 text-[--color-grey-2] font-[700]">
              รวมกับ
            </div>
            {/* overflow-x-scroll flex flex-nowrap gap-[min(1.07vw,11px)] scroll-smooth */}
            <div
              className="w-[40vw] lg:w-[50vw] cursor-grabbing"
              onMouseLeave={dragStop}
            >
              <ScrollMenu
                onUpdate={(v) => {

                }}
                scrollContainerClassName={
                  "pt-[min(1.17vw,12px)] gap-[min(1.07vw,11px)] hide-scroll"
                }
                LeftArrow={<LeftArrow items={tableMergeSelectedListObj.tableMergeSelectedList}/>}
                RightArrow={<RightArrow items={tableMergeSelectedListObj.tableMergeSelectedList} />}
                onMouseDown={() => dragStart}
                onMouseUp={() => dragStop}
                onMouseMove={handleDrag}
              >
                {tableMergeSelectedListObj.tableMergeSelectedList.map(
                  (value, index) => {
                    return (
                      <TableTile
                        key={index}
                        info={{
                          tableNo: tableCardList[value].tableNo,
                        }}
                        isMergeMode={true}
                        isCanClose={true}
                        setTableMergeSelectedList={
                          tableMergeSelectedListObj.setTableMergeSelectedList
                        }
                        onClose={() =>
                          tableMergeSelectedListObj.setTableMergeSelectedList(
                            (prevArray) =>
                              prevArray.filter((item) => item !== value)
                          )
                        }
                      />
                    );
                  }
                )}
              </ScrollMenu>
            </div>
          </div>
        </div>

        <div className="flex justify-center gap-[min(0.98vw,10px)] mt-[min(2.44vw,25px)]">
          <CustomBtn
            onClick={onCancel}
            sx={{
              "&:hover": {
                boxShadow: "inherit",
              },
            }}
            className={`shadow-table-btn-2 leading-[0.8] w-fit min-w-[min(9.18vw,94px)] text-14 text-[#6D788D] font-[600] h-[min(3.32vw,34px)] rounded-[min(0.78vw,8px)] btn-second-2-active`}
            btntxt={"ยกเลิก"}
          />
          <CustomBtn
            onClick={onConfirm}
            sx={{
              "&:hover": {
                boxShadow: "inherit",
              },
            }}
            className={`shadow-table-btn-2 leading-[0.8] w-fit min-w-[min(9.18vw,94px)] text-14 text-white font-[600] h-[min(3.32vw,34px)] rounded-[min(0.78vw,8px)] ${
              tableMergeSelectedListObj &&
              tableMergeSelectedListObj.tableMergeSelectedList.length > 0
                ? "btn-main-active"
                : "disabled-btn"
            }`}
            btntxt={"บันทึก"}
          />
        </div>
      </div>
    </div>
  );
}
