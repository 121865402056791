import { Button } from "@mui/material";
import React from "react";

export default function OperatorBtn({ content, isActive, ...props }) {
  return (
    <Button
      {...props}
      sx={{
        "&:hover": {
          boxShadow: "inherit",
        },
        boxShadow: 0,
      }}
      className={`px-0 py-0 flex justify-center items-center rounded-full ${
        isActive ? "" : "btn-table-not-active"
      } ${props.className}`}
      variant="contained"
    >
      {content}
    </Button>
  );
}
