import axiosInstance from './axios';

const endPoint = "/pos-activities";

export const createPOSActivity = async (posSetting, operationDate) => {
    // console.log("🚀 ~ createPOSActivity ~ posSetting:", posSetting)
    try {
        // const operationDate = new Date();
        // operationDate: "2023-01-01T12:34:56"
        let isoFormattedDate = null;
        if (posSetting.activityTypeId == 1) {
            let tempOperationDate = null;
            if (operationDate) {
                tempOperationDate = new Date(operationDate);
            } else {
                tempOperationDate = new Date(posSetting.startTime);
            }
            tempOperationDate.setMilliseconds(500);
            // operationDate.setSeconds(0, 0);            ;
            isoFormattedDate = tempOperationDate.toISOString();
            // console.log("🚀 ~ createPOSActivity ~ isoFormattedDate:", isoFormattedDate)
        } else if (operationDate) {
            isoFormattedDate = operationDate;
        } else {
            isoFormattedDate = new Date().toISOString();
        }

        const payload = {
            posId: posSetting.posId,
            employeeBranchAccountId: posSetting.employeeBranchAccountId,
            activityTypeId: posSetting.activityTypeId ? posSetting.activityTypeId : 1,
            amount1000: posSetting.amount1000 ? posSetting.amount1000 : 0,
            amount500: posSetting.amount500 ? posSetting.amount500 : 0,
            amount100: posSetting.amount100 ? posSetting.amount100 : 0,
            amount50: posSetting.amount50 ? posSetting.amount50 : 0,
            amount20: posSetting.amount20 ? posSetting.amount20 : 0,
            amount10: posSetting.amount10 ? posSetting.amount10 : 0,
            amount5: posSetting.amount5 ? posSetting.amount5 : 0,
            amount1: posSetting.amount1 ? posSetting.amount1 : 0,
            cashTotal: posSetting.amountTotal ? posSetting.amountTotal : posSetting.cashTotal ? posSetting.cashTotal : 0,
            operationDate: isoFormattedDate,
        };
        // console.log("🚀 ~ createPOSActivity ~ payload:", payload)

        const response = await axiosInstance.post(
            `/pos-activities`,
            payload,
        );

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            posActivity: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getAllPOSActivity = async () => {
    try {
        const response = await axiosInstance.get('/pos-activities');

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            posActivity: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getPOSActivity = async (id) => {
    try {
        const response = await axiosInstance.get(`/pos-activities/${id}`);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            posActivity: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const updatePOSActivity = async (posSetting) => {
    try {
        const response = await axiosInstance.patch(`/pos-activities/${posSetting.posId}`, posSetting);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            posActivity: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getPOSClosureSummary = async (posId) => {
    try {
        const queryParams = {
            posId: posId
        };
        const response = await axiosInstance.get(`/summary/pos-closure`, { params: queryParams });

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            summary: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}