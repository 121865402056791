import React from "react";

export default function CustomInputSearch({ icon, inputprops, ...props }) {
  return (
    <div
      {...props}
      className={`custom-input sarabun text-18 ${props.className ?? ""}`}
    >
      <input
        {...inputprops}
        className={`w-full ${
          inputprops && inputprops.className ? inputprops.className : ""
        } ${inputprops && inputprops.error ? "error" : ""}`}
      />
      <div className="relative">
        <div className="w-[min(1.56vw,16px)]  aspect-square">
          <img
            className="absolute top-0 right-[min(2.44vw,25px)] w-full h-full"
            src="/img/icons/icon-search.svg"
          />
        </div>
      </div>
    </div>
  );
}
