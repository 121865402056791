import { Button } from "@mui/material";
import React from "react";

export default function MenuBtn({ content, ...props }) {
  return (
    <Button
      {...props}
    >
      {content}
    </Button>
  );
}
