import axiosInstance from './axios';

export const getAllEmployee = async (businessId) => {
    try {
        // const queryParams = {
        //     bus: branchId
        // }
        const response = await axiosInstance.get(`/employee/${businessId}`,);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            employees: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}