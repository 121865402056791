import CustomBtn from "components/fragments/CustomBtn";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import 'print.css';
// import { useUpdatePos } from "state/pos";
import { useRecoilValue } from "recoil";
import { posState, useGetPOSClosureSummary, useClearPOS } from "state/pos";
import { useAuthLogout, authState, useFetchBranch } from "state/auth";
import { localeStringDateTimeOptions, localeStringDateOptions } from "utils/dateFormat"
import { numberWithTwoDecimals } from "utils/numberFormat";
import { appState } from "state/app";

export default function CloseBalancePrint() {
  const navigate = useNavigate();
  const { slug, slugBranch } = useRecoilValue(appState);

  // const updatePOS = useUpdatePos();
  const logout = useAuthLogout();
  const getPOSClosureSummary = useGetPOSClosureSummary();
  const clearPOS = useClearPOS();
  const { currentPOS, currentPOSActivity, summary, closure, branch } = useRecoilValue(posState);
  const { user } = useRecoilValue(authState);
  const fetchBranch = useFetchBranch();

  useEffect(() => {
    if(user.branchId) {
      fetchBranch(user.branchId)
    }
  }, [user])

  useEffect(() => {
    if(currentPOS.id) {
      const fetchData = async () => {
        const fetch = await getPOSClosureSummary(currentPOS.id);
        window.print();
      }
      fetchData().catch(console.error);
    }
  }, [currentPOS])
  
  const onEndBtnHandler = async () => {
    // updatePOS(currentPOS);
    clearPOS();
    logout();
    navigate(`/${slug}/${slugBranch}/login`);
  };

  return (
    <div className="bg-[#EFEFEF] pt-[4.00vw] pb-[12.79vw]">
      <div className="flex justify-center text-[0.78vw] w-full">
        <div
          id={"section-to-print"}
          className="w-[21.48vw] bg-[#FFF] px-[1.70vw] py-[1.31vw] flex flex-col gap-[0.59vw] "
        >
          <div className="text-center">
            <div className="font-[600]">ใบสรุปยอดขายตามรอบการขาย</div>
            {/* <div>จูน เบเกอร์มาร์ท (CRI-001)</div> */}
            <div>{branch?.name ?? "-"}</div>
            {/* <div>บริษัท ค้าขายมั่งมี จำกัด (00002)</div> */}
            <div>{`${branch?.business?.businessdetail?.prefix} ${branch?.business?.businessdetail?.name} ${branch?.business?.businessdetail?.suffix} (${branch?.business?.businessdetail?.vat_code})`}</div>
            {/* <div>Tax ID : 0565562000208 (VAT Included)</div> */}
            <div>Tax ID : {branch?.business?.businessdetail?.taxpaper_id_number ?? "-"} {branch?.financial?.is_include_vat && "(VAT Included)"}</div>
          </div>
          <div className="flex flex-col gap-[0.59vw]">
            <div className="flex justify-between gap-[0.98vw] ">
              {/* <div className="whitespace-nowrap">วันที่ 28/04/2020 16:53</div> */}
              <div className="whitespace-nowrap">วันที่ {new Date(summary.posEndTime).toLocaleString('th-TH', localeStringDateTimeOptions)}</div>
              <div className="text-right">POS# : 2020103000001</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              {/* <div className="whitespace-nowrap">POS NO : POS1</div> */}
              <div className="whitespace-nowrap">POS NO : {currentPOS?.code ?? ""}</div>
              {/* <div className="text-right">พนักงาน : เบญจวรรณ บัวพิทักษ์</div> */}
              <div className="text-right">พนักงาน : {user ? `${user.firstNameTH} ${user.lastNameTH}` : ""}</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="">RD#: E085700002A2316</div>
            </div>
            <div className="flex justify-between w-full">
              <div className="whitespace-nowrap">รายงานวันที่</div>
              {/* <div className="text-left w-[70%]">28/04/2023</div> */}
              <div className="text-left w-[70%]">{new Date(summary.posEndTime).toLocaleString('th-TH', localeStringDateOptions)}</div>
            </div>
            {/* <div className="flex justify-between w-full">
              <div className="whitespace-nowrap">วันที่พิมพ์</div>
              <div className="text-left w-[70%]">12/11/2023 16:53</div>
            </div> */}
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">เลขที่ / เล่มที่</div>
              {/* <div className="text-right">000020832P2-000020894P2</div> */}
              <div className="text-right">{`${summary.startOrderId} - ${summary.endOrderId}`}</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">เวลาเริ่มขาย</div>
              {/* <div className="text-right">28/04/2020 16:53</div> */}
              <div className="text-right">{new Date(summary.posStartTime).toLocaleString('th-TH', localeStringDateTimeOptions)}</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">เวลาสิ้นสุด</div>
              {/* <div className="text-right">28/04/2020 16:53</div> */}
              <div className="text-right">{new Date(summary.posEndTime).toLocaleString('th-TH', localeStringDateTimeOptions)}</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">ระยะเวลาขาย</div>
              {/* <div className="text-right">07:12:12</div> */}
              <div className="text-right">{summary?.posActiveRange ?? "-"}</div>
            </div>
            {/* line */}
            <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">จำนวนบิลขาย</div>
              {/* <div className="text-right">0</div> */}
              <div className="text-right">{summary?.totalBills ?? 0}</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">จำนวนบิลที่ยกเลิก</div>
              {/* <div className="text-right">0</div> */}
              <div className="text-right">{summary?.totalVoidBills ?? 0}</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">จำนวนบิล Refund</div>
              <div className="text-right">{summary?.totalRefundBills ?? 0}</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">จำนวนบิล ออกใบกำกับภาษีเต็มรูปแบบ</div>
              <div className="text-right">0</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">
                มูลค่า ออกใบกำกับภาษี เต็มรูปแบบ
              </div>
              <div className="text-right">0</div>
            </div>
            {/* line */}
            <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">เงินสดตั้งต้น</div>
              {/* <div className="text-right">0</div> */}
              <div className="text-right">{summary?.initialAmount ? numberWithTwoDecimals(summary.initialAmount) : "0.00"}</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">ยอดขาย</div>
              {/* <div className="text-right">23,253.00</div> */}
              <div className="text-right">{summary?.totalSales ? numberWithTwoDecimals(summary.totalSales) : "0.00"}</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">-Sale Refund</div>
              <div className="text-right">0.00</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">-Affer Refund</div>
              <div className="text-right">0.00</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">-Item Discount</div>
              <div className="text-right">0.00</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">-Sub Discount</div>
              {/* <div className="text-right">0.00</div> */}
              <div className="text-right">{summary?.totalDiscount ? numberWithTwoDecimals(summary.totalDiscount) : "0.00"}</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">-Point Discount</div>
              {/* <div className="text-right">0.00</div> */}
              <div className="text-right">{summary?.totalPointDiscount ? numberWithTwoDecimals(summary.totalPointDiscount) : "0.00"}</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">-Coupon Discount</div>
              <div className="text-right">0.00</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">ค่าธรรมเนียม</div>
              <div className="text-right">{summary?.totalFee ? numberWithTwoDecimals(summary.totalFee) : "0.00"}</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">มูลค่าสินค้าไม่มีภาษี</div>
              {/* <div className="text-right">0.00</div> */}
              <div className="text-right">{summary?.totalNonVATPrice ? numberWithTwoDecimals(summary.totalNonVATPrice) : "0.00"}</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">มูลค่าสินค้ามีภาษี</div>
              {/* <div className="text-right">0.00</div> */}
              <div className="text-right">{summary?.totalVATPrice ? numberWithTwoDecimals(summary.totalVATPrice) : "0.00"}</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">VAT</div>
              {/* <div className="text-right">1,448.41</div> */}
              <div className="text-right">{summary?.totalVAT ? numberWithTwoDecimals(summary.totalVAT) : "0.00"}</div>
            </div>
            <div className="flex justify-center">
              <div className="flex justify-between w-[70%] gap-[0.98vw] ">
                <div className="whitespace-nowrap">Net Amount</div>
                {/* <div className="text-right font-[600]">23,046.00</div> */}
                <div className="text-right font-[600]">
                  {
                    summary?.totalSales ?
                      summary?.totalFee && summary.totalFee > 0 ?
                      numberWithTwoDecimals(summary.totalSales + summary.totalFee - summary.totalDiscount - summary.totalPointDiscount) :
                    summary?.totalSales ? numberWithTwoDecimals(summary.totalSales - summary.totalDiscount - summary.totalPointDiscount) :
                    "0.00" : "0.00"
                  }
                </div>
              </div>
            </div>
            
            {/* line */}
            <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">ช่องทางการชำระ</div>
            </div>
            
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">โอนเงิน</div>
              {/* <div className="text-right">6,448.41</div> */}
              <div className="text-right">{summary?.totalTransfer ? numberWithTwoDecimals(summary.totalTransfer) : "0.00"}</div>
            </div>

            {summary.summaryPaymentChannels.filter(p => p.name == "Transfer").map(p => (
              <div className="flex justify-center">
                <div className="flex justify-between w-[70%] gap-[0.98vw] ">
                  {/* <div className="whitespace-nowrap">- ธนาคารกสิกรไทย</div> */}
                  <div className="whitespace-nowrap">{`- ${p.bank_title}`}</div>
                  {/* <div className="text-right font-[600]">6,846.00</div> */}
                  <div className="text-right font-[600]">{numberWithTwoDecimals(p.total)}</div>
                </div>
              </div>
            ))}

            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">เงินสด</div>
              {/* <div className="text-right">16,448.41</div> */}
              {/* <div className="text-right">{(summary?.totalCash).toFixed(2) ?? 0}</div> */}
              <div className="text-right">{summary?.totalCash ? numberWithTwoDecimals(summary.totalCash) : "0.00"}</div>

            </div>

            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">บัตรเครดิต</div>
              {/* <div className="text-right">16,448.41</div> */}
              {/* <div className="text-right">{(summary?.totalCredit).toFixed(2) ?? 0}</div> */}
              <div className="text-right">{summary?.totalCredit ? numberWithTwoDecimals(summary.totalCredit) : "0.00"}</div>
            </div>

            {summary.summaryPaymentChannels.filter(p => p.name == "Debit" ||  p.name == "Credit").map(p => (
              <div className="flex justify-center">
                <div className="flex justify-between w-[70%] gap-[0.98vw] ">
                  <div className="whitespace-nowrap">{`- ${p.bank_title}`}</div>
                  <div className="text-right font-[600]">{numberWithTwoDecimals(p.total)}</div>
                </div>
              </div>
            ))}

            {/* <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">ค่าธรรมเนียม</div>
              <div className="text-right">{(summary?.totalFee).toFixed(2) ?? 0}</div>
            </div> */}

            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">เดลิเวอรี่</div>
              {/* <div className="text-right">6,448.41</div> */}
              {/* <div className="text-right">{(summary?.totalDelivery).toFixed(2) ?? 0}</div> */}
              <div className="text-right">{summary?.totalDelivery ? numberWithTwoDecimals(summary.totalDelivery) : "0.00"}</div>
            </div>

            {summary.summaryPaymentChannels.filter(p => p.name == "Delivery").map(p => (
              <div className="flex justify-center">
                <div className="flex justify-between w-[70%] gap-[0.98vw] ">
                  {/* <div className="whitespace-nowrap">- ธนาคารกสิกรไทย</div> */}
                  <div className="whitespace-nowrap">{`- ${p.delivery_name}`}</div>
                  {/* <div className="text-right font-[600]">6,846.00</div> */}
                  <div className="text-right font-[600]">{numberWithTwoDecimals(p.total)}</div>
                </div>
              </div>
            ))}

            {/* line */}
            <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>

            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">ยอดนับเงินสด</div>
              {/* <div className="text-right">0.00</div> */}
              <div className="text-right">{numberWithTwoDecimals(closure.cashCountAmount)}</div>
            </div>

            <div className="flex justify-center">
              <div className="flex justify-between w-[70%] gap-[0.98vw] ">
                <div className="whitespace-nowrap">Diff</div>
                {/* <div className="text-right font-[600]">0.00</div> */}
                <div className="text-right font-[600]">{numberWithTwoDecimals(closure.diff)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full h-auto fixed bottom-0 gap-[min(2.56vw,26.18px)] bg-[#EFEFEF] py-[min(3.42vw,35px)]">
        <CustomBtn
          onClick={onEndBtnHandler}
          className={`leading-[0.8] w-[min(16.89vw,173px)] text-white font-[400] text-16 min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-error-active`}
          btntxt={"สิ้นสุด"}
        />
        <CustomBtn
          onClick={() => window.print()}
          className={`leading-[0.8] w-[min(16.89vw,173px)] text-white font-[400] text-16 min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-main-active capitalize`}
          btntxt={"Print"}
        />
      </div>
    </div>
  );
}
