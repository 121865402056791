import moment from "moment";
import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";

export default function CustomDateRange({ inputprops, dateObj, handleDateRangeSelect, selectedDate }) {
  const currentDate = moment();

  // // Subtract 1 month from the current date
  // const [dates, setDates] = useState({
  //   startDate: currentDate.clone().subtract(1, "months"),
  //   endDate: currentDate,
  // });

  return (
    <div className="relative custom-input bg-[#FFF]">
      <span className="absolute px-[min(0.29vw,3px)] top-[calc(min(0.59vw,6px)*-1)] left-[min(0.88vw,9px)] text-9 bg-[#F8F8F8] text-[rgba(76,78,100,0.68)]">
        เลือกช่วงวันที่
      </span>
      <DateRangePicker
        initialSettings={{
          locale: {
            format: 'DD/MM/YYYY',
          },
          startDate: selectedDate.startDate,
          endDate: selectedDate.endDate,
          maxDate: moment(),
        }}
        onCallback={handleDateRangeSelect}
      >
        <input
          {...inputprops}
          readOnly
          className={`w-full ${
            inputprops && inputprops.className ? inputprops.className : ""
          } ${inputprops && inputprops.error ? "error" : ""}`}
        />
      </DateRangePicker>
    </div>
  );
}
