import { atom, selector, useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { getBranchSummaryStat } from '../services/summary';

// State
export const summaryState = atom({
    key: 'summaryState',
    default: {
        summary: null
    },
});

// Action
export const useFetchBranchSummary = () => {
    const setState = useSetRecoilState(summaryState);

    return async ({ branchId, startDate, endDate }) => {
        try {
            const params = {
                branchId: branchId,
                startDate: startDate,
                endDate: endDate,
            };
            const fetch = await getBranchSummaryStat(params);
            setState(state => ({ ...state, summary: fetch.summary }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
};