import React from "react";

export default function CustomInputSearchV2({ icon, inputprops, ...props }) {
  return (
    <div
      {...props}
      className={`custom-input relative sarabun text-18 ${props.className ?? ""}`}
    >
      <input
        {...inputprops}
        className={`w-full ${
          inputprops && inputprops.className ? inputprops.className : ""
        } ${inputprops && inputprops.error ? "error" : ""}`}
      />
      <div className="w-0 aspect-square">
        <img
          className="absolute top-0 right-[min(2.44vw,25px)] w-[min(1.56vw,16px)] h-full cursor-pointer"
          src="/img/icons/icon-search.svg"
        />
      </div>
    </div>
  );
}
