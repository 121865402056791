import axiosInstance from './axios';
import checkImage from 'utils/checkImage';

// const endPoint = "/products";

// const baseAPI = "http://localhost:3000/";
const baseAPI = "https://uat-api.allder.co/";

export const getAllProduct = async () => {
    const products = [];
    try {
        const response = await axiosInstance.get('/products');

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        if (response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
                const product = {
                    productID: response.data[i].id,
                    // productImage: "img/product/img2.png",
                    // productImage: "img/product/demo.png",
                    // productImage: "img/product/food-tray.png",
                    productImage: baseAPI + response.data[i].product.img_url,
                    productName: response.data[i].name,
                    productPrice: response.data[i].price,
                    optionstandard: [],
                    optionproduct: [],
                    optionnote: [],
                    branchProductId: response.data[i].branch_product ? response.data[i].branch_product.id : null,
                    branchId: response.data[i].branch_product ? response.data[i].branch_product.branch_id : null,
                    branchProduct: response.data[i].branch_product ? response.data[i].branch_product : null,
                    subCategoryId: response.data[i].sub_product_category_id ? response.data[i].sub_product_category_id : null
                }
                products.push(product);
            }
        }

        return {
            products: products
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getProduct = async (id) => {
    try {
        const response = await axiosInstance.get(`/products/${id}`);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            product: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getAllProductCategory = async (businessId, branchId = 1) => {
    try {
        const queryParams = {
            business_id: businessId,
            branch_id: branchId
        }
        const response = await axiosInstance.get('/product-category', { params: queryParams });

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        const filtered = response.data.filter(cat => cat.is_active)
            .map(cat => ({
                ...cat,
                subCategory: cat.subCategory.filter(subCat => subCat.is_active)
            }));

        return {
            productCategory: filtered
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getAllBranchProduct = async (branchId) => {
    const products = [];
    try {
        const queryParams = {
            branch_id: branchId
        }
        const response = await axiosInstance.get('/branch-products', { params: queryParams });

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        if (response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
                const responseData = response.data[i];
                if (!responseData.is_active) {
                    continue;
                }

                const optionStandard = [];
                const optionProduct = [];
                let optionNote = [];

                const productOptions = responseData.product_options;
                if (productOptions.length > 0) {
                    for (let j = 0; j < productOptions.length; j++) {
                        const productOption = productOptions[j];
                        const optionList = [];

                        if (productOption.branch_product_option_detail_binding && productOption.branch_product_option_detail_binding.length > 0) {
                            for (let k = 0; k < productOption.branch_product_option_detail_binding.length; k++) {
                                const binding = productOption.branch_product_option_detail_binding[k];
                                optionList.push({
                                    listID: binding.id,
                                    listName: binding.product_option_detail.name,
                                    listPrice: binding.product_option_detail.price,
                                    optionType: productOption.name,
                                    isMultiple: productOption.can_select_multi,
                                    optionDetailBindingId: binding.id
                                });
                            }
                        }

                        if (productOption.option_type.id === 1) {
                            optionStandard.push({
                                optionID: productOption.id,
                                optionName: productOption.name,
                                selecttype: productOption.can_select_multi ? 1 : 0,
                                optionList: optionList
                            });
                        }
                        else if (productOption.option_type.id === 2) {
                            optionProduct.push({
                                optionID: productOption.id,
                                optionName: productOption.name,
                                selecttype: productOption.can_select_multi ? 1 : 0,
                                optionList: optionList
                            });
                        }
                        else if (productOption.option_type.id === 3) {
                            optionNote = optionList;
                        }
                    }
                }

                // const exists = checkImage.checkURL(responseData.product.img_url);
                // const img_url = exists ? responseData.product.img_url : baseAPI + "cdn-cgi/image/fit=scale-down,width=150,quality=40/" + responseData.product.img_url;

                const product = {
                    id: responseData.id,
                    productID: responseData.id,
                    productImage: checkImage.checkURL(responseData.product.img_url),
                    productName: responseData.product.name,
                    productPrice: responseData.price,
                    optionstandard: optionStandard,
                    optionproduct: optionProduct,
                    optionnote: optionNote,
                    branchProductId: responseData.id,
                    branchId: responseData.branch.id,
                    subCategoryId: responseData.product.sub_product_category_id,
                    categoryId: responseData.product.sub_product_category.product_category_id
                };
                products.push(product);
            }
        }


        return {
            products: products
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}