import { atom, selector, useRecoilState, useSetRecoilState } from 'recoil';
import { getBranch } from '../services/branch';

// State
export const branchState = atom({
    key: 'branchState',
    default: {
        branch: {
            name: null
        },
    },
});

// Action
// export const useFetchAllBranch = () => {
//     const setState = useSetRecoilState(branchState);

//     return async () => {
//         try {
//             const fetch = await getAllBranch();
//             setState(({ branch: fetch.branch }));
//         } catch (error) {
//             console.error('Error fetching data:', error);
//             // setState(state => ({ ...state, isLoggedIn: false }));
//         }
//     };
// };

export const useFetchBranch = () => {
    const setState = useSetRecoilState(branchState);

    return async (branchId) => {
        try {
            const fetch = await getBranch(branchId);
            setState(({ branch: fetch.branch }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};