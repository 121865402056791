

export default function  Formmoneypay(props) {
    return (
    <input 
        type="number" 
        className="form-control form-st-2 mb-3" 
        value={props.moneypay}
        readOnly
    />
    )
}