import React, { useState, useEffect } from "react";
import { Badge } from "@mui/material";
import AvartarHeader from "components/fragments/AvartarHeader";
import NotifyIcon from "components/fragments/NotifyIcon";
import ModalSuccess from "./ModalSuccess";
import ModalPin from "./ModalPin";
import ModalChangeStaff from "./ModalChangeStaff";
import CustomInput from "components/fragments/CustomInput";
import CustomInputSearch from "components/fragments/CustomInputSearch";
import { useRecoilValue } from "recoil";
import { authState, useFetchBranch } from "state/auth";
import { posState, useCreateStaffLogin } from "state/pos";
import { useFetchAllEmployee } from "state/employee";
import { employeeState } from "state/employee";

export default function HeaderPos({ isNotify, isSearch, filterText, setFilterText }) {
  const [modalChangeStaffOpen, setModalChangeStaffOpen] = useState(false);
  const [modalPinOpen, setModalPinOpen] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const { user, logMachine } = useRecoilValue(authState);
  const { currentPOS, branch, currentPOSActivity } = useRecoilValue(posState);
  const fetchBranch = useFetchBranch();
  const fetchAllEmpolyee = useFetchAllEmployee();
  const { employees } = useRecoilValue(employeeState);
  const createStaffLogin = useCreateStaffLogin();

  useEffect(() => {
    if(user.employeeId && user.branchId && currentPOS.id) {
      fetchBranch(user.branchId)
      fetchAllEmpolyee(user.businessId);
    }
  }, [user, currentPOS])

  const swithStaff = async () => {
    const newStaff = employees.filter(e => e.id == selectedUserId)[0];

    const switched = await createStaffLogin(
      {
        posId: currentPOS.id,
        employeeId: newStaff.id,
        employeeBranchAccountId: newStaff.EmployeePermission[0].EmployeeBranchAccount[0].id,
        employeePin: newStaff.EmployeePermission[0].EmployeeBranchAccount[0].pin_code,
        startTime: currentPOSActivity.operation_date
      },
      true,
      logMachine
    );
    if(switched) {
      window.location.reload(false);
    }
  }

  return (
    <div className="h-[min(5.86vw,60px)] flex items-center py-[min(1.01vw,10.31px)] px-[min(1.62vw,16.55px)] bg-white">
      <div className="mr-[min(0.49vw,5px)]">
        <div className="flex items-center text-14">
          <div className="mr-[min(0.61vw,6.24px)] font-[500]">
            {branch?.business?.businessdetail?.name} :
          </div>
          <div className="mr-[min(1.37vw,14px)] text-[#575B5E]">สาขา {branch?.name}</div>
          <div className="mr-[min(0.49vw,5px)] font-[500]">POS {currentPOS.order} :</div>
          <div className="mr-[min(0.61vw,6.24px)] text-[#575B5E]">{currentPOS.code}</div>
        </div>
        <div className="flex text-12 gap-[min(1.07vw,11px)]">
          <div>พนักงาน :</div>
          <div className="w-[min(1.76vw,18px)] aspect-square">
            <AvartarHeader
              userInfo={{
                avartar: "/img/avatar-female.png",
              }}
            />
          </div>
          <div className="text-[#575B5E]">{user.firstNameTH} {user.lastNameTH}</div>
          <button
            onClick={() => setModalChangeStaffOpen(!modalChangeStaffOpen)}
            className="text-[var(--color-green)] underline"
          >
            เปลี่ยน
          </button>
        </div>
      </div>
      {/* {isNotify && <NotifyIcon countNotify={15} />} */}
      {isSearch && filterText !== undefined && setFilterText !== undefined && (
        <div className="items-center ml-auto flex gap-[min(0.98vw,10px)]">
          <button
            onClick={() => setFilterText("")}
            className="text-14 text-[var(--color-green)] underline"
          >
            ล้าง
          </button>
          <div className="min-w-[min(16.70vw,171px)]">
            <CustomInputSearch
              inputprops={{
                type: "text",
                className: "search-input text-12 pt-2",
                placeholder: "ค้นหาเมนูอาหาร / สินค้า",
                min: 0,
                value: filterText,
                onChange: (e) => setFilterText(e.target.value),
              }}
            />
          </div>
        </div>
      )}

      <ModalChangeStaff
        modalOpenObj={{
          modalOpen: modalChangeStaffOpen,
          setModalOpen: setModalChangeStaffOpen,
        }}
        modalPinOpenObj={{
          modalOpen: modalPinOpen,
          setModalOpen: setModalPinOpen,
        }}
        selectedUserIdObj={{
          selectedUserId,
          setSelectedUserId,
        }}
        staffList={employees}
      />

      <ModalPin
        modalOpenObj={{
          modalOpen: modalPinOpen,
          setModalOpen: setModalPinOpen,
        }}
        modalSuccessOpenObj={{
          modalOpen: modalSuccessOpen,
          setModalOpen: setModalSuccessOpen,
        }}
        onPinSubmitHandler={swithStaff}
      />

      <ModalSuccess
        modalOpenObj={{
          modalOpen: modalSuccessOpen,
          setModalOpen: setModalSuccessOpen,
        }}
      />
    </div>
  );
}
