import CustomInput from "components/fragments/CustomInput";
import CustomBtn from "components/fragments/CustomBtn";
import React from "react";
import * as yup from "yup";
import {
  onChangeHandleHour,
  onChangeHandleMinute,
} from "utils/inputOnChangeHandle";
import { useFormik } from "formik";
import { useBookingTable } from "state/table";

const validationSchema = yup.object({
  name: yup.string().required(),
  phone_number: yup
    .string()
    .matches(/^[0-9]+$/, "Phone number must only contain digits")
    .min(10, "Phone number must be exactly 10 digits")
    .max(10, "Phone number must be exactly 10 digits")
    .required(),
  hour: yup.string().required(),
  minute: yup.string().required(),
});

export default function FormTableReserved({ isReservedModeObj, tableId }) {
  const bookTable = useBookingTable();

  const formik = useFormik({
    initialValues: {
      name: "",
      phone_number: "",
      hour: "",
      minute: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const bookingTime = new Date();
      bookingTime.setHours(values.hour);
      bookingTime.setMinutes(values.minute);
      const bookingDetail = {
        tableId: tableId,
        guestName: values.name,
        guestPhoneNo: values.phone_number,
        bookingTime: bookingTime.toISOString()
        // bookingTime: bookingTime.toLocaleTimeString('th-TH',{ hourCycle: 'h23' })
      };
      bookTable(bookingDetail);
      isReservedModeObj.isSetMode(false);
    },
  });

  return (
    <form
      className="w-full mt-[min(0.49vw,5px)]"
      method="post"
      onSubmit={formik.handleSubmit}
    >
      <div className="flex pl-[min(2.44vw,25px)]  pb-[min(5.76vw,59px)] rounded-[min(1.46vw,15px)]">
        <div className="bg-[#FFF] p-[min(1.56vw,16px)] shadow-table-reserved-panel w-full flex justify-between items-center">
          <div className="flex">
            <div className="text-18 w-[min(23.73vw,243px)] mr-[min(1.37vw,14px)]">
              <div>ชื่อลูกค้า</div>
              <CustomInput
                inputprops={{
                  type: "text",
                  className: `modal-table-input px-[min(0.49vw,5px)] leading-[2] text-14 mt-[min(0.98vw,10px)]`,
                  placeholder: "กรอกชื่อลูกค้า",
                  name: "name",
                  onChange: formik.handleChange,
                  onBlur: formik.handleBlur,
                  value: formik.values.name,
                  error:
                    formik.touched.name && formik.errors.name
                      ? "error"
                      : undefined,
                }}
              />
            </div>
            <div className="text-18 w-[min(15.82vw,162px)] mr-[min(2.54vw,26px)]">
              <div>เบอร์โทรศัพท์</div>
              <CustomInput
                inputprops={{
                  type: "number",
                  className: `modal-table-input px-[min(0.49vw,5px)] no-spinner leading-[2] text-14 mt-[min(0.98vw,10px)]`,
                  placeholder: "กรอกเบอร์มือถือ",
                  name: "phone_number",
                  maxLength: 10,
                  onChange: (e) => {
                    if (e.currentTarget.value.length > 10) {
                      return;
                    }
                    const syntheticEvent = {
                      target: {
                        name: "phone_number",
                        value: e.currentTarget.value,
                      },
                    };
                    formik.handleChange(syntheticEvent);
                  },
                  onBlur: formik.handleBlur,
                  value: formik.values.phone_number,
                  error:
                    formik.touched.phone_number && formik.errors.phone_number
                      ? "error"
                      : undefined,
                }}
              />
            </div>
            <div className="text-18">
              <div>เวลานัด</div>
              <div className="flex items-center gap-[min(0.29vw,3px)] mt-[min(0.98vw,10px)]">
                <div className="w-[min(6.54vw,67px)]">
                  <CustomInput
                    inputprops={{
                      type: "text",
                      className: `modal-table-input text-center no-spinner text-14 leading-[2]`,
                      placeholder: "-",
                      name: "hour",
                      onChange: (e) => {
                        const inputValue = onChangeHandleHour(e);
                        const syntheticEvent = {
                          target: {
                            name: "hour",
                            value: inputValue,
                          },
                        };
                        formik.handleChange(syntheticEvent);
                      },
                      onBlur: formik.handleBlur,
                      value: formik.values.hour,
                      error:
                        formik.touched.hour && formik.errors.hour
                          ? "error"
                          : undefined,
                      onClick: (e) => {
                        const input = e.target;
                        input.select();
                      },
                    }}
                  />
                </div>
                <div className="poppins text-16">:</div>
                <div className="w-[min(6.54vw,67px)]">
                  <CustomInput
                    inputprops={{
                      type: "text",
                      className: `modal-table-input text-center no-spinner text-14 leading-[2]`,
                      placeholder: "-",
                      name: "minute",
                      onBlur: formik.handleBlur,
                      value: formik.values.minute,
                      error:
                        formik.touched.minute && formik.errors.minute
                          ? "error"
                          : undefined,
                      onChange: (e) => {
                        const inputValue = onChangeHandleMinute(e);
                        const syntheticEvent = {
                          target: {
                            name: "minute",
                            value: inputValue,
                          },
                        };
                        formik.handleChange(syntheticEvent);
                      },
                      onClick: (e) => {
                        const input = e.target;
                        input.select();
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center gap-[min(0.98vw,10px)] mt-[min(2.44vw,25px)]">
            <CustomBtn
              onClick={() => isReservedModeObj.isSetMode(false)}
              sx={{
                "&:hover": {
                  boxShadow: "inherit",
                },
              }}
              className={`shadow-table-btn-2 leading-[0.8] w-fit min-w-[min(9.18vw,94px)] text-14 text-[#6D788D] font-[600] h-[min(3.32vw,34px)] rounded-[min(0.78vw,8px)] btn-second-2-active`}
              btntxt={"ยกเลิก"}
            />
            <CustomBtn
              sx={{
                "&:hover": {
                  boxShadow: "inherit",
                },
              }}
              className={`shadow-table-btn-2 leading-[0.8] w-fit min-w-[min(9.18vw,94px)] text-14 text-white font-[600] h-[min(3.32vw,34px)] rounded-[min(0.78vw,8px)] btn-main-active`}
              btntxt={"บันทึก"}
              type={"submit"}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
