import React, { useEffect, useState } from "react";
import moment from "moment";

export default function PreCloseBalancePrint() {
  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    updateCurrentDate();
  }, []);

  const updateCurrentDate = () => {
    const formattedDateTime = moment().format('DD/MM/YYYY HH:mm:ss');
    setCurrentDate(formattedDateTime);
  };

  return (
    <div className="bg-[#EFEFEF] min-h-[100vh] pt-[4.00vw] pb-[12.79vw] invisible fixed">
      <div className="flex justify-center text-[0.78vw] w-full">
        <div
          id={"section-to-print"}
          className="w-[21.48vw] bg-[#FFF] px-[1.70vw] py-[1.31vw] flex flex-col gap-[0.59vw] "
        >
          <div className="text-center font-[600]">
            <div>เปิดลิ้นชักเมื่อ</div>
            <div>{currentDate}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
