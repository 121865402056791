import React, { useEffect, useState } from "react";

import Calculate from "components/layouts/payment/Calculator";
import Formmoneypay from "components/layouts/payment/Inputmoneypay";
import { nanoid } from 'nanoid'
export default function  PayOther({
    PaymentSelect,
    setPaymentSelect,
    setProvider,
    ...props
    }) {

    const [PaymentChannelList, setPaymentChannelList] = useState(
        [
          {"paymentChannelID":8,"paymentChannelName":"ใช้คะแนนแลกส่วนลด","icon":"point","disabled":false},
          {"paymentChannelID":9,"paymentChannelName":"บันทึกลูกหนี้","icon":"debtors","disabled":true},
        ]
      );

    return (
        <div style={{minHeight:'280px'}}>
            <div className="row mb-0">
                <div className="col-4">
                    <button  className={`btn px-1 text-[#FFF] btn-select-payment back bg-[#2DC76D]  `} 
                        onClick={() => setPaymentSelect({"paymentChannelID":"1"}) }
                    >
                        <div className="pay-icon"></div><div className="lh">กลับช่องทางหลัก</div>
                    </button>
                </div>
                {
                PaymentChannelList && PaymentChannelList.map((item, index) => 
                    <div className="col-4" key={index}>
                    <button  
                        className={`btn btn-select-payment ${item.icon} ${ PaymentSelect.paymentChannelID == item.paymentChannelID ? 'active' : ''  }`} 
                        onClick={() => { setPaymentSelect(item);setProvider(null); } }
                        disabled={item.disabled}
                    >
                        <div className="pay-icon"></div><div className="lh">{item.paymentChannelName}</div>
                    </button>
                    </div>
                )
                }
            </div>
        </div>
    )

}

