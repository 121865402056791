import { Button } from "@mui/material";
import React from "react";

export default function CustomPlainBtn({ btntxt, ...props }) {
  return (
    <Button
      sx={{
        boxShadow: 0,
        "&:hover": {
          boxShadow: "inherit",
        },
      }}
      {...props}
      className={`sarabun ${props.className}`}
      variant="contained"
    >
      {btntxt}
    </Button>
  );
}
