import React from "react";

export default function MemberCard({ handleOnClick, info, isSelected = false }) {
  return (
    <div
      onClick={handleOnClick}
      className={`cursor-pointer pl-[min(1.50vw,15.35px)] pr-[min(1.00vw,10.29px)] flex items-center justify-between h-[min(5.25vw,53.76px)] rounded-[min(1.41vw,14.39px)] ${
        isSelected ? "bg-[#FAFAFA]" : "bg-[#FFF]"
      } `}
    >
      <div className="flex items-center gap-[min(1.41vw,14.39px)]">
        <img
          className="object-cover aspect-square w-[min(3.74vw,38.3px)] h-fit rounded-full"
          src={info.avatar}
          alt=""
        />
        <div className="text-15">
          <div>{info.name}</div>
          <div className="text-[var(--color-grey-2)]">{info.phoneNumber}</div>
        </div>
      </div>
      <div className="flex items-center justify-center rounded-full prompt font-[300] bg-[var(--color-blue-2)] min-w-[min(7.80vw,79.89px)] h-[min(1.58vw,16.17px)] text-[#FFF] text-11 uppercase">
        {info.position}
      </div>
    </div>
  );
}
