import { atom, selector, useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { getAllPaymentChannel, createPaymentHistory } from 'services/payment';
import { getAllBill, updateBill } from 'services/bill';
import { billState } from './bill';

// State
export const paymentState = atom({
    key: 'paymentState',
    default: {
        paymentChannels: [],
        currentPayment: {
            billTotalAmount: 0,
            paidAmount: 0,
            remainingAmount: 0,
            changes: 0
        }
    },
});

// Action
export const useFetchAllPaymentChannel = () => {
    const setState = useSetRecoilState(paymentState);

    return async (branchId) => {
        try {
            const fetch = await getAllPaymentChannel(branchId);
            setState((state => ({ ...state, paymentChannels: fetch.paymentChannels })));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useCreatePayment = () => {
    const setState = useSetRecoilState(paymentState);
    const setBillState = useSetRecoilState(billState);
    // const { paymentChannels } = useRecoilValue(paymentState)

    return async (order, bill, payments, paidPosId, billDiscount) => {
        // console.log("🚀 ~ file: payment.js:31 ~ return ~ order:", order)
        // console.log("🚀 ~ file: payment.js:31 ~ return ~ bill:", bill)
        // console.log("🚀 ~ file: payment.js:31 ~ return ~ payments:", payments)
        const paidAmount = payments.reduce((a,b) => a + Number(b.amount), 0);
        // console.log("🚀 ~ file: payment.js:36 ~ return ~ paidAmount:", paidAmount)
        const tableActivityId = bill?.table_activity_id ?? 1;
        // console.log("🚀 ~ file: payment.js:40 ~ return ~ tableActivityId:", tableActivityId)
        const remaining = bill?.total && bill?.total > paidAmount ? bill.total - paidAmount : 0;
        try {
            // Create Payment History
            const create = [];
            for (let i = 0; i < payments.length; i++) {
                const paid = await createPaymentHistory({
                    billId: bill.id,
                    // paymentChannelID: payments[i].channel.paymentChannelID,
                    paymentChannelID: payments[i].selectedPaymentChannelID,
                    paymentStatusID: payments[i].status,
                    paidAmount: Number(payments[i].amount),
                    changeAmount: payments[i].changeAmount ?? 0,
                    feeAmount: parseFloat(payments[i].feeAmount) ?? 0,
                    feePercent: parseFloat(payments[i].feePercent) ?? 0,
                    isViaDelivery: payments[i].isViaDelivery ?? false,
                    deliveryId: payments[i].deliveryId ?? false,
                });
                create.push(paid);
            }

            // Update Bill detail
            const paymentStatusId = 2;
            const update = await updateBill(bill.id, tableActivityId, paidAmount, paymentStatusId, paidPosId, billDiscount);
            // console.log("🚀 ~ return ~ update:", update)
            
            // handle negative changes value
            var netChanges = update?.bill?.change_amount ?? 0;
            if(netChanges < 0) {
                netChanges = 0;
            }

            setState(state => ({ 
                ...state, 
                currentPayment: {
                    paidAmount: paidAmount,
                    billTotalAmount: bill?.total ?? 0,
                    remainingAmount: remaining,
                    // changes: update?.bill?.change_amount ?? 0,
                    changes: netChanges
                }
            }))

            // Refresh Bill state
            // const fetch = await getAllBill();
            // console.log("🚀 ~ file: bill.js:64 ~ return ~ fetch:", fetch)

            const member = {
                ...update.bill?.member,
                // point: update.bill?.member?.point ? update.bill.member.point : 0,
                // point: create[create.length - 1].paymentChannels?.member?.point ?? 0,
                point: update.bill?.member_previous_point ?? 0, // member point before this bill
                // ...create[create.length - 1].paymentChannels?.member,
                // pointUsage: create[create.length - 1].paymentChannels?.member?.deductPoint ?? 0,
                pointUsage: update.bill?.point_usage ?? 0,
                // pointReceive: create[create.length - 1].paymentChannels?.member?.receivePoint ?? 0,
                pointReceive: update.bill?.point_receive ?? 0,
                // pointTotal: 0
            }

            setBillState(state => ({ 
                ...state, 
                // bills: fetch.bill, 
                currentBill: {
                    ...state.currentBill, // TODO: Try comment this line to take only refreshed bill data
                    ...update.bill,
                    paid: paidAmount,
                    // change: update?.bill?.change_amount ?? 0,
                    change: netChanges,
                    member: member
                }
            }));

        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
}

export const useResetCurrentPayment = () => {
    const setState = useSetRecoilState(paymentState);

    return async () => {
        setState(state => ({ 
            ...state, 
            currentPayment: {
                billTotalAmount: 0,
                paidAmount: 0,
                remainingAmount: 0,
                changes: 0
            }
        }))
    }
}