import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomPlainBtn from "components/fragments/CustomPlainBtn";
import CustomInputSearchV2 from "components/fragments/CustomInputSearchV2";
import MemberCard from "./MemberCard";
import { numberNoDecimals } from "utils/numberFormat";

export default function ModalSearchMember({
  customerList,
  setSelectIdCf,
  modalOpenObj,
  modalRegisterMemberOpenObj,
  addMemberToOrder,
  ...props
}) {
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [isNotFound, setIsNotFound] = useState(false);
  const [custSeletedId, setCustSeletedId] = useState();
  // console.log("🚀 ~ custSeletedId:", custSeletedId)
  // console.log("🚀 ~ customer:", customerList.find((v) => v.id === custSeletedId))

  const resetInit = () => {
    setKeyword("");
    setIsNotFound(false);
    setCustSeletedId(undefined);
    setFilteredUserList([]);
  };

  useEffect(() => {
    if (!keyword) {
      return setFilteredUserList([]);
    }
    const filteredList = customerList.filter(
      (user) =>
        user.name.toLowerCase().includes(keyword.toLowerCase()) ||
        user.phoneNumber.toLowerCase().includes(keyword.toLowerCase()) ||
        user.customerId.toLowerCase().includes(keyword.toLowerCase())
    );
    if (filteredList.length === 0) {
      setIsNotFound(true);
    } else {
      setIsNotFound(false);
    }
    setFilteredUserList(filteredList);
  }, [customerList, keyword]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => {
          modalOpenObj.setModalOpen(false);
          resetInit();
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          <div className="bg-white w-[min(88.38vw,905px)] min-h-[min(59.57vw,610px)] max-h-[100vh] px-[min(4.00vw,41px)] pt-[min(7.83vw,80.21px)] pb-[min(2.58vw,26.38px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-24 text-[var(--color-black-2)] text-center leading-[1.8] font-[500]">
            <div className="flex">
              <div className="bg-[#F4FCF8] rounded-[min(1.46vw,15px)] aspect-[369/409] w-[min(36.04vw,369px)] h-fit py-[min(2.23vw,22.79px)] px-[min(1.56vw,16px)]">
                <div className="text-left text-20 font-[700] leading-[2] text-[var(--color-green)]">
                  เพิ่มสมาชิกบิลนี้
                </div>
                <div className="w-[min(32.32vw,331px)] mt-[min(0.78vw,8px)]">
                  <CustomInputSearchV2
                    inputprops={{
                      type: "text",
                      className:
                        "rounded-[min(0.78vw,8px)] w-[min(32.32vw,331px)] h-[min(4.39vw,45px)] pl-[min(2.29vw,23.5px)] pr-[min(4.20vw,43px)] py-[min(1.03vw,10.5px)] text-14 leading-[4] placeholder:h-full ",
                      placeholder: "ชื่อ,เบอร์โทรศัพท์,หมายเลขสมาชิก",
                      onChange: (e) => setKeyword(e.currentTarget.value),
                    }}
                  />
                </div>
                {isNotFound && (
                  <div className="h-[min(25.39vw,260px)] flex flex-col justify-center items-center text-14 text-[var(--color-red)] font-[700] leading-[1.7]">
                    <img
                      className="object-contain aspect-square w-[min(6.93vw,71px)] h-fit"
                      src="/img/icons/icon-not-found-red.svg"
                      alt=""
                    />
                    <div className="mt-[min(0.88vw,9px)]">ไม่พบสมาชิก</div>
                    <div>กรุณาสมัครสมาชิกใหม่</div>
                  </div>
                )}
                {!isNotFound && (
                  <div className="mt-[min(0.49vw,5px)] flex flex-col gap-[min(0.49vw,5px)] max-h-[min(25.39vw,260px)] w-[min(32.32vw,331px)] overflow-y-auto">
                    {filteredUserList.map((v, i) => {
                      return (
                        <MemberCard
                          key={i}
                          info={{ ...v }}
                          handleOnClick={() => {
                            if (v.id !== custSeletedId) {
                              return setCustSeletedId(v.id);
                            }
                          }}
                          isSelected={v.id === custSeletedId}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="w-full h-fit px-[min(2.93vw,30px)] pt-[min(2.23vw,22.79px)] pb-[min(1.66vw,17px)] text-18">
                <div className="text-left text-[var(--color-green)] font-[700]">
                  ข้อมูลสมาชิก
                </div>
                <div className="flex flex-col justify-between h-[min(29.79vw,305px)] mt-[min(2.44vw,25px)]">
                  <div className="flex">
                    <div className="flex w-full justify-between items-center">
                      <div className="flex items-center gap-[min(1.27vw,13px)]">
                        <img
                          className="object-contain aspect-square w-[min(2.44vw,25px)] h-fit"
                          src="/img/icons/icon-user-green.svg"
                          alt=""
                        />
                        <div>รหัสลูกค้า</div>
                      </div>
                      <div className="flex gap-[min(5.37vw,55px)]">
                        <div>
                          {custSeletedId !== undefined
                            ? (
                                customerList.find(
                                  (v) => v.id === custSeletedId
                                ) || {}
                              ).customerId || "-"
                            : "-"}
                        </div>
                        <div className="aspect-square w-[min(2.05vw,21px)]"></div>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex w-full justify-between items-center">
                      <div className="flex items-center gap-[min(1.27vw,13px)]">
                        <img
                          className="object-contain aspect-square w-[min(2.44vw,25px)] h-fit"
                          src="/img/icons/icon-user-type-green.svg"
                          alt=""
                        />
                        <div>ประเภทลูกค้า</div>
                      </div>

                      <div className="flex gap-[min(5.37vw,55px)]">
                        <div>
                          {custSeletedId !== undefined
                            ? (
                                customerList.find(
                                  (v) => v.id === custSeletedId
                                ) || {}
                              ).customerType || "-"
                            : "-"}
                        </div>
                        <div className="aspect-square w-[min(2.05vw,21px)]"></div>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex w-full justify-between items-center">
                      <div className="flex items-center gap-[min(1.27vw,13px)]">
                        <img
                          className="object-contain aspect-square w-[min(2.44vw,25px)] h-fit"
                          src="/img/icons/icon-user-id-green.svg"
                          alt=""
                        />
                        <div>ชื่อลูกค้า</div>
                      </div>

                      <div className="flex gap-[min(5.37vw,55px)]">
                        <div>
                          {custSeletedId !== undefined
                            ? (
                                customerList.find(
                                  (v) => v.id === custSeletedId
                                ) || {}
                              ).name || "-"
                            : "-"}
                        </div>
                        <div className="aspect-square w-[min(2.05vw,21px)]"></div>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex w-full justify-between items-center">
                      <div className="flex items-center gap-[min(1.27vw,13px)]">
                        <img
                          className="object-contain aspect-square w-[min(2.44vw,25px)] h-fit"
                          src="/img/icons/icon-tel-green.svg"
                          alt=""
                        />
                        <div>เบอร์โทรศัพท์</div>
                      </div>

                      <div className="flex gap-[min(5.37vw,55px)]">
                        <div>
                          {custSeletedId !== undefined
                            ? (
                                customerList.find(
                                  (v) => v.id === custSeletedId
                                ) || {}
                              ).phoneNumber || "-"
                            : "-"}
                        </div>
                        <div className="aspect-square w-[min(2.05vw,21px)]"></div>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex w-full justify-between items-center">
                      <div className="flex items-center gap-[min(1.27vw,13px)]">
                        <img
                          className="object-contain aspect-square w-[min(2.44vw,25px)] h-fit"
                          src="/img/icons/icon-cake-green.svg"
                          alt=""
                        />
                        <div>วันเกิด</div>
                      </div>

                      <div className="flex gap-[min(5.37vw,55px)]">
                        <div>
                          {custSeletedId !== undefined
                            ? (
                                customerList.find(
                                  (v) => v.id === custSeletedId
                                ) || {}
                              ).birthdate || "-"
                            : "-"}
                        </div>
                        <div className="aspect-square w-[min(2.05vw,21px)]"></div>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex w-full justify-between items-center">
                      <div className="flex items-center gap-[min(1.27vw,13px)]">
                        <img
                          className="object-contain aspect-square w-[min(2.44vw,25px)] h-fit"
                          src="/img/icons/icon-gift-green.svg"
                          alt=""
                        />
                        <div>คะแนนคงเหลือ</div>
                      </div>
                      <div className="flex gap-[min(5.37vw,55px)]">
                        <div>
                          {custSeletedId !== undefined
                            ? numberNoDecimals(
                                customerList.find((v) => v.id === custSeletedId)
                                  .point || 0
                              ) || "-"
                            : "-"}
                        </div>
                        <div className="aspect-square w-[min(2.05vw,21px)]">
                          <img
                            className="object-contain w-full h-full"
                            src="/img/icons/icon-star-yellow.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-[min(4.11vw,42.08px)] pr-[min(2.93vw,30px)]">
              <CustomPlainBtn
                onClick={() => {
                  modalOpenObj.setModalOpen(false);
                  modalRegisterMemberOpenObj.setModalOpen(true);
                  resetInit();
                }}
                sx={{
                  border: "min(0.10vw,1px) solid var(--color-blue)",
                  boxShadow: 0,
                  "&:hover": {
                    boxShadow: "inherit",
                  },
                }}
                className="aspect-[210/52.8] min-w-[min(20.51vw,210px)] text-[var(--color-blue)] text-20 rounded-[min(0.78vw,8px)] bg-[#fafafa]"
                btntxt={"สมัครสมาชิก"}
              />
              <div className="flex gap-[min(1.17vw,12px)]">
                <CustomPlainBtn
                  onClick={() => {
                    modalOpenObj.setModalOpen(false);
                    resetInit();
                  }}
                  className="aspect-[114/52.8] min-w-[min(11.13vw,114px)] text-20 rounded-[min(0.78vw,8px)] btn-grey-3-active"
                  btntxt={"ยกเลิก"}
                />
                <CustomPlainBtn
                  onClick={async () => {
                    // modalOpenObj.setModalOpen(false);
                    // setSelectIdCf(custSeletedId);
                    modalOpenObj.setModalOpen(false);
                    resetInit();
                    await addMemberToOrder(custSeletedId);
                    setSelectIdCf(custSeletedId);
                  }}
                  className={`aspect-[114/52.8] min-w-[min(11.13vw,114px)] text-20 rounded-[min(0.78vw,8px)] ${custSeletedId !== undefined ? 'btn-main-active' : 'disabled-btn'}`}
                  btntxt={"ยืนยัน"}
                />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
