import DeleteNumberImg from 'img/14T090753.464.png';

export default function  Calculate(props) {

    const Num = (n) => {
        let m = props.moneypay == '0.00' ? '' : props.moneypay ;
        m += n;
        props.setMoneypay(m)
    }
    
    return (
        <div className="mt-2 d-flex">
          <div className="">
              <div className="d-flex">
                  <div>
                      <div id="clear" className="clear btn-cal-5" onClick={() => { props.setMoneypay('0.00') }}>CE</div>
                  </div>
                  <div 
                    id="del" 
                    className="del btn-cal-4 d-flex justify-content-center " 
                    onClick={() => { 
                        if(props.moneypay != "0.00") {
                            props.setMoneypay(String(props.moneypay).slice(0, -1).length > 0 ? String(props.moneypay).slice(0, -1) : '0.00') 
                        }
                    }}
                  >
                      {/* <img src="img/14T090753.464.png" width="29" height="20" className="align-self-center" /> */}
                      <img src={DeleteNumberImg} width="29" height="20" className="align-self-center" />
                  </div>
                  
              </div>
              <div className="d-flex">
                  <div className="btn-cal-1" onClick={() => Num('7') }>7</div>
                  <div className="btn-cal-1" onClick={() => Num('8') }>8</div>
                  <div className="btn-cal-1" onClick={() => Num('9') }>9</div>
              </div>
              <div className="d-flex">
                  <div>
                      <div className="d-flex">
                          <div className="btn-cal-1" onClick={() => Num('4') }>4</div>
                          <div className="btn-cal-1" onClick={() => Num('5') }>5</div>
                          <div className="btn-cal-1" onClick={() => Num('6') }>6</div>
                      </div>
                      <div className="d-flex">
                          <div className="btn-cal-1" onClick={() => Num('1') }>1</div>
                          <div className="btn-cal-1" onClick={() => Num('2') }>2</div>
                          <div className="btn-cal-1" onClick={() => Num('3') }>3</div>
                      </div>
                      <div className="d-flex">
                          <div className="btn-cal-6" onClick={() => Num('0') }>0</div>
                          <div className="btn-cal-1" onClick={() => Num('.') }>.</div>
                      </div>
                  </div>
                  
              </div>
          </div>
          <div className=" ">
              <div>
                  <div className="btn-cal-7 tmp_price" onClick={() => { props.setMoneypay(Math.abs(props.PaymentDetail.paymentRemaining)) }} >{Math.abs(props.PaymentDetail.paymentRemaining)}</div>
              </div>
              <button className="tmp_price btn-tran" onClick={() => { props.setMoneypay(prev => (parseFloat(prev) + 20)) }}>20</button>
              <button className="tmp_price btn-tran" onClick={() => { props.setMoneypay(prev => (parseFloat(prev) + 50)) }}>50</button>
              <button className="tmp_price btn-tran" onClick={() => { props.setMoneypay(prev => (parseFloat(prev) + 100)) }}>100</button>
              <button className="tmp_price btn-tran" onClick={() => { props.setMoneypay(prev => (parseFloat(prev) + 500)) }}>500</button>
              <button className="tmp_price  btn-tran" onClick={() => { props.setMoneypay(prev => (parseFloat(prev) + 1000)) }}>1000</button>
          </div>
        </div>
    )
    
}