import CustomBtn from "components/fragments/CustomBtn";
import CustomInput from "components/fragments/CustomInput";
import MenuBtn from "components/fragments/MenuBtn";
import ModalCloseBalanceCf from "components/layouts/ModalCloseBalanceCf";
import ModalPin from "components/layouts/ModalPin";
import ModalSuccessMoneyDrawer from "components/layouts/ModalSuccessMoneyDrawer";
import MoneyCountController from "components/layouts/MoneyCountController";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { numberWithTwoDecimals } from "utils/numberFormat";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";
import { posState, selectedPosSettingState, useGetPOSClosureSummary, useSetPosClosure } from "state/pos";
import { getTimeDifference } from "utils/dateFormat";
import { useClosePOSActivity, useFetchAllPosActivity, useDrawerPosActivity } from "state/pos";
import { useFetchAllOrder, orderState } from "state/order";
import { appState } from "state/app";
import PreCloseBalancePrint from "components/layouts/PreCloseBalancePrint";

const dateOptions = { 
  day: '2-digit', 
  month: '2-digit', 
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export default function MoneyDrawerPage() {
  const [btnList, setBtnList] = useState([
    {
      icon: "/img/icons/icon-deposit.svg",
      btnTxt: "นำเงินเข้า",
      disable: true
    },
    {
      icon: "/img/icons/icon-withdraw.svg",
      btnTxt: "นำเงินออก",
      disable: true
    },
    {
      icon: "/img/icons/icon-money-bag.svg",
      btnTxt: "ปิดยอด",
      disable: false
    },
  ]);
  const [currentMenu, setCurrentMenu] = useState();
  const navigate = useNavigate();
  const { slug, slugBranch } = useRecoilValue(appState);

  const handleOnSubmit = () => {
    // console.log("handleOnSubmit - currentPOS: ", currentPOS)
    // console.log("handleOnSubmit - currentPOSActivity: ", currentPOSActivity)
    // console.log("handleOnSubmit - posActivities: ", posActivities)
    // console.log("handleOnSubmit - summary: ", summary)
    // console.log("handleOnSubmit - user: ", user)

    setModalPinOpen(!modalPinOpen);
  };

  const [isBtnActive, setIsBtnActive] = useState(false);
  const [startValue, setStartValue] = useState("0.00");
  const [modalPinOpen, setModalPinOpen] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({
    headTxt: "",
    headModalTxt: "",
    btnTxt: "",
    billCountTxt: "",
    billCreateAtTxt: "",
    billTotalTxt: "",
    billTotalNoteTxt: "",
  });
  const [isResetMoneyController, setIsResetMoneyController] = useState(false);

  const initTestInfo = {
    calTxt: "",
    eventTxt: "",
    cashierName: "POS 1",
  };
  const [moneyCountConInfo, setMoneyCountConInfo] = useState(initTestInfo);
  // const [viaPaymentInfo, setViaPaymentInfo] = useState({
  //   transferTotal: 40000,
  //   otherTotal: 60000,
  //   cashTotal: 35000,
  //   diffTotal: 0,
  // });
  const [viaPaymentInfo, setViaPaymentInfo] = useState({
    transferTotal: 0,
    otherTotal: 0,
    cashTotal: 0,
    diffTotal: 0,
  });
  const [isCloseBalanceConfirm, setIsCloseBalanceConfirm] = useState(false);
  const [modalCloseBalanceCf, setModalCloseBalanceCf] = useState(false);
  const [initPOSActivity, setInitPosAcitivity] = useState(null);
  const [total, setTotal] = useState({
    deposit: 0,
    withdraw: 0
  });
  const [drawerCount, setDrawerCount] = useState({
    deposit: 0,
    withdraw: 0
  })

  const { user } = useRecoilValue(authState);
  const { currentPOS, currentPOSActivity, posActivities, summary } = useRecoilValue(posState);
  // console.log("🚀 ~ MoneyDrawerPage ~ summary:", summary)
  const selectedPosSetting = useRecoilValue(selectedPosSettingState);
  const closePOSActivity = useClosePOSActivity();
  const fetchAllPosActivity = useFetchAllPosActivity();
  const drawerPosActivity = useDrawerPosActivity();
  const getPOSClosureSummary = useGetPOSClosureSummary();
  const [closureDiff, setClosureDiff] = useState(0)
  const fetchAllOrder = useFetchAllOrder();
  const { orders } = useRecoilValue(orderState);
  const [warningText, setWarningText] = useState(false)
  const setPosClosure = useSetPosClosure()

  const onConfirmBtnHandler = async () => {
    let closePOSActivityDetail = {
      posId: currentPOS.id,
      employeeBranchAccountId: user.employeeBranchAccountId,
      activityTypeId: 5, // Close POS
      amount1000: selectedPosSetting?.amount1000,
      amount500: selectedPosSetting?.amount500,
      amount100: selectedPosSetting?.amount100,
      amount50: selectedPosSetting?.amount50,
      amount20: selectedPosSetting?.amount20,
      amount10: selectedPosSetting?.amount10,
      amount5: selectedPosSetting?.amount5,
      amount1: selectedPosSetting?.amount1,
      cashTotal: selectedPosSetting?.amountTotal,
      startTime: new Date(currentPOSActivity.created_at),
    }
    await closePOSActivity(closePOSActivityDetail, currentPOSActivity.operation_date);
    navigate(`/${slug}/${slugBranch}/close-balance-print`)
  }

  useEffect(() => {
    fetchAllPosActivity()
  }, [])

  useEffect(() => {
    if(currentPOS.id) {
      getPOSClosureSummary(currentPOS.id);
      fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
    }
  }, [currentPOS])
  
  useEffect(() => {
    if(currentPOS && posActivities && posActivities.length > 0) {
      // console.log("currentPOS: ", currentPOS);
      // console.log("posActivities: ", posActivities);
      // console.log("currentPOSActivity: ", currentPOSActivity);
      const posActivity = [...posActivities].reverse().filter(pa => pa.pos_id == currentPOS.id && pa.activity_type_id == 1)[0];
      // console.log("🚀 ~ file: MoneyDrawerPage.jsx:109 ~ useEffect ~ posActivity:", posActivity)
      setInitPosAcitivity({...posActivity});

      // Sum Deposit
      const deposit = [...posActivities]
        .reverse()
        .filter(pa => pa.pos_id == currentPOS.id && pa.activity_type_id == 3 && pa.operation_date == currentPOSActivity.operation_date)
        .reduce((a,b) => a + b.cash_total, 0);
      const depositCount = [...posActivities]
        .reverse()
        .filter(pa => pa.pos_id == currentPOS.id && pa.activity_type_id == 3 && pa.operation_date == currentPOSActivity.operation_date).length + 1;
      setDrawerCount(prev => ({ ...prev, deposit: depositCount }));

      // Sum Withdraw
      const withdraw = [...posActivities]
        .reverse()
        .filter(pa => pa.pos_id == currentPOS.id && pa.activity_type_id == 4 && pa.operation_date == currentPOSActivity.operation_date)
        .reduce((a,b) => a + b.cash_total, 0);
      const withdrawCount = [...posActivities]
        .reverse()
        .filter(pa => pa.pos_id == currentPOS.id && pa.activity_type_id == 4 && pa.operation_date == currentPOSActivity.operation_date).length + 1;
      setDrawerCount(prev => ({ ...prev, withdraw: withdrawCount }));
      
      setTotal(prev => ({...prev, deposit: deposit, withdraw: withdraw}))
    }
  }, [posActivities])
  

  useEffect(() => {
    if (startValue > 0) {
      return setIsBtnActive(true);
    }
    setIsBtnActive(false);
  }, [startValue]);

  useEffect(() => {
    // console.log("currentMenu: ", currentMenu)
    setIsResetMoneyController(true);
    if (currentMenu !== 2) {
      setMoneyCountConInfo(initTestInfo);
    } else if (currentMenu == 2) {
      getPOSClosureSummary(currentPOS.id);
    }

    let currentTs = null;
    let posStartTs = new Date(currentPOSActivity.created_at);
    let diffTime = 0;
    if (currentMenu === 2) {
      currentTs = new Date();
      diffTime = getTimeDifference(posStartTs,currentTs);
    }

    // Sum Deposit
    // console.log('posActivities: ', posActivities)
    const deposit = [...posActivities]
      .reverse()
      .filter(pa => pa.pos_id == currentPOS.id && pa.activity_type_id == 3 && pa.operation_date == currentPOSActivity.operation_date)
      .reduce((a,b) => a + b.cash_total, 0);
    const depositCount = [...posActivities]
      .reverse()
      .filter(pa => pa.pos_id == currentPOS.id && pa.activity_type_id == 3 && pa.operation_date == currentPOSActivity.operation_date).length + 1;
    setDrawerCount(prev => ({ ...prev, deposit: depositCount }));

    // Sum Withdraw
    const withdraw = [...posActivities]
      .reverse()
      .filter(pa => pa.pos_id == currentPOS.id && pa.activity_type_id == 4 && pa.operation_date == currentPOSActivity.operation_date)
      .reduce((a,b) => a + b.cash_total, 0);
    const withdrawCount = [...posActivities]
      .reverse()
      .filter(pa => pa.pos_id == currentPOS.id && pa.activity_type_id == 4 && pa.operation_date == currentPOSActivity.operation_date).length + 1;
    setDrawerCount(prev => ({ ...prev, withdraw: withdrawCount }));

    setTotal(prev => ({...prev, deposit: deposit, withdraw: withdraw}))

    switch (currentMenu) {
      case 0:
        setCurrentEvent((prevEvent) => ({
          ...prevEvent,
          headTxt: "นำเงินเข้าครั้งที่",
          headModalTxt: "นำเงินเข้าสำเร็จ",
          btnTxt: "นำเงินเข้า",
          billCountTxt: "นำเข้าครั้งที่",
          billCreateAtTxt: "เวลานำเข้า",
          billTotalTxt: "รวมนำเข้า",
          billTotalNoteTxt: "นำเข้ารวมทั้งหมด",
        }));
        setMoneyCountConInfo((prevInfo) => ({
          ...prevInfo,
          calTxt: "เพิ่ม",
          eventTxt: "เงินสดนำเข้า",
          cashierName: currentPOS.name,
          staffName: `${user.firstNameTH} ${user.lastNameTH}`,
          startTime: posStartTs.toLocaleDateString('en-GB', dateOptions).replace(',', ''),
        }));
        setStartValue("0.00")
        setIsResetMoneyController(true);
        break;
      case 1:
        setCurrentEvent((prevEvent) => ({
          ...prevEvent,
          headTxt: "นำเงินออกครั้งที่",
          headModalTxt: "นำเงินออกสำเร็จ",
          btnTxt: "นำเงินออก",
          billCountTxt: "นำออกครั้งที่",
          billCreateAtTxt: "เวลานำออก",
          billTotalTxt: "รวมนำออก",
          billTotalNoteTxt: "นำออกรวมทั้งหมด",
        }));
        setMoneyCountConInfo((prevInfo) => ({
          ...prevInfo,
          calTxt: "นำออก",
          eventTxt: "เงินสดนำออก",
          cashierName: currentPOS.name,
          staffName: `${user.firstNameTH} ${user.lastNameTH}`,
          startTime: posStartTs.toLocaleDateString('en-GB', dateOptions).replace(',', ''),
        }));
        setStartValue("0.00")
        setIsResetMoneyController(true);
        break;
      case 2:
        setCurrentEvent((prevEvent) => ({
          ...prevEvent,
          headTxt: "ปิดยอด",
          billCountTxt: "",
          billCreateAtTxt: "",
          billTotalTxt: "",
          billTotalNoteTxt: "",
        }));
        setMoneyCountConInfo((prevInfo) => ({
          ...prevInfo,
          cashierName: currentPOS.name,
          staffName: `${user.firstNameTH} ${user.lastNameTH}`,
          startTime: posStartTs.toLocaleDateString('en-GB', dateOptions).replace(',', ''),
          // endTime: "05/19/2020 20:30",
          endTime: currentTs.toLocaleDateString('en-GB', dateOptions).replace(',', ''),
          // duration: "08:10:24",
          duration: diffTime,
          eventTxt: "เงินสดตั้งต้น",
          // startValue: currentPOSActivity.cash_total,
          // startValue: initPOSActivity.cash_total ?? 0,
          startValue: summary?.initialAmount ?? 0,
          // totalDeposit: total.deposit,
          totalDeposit: summary?.totalDeposit,
          // totalWithDraw: total.withdraw,
          totalWithDraw: summary?.totalWithdraw,
          // grandTotal: currentPOS.cash_total, // ยอดขาย
          grandTotal: summary?.totalSales - summary?.totalDiscount, // ยอดขาย
          totalFee: summary?.totalFee
        }));
        window.print()
        break;

      default:
        break;
    }
  }, [currentMenu]);

  const onPinSubmitHandler = async () => {
    // console.log("onPinSubmitHandler - currentPOS: ", currentPOS)
    // console.log("onPinSubmitHandler - moneyCountConInfo: ", moneyCountConInfo)
    // console.log("onPinSubmitHandler - selectedPosSetting: ", selectedPosSetting)
    if(currentMenu !== 2) {
      await drawerPosActivity(
        {
          posId: currentPOS.id,
          employeeBranchAccountId: user.employeeBranchAccountId,
          activityTypeId: currentMenu + 3, // According to POS Activty Type id in DB
          amount1000: selectedPosSetting?.amount1000 ?? 0,
          amount500: selectedPosSetting?.amount500 ?? 0,
          amount100: selectedPosSetting?.amount100 ?? 0,
          amount50: selectedPosSetting?.amount50 ?? 0,
          amount20: selectedPosSetting?.amount20 ?? 0,
          amount10: selectedPosSetting?.amount10 ?? 0,
          amount5: selectedPosSetting?.amount5 ?? 0,
          amount1: selectedPosSetting?.amount1 ?? 0,
          cashTotal: startValue ? parseInt(startValue) : selectedPosSetting ? selectedPosSetting.amountTotal : 0,
        },
        currentPOSActivity.operation_date
      );
      setModalSuccessOpen(true)
    } else {
      setModalCloseBalanceCf(true)
    }
    // setStartValue("0.00")
    // setIsResetMoneyController(true);
  }

  const onCloseBalanceHandler = async () => {
    // console.log('viaPaymentInfo: ', viaPaymentInfo)
    // console.log('summary: ', summary)
    // console.log('startValue: ', startValue)
    const diff = startValue - summary?.totalCash - summary?.initialAmount;
    // const diff = startValue - summary?.totalCash - summary?.totalTransfer - summary?.totalOther;
    setClosureDiff(diff)
    setViaPaymentInfo(prev => ({ ...prev, diffTotal: diff }))
    setPosClosure(startValue, diff)
  }

  const onClosureCashConfirmBtnHandler = async () => {
    // console.log(orders)
    const openOrder = orders.find(od => od.status_id == 1);
    // console.log("🚀 ~ onClosureCashConfirmBtnHandler ~ openOrder:", openOrder)
    // const holdOrder = orders.find(od => od.status_id == 4);
    // console.log("🚀 ~ onClosureCashConfirmBtnHandler ~ holdOrder:", holdOrder)
    if(openOrder) {
      setWarningText(true)
    } else {
      setWarningText(false)
      onCloseBalanceHandler();
      setIsCloseBalanceConfirm(true);
    }
  }

  return (
    <div className="py-[min(4.10vw,42px)] px-[min(4.20vw,43px)] flex flex-col items-center">
      <div className="flex gap-[min(2.64vw,27px)] ml-[min(1.56vw,16px)]">
        <MenuBtn
          onClick={() => {
            navigate(-1);
          }}
          className={
            "aspect-[115/80] w-[min(11.23vw,115px)] shadow-menu-btn btn-main-active pt-[min(1.63vw,16.69px)] pb-0"
          }
          content={
            <div className="flex flex-col items-center gap-[min(1.17vw,12px)]">
              <img
                className="aspect-[39/29] w-[min(3.81vw,39px)] object-contain"
                src={"/img/icons/icon-arrow-back.svg"}
                alt=""
              />
              <div className="text-14 leading-[1.4] text-[#FFF] font-[400] whitespace-nowrap">
                {"กลับช่องทางหลัก"}
              </div>
            </div>
          }
        />
        {btnList &&
          btnList.map((value, index) => {
            return (
              <MenuBtn
                key={index}
                onClick={() => setCurrentMenu(index)}
                className={`aspect-[115/80] w-[min(11.23vw,115px)] shadow-menu-btn ${
                  currentMenu === index ? "bg-[#DFF7E9]" : "bg-[#FFF]"
                } menu-btn-active ${value.disable && "opacity-50"}`}
                content={
                  <div className="flex flex-col items-center gap-[min(1.17vw,12px)]">
                    <img
                      className="aspect-[39/29] w-[min(3.81vw,39px)] object-contain"
                      src={value.icon}
                      alt=""
                    />
                    <div className="text-14 leading-[1.4] text-[#606060] font-[600] whitespace-nowrap">
                      {value.btnTxt}
                    </div>
                  </div>
                }
                disabled={value.disable}
              />
            );
          })}
      </div>
      {currentMenu !== undefined && (
        <div className="mt-[min(3.03vw,31px)] w-[min(60.55vw,620px)]">
          <div className=" p-[min(2.44vw,25px)] pb-[min(1.46vw,15px)]">
            {currentMenu !== 2 && (
              <div className="flex justify-between">
                <div className="text-20 leading-[2] font-[700] w-[min(26.86vw,275px)]">
                  {currentEvent.headTxt}
                  {/* <span className="ml-[10px]">1</span> */}
                  <span className="ml-[10px]">{currentMenu == 0 ? drawerCount.deposit : currentMenu == 1 ? drawerCount.withdraw : ""}</span>
                </div>
                <div className="text-20 leading-[2] font-[700] min-w-[min(22.07vw,226px)]">
                  ยอดเงินตั้งต้นเดิม
                  {/* <span className="ml-[5px]"> 2,500</span> */}
                  <span className="ml-[5px]"> {initPOSActivity.cash_total}</span>
                </div>
              </div>
            )}
            {currentMenu === 2 && (
              <div className="mb-[min(1.56vw,16px)]">
                <div className="bg-[#EDEDED] w-full h-[min(0.10vw,1px)]"></div>
                <div className="leading-[2] text-20 font-[700]">
                  {currentEvent.headTxt}
                </div>
              </div>
            )}

            <div>
              <MoneyCountController
                startValueObj={{
                  startValue: startValue,
                  setStartValue: setStartValue,
                }}
                info={moneyCountConInfo}
                viaPaymentInfoObj={{
                  viaPaymentInfo: viaPaymentInfo,
                  setViaPaymentInfo: setViaPaymentInfo,
                }}
                isResetObj={{
                  isReset: isResetMoneyController,
                  setIsReset: setIsResetMoneyController,
                }}
                isCloseBalance={currentMenu === 2}
                isCloseBalanceCfObg={{
                  isCloseBalanceConfirm: isCloseBalanceConfirm,
                  setIsCloseBalanceConfirm: setIsCloseBalanceConfirm,
                }}
                isPOSClosure={currentMenu == 2}
                posClosureSummary={summary}
                drawerMenu={currentMenu}
              />

              <div className="bg-[#EDEDED] w-full h-[min(0.10vw,1px)] mt-[min(1.96vw,20.12px)]"></div>

              {currentMenu !== 2 && (
                <div className="flex justify-center">
                  <CustomBtn
                    onClick={handleOnSubmit}
                    className={`leading-[0.8] min-w-[min(24.71vw,253px)] text-white font-[400] text-16 min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] mt-[min(1.56vw,16px)] mb-[min(0.98vw,10px)] ${
                      isBtnActive ? "btn-main-active" : "disabled-btn"
                    }`}
                    btntxt={currentEvent.btnTxt}
                  />
                </div>
              )}

              {currentMenu === 2 && (
                <>
                  <div className="text-20 leading-[2]">ช่องทางการชำระเงิน</div>
                  <div className="flex text-18 leading-[1.1] gap-[min(3.03vw,31px)]">
                    <div className="w-[min(19.43vw,199px)] mt-[min(2.64vw,27px)] flex-grow-0 flex flex-col gap-[min(1.76vw,18px)]">
                      <div className="flex justify-between">
                        <div className="text-[#606060]">เงินโอน</div>
                        <div className="text-[var(--color-green)]">
                          {/* {numberWithTwoDecimals(viaPaymentInfo.transferTotal)} */}
                          {numberWithTwoDecimals(summary?.totalTransfer)}
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="text-[#606060]">บัตรเครดิต</div>
                        <div className="text-[var(--color-green)]">
                          {numberWithTwoDecimals(summary?.totalCredit)}
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="text-[#606060]">เดลิเวอรี่</div>
                        <div className="text-[var(--color-green)]">
                          {numberWithTwoDecimals(summary?.totalDelivery)}
                        </div>
                      </div>
                    </div>
                    <div className="mt-[min(2.64vw,27px)] flex-grow flex flex-col gap-[min(0.88vw,9px)]">
                      <div className="flex justify-between pr-[min(7.42vw,76px)]">
                        <div className="text-[#606060]">เงินสด</div>
                        <div className="text-[var(--color-green)]">
                          {/* {numberWithTwoDecimals(viaPaymentInfo.cashTotal)} */}
                          {numberWithTwoDecimals(summary?.totalCash)}
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="text-[#606060]">ยอดนับเงินสด</div>
                        <div className="flex items-center gap-[min(1.27vw,13px)]">
                          <div className="w-[min(13.67vw,140px)]">
                            <CustomInput
                              inputprops={{
                                type: "text",
                                className:
                                  "modal-input text-18 disabled-input text-[var(--color-green)]",
                                readOnly: true,
                                value: numberWithTwoDecimals(startValue),
                                min: 0,
                                onClick: (e) => {
                                  const input = e.target;
                                  input.select();
                                },
                              }}
                            />
                          </div>

                          <CustomBtn
                            onClick={onClosureCashConfirmBtnHandler}
                            className={`leading-[0.8] w-[min(5.96vw,61px)] text-white font-[400] text-16 h-[min(2.93vw,30px)] rounded-[min(0.78vw,8px)] btn-main-active`}
                            btntxt={"ยืนยัน"}
                          />
                        </div>
                      </div>
                      <div className="flex justify-between pr-[min(7.42vw,76px)]">
                        <div className="text-[#606060]">Diff</div>
                        <div className="w-[min(13.67vw,140px)]">
                          <CustomInput
                            inputprops={{
                              type: "text",
                              className: `modal-input text-18 disabled-input ${
                                viaPaymentInfo.diffTotal < 0
                                  ? "text-[var(--color-red)]"
                                  : "text-[var(--color-green)]"
                              }`,
                              readOnly: true,
                              // value: `${
                              //   isCloseBalanceConfirm
                              //     ? numberWithTwoDecimals(
                              //         viaPaymentInfo.diffTotal
                              //       )
                              //     : "-"
                              // }`,
                              value: `${
                                isCloseBalanceConfirm
                                  ? numberWithTwoDecimals(
                                      closureDiff
                                    )
                                  : "-"
                              }`,
                              min: 0,
                              onClick: (e) => {
                                const input = e.target;
                                input.select();
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  { warningText &&
                    <div className="flex justify-center mt-[min(2.44vw,25px)]">
                      <p className="font-bold text-[var(--color-red)]">มีรายการบิลขายที่ยังไม่ชำระเงิน กรุณาปิดบิลขายทั้งหมด</p>
                    </div>
                  }
                  <div className="flex justify-center mt-[min(2.44vw,25px)]">
                    <CustomBtn
                      onClick={() => {
                        setModalPinOpen(true);
                      }}
                      className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-white font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] ${
                        isCloseBalanceConfirm
                          ? "btn-error-active"
                          : "disabled-btn"
                      }`}
                      btntxt={"ปิดยอด"}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <ModalPin
        modalOpenObj={{
          modalOpen: modalPinOpen,
          setModalOpen: setModalPinOpen,
        }}
        modalSuccessOpenObj={{
          modalOpen: currentMenu !== 2 ? modalSuccessOpen : modalCloseBalanceCf,
          setModalOpen:
            currentMenu !== 2 ? setModalSuccessOpen : setModalCloseBalanceCf,
        }}
        onPinSubmitHandler={onPinSubmitHandler}
      />
      <ModalSuccessMoneyDrawer
        modalOpenObj={{
          modalOpen: modalSuccessOpen,
          setModalOpen: setModalSuccessOpen,
        }}
        startValueObj={{
          startValue: startValue,
          setStartValue: setStartValue,
        }}
        isResetMoneyControllerObj={{
          isReset: isResetMoneyController,
          setReset: setIsResetMoneyController,
        }}
        info={{
          headModalTxt: currentEvent.headModalTxt,
          billCountTxt: currentEvent.billCountTxt,
          billCreateAtTxt: currentEvent.billCreateAtTxt,
          billTotalTxt: currentEvent.billTotalTxt,
          billTotalNoteTxt: currentEvent.billTotalNoteTxt,
          currentMenu: currentMenu,
          drawerCount: drawerCount,
        }}
      />
      <ModalCloseBalanceCf
        info={{
          diffTotal: viaPaymentInfo.diffTotal,
        }}
        modalOpenObj={{
          modalOpen: modalCloseBalanceCf,
          setModalOpen: setModalCloseBalanceCf,
        }}
        onConfirmBtnHandler={onConfirmBtnHandler}
      />
      <PreCloseBalancePrint />
    </div>
  );
}
