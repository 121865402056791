import React, { useEffect, useState } from "react";

// import Calculate from "components/layouts/payment/Calculator";
import Calculate from "components/layouts/payment/CalculatorCredit";
import Formmoneypay from "components/layouts/payment/Inputmoneypay";
import { nanoid } from 'nanoid'

const bankLogoList = [
    { "id": 1, "name": "ธนาคารกสิกรไทย", "logo": "/img/payment/bank/kbank.png" },
    { "id": 2, "name": "ธนาคารกรุงศรีอยุธยา", "logo": "/img/payment/bank/bay.png" },
    { "id": 3, "name": "ธนาคารกรุงเทพ", "logo": "/img/payment/bank/bbl.png" },
    { "id": 4, "name": "ธนาคารไทยพาณิชย์", "logo": "/img/payment/bank/scb.png" },
    { "id": 5, "name": "ธนาคารกรุงไทย", "logo": "/img/payment/bank/ktb.png" },
];

export default function  PayCredit({
    moneypayObj,
    eObj,
    PaymentDetailObj,
    btnObj,
    Payment,
    providerObj,
    modalSuccessOpenObj,
    creditObj,
    paymentCreditList,
    deliveryObj,
    ...props
}) {
    
    // const [bankList, setBankList] = useState([
    //     { "id": 1, "name": "ธนาคารกสิกรไทย", "logo": "/img/payment/bank/kbank.png" },
    //     { "id": 2, "name": "ธนาคารกรุงศรีอยุธยา", "logo": "/img/payment/bank/bay.png" },ธนาคารไทยพาณิชย์
    //     { "id": 3, "name": "ธนาคารกรุงเทพ", "logo": "/img/payment/bank/bbl.png" },
    //     { "id": 4, "name": "ธนาคารไทยพานิชย์", "logo": "/img/payment/bank/scb.png" },
    //     { "id": 5, "name": "ธนาคารกรุงไทย", "logo": "/img/payment/bank/ktb.png" },
    // ]);
    const [bankList, setBankList] = useState([]);
    const [bankSelect, setBankSelect] = useState(null)

    const [amount, setAmount] = useState(Math.abs(PaymentDetailObj.PaymentDetail.paymentRemaining))
    const [feeAmount, setFeeAmount] = useState(0)
    const [remark, setRemark] = useState(null);
    const [netAmount, setNetAmount] = useState(PaymentDetailObj.PaymentDetail.paymentNet);
    const [step, setStep] = useState(1);
    
    const getSum = () => {
        let fee = 0;
        let type = creditObj.creditFeeType
        let feevalue = creditObj.creditFeeValue
        if(type == 1){
            fee = (( amount * feevalue ) / 100).toFixed(2)
        }else if(type == 2){
            fee = feevalue ? parseFloat(feevalue) : 0;
        }
        setFeeAmount(fee)
    }

    const handleAddPayment = () => {
        if(step == 1) {
            setStep(2)
        }

        if(step == 2) {
            if(deliveryObj.deliverySelect){
                var item = {
                "id":   nanoid() ,
                "channel":{"paymentChannelID":4,"paymentChannelName":"Delivery"},
                "subchannel":Payment.PaymentSelect,
                // "amount": parseFloat(amount),
                "amount": parseFloat(moneypayObj.moneypay),
                "provider":bankSelect,
                "remark":remark,
                "status": 1,
                "feePercent": (creditObj.creditFeeType == 1 && creditObj.creditFeeValue  > 0) ? creditObj.creditFeeValue : 0,
                "feeAmount": feeAmount ?? 0
                }
            } else {
                var item = {
                    "id":  nanoid() ,
                    "channel":Payment.PaymentSelect,
                    "subchannel":null,
                    // "amount": parseFloat(amount),
                    "amount": parseFloat(moneypayObj.moneypay),
                    "provider":bankSelect,
                    "remark":remark,
                    "status": 1,
                    "feePercent": (creditObj.creditFeeType == 1 && creditObj.creditFeeValue  > 0) ? creditObj.creditFeeValue : 0,
                    "feeAmount": feeAmount ?? 0
                }
            }
            moneypayObj.setMoneypay('0.00')
            Payment.setPaymentObj( (prev) => [ ...prev,item ])
            
            var remain = parseInt(parseFloat(moneypayObj.moneypay) - Math.abs(PaymentDetailObj.PaymentDetail.paymentRemaining));
            if(remain >= 0) {
                // console.log('ready yo pay')
                Payment.setPaymentSelect(prev => ({ ...prev, paymentChannelID: "1" }))
            }
        }
        // moneypayObj.setMoneypay('0.00')
        // // if(parseFloat(netAmount) >= Math.abs(PaymentDetailObj.PaymentDetail.paymentRemaining)) {
        // //     console.log('whole')
        // //     Payment.setPaymentSelect({"paymentChannelID":"0"})
        // // } else {
        // //     console.log('partial')
        // //     Payment.setPaymentSelect({"paymentChannelID":"1"})
        // // }
        // Payment.setPaymentSelect(prev => ({ ...prev, paymentChannelID: "1" }))
    }

    useEffect(() => {
        return () => {
            setStep(1);
            moneypayObj.setMoneypay('0.00');
            creditObj.setCreditFeeType(0);
            creditObj.setCreditFeeValue(0);
        }
    }, []);

    useEffect(() => {
        // getSum()
        if(creditObj.creditFeeType != 1 || creditObj.creditFeeType != 2) {
            setFeeAmount(parseFloat(creditObj.creditFeeValue))
            // getSum()
        } else {
            setFeeAmount(0)
            // getSum()
        }
        getSum()
    }, [creditObj]);
    
    useEffect(() => {
        setNetAmount((parseFloat(amount) + parseFloat(feeAmount)).toFixed(2))
    }, [feeAmount]);

    useEffect(() => {
        if(paymentCreditList && paymentCreditList.length > 0) {
            const newBankList = [];
            for (const pb of paymentCreditList) {
                // console.log("🚀 ~ file: Tranfers.jsx:44 ~ useEffect ~ pb:", pb)
                const bankLogo = bankLogoList.filter(blg => blg.name.includes(pb.bank_title))[0];
                // console.log("🚀 ~ file: Tranfers.jsx:46 ~ useEffect ~ bankLogo:", bankLogo)
                newBankList.push({
                    ...pb,
                    logo: bankLogo.logo
                });
            }
            setBankList(newBankList);
        }
    }, [paymentCreditList])
    
    // useEffect(() => {
    //   setNetAmount((parseFloat(amount) + parseFloat(feeAmount)).toFixed(2))
    // }, [amount])

    useEffect(() => {
        if(bankList && bankList.length > 0 && !bankSelect) {
            setBankSelect(bankList[0])
        }
    }, [bankList])

    return (
        <div className="d-flex flex-column h-100">
            <div>
                {
                    deliveryObj.deliverySelect ? (
                        <button 
                            type="button" 
                            className="fs-12 mb-2" 
                            style={{color:'#0760FC'}}
                            onClick={() => { 
                                deliveryObj.setDeliverySelect(null)
                                Payment.setPaymentObj([])
                            }}
                        >{`<<`} ล้างรายการ</button>
                    ) : ''
                }
            </div>
            <div className="d-flex align-items-center text-[#061126] mb-1">
                <div className="me-auto">
                <span className="fs-18 fw-500">Order NO.</span>
                <span className="fs-14 fw-500 ms-2">{PaymentDetailObj.PaymentDetail.orderNo}</span>
                </div>
                <div className="fs-18 fw-bold text-[#2DC76D]">ชำระผ่านบัตรเครดิต</div>
            </div>
            {
                deliveryObj.deliverySelect ? (
                    <div className="fs-14 fw-500 mb-0 d-flex align-items-center"> 
                        <span className="me-2"> กรุณาระบุยอดเงินรับชำระของ </span> 
                        <img src={deliveryObj.deliverySelect.logo} style={{width: '26px',height: '26px',objectFit:'cover',borderRadius:'5px'}} />
                    </div>
                ) : ''
            }

            <div className="row mb-4 mt-2">
                <div className="col-6">
                    <div className="fs-12 mb-1">ยอดเงิน</div>
                    <input 
                        type="number"
                        className="form-control form-st-4 border-0 text-end fs-24 fw-500" 
                        style={{background: '#FFF',borderRadius:'8px',filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14))'}} 
                        defaultValue={parseFloat(moneypayObj.moneypay).toFixed(2)}
                        value={parseFloat(moneypayObj.moneypay).toFixed(2)}
                        max={PaymentDetailObj.PaymentDetail.paymentNet}
                        // onChange={(e) => setAmount(e.target.value)}
                        onChange={(e) => moneypayObj.setMoneypay(e.target.value)}
                    />
                </div>
                <div className="col-6">
                    <div className="fs-12 mb-1">ยอดเงินที่ต้องรับชำระ</div>
                    <input 
                        className="form-control form-st-4 border-0 text-end fs-24 fw-500" 
                        style={{
                            background: step == 1 ? '#FFF' : step == 2 ? 'lightred' : '#FFF',
                            borderRadius: '8px',
                            filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14))'
                        }} 
                        disabled={true}
                        // value={ (parseFloat(amount) + parseFloat(feeAmount)).toFixed(2) }
                        // value={ netAmount ? parseFloat(netAmount).toFixed(2) : "0.00" }
                        // value={(parseFloat(PaymentDetailObj.PaymentDetail.paymentNet) - parseFloat(PaymentDetailObj.PaymentDetail.paymentTotal) + parseFloat(feeAmount)).toFixed(2)}
                        value={(parseFloat(Math.abs(PaymentDetailObj.PaymentDetail.paymentRemaining)) + parseFloat(feeAmount)).toFixed(2)}
                    />
                </div>
            </div>

            { 
                step == 1 &&
                <>
                    <div className="fs-12 fw-500 text-[#061126] mb-2">กรุณาเลือกธนาคารรับเงิน</div>
                    <div className="bx-paylist p-3 overflow-auto mb-3" style={{ background: '#FFF',filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14))',borderRadius:'8px',height:'250px' }}>
                        <div className="row gx-3">
                            {
                                bankList && bankList.map((item, index) => 
                                    <div className="col-6" key={item.id}>
                                        <div className={`d-flex align-items-center card-bank ${ bankSelect && item.id == bankSelect.id ? 'active' : ''  } mb-3`} style={{cursor:'pointer'}}
                                        onClick={() => setBankSelect(item) }
                                        >
                                            <img src={item.logo} style={{width: '32px',height: '32px',objectFit:'cover',borderRadius:'50%'}} />
                                            <div className="text-[#061126] fs-14 ms-3">{item.bank_title ? item.bank_title : item.name}</div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="mb-4 mb-auto">
                        <div className="fs-16 fw-500 text-[#061126] mb-3">Remark</div>
                        <textarea 
                        onChange={(e) => setRemark(e.target.value)}
                        className="form-control border-0" 
                        style={{background: '#FFF',borderRadius:'8px',filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14))'}}
                        ></textarea>
                    </div>
                </>
            }

            {
                step == 2 &&
                <>
                    {/* <div className="py-2">
                        <Formmoneypay 
                        moneypay={moneypayObj.moneypay}
                        setMoneypay={moneypayObj.setMoneypay}
                        />
                    </div> */}
                    <Calculate 
                        setMoneypay={moneypayObj.setMoneypay}
                        moneypay={moneypayObj.moneypay}
                        PaymentDetail={PaymentDetailObj.PaymentDetail}
                        feeAmount={feeAmount}
                    />
                </>
            }
            
            <div className="d-flex align-items-center justify-content-start pt-2">
                <button className=" btn btn-st-q1 px-5"
                onClick={handleAddPayment}
                disabled={ !bankSelect || (step == 2 && Number(moneypayObj.moneypay) == 0)}
                >Enter
                </button>
            </div>
        </div>
    )

}

const AddAmount = ({moneypayObj,eObj,PaymentDetailObj,btnObj,DeliveryDetailObj}) => {
    return (
        <div>
            {/* <div className="d-flex align-items-center text-[#061126] mb-1">
                <div className="me-auto">
                <span className="fs-18 fw-500">Order NO.</span>
                <span className="fs-14 fw-500 ms-2">{PaymentDetailObj.PaymentDetail.orderNo}</span>
                </div>
                <div className="fs-18 fw-bold text-[#2DC76D]">ชำระเงินปกติ</div>
            </div> */}
            {/* <div className="fs-14 fw-500 mb-3">กรุณาระบุยอดเงินรับชำระ</div> */}
            <div className="py-2">
                <Formmoneypay 
                moneypay={moneypayObj.moneypay}
                setMoneypay={moneypayObj.setMoneypay}
                />
            </div>

            <Calculate 
                setMoneypay={moneypayObj.setMoneypay}
                moneypay={moneypayObj.moneypay}
                PaymentDetail={PaymentDetailObj.PaymentDetail}
            />

            <div className="d-flex align-items-center  pt-2">
                <button className=" btn btn-st-q1 me-auto px-4"
                onClick={eObj.handleAddPayment}
                disabled={btnObj.BtnenterDisabled || Number(moneypayObj.moneypay) == 0}
                >Enter
                </button>
                <button className="btn btn-st-q1"
                onClick={eObj.handlePayment}
                disabled={btnObj.BtnpaymentDisabled}
                >ชำระเงิน</button>
            </div>
        </div>
    )
}