import React, { useEffect, useState } from "react";

import Calculate from "components/layouts/payment/Calculator";
import Formmoneypay from "components/layouts/payment/Inputmoneypay";
import { nanoid } from 'nanoid'
export default function  PayCash({
    moneypayObj,
    eObj,
    PaymentDetailObj,
    btnObj,
    Payment,
    deliveryObj,
    ...props
    }) {


    return (
        <>

        {
            deliveryObj.deliverySelect ? (
                <button 
                    type="button" 
                    className="fs-12 mb-1" 
                    style={{color:'#0760FC'}}
                    onClick={() => { 
                        deliveryObj.setDeliverySelect(null)
                        Payment.setPaymentObj([])
                    }}
                >{`<<`} ล้างรายการ</button>
            ) : ''
        }
        <div className="d-flex align-items-center text-[#061126] mb-1">
            <div className="me-auto">
            <span className="fs-18 fw-500">Order NO.</span>
            <span className="fs-14 fw-500 ms-2">{PaymentDetailObj.PaymentDetail.orderNo}</span>
            </div>
            <div className="fs-18 fw-bold text-[#2DC76D]">ชำระเงินสด</div>
        </div>

        {
            deliveryObj.deliverySelect ? (
                <div className="fs-14 fw-500 mb-1 d-flex align-items-center"> 
                    <span className="me-2"> กรุณาระบุยอดเงินรับชำระของ </span> 
                    <img src={deliveryObj.deliverySelect.logo} style={{width: '26px',height: '26px',objectFit:'cover',borderRadius:'5px'}} />
                </div>
            ) : (
                <div className="fs-14 fw-500 mb-3">กรุณาระบุยอดเงินรับชำระ</div>     
            )
        }
        
        <div className="py-2">
            <Formmoneypay 
            moneypay={moneypayObj.moneypay}
            setMoneypay={moneypayObj.setMoneypay}
            />
        </div>

        <Calculate 
            setMoneypay={moneypayObj.setMoneypay}
            moneypay={moneypayObj.moneypay}
            PaymentDetail={PaymentDetailObj.PaymentDetail}
        />
        
        <div className="d-flex align-items-center pt-2">
            <button className="me-auto btn btn-st-q1 px-5"
            onClick={eObj.handleAddPayment}
            disabled={btnObj.BtnenterDisabled || Number(moneypayObj.moneypay) == 0}
            >Enter
            </button>
            <button className="btn btn-st-q1"
            onClick={eObj.handlePayment}
            disabled={btnObj.BtnpaymentDisabled}
            >ชำระเงิน</button>
        </div>
        </>
    )

}

