import axiosInstance from './axios';
import { jwtDecode }  from 'jwt-decode';

export const authLogin = async (auth, slug, slugBranch) => {
    try {
        let decoded = null;

        const payload = {
            user_name: auth.emailOrPhone,
            password: auth.password,
            slug: slug,
            slugBranch: slugBranch
        }

        const response = await axiosInstance.post(
            '/auth/login',
            payload,
            {
                'Content-Type': 'application/json'
            }
        );

        if(response.data.accessToken) {
            window.localStorage.setItem('allDerCafeToken', response.data.accessToken);
        }

        decoded = jwtDecode(response.data.accessToken);
        // console.log("🚀 ~ authLogin ~ decoded:", decoded)

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        const user = {
            accountId: decoded.accountId
        };

        if(decoded.employee) {
            user.employeeId = decoded.employee.id;
            user.firstNameTH = decoded.employee.first_name_th;
            user.lastNameTH = decoded.employee.last_name_th;
            user.firstNameEN = decoded.employee.first_name_en;
            user.lastNameEN = decoded.employee.last_name_en;
            user.nickName = decoded.employee.nickname;
            user.branchId = decoded.employee.EmployeePermission[0].branch_id;
            user.employeeBranchAccountId = decoded.employee.EmployeePermission[0].EmployeeBranchAccount[0].id;
            user.employeePermissionId = decoded.employee.EmployeePermission[0].id;
            user.employeeCode = decoded.employee.code;
            user.businessId = decoded.employee.business_id;
        }

        return {
            token: response.data.accessToken,
            user: user
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const authRefreshToken = async (token, client) => {
    try {
        let decoded = null;

        const payload = {
            token: token,
            client: client
        }

        const response = await axiosInstance.post(
            '/auth/refresh-token',
            payload,
            {
                'Content-Type': 'application/json'
            }
        );

        if(response.data.accessToken) {
            window.localStorage.setItem('allDerCafeToken', response.data.accessToken);
        }

        decoded = jwtDecode(response.data.accessToken);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        const user = {
            accountId: decoded.accountId
        };

        if(decoded.employee) {
            user.employeeId = decoded.employee.id;
            user.firstNameTH = decoded.employee.first_name_th;
            user.lastNameTH = decoded.employee.last_name_th;
            user.firstNameEN = decoded.employee.first_name_en;
            user.lastNameEN = decoded.employee.last_name_en;
            user.nickName = decoded.employee.nickname;
            user.branchId = decoded.employee.EmployeePermission[0].branch_id;
            user.employeeBranchAccountId = decoded.employee.EmployeePermission[0].EmployeeBranchAccount[0].id;
            user.employeePermissionId = decoded.employee.EmployeePermission[0].id;
            user.employeeCode = decoded.employee.code;
            user.businessId = decoded.employee.business_id;
        }

        return {
            token: response.data.accessToken,
            user: user
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getBranchById = async (branchId) => {
    try {
        const response = await axiosInstance.get(`/branch/${branchId}`);
        const responseFinancial = await axiosInstance.get(`/branch/financial/${branchId}`);

        return {
            branch: {...response.data, financial: responseFinancial.data.business.businessfinancialdetail}
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const authPin = async (employeeId, employeePin) => {
    try {
        let decoded = null;

        const payload = {
            employeeId: employeeId,
            employeePin: employeePin
        }

        const response = await axiosInstance.post(
            '/auth/pin-auth',
            payload,
            {
                'Content-Type': 'application/json'
            }
        );

        if(response.data.accessToken) {
            window.localStorage.setItem('allDerCafeToken', response.data.accessToken);
        }

        decoded = jwtDecode(response.data.accessToken);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        const user = {
            accountId: decoded.accountId
        };

        if(decoded.employee) {
            user.employeeId = decoded.employee.id;
            user.firstNameTH = decoded.employee.first_name_th;
            user.lastNameTH = decoded.employee.last_name_th;
            user.firstNameEN = decoded.employee.first_name_en;
            user.lastNameEN = decoded.employee.last_name_en;
            user.nickName = decoded.employee.nickname;
            user.branchId = decoded.employee.EmployeePermission[0].branch_id;
            user.employeeBranchAccountId = decoded.employee.EmployeePermission[0].EmployeeBranchAccount[0].id;
            user.employeePermissionId = decoded.employee.EmployeePermission[0].id;
            user.employeeCode = decoded.employee.code;
            user.businessId = decoded.employee.business_id;
        }

        return {
            token: response.data.accessToken,
            user: user
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}