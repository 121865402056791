import React, { useEffect, useState } from "react";
import SelectStaffCard from "components/layouts/SelectStaffCard";
import { numberWithTwoDecimals } from "utils/numberFormat";

export default function BillDetail({
  billID,
  staffObj,
  selectedUserObj,
  userInfoObj,
  order,
  pos,
  branch,
  onPrintBtn,
  endBillDiscount,
  ...props
}) {
  // console.log("🚀 ~ order:", order)
  // console.log("🚀 ~ file: BillDetail.jsx:12 ~ userInfoObj:", userInfoObj)
  // console.log("🚀 ~ file: BillDetail.jsx:12 ~ selectedUserObj:", selectedUserObj)
  // console.log("🚀 ~ file: BillDetail.jsx:12 ~ staffObj:", staffObj)
  const [bill, setBill] = useState(order ? {
    branch: "",
    pos: "",
    // staff: userInfoObj?.userInfo?.fullName ?? "",
    staff: order.employee? `${order.employee.first_name_th} ${order.employee.last_name_th}` : "-",
    orderDateTime: new Date(order.operation_date).toLocaleTimeString(),
    ...order,
    // orderDate: order.orderDate ? order.orderDate : new Date(order.operation_date).toLocaleTimeString(),
  } : null);
  // console.log("🚀 ~ file: BillDetail.jsx:13 ~ bill:", bill)

  const getBillData = () => {
    fetch("json/bill2.json")
      .then((response) => response.json())
      .then((result) => setBill(result))
      .catch((error) => console.log("error", error));
  };

  // useEffect(() => {
    // getBillData(billID);
  // }, [billID]);

  useEffect(() => {
    setBill({
      branch: order.branch?.name ? order.branch?.name : branch ? branch.name : null,
      pos: "",
      // staff: userInfoObj?.userInfo?.fullName ?? "",
      staff: order.employee? `${order.employee.first_name_th} ${order.employee.last_name_th}` : "-",
      // orderDateTime: new Date(order.operation_date).toLocaleTimeString(),
      orderDateTime: new Date(order.start_time).toLocaleTimeString(),
      ...order,
      // orderDate: order.orderDate ? order.orderDate : new Date(order.operation_date).toLocaleTimeString(),
    });
  }, [order]);

  return (
    <div>
      {bill ? (
        <>
          <div
            className="pe-2 bx-scroll overflow-auto mb-2"
            style={{ height: "calc(100vh - 370px)" }}
          >
            <div
              className="bg-[#F4F5F6] p-4"
              style={{ minHeight: "calc(100vh - 370px)" }}
            >
              {/* <div className="text-center fs-24 text-[#4A4A4A]">โรงคั่วกาแฟพะเยา</div> */}
              <div className="text-center fs-24 text-[#4A4A4A]">{branch?.business?.businessdetail?.name ?? ""}</div>
              <div className="text-center text-[#4A4A4A] fs-14 fw-500 mb-4">
                {" "}
                Order : {bill.orderNo}{" "}
              </div>

              <div className="d-flex align-items-center fs-14 mb-1">
                <div className="me-auto">สาขา :</div>
                <div className="">{bill.branch}</div>
              </div>
              <div className="d-flex align-items-center fs-14 mb-1">
                <div className="me-auto">POS ID :</div>
                <div className="">{bill.pos_id ? bill.pos_id : bill.pos.id}</div>
              </div>
              <div className="d-flex align-items-center fs-14 mb-1">
                <div className="me-auto">ชื่อพนักงาน : </div>
                {/* <div className="">{bill.staff ? bill.staff : userInfoObj?.userInfo?.fullName ? userInfoObj?.userInfo?.fullName : "-"}</div> */}
                {/* <div className="">{bill.staff ? bill.staff : "-"}</div> */}
                <div className="">{order?.employee? `${order.employee.first_name_th} ${order.employee.last_name_th}` : "-"}</div>
              </div>
              <div className="d-flex align-items-center fs-14">
                <div className="me-auto">เวลา :</div>
                {/* <div className=""> {bill.orderDate}</div> */}
                <div className=""> {bill.orderDateTime}</div>
              </div>

              <hr className="my-2" />

              {bill.product &&
                bill.product.filter(pd => pd.productStatus != 3).map((item, index) => (
                  <div
                    className="d-flex align-items-start mb-2 fs-14 "
                    key={item.id}
                  >
                    <div className="flex-fill">
                      <div>
                        {item.productQty} x {item.productName}{" "}
                      </div>
                      <div className="ps-3 my-1">
                        {item.optionstandard &&
                          item.optionstandard.map((item2, index) => (
                            <div className="mb-1" key={item2.listID}>
                              - {item2.listName} {item2.optionQty > 1 && `x${item2.optionQty}`}
                            </div>
                          ))}
                        {item.optionproduct &&
                          item.optionproduct.map((item2, index) => (
                            <div className="mb-1" key={item2.listID}>
                              - {item2.listName} {item2.optionQty > 1 && `x${item2.optionQty}`}
                            </div>
                          ))}
                        {/* {item.optionnote &&
                          item.optionnote.map((item2, index) => (
                            <span key={item2.listID}>{item2.listName}{index != item.optionnote.length - 1 && ", "}</span>
                          ))} */}
                        {item.note}
                      </div>
                      {/* <div className="">
                        {item.optionnote &&
                          item.optionnote.map((item2, index) => (
                            <span key={item2.listID}>{item2.listName}{index != item.optionnote.length - 1 && ", "}</span>
                          ))}
                        {item.note}
                      </div> */}
                    </div>
                    <div>{numberWithTwoDecimals(item.productSumPrice)}฿</div>
                  </div>
                ))}

              <hr className="my-2" />

              <div className="d-flex align-items-center fs-14 mb-1">
                <div className="me-auto">ยอดรวม </div>
                <div className="">
                  {bill ? numberWithTwoDecimals(bill.orderPrice.sumprice) : "0.00"}฿
                </div>
              </div>
              <div className="d-flex align-items-center fs-12 mb-1">
                <div className="me-auto">ส่วนลด </div>
                <div className="">
                  {/* {bill ? numberWithTwoDecimals(bill.orderPrice.discount) : "0.00"}฿ */}
                  {numberWithTwoDecimals(endBillDiscount.value + endBillDiscount.discountFromPoint)}฿
                </div>
              </div>
              <div className="d-flex align-items-center fs-12 mb-1">
                <div className="me-auto">ส่วนลดหลังปัดเศษ </div>
                <div className="">
                  {/* {bill ? numberWithTwoDecimals(bill.orderPrice.discount) : "0.00"}฿ */}
                  {numberWithTwoDecimals(endBillDiscount.valueRound + Math.ceil(endBillDiscount.discountFromPoint))}฿
                </div>
              </div>
              <div className="d-flex align-items-center fs-12 mb-1">
                <div className="me-auto">ภาษี </div>
                <div className="">{bill ? numberWithTwoDecimals(bill.orderPrice.vat) : "0.00"}฿</div>
              </div>
              <div className="d-flex align-items-center fs-14">
                <div className="me-auto">ยอดรวมทั้งหมด </div>
                <div className="">{bill ? numberWithTwoDecimals(bill.orderPrice.net - endBillDiscount.valueRound - endBillDiscount.discountFromPoint) : "0.00"}฿ </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center pt-2">
            {order?.employee ? (
              <div className="card-staff me-auto">
                <div className="flex gap-[min(1.46vw,15px)]">
                  <img
                    className="aspect-square w-[min(3.91vw,40px)] rounded-full object-contain"
                    src="/img/avartar-male.png"
                    alt=""
                  />
                  <div className="text-[#4c4e64] opacity-87 leading-[1.4] text-14 font-[400] tracking-[0.015em]">
                    {/* <div>{userInfoObj.userInfo.fullName}</div> */}
                    <div>{`${order.employee.first_name_th} ${order.employee.last_name_th}`}</div>
                    <div className="opacity-60">
                      {/* {userInfoObj.userInfo.code} */}
                      {order.employee.code}
                    </div>
                  </div>

                  <button
                    className="btn btn-edit-st-2 align-self-end"
                    onClick={() => staffObj.setStaffSelect(true)}
                  ></button>
                </div>
              </div>
            ) : (
              <button
                className="btn me-auto  btn-x1 text-[#FFF] bg-[#2DC76D]"
                onClick={() => {
                  selectedUserObj.setSelectedUser(userInfoObj);
                  staffObj.setStaffSelect(true);
                }}
              >
                เพิ่มพนักงานขาย
              </button>
            )}

            <button 
              className="btn btn-x1 btn-x1-print pe-4 ps-5 text-[#FFF] "
              onClick={onPrintBtn}
            >
              พิมพ์
            </button>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
