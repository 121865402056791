import React, { useState } from "react";
import { useAuthLogout } from '../../state/auth';

export default function PosAvartarCard({userInfo}) {
  const [isOpen, setIsOpen] = useState(false);
  const logout = useAuthLogout();

  return (
    <div className="z-[999] text-12 text-[#575B5E] absolute top-[min(1.17vw,12px)] right-[min(0.98vw,10px)] cursor-pointer min-w-[min(18.26vw,187px)]">
      <div>
        <div onClick={() => setIsOpen(!isOpen)} className="flex bg-[#F4F4F4] rounded-[min(0.78vw,8px)] py-[min(0.78vw,8px)] pl-[min(0.39vw,4px)] pr-[min(1.27vw,13px)]">
          <div>
            <img
              className="aspect-square w-[min(3.13vw,32px)] rounded-full object-cover"
              src={userInfo.avartar ?? '-'}
              alt=""
            />
            <div className="relative">
              <div className="absolute aspect-square w-[min(0.94vw,9.6px)] bg-[#72E128] border-[min(0.20vw,2px)] border-white rounded-full  bottom-0 right-0"></div>
            </div>
          </div>
          <div className="ml-[min(1.56vw,16px)]">
            <div>{userInfo.fullName ?? '-'}</div>
            <div>{userInfo.code ?? '-'}</div>
          </div>
          <div className="aspect-[9/15] w-[min(0.88vw,9px)] ml-[min(1.17vw,12px)]">
            <img
              className="w-full h-full object-contain"
              src="/img/icons/icon-arrow-down.svg"
              alt=""
            />
          </div>
        </div>
        {isOpen && (
          <button 
            className="text-18 font-[500] text-[#4A4A4A] rounded-[min(0.78vw,8px)] w-full pl-[min(1.37vw,14px)] py-[min(0.88vw,9px)] shadow-logout-avartar bg-white flex items-center gap-[min(1.07vw,11px)] logout-avartar"
            onClick={() => logout()}
          >
            <img
              className="aspect-square w-[min(1.95vw,20px)] object-contain"
              src="/img/icons/icon-logout.svg"
              alt=""
            />
            <div>ออกจากระบบ</div>
          </button>
        )}
      </div>
    </div>
  );
}
