import React, { useEffect, useState } from "react";

export default function DiscountTag({ data, handleOnClick, isActive = false }) {
  const [theme, setTheme] = useState({
    backgroundColor: isActive ? "bg-[#F95057]" : "bg-[#FFD8DA]",
    color: isActive ? "text-white" : "text-[#F95057]",
  });

  useEffect(() => {
    setTheme({
      backgroundColor: isActive ? "bg-[#F95057]" : "bg-[#FFD8DA]",
      color: isActive ? "text-white" : "text-[#F95057]",
    });
  }, [isActive]);
  
  return (
    <div
      onClick={handleOnClick}
      className={`cursor-pointer flex items-center justify-center w-fit px-[min(0.98vw,10px)] h-[min(3.32vw,34px)] ${theme.backgroundColor} ${theme.color} text-18 text-[500] rounded-[min(0.39vw,4px)]`}
    >
      {data.name}
    </div>
  );
}
