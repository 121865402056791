import MenuBtn from "components/fragments/MenuBtn";
import HeaderPos from "components/layouts/HeaderPos";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from 'recoil';
import { appState } from "state/app";

export default function MenuPage() {
  const navigate = useNavigate();
  const { slug, slugBranch } = useRecoilValue(appState);
  const [menuList, setMenuList] = useState([
    {
      icon: "/img/icons/icon-money-bag.svg",
      btnTxt: "ลิ้นชัก",
      // url: `/money-drawer`,
      url: `/${slug}/${slugBranch}/money-drawer`,
      disable: false,
    },
    {
      icon: "/img/icons/icon-chart.svg",
      btnTxt: "สรุปยอดขาย",
      // url: `sales-summary`,
      url: `/${slug}/${slugBranch}/sales-summary`,
      disable: false,
    },
    {
      icon: "/img/icons/icon-medal.svg",
      btnTxt: "สถิติที่น่าสนใจ",
      disable: true,
    },
    {
      icon: "/img/icons/icon-report.svg",
      btnTxt: "รายงาน",
      disable: true,
    },
    {
      icon: "/img/icons/icon-3d-box.svg",
      btnTxt: "คลังสินค้า",
      disable: true,
    },
    {
      icon: "/img/icons/icon-setting.svg",
      btnTxt: "ตั้งค่า",
      disable: true,
    },
    {
      icon: "/img/icons/icon-user.svg",
      btnTxt: "โปรไฟล์",
      disable: true,
    },
  ]);

  // console.log("🚀 ~ MenuPage ~ menuList:", menuList)

  return (
    <div>
      <HeaderPos isNotify={true}/>
      <div className="pl-[min(9.96vw,102px)] pt-[min(2.15vw,22px)]">
        <div className="text-18 font-[500] leading-[1.3] text-[#333]">เมนู</div>
        <div className="mt-[min(3.42vw,35px)] flex flex-wrap gap-[min(2.44vw,25px)]">
          {menuList.map((value, index) => {
            return (
              <MenuBtn
                onClick={() => {
                  if (value.url) {
                    navigate(value.url);
                  }
                }}
                className={
                  `aspect-[190/132] w-[min(18.55vw,190px)] shadow-menu-btn bg-[#FFF] menu-btn-active ${value.disable && "opacity-50"}`
                }
                key={index}
                content={
                  <div className="flex flex-col items-center gap-[min(0.88vw,9px)]">
                    <img
                      className="aspect-square w-[min(5.57vw,57px)] object-contain"
                      src={value.icon}
                      alt=""
                    />
                    <div className="text-20 text-[#333] font-[600]">
                      {value.btnTxt}
                    </div>
                  </div>
                }
                disabled={value.disable}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
