import { Button } from "@mui/material";
import React from "react";

export default function TableZoneBtn({ btnTxt, isActive = false, ...props }) {
  return (
    <Button
      {...props}
      sx={{
        "&:hover": {
          boxShadow: "inherit",
        },
        boxShadow: 0,
      }}
      className={`text-14 font-[300] px-[min(0.49vw,5px)] sarabun min-w-[min(6.35vw,65px)] rounded-[min(0.78vw,8px)] ${
        isActive ? "btn-table-zone-active" : "btn-table-zone-not-active"
      } ${props.className}`}
      variant="contained"
    >
      {btnTxt}
    </Button>
  );
}
