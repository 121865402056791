import { Button } from "@mui/material";
import React from "react";

export default function TableOpenBtn({ btnTxt, isActive = true, ...props }) {
  return (
    <Button
      {...props}
      sx={{
        "&:hover": {
          boxShadow: "inherit",
        },
        boxShadow: 0,
      }}
      className={`text-14 btn-table-open-active font-[300] px-[min(0.49vw,5px)] flex gap-[min(0.39vw,4px)] sarabun min-w-[min(8.98vw,92px)] rounded-[min(0.78vw,8px)] ${
        isActive ? "" : "btn-table-not-active"
      } ${props.className}`}
      variant="contained"
    >
      {btnTxt}
    </Button>
  );
}
