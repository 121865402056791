import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomBtn from "components/fragments/CustomBtn";
import OTPInput from "react-otp-input";
import CustomInput from "components/fragments/CustomInput";
import CustomInputSearch from "components/fragments/CustomInputSearch";
import ProductOrderDetail from "components/layouts/ProductOrderDetail";

export default function ModalCallBill({
  modalSuccessOpenObj,
  modalOpenObj,
  setOrders,
  alertObj,
  bills,
  staffName = "-",
  pos,
  cancelOrder,
  ...props
}) {

  const navigate = useNavigate();
  const [BillStatus, setBillStatus] = useState(1);

  const [billdatalist, setBilldatalist] = useState([]);
  const [billdata, setBilldata] = useState(false);

  const [filterText, setFilterText] = useState("");
  const [billfilter, setBillFilter] = useState([]);

  const getBillDataList = () => {
    fetch("json/bill.json")
      .then((response) => response.json())
      .then((result) => setBilldatalist(result))
      .catch((error) => console.log("error", error));
  };

  // useEffect(() => {
  // getBillDataList();
  // }, []);

  useEffect(() => {
    if (bills && bills.length > 0) {
      const parsed = [...bills].sort((a,b) => b.id - a.id).map((b) => {
        // let productInBill = [];
        // for (let i = 0; i < b.orderDetail.length; i++) {
        //   productInBill.push({
        //     id: b.orderDetail[i].id,
        //       productID: b.orderDetail[i].branch_product.id,
        //       productImage: "img/product/img2.png",
        //       productName:  b.orderDetail[i].branch_product.product.name,
        //       productPrice: b.orderDetail[i].branch_product.price,
        //       productSumPrice: b.orderDetail[i].branch_product.price * b.orderDetail[i].amount,
        //       productQty: b.orderDetail[i].amount,
        //       productStatus: b.status_id != 4 ? b.orderDetail[i].status_id : 4,
        //       optionnote: b.orderDetail[i],
        //       optionproduct: [],
        //       optionstandard: [],
        //       note: "",
        //   })
        // }
        return {
          // ...bills,
          // ...b,
          id: b.id,
          start_time: b.start_time,
          operation_date: b.operation_date,
          billID: b.id,
          receiptNo: b.bill ? b.bill.receipt_no : b.receipt_no ? b.receipt_no : "-",
          pos: pos.name,
          billStatus: b.status_id,
          // staff: staffName,
          staff: b.employee ? `${b.employee.first_name_th} ${b.employee.last_name_th}` : "-",
          customerID: b.member_id ? b.member_id : "-",
          customerName: b.member_id ? b.member.name : "-",
          orderNo: b.order_id,
          // orderDate: "19/09/2023",
          // orderDate: new Date(b.start_time).toLocaleDateString("en-GB"),
          orderDate: new Date(b.start_time).toLocaleString('en-GB').replace(',',''),
          tableID: b?.table_activity?.table_id ?? null,
          tableName: b.table_activity ? `${b.table_activity.table.no} - ${b.table_activity.table.zone.alias}` : null,
          zone: b.table_activity ? b.table_activity.table.zone.alias : null,
          delivery: b.delivery ? b.delivery : b.type_id ? b.type_id : 1,
          orderPrice: {
            sumprice: b.sumPrice,
            discount: 0,
            vat: 0,
            net: b.sumPrice,
          },
          // product: productInBill,
          product: b.product,
          member_id: b.member_id ? b.member_id : null,
          member: b.member_id? b.member : null,
          table_activity_id: b.table_activity_id ? b.table_activity_id : null,
          table_activity: b.table_activity ? b.table_activity : null,
          employee_id: b.employee_id ? b.employee_id : null,
          employee: b.employee_id? b.employee : null,
          remark: b.remark,
          delivery_remark: b.delivery_remark,
          delivery_remark_channel: b.delivery_remark_channel,
        };
      });
      setBilldatalist(parsed);
    }
  }, [bills, modalOpenObj.modalOpen]);

  useEffect(() => {
    if(billdatalist.length > 0) {
      setBillFilter(billdatalist.filter(
        (item) =>
          ( 
            item.orderNo &&
            item.orderNo.toLowerCase().includes(filterText.toLowerCase()) &&
            item.billStatus == BillStatus &&
            item.orderNo != "NA"
          ) ||
          ( 
            item.pos &&
            item.pos.toLowerCase().includes(filterText.toLowerCase()) &&
            item.billStatus == BillStatus
          ) ||
          ( 
            item.customerName &&
            item.customerName.toLowerCase().includes(filterText.toLowerCase()) &&
            item.billStatus == BillStatus
          )
      ))
    }
  }, [billdatalist, BillStatus, filterText])

  useEffect(() => {
    if(billdatalist && billdatalist.length > 0 && billdata) {
      var newData = billfilter.find(b => b.id == billdata.id);
      setBilldata(newData);
    }
  }, [billdatalist])

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => modalOpenObj.setModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          <div
            className="bg-white focus-visible-none w-[min(64.11vw,662px)] p-[min(1.74vw,15px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-16 text-[var(--color-black-2)]"
            style={{ height: "calc(100vh - 100px)" }}
          >
            <div className=" mb-2 text-22 flex align-items-center font-[700] leading-[2.2]">
              <div className="me-auto text-18 font-[600] text-[#061126]">
                เรียกบิล
              </div>
            </div>

            <div className="row gx-2">
              <div className="col-6 pe-1">
                <div className="bg-[#F4F4F4]  p-[min(2.44vw,10px)] rounded-[min(0.78vw,8px)] ">
                  <div className=" mb-2">
                    <button
                      type="button"
                      className="btn btn-bill-status me-2"
                      style={
                        BillStatus == 4
                          ? {
                              background: "#2DC76D",
                              color: "#FFF",
                              border: "1px solid #2DC76D",
                            }
                          : {}
                      }
                      onClick={() => setBillStatus(4)}
                    >
                      บิลที่พัก
                    </button>
                    <button
                      type="button"
                      className="btn btn-bill-status"
                      style={
                        BillStatus == 1
                          ? {
                              background: "#2DC76D",
                              color: "#FFF",
                              border: "1px solid #2DC76D",
                            }
                          : {}
                      }
                      onClick={() => setBillStatus(1)}
                    >
                      บิลที่เปิดอยู่
                    </button>
                  </div>
                  <div className="mb-2">
                    <CustomInputSearch
                      inputprops={{
                        type: "text",
                        className: "search-input text-12 w-100 pt-2",
                        placeholder: "ค้นหา",
                        min: 0,
                        onChange: (e) => {},
                      }}
                      onKeyUp={(e) => setFilterText(e.target.value)}
                    />
                  </div>

                  <div className="bx-bill pe-2">
                    {billfilter &&
                      billfilter.map((item, index) => (
                        <div
                          className="card card-bill mb-2"
                          key={index}
                          onClick={() => setBilldata(item)}
                        >
                          <div className="d-flex align-items-center mb-1">
                            <div className="me-auto text-12 text-[#B6BBC6]">
                              {item.orderNo && item.orderNo != "NA" ? item.orderNo : "-"}
                            </div>
                            {item.billStatus == 1 ? (
                              <div className="bill-status-s2">เปิดบิลอยู่</div>
                            ) : (
                              ""
                            )}
                            {item.billStatus == 4 ? (
                              <div className="bill-status-s1">พักบิล</div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="d-flex align-items-center text-16 text-[#061126] mb-1">
                            <div className="me-auto">
                              {item.customerName != ""
                                ? item.customerName
                                : "-"}
                            </div>
                            {/* <div>{item.orderPrice.net ? item.orderPrice.net.toFixed(2) : (0).toFixed(2)}</div> */}
                            <div>{item.orderPrice.net ? item.orderPrice.net.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : (0).toFixed(2)}</div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="me-2">
                              {item.delivery == 1 ? (
                                <div className="dl1">ทานที่ร้าน</div>
                              ) : (
                                ""
                              )}
                              {item.delivery == 2 ? (
                                <div className="dl2">กลับบ้าน</div>
                              ) : (
                                ""
                              )}
                              {item.delivery == 3 ? (
                                <div className="dl3">เดลิเวอรี่</div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="dl1-text pr-2">
                              {item.tableName}
                            </div>
                            <div className="text-[#B6BBC6] fs-10 pt-[1px]">
                              {item.orderDate}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="card card-body p-2">
                  <div className="d-flex align-items-center">
                    <div className="me-auto ">
                      <span className="text-14 text-[#2DC76D] me-2">
                        Order No.
                      </span>{" "}
                      <span className="text-14 text-[#2F4858]">
                        {billdata && billdata.orderNo != "NA" ? billdata.orderNo : "-"}
                      </span>
                    </div>
                    {billdata ? (
                      billdata.billStatus == 1 ? (
                        <div className="bill-status-s2">เปิดบิลอยู่</div>
                      ) : billdata.billStatus == 4 ? (
                        <div className="bill-status-s1">พักบิล</div>
                      ) : (
                        ""
                      )
                    ) : (
                      "-"
                    )}
                  </div>

                  <div>
                    <span className="text-14 text-[#2DC76D] me-2">
                      Receipt No.
                    </span>
                    <span className="text-[#2F4858] text-14">
                      {billdata ? billdata.receiptNo : "-"}{" "}
                    </span>
                  </div>
                  <div className="row g-0">
                    <div className="col">
                      <div>
                        <span className="text-12 text-[#2DC76D] me-2">
                          วันที่ :
                        </span>
                        <span className="text-12 text-[#2F4858] me-2">
                          {billdata ? billdata.orderDate : "-"}{" "}
                        </span>
                        <span className="text-12 text-[#2DC76D] me-2">
                          จุดขาย :
                        </span>
                        <span className="text-12 text-[#2F4858]">
                          {billdata ? billdata.pos : "-"}
                        </span>
                      </div>
                      <div>
                        <span className="text-12 text-[#2DC76D] me-2">
                          พนักงาน :
                        </span>
                        <span className="text-12 text-[#2F4858]">
                          {billdata ? billdata.staff : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="text-14 text-[#2F4858] text-end mb-1">
                        Total
                      </div>
                      <div className="text-16 fw-500 text-[#2DC76D]">
                        {billdata && billdata.orderPrice.net ? billdata.orderPrice.net.toFixed(2) : (0).toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div className="my-2">
                    {billdata.delivery == 1 ? (
                      <div className="dl1">ทานที่ร้าน</div>
                    ) : (
                      ""
                    )}
                    {billdata.delivery == 2 ? (
                      <div className="dl2">กลับบ้าน</div>
                    ) : (
                      ""
                    )}
                    {billdata.delivery == 3 ? (
                      <div className="dl3">เดลิเวอรี่</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <div className="">
                      <div className="row gx-2 tc-17 fs-12">
                        <div className="col-7">รายการ</div>
                        <div className="col-2 text-center">จำนวน</div>
                        <div className="col-3 text-end">ราคา</div>
                      </div>
                    </div>
                    <hr className="mt-1 mb-0" />
                    <div
                      className="bx-main-order-list "
                      style={{ height: "calc(100vh - 440px)" }}
                    >
                      <ul className="p-0 m-0  w-100 ">
                        <ProductOrderDetail
                          products={billdata.product}
                          handleClick={(id) => {
                            return false;
                          }}
                        />
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between">
                    <div className="mt-2">
                      <CustomBtn
                        onClick={async () => {
                          // console.log("billdata: ", billdata)
                          // setOrders(billdata);
                          await cancelOrder(billdata);
                          setBilldata(false);
                          // alertObj.setAlerttable(false); // TODO: Recheck undefined setAlerttable
                          // alertObj.setAlertproduct(false); // TODO: Recheck undefined setAlertproduct
                          modalOpenObj.setModalOpen(false);
                        }}
                        disabled={billdata === false}
                        className={` leading-[0.8] w-fit min-w-[min(16.25vw,148px)] text-18 font-[600] min-h-[min(5.51vw,57px)] rounded-[min(0.78vw,8px)] bg-[#E6E6E6] text-[#6D788D] shadow-none hover:text-black hover:bg-[#E6E6E6] ${billdata.billStatus == 4 ? 'visible' : 'invisible'}`}
                        btntxt={"ยกเลิกบิล"}
                      />
                    </div>
                  <div className="mt-2">
                    <CustomBtn
                      onClick={async () => {
                        // console.log("billdata: ", billdata)
                        await setOrders(billdata);
                        setBilldata(false);
                        // alertObj.setAlerttable(false); // TODO: Recheck undefined setAlerttable
                        // alertObj.setAlertproduct(false); // TODO: Recheck undefined setAlertproduct
                        await modalOpenObj.setModalOpen(false);
                      }}
                      disabled={billdata === false}
                      className={` leading-[0.8] w-fit min-w-[min(16.25vw,148px)] text-18 text-white font-[600] min-h-[min(5.51vw,57px)] rounded-[min(0.78vw,8px)] bg-[#FF731D] hover:bg-[#FF731D] shadow-none`}
                      btntxt={"เรียกบิล"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
