import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomBtn from "components/fragments/CustomBtn";
import OTPInput from "react-otp-input";
import CustomInput from "components/fragments/CustomInput";
import CustomInputSearch from "components/fragments/CustomInputSearch";
import { useNavigate } from "react-router-dom";
import AvatarMaleImg from "img/avartar-male.png"
import AvatarFemaleImg from "img/avatar-female.png"

// TODO: Implement this handle on API side
const PAYMENT_TYPE_NAME = {
    cash: "เงินสด",
    qr: "QR",
    transfer: "เงินโอน",
    delivery: "Delivery",
    debit: "บัตรเครดิต",
}

export default function ModalPaymentSuccess({
      modalSuccessOpenObj,
      modalOpenObj,
      orders,
      handleClick,
      bill,
      payment,
      currentBillPayments,
      onPrintBtn,
      setRequestLoading,
      ...props
    }) {
    //   console.log("🚀 ~ orders:", orders)
    //   console.log("🚀 ~ currentBillPayments:", currentBillPayments)
    //   console.log("🚀 ~ bill:", bill)
    //   console.log("🚀 ~ payment:", payment)

    const navigate = useNavigate();
    const [paymentdetail, setPaymentdetail] = useState({
        customer: orders.member_id ? { ...orders.member, ...bill?.member, point: bill?.member?.point } : null,
        // customer: orders.member_id ? bill.member : null,
        detail: { 
            ...bill,
            // payment_type_name: bill.payment_channel ? PAYMENT_TYPE_NAME[bill.payment_channel.name.toLowerCase()] : ""
            payment_type_name: currentBillPayments ? currentBillPayments : bill?.payment_channel ? PAYMENT_TYPE_NAME[bill.payment_channel.name.toLowerCase()] : ""
        },
    });
    // console.log("🚀 ~ paymentdetail:", paymentdetail)
    const [customerImageSrc, setCustomerImageSrc] = useState(AvatarMaleImg);
    // console.log("🚀 ~ customerImageSrc:", customerImageSrc)

    // const getPaymentDetail = () => {
    //     fetch("/json/paymentdetail.json")
    //       .then((response) => response.json())
    //       .then((result) => setPaymentdetail(result) )
    //       .catch((error) => console.log("error", error));
    // };

    // useEffect(() => {
        // getPaymentDetail()
    // }, []);
    
    useEffect(() => {
        setPaymentdetail(prev => ({ 
            ...prev, 
            detail: { 
                ...prev.detail, 
                ...bill,
            },
            customer: 
                bill?.member ? 
                {
                    ...prev.customer,
                    ...bill?.member
                } : null
        }))
    }, [bill]);

    useEffect(() => {
        setPaymentdetail(prev => ({ ...prev, detail: { ...prev.detail, payment_type_name: currentBillPayments }}))
    }, [currentBillPayments]);

    useEffect(() => {
        if(orders.member_id || orders.member?.id) {
            setPaymentdetail(prev => ({ ...prev, customer: orders?.member ? orders.member : null }))
        }
    }, [orders]);

    useEffect(() => {
        if (paymentdetail.customer) {
            if(paymentdetail.customer.img_url || paymentdetail.customer.img_url != "") {
                setCustomerImageSrc(paymentdetail.customer.img_url )
            } else if (paymentdetail.customer.gender == "Male") {
                setCustomerImageSrc(AvatarMaleImg)
            } else if (paymentdetail.customer.gender == "Female" ) {
                setCustomerImageSrc(AvatarFemaleImg)
            } else {
                setCustomerImageSrc(AvatarMaleImg)
            }
        }
    }, [paymentdetail])
    
    useEffect(() => {
        if(modalOpenObj.modalOpen) {
            // console.log('success')
            setTimeout(async () => {
                // onPrintBtn();
                await setRequestLoading({
                    isRequestLoading: false
                })
            }, 1000);
        }
    }, [modalOpenObj.modalOpen])
    
    return (
      <div>
      <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={modalOpenObj.modalOpen}
            onClose={() => {
                handleClick();
                modalOpenObj.setModalOpen(false)
            }}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={modalOpenObj.modalOpen}>
            <div className="bg-white position-relative focus-visible-none w-[min(90.11vw,905px)]  absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-16 text-[var(--color-black-2)]" >
                <div className="text-end absolute top-[10px] right-[10px]">
                    <button 
                        className="btn btn-close-modal" 
                        onClick={() => {
                            handleClick();
                            modalOpenObj.setModalOpen(false)
                        }}>
                    </button>
                </div>
                <div className="p-[min(2.74vw,25px)]">
                    <div className=" mb-4 pb-2">
                        <div className="d-flex align-items-center">
                            <div className="icon-succes-st-1 me-3"></div>
                            <div className="text-[#2DC76D] fw-bold fs-24">การชำระเงินเสร็จสิ้น</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className="p-3" style={{borderRadius:'15px',background: '#F4FCF8'}}>
                                <div className="d-flex tq-1 mb-4">
                                    <div className="align-self-center me-auto text-[#2DC76D] fw-bold">ข้อมูลลูกค้า</div>
                                    <img
                                    className="aspect-square w-[min(6.13vw,62px)] rounded-full object-cover"
                                    src={customerImageSrc ?? AvatarMaleImg}
                                    alt=""
                                    />
                                    
                                </div>
                                <div className="d-flex justify-content-between mb-3 text-[#2F4858] fs-18">
                                    <div className="">ชื่อลูกค้า</div>
                                    <div className=""> { paymentdetail.customer ? paymentdetail.customer.name : "-" } </div>
                                </div>
                                <div className="d-flex justify-content-between mb-3 text-[#2F4858] fs-18">
                                    <div className="">แต้มสะสมที่มีอยู่</div>
                                    <div className="fw-light">{ paymentdetail.customer?.point ?? "0" }</div>
                                </div>
                                <div className="d-flex justify-content-between mb-3 text-[#2F4858] fs-18">
                                    <div className="">ใช้แต้มสะสมไป</div>
                                    {/* <div className="fw-light">{ paymentdetail.customer ? paymentdetail.customer.pointUsage : "-" }</div> */}
                                    <div className="fw-light">{ paymentdetail.customer?.pointUsage ?? "-" }</div>
                                </div>
                                <div className="d-flex justify-content-between mb-3 text-[#2F4858] fs-18">
                                    <div className="">ได้รับแต้มสะสม</div>
                                    {/* <div className="fw-light">{ paymentdetail.customer ? paymentdetail.customer.pointReceive : "-" }</div> */}
                                    <div className="fw-light">{ paymentdetail.customer?.pointReceive ?? "-" }</div>
                                </div>
                                <div className="d-flex justify-content-between mb-3 text-[#2F4858] fs-18">
                                    <div className=""> <span className="icon-star"></span> แต้มสะสมคงเหลือ</div>
                                    <div className="text-[#2DC76D] fw-bold">
                                    { 
                                        (paymentdetail.customer && paymentdetail.customer.point) ? 
                                            ((paymentdetail.customer.point + paymentdetail.customer.pointReceive) - paymentdetail.customer.pointUsage ) :
                                        bill?.member_net_point ? 
                                            bill.member_net_point :
                                        "0" 
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex  mb-2">
                                <div className="align-self-center fw-500 text-[#061126] fs-18">รายละเอียดการชำระเงิน</div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                                <div className="fs-18 fw-light text-[#2F4858]">เลชที่ใบเสร็จ</div>
                                {/* <div className="fs-18 text-[#2F4858]">{paymentdetail.detail ?  paymentdetail.detail.no : ''}</div> */}
                                <div className="fs-18 text-[#2F4858]">{paymentdetail.detail?.receiptNo ?  paymentdetail.detail.receiptNo : ''}</div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                                <div className="fs-18 fw-light text-[#2F4858]">ช่องทางการชำระ</div>
                                {/* <div className="fs-18 text-[#2F4858]">{paymentdetail.detail ?  paymentdetail.detail.channel : ''}</div> */}
                                <div className="fs-18 text-[#2F4858]">{paymentdetail.detail ?  paymentdetail.detail.payment_type_name : ''}</div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                                <div className="fs-18 fw-light text-[#2F4858]">มูลค่ารวม</div>
                                <div className=" fs-24 fw-500 text-[#2F4858]">{paymentdetail.detail ? paymentdetail.detail.total : '0.00'} <span className="fs-18  fw-500 text-[#00BC3A] ms-4">THB</span></div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                                <div className="fs-18 fw-light text-[#2F4858]">ส่วนลด</div>
                                <div className=" fs-24 fw-500 text-[#2F4858]">
                                    {
                                        paymentdetail.detail?.discount_round ? paymentdetail.detail.discount_round + paymentdetail.detail.discount_from_point :
                                        paymentdetail.detail?.discount ? paymentdetail.detail.discount + paymentdetail.detail.discount_from_point:
                                        '0.00'
                                    }
                                    <span className="fs-18  fw-500 text-[#00BC3A] ms-4">THB</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                                <div className="fs-18 fw-light text-[#2F4858]">ราคาสุทธิ</div>
                                <div className=" fs-24 fw-500 text-[#2F4858]">{paymentdetail.detail ? paymentdetail.detail.net : '0.00'} <span className="fs-18  fw-500 text-[#00BC3A] ms-4">THB</span></div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                                <div className="fs-18 fw-light text-[#2F4858]">จำนวนที่ชำระ</div>
                                <div className=" fs-24 fw-500 text-[#2F4858]">{paymentdetail.detail ? paymentdetail.detail.paid : '0.00'} <span className="fs-18  fw-500 text-[#00BC3A] ms-4">THB</span></div>
                            </div>
                            <hr className="line-st1 my-3" style={{opacity:0.8}} />
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="text-[#2DC76D] fs-24 fw-bold">เงินทอน</div>
                                <div className="d-flex align-items-center" >
                                    <div className="text-[#2DC76D] fs-30 fw-bold px-5 py-1" style={{borderRadius: '8px',background: 'rgba(45, 199, 109, 0.25)'}}>{paymentdetail.detail ? Number(paymentdetail.detail.change).toFixed(2) : '0.00'}</div>
                                    <span className="fs-18  fw-500 text-[#00BC3A] ms-4">THB</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="line-st1 mt-3 mb-0" style={{opacity:0.8}} />
                <div className="d-flex justify-content-end p-4 my-2 ">
                    <button className="btn btn-st4 me-3"
                    onClick={onPrintBtn}> 
                    Print
                    </button>
                    <button className="btn btn-st5" 
                    onClick={handleClick}
                    >เริ่มต้นขายใหม่</button>
                </div>

            </div>
            </Fade>
        </Modal>
      </div>

    )
}