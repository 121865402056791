import logo from "./img/logo.png";
import "./App.css";

import { Home } from "components/pages/Home";

import React, { useState, useEffect, Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import LoginPage from "components/pages/LoginPage";
import PosSettingPage from "components/pages/PosSettingPage";
import MenuPage from "components/pages/MenuPage";
import MoneyDrawerPage from "components/pages/MoneyDrawerPage";
import CloseBalancePrint from "components/pages/CloseBalancePrintPage";
import PosStartPrintPage from "components/pages/PosStartPrintPage";
import TablePage from "components/pages/TablePage";
import KitchenPage from "components/pages/KitchenPage";
import KitchenHistoryPage from "components/pages/KitchenHistoryPage";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { authState, useAuthTokenCheck, useAuthCheckActive, useClientCheck } from "./state/auth";
import SalesSummaryPage from "components/pages/SalesSummaryPage";
import BillPage from "components/pages/BillPage";
import { posState } from "state/pos";
import { selectedTableState } from "state/table";
import { orderState } from "state/order";
import ReceiptPrintPage from "components/pages/ReceiptPrintPage";
import PosDepositPrintPage from "components/pages/PosDepositPrintPage";
import PosWithdrawPrintPage from "components/pages/PosWithdrawPrintPage";
import PosOpenStorePrintPage from "components/pages/PosOpenStorePrintPage";
import ShowNotify from "components/fragments/ShowNotify";
import NotificationPage from "components/pages/NotificationPage";
import { notificationState, useFetchAllNotification } from "state/notfication";
import { appState, useBusinessSlugCheck } from "state/app";
import NotFoundPage from "components/pages/NotFoundPage";
import { socket } from './socket';

function App() {
  const navigate = useNavigate();
  const clientCheck = useClientCheck();
  const authTokenCheck = useAuthTokenCheck();
  const { isLoggedIn, isTokenChecked, isFromLoginPage } = useRecoilValue(authState);
  const { isPosStarted, isKitchenStarted } = useRecoilValue(posState);
  const location = useLocation();
  const businessSlugCheck = useBusinessSlugCheck();
  const { slug, isValidSlugChecked, isValidSlug, slugBranch } = useRecoilValue(appState);
  const [isConnected, setIsConnected] = useState(socket.connected);

  // Init load, check slug
  useEffect(() => {
    console.log('dev fix 0.5.8')
    if(location.pathname.split("/").length > 0) {
      var posSlug = location.pathname.split("/")[1];
      var posSlugBranch = location.pathname.split("/")[2];
      businessSlugCheck({
        slug: posSlug,
        slugBranch: posSlugBranch
      });
    }

    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);

  // After check slug
  useEffect(() => {
    if(isValidSlugChecked) {
      // If slug valid, continue client and auth check
      if(isValidSlug) {
        clientCheck();
        authTokenCheck();
      }
      else {
        // For seamless migration
        authTokenCheck();
      }
    }
  }, [isValidSlugChecked, isValidSlug]);

  useEffect(() => {
    if (isTokenChecked) {
      if(slug) {
        if (!isLoggedIn) {
          navigate(`/${slug}/${slugBranch}/login`);
        } else if (isLoggedIn && isKitchenStarted) {
          navigate(`/${slug}/${slugBranch}/kitchen`);
        } else if (isLoggedIn && isFromLoginPage) {
          // authTokenCheck();
          if(isLoggedIn && !isPosStarted) {
            navigate(`/${slug}/${slugBranch}/pos-setting`);
          } else if(isLoggedIn && isPosStarted) {
            navigate(`/${slug}/${slugBranch}/`);
          } else {
            navigate(`/${slug}/${slugBranch}/pos-setting`);
          }
        } else if (isLoggedIn && isPosStarted) {
          navigate(`/${slug}/${slugBranch}/`);
        } else {
          navigate(`/${slug}/${slugBranch}/login`);
        }
      }
      else {
        navigate('/not-found');
      }
    }
  }, [isTokenChecked, isLoggedIn, isKitchenStarted, isPosStarted]);

  // useEffect(() => {
  //   if (isLoggedIn && !isPosStarted) {
  //     navigate("/pos-setting");
  //   }
  // }, [isPosStarted]);

  // useEffect(() => {
  //   if (isLoggedIn && isKitchenStarted) {
  //     navigate("/kitchen");
  //   }
  // }, [isKitchenStarted]);

  return (
    <Routes>
      <Route path={`/${slug}/${slugBranch}/login`} element={<LoginPage />}></Route>
      <Route path={`/${slug}/${slugBranch}/pos-setting`} element={<PosSettingPage />}></Route>
      <Route path={`/${slug}/${slugBranch}/money-drawer`} element={<MoneyDrawerPage />}></Route>
      <Route path={`/${slug}/${slugBranch}/close-balance-print`} element={<CloseBalancePrint />}></Route>
      <Route path={`/${slug}/${slugBranch}/recipt-print`} element={<ReceiptPrintPage />}></Route>
      <Route path={`/${slug}/${slugBranch}/recipt-print-copy`} element={<ReceiptPrintPage $isCopy={true}/>}></Route>
      <Route path={`/${slug}/${slugBranch}/recipt-print-invoice`} element={<ReceiptPrintPage $isInvoice={true}/>}></Route>
      <Route path={`/${slug}/${slugBranch}/pos-start-print`} element={<PosStartPrintPage />}></Route>
      <Route path={`/${slug}/${slugBranch}/pos-deposit-print`} element={<PosDepositPrintPage />}></Route>
      <Route path={`/${slug}/${slugBranch}/pos-withdraw-print`} element={<PosWithdrawPrintPage />}></Route>
      <Route path={`/${slug}/${slugBranch}/pos-open-store-print`} element={<PosOpenStorePrintPage />}></Route>
      <Route path={`/${slug}/${slugBranch}/kitchen`} element={(isLoggedIn && isKitchenStarted) && <KitchenPage />}></Route>
      <Route path={`/${slug}/${slugBranch}/kitchen-history`} element={(isLoggedIn && isKitchenStarted) && <KitchenHistoryPage />}></Route>
      <Route path={`/${slug}/${slugBranch}/`} element={(isLoggedIn && isPosStarted) && <Layout />}>
        <Route path={`/${slug}/${slugBranch}/`} element={(isLoggedIn && isPosStarted) && <Home />} />
        <Route path={`/${slug}/${slugBranch}/notification`} element={<NotificationPage />} />
        <Route path={`/${slug}/${slugBranch}/table`} element={<TablePage />} />
        <Route path={`/${slug}/${slugBranch}/bill`} element={<BillPage />} />
        <Route path={`/${slug}/${slugBranch}/save`} element={<Save />} />
        <Route path={`/${slug}/${slugBranch}/menu`} element={<MenuPage />} />
        <Route path={`/${slug}/${slugBranch}/shop`} element={<Shop />} />
        <Route path={`/${slug}/${slugBranch}/sales-summary`} element={<SalesSummaryPage />}></Route>
      </Route>
      <Route path={`/not-found`} element={<NotFoundPage />}></Route>
      {/* <Route path="*" element={!isLoggedIn ? <Navigate replace to={"/login"} /> : <Navigate replace to={"/"} />}></Route> */}
    </Routes>
  );
}

function Layout() {
  const [selectedTable, setSelectedTable] = useRecoilState(selectedTableState);
  const setOrderState = useSetRecoilState(orderState);
  const authCheckActive = useAuthCheckActive();
  const { user, logMachine } = useRecoilValue(authState);
  const { currentPOSActivity, currentPOS } = useRecoilValue(posState);
  const fetchAllNotification = useFetchAllNotification();
  const { notifications } = useRecoilValue(notificationState);
  const location = useLocation();
  const { slug, slugBranch } = useRecoilValue(appState);

  const sideMenuPath =[
    {
      path: "",
      element: 0
    },
    {
      path: "notification",
      element: 1
    },
    {
      path: "table",
      element: 2
    },
    {
      path: "bill",
      element: 3
    },
    {
      path: "save",
      element: 4
    },
    {
      path: "menu",
      element: 5
    },
    {
      path: "sales-summary",
      element: 5
    },
    {
      path: "shop",
      element: 6
    },
  ];

  function handleClickMenu(e) {
    var elems = document.querySelectorAll(".btn-st-m1");
    [].forEach.call(elems, function (el) {
      el.classList.remove("active");
    });
    if (e.target.classList.value == "btn btn-st-m1") {
      e.target.classList.add("active");
    } else {
      e.target.parentElement.classList.add("active");
    }
    // Reset selected table from Table page in case it exist
    setSelectedTable(null)
    // Reset selected order from Table page in case it exist
    window.localStorage.removeItem("selectedOrder");
    setOrderState(state => ({ ...state, selectedOrder: {} }))
  }

  // useEffect(() => {
    // // Set interval call
    // const intervalCheckActive = setInterval(() => {
    //   if(logMachine && user.id) {
    //     // authCheckActive(currentPOS.id, user.employeeId, currentPOSActivity.operation_date, logMachine, false, slug)
    //     fetchAllNotification(currentPOS.id, currentPOSActivity.operation_date);
    //   }
    // }, 1500);
    // // Clean up the interval when the component is unmounted
    // return () => clearInterval(intervalCheckActive);
  // }, [])

  useEffect(() => {
    if(location) {
      var elems = document.querySelectorAll(".btn-st-m1");
      [].forEach.call(elems, function (el) {
        el.classList.remove("active");
      });
      const current = location.pathname.split("/")[3];
      var menuIndex = 0;
      if(current) {
        menuIndex = sideMenuPath.find(smp => smp.path == current).element;
      }
      elems.item(menuIndex).classList.add("active");
    }
  }, [location])

  useEffect(() => {
    function onPosLogin (value) {
      if(logMachine && user.id) {
        authCheckActive(currentPOS.id, user.employeeId, currentPOSActivity.operation_date, logMachine, false, slug, slugBranch)
      }
    }

    async function fetchNoti () {
      if(logMachine && user.id) {
        await fetchAllNotification(currentPOS.id, currentPOSActivity.operation_date);
      }
    }

    if(logMachine && user.id) {
      socket.on(`POS_${currentPOS.id}_LOGIN`, onPosLogin);
      socket.onAny(async (eventName) => {
        if(eventName.includes(`POS_${user.branchId}`)) {
          await fetchNoti();
        }
      })
    }

    return () => {
    }
  }, [logMachine, user])

  return (
    <div className="wrapper">
      <div id="content">
        <div className="min-h-[100vh]">
          <div className="block-1">
            <div className="customShow">
              <div className="mb-4 py-2 d-flex justify-content-center">
                <img src="/img/logo-3.png" width="45" />
              </div>
              <div className="mb-2">
                <Link
                  to={`/${slug}/${slugBranch}/`}
                  className="btn btn-st-m1 active"
                  id="btn-m1"
                  onClick={handleClickMenu}
                >
                  <div className="i-m1 i-m1-1"></div>
                  <div className="">POS</div>
                </Link>
              </div>
              <div className="mb-2">
                <Link
                  to="notification"
                  className="btn btn-st-m1"
                  id="btn-m2"
                  onClick={handleClickMenu}
                >
                  <div className="i-m1 i-m1-2 relative">
                    <ShowNotify countNotify={(notifications && notifications.length > 0 && notifications.filter(noti => !noti.is_read).length > 0) ? notifications.filter(noti => !noti.is_read).length : null} />
                  </div>
                  <div className="">แจ้งเตือน</div>
                </Link>
              </div>
              <div className="mb-2">
                <Link
                  to="table"
                  className="btn btn-st-m1 "
                  id="btn-m3"
                  onClick={handleClickMenu}
                >
                  <div className="i-m1 i-m1-3"></div>
                  <div className="">โต๊ะ</div>
                </Link>
              </div>
              <div className="mb-2">
                <Link
                  to="bill"
                  className="btn btn-st-m1 "
                  id="btn-m4"
                  onClick={handleClickMenu}
                >
                  <div className="i-m1 i-m1-4"></div>
                  <div className="">บิลขาย</div>
                </Link>
              </div>
              <div className="mb-2">
                <Link
                  to="save"
                  className="btn btn-st-m1 "
                  id="btn-m5"
                  onClick={handleClickMenu}
                >
                  <div className="i-m1 i-m1-5"></div>
                  <div className="">บันทึก</div>
                </Link>
              </div>
              <div className="mb-2">
                <Link
                  to="menu"
                  className="btn btn-st-m1 "
                  id="btn-m6"
                  onClick={handleClickMenu}
                >
                  <div className="i-m1 i-m1-6"></div>
                  <div className="">เมนู</div>
                </Link>
              </div>
              <div className="mb-2">
                <Link
                  to="shop"
                  className="btn btn-st-m1 "
                  id="btn-m7"
                  onClick={handleClickMenu}
                >
                  <div className="i-m1 i-m1-7"></div>
                  <div className="">ร้านค้า</div>
                </Link>
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
function Notification() {
  return (
    <div>
      <h1>Notification</h1>
    </div>
  );
}
function Table() {
  return (
    <div>
      <h1>Table</h1>
    </div>
  );
}
function Bill() {
  return (
    <div>
      <h1>Bill</h1>
    </div>
  );
}
function Save() {
  return (
    <div>
      <h1>Save</h1>
    </div>
  );
}
function Menu() {
  return (
    <div>
      <h1>Menu</h1>
    </div>
  );
}
function Shop() {
  return (
    <div>
      <h1>Shop</h1>
    </div>
  );
}

export default App;
