import CustomInput from "components/fragments/CustomInput";
import React, { useEffect, useState } from "react";
import { numberNoDecimals } from "utils/numberFormat";

export default function MoneyDisplayCount({
  countValue,
  currentValue,
  index,
  handleInputChange,
  isCloseBalance = false,
  isActive = false,
}) {
  const [theme, setTheme] = useState({
    color: "bg-[#b6bbc6]",
  });
  useEffect(() => {
    if(!isCloseBalance) {
      return setTheme({
        ...theme,
        color: isActive ? "bg-[var(--color-blue)]" : "bg-[#b6bbc6]",
      });
    }

  }, [isActive]);

  return (
    <div className="flex aspect-[110/24] w-[min(10.74vw,110px)] border-[min(0.10vw,1px)] rounded-[]">
      <div className="w-1/2">
        <CustomInput
          inputprops={{
            type: "number",
            className: "modal-input-2 no-spinner text-16 leading-[0.5]",
            value: currentValue,
            min: 0,
            max: 999,
            onChange: (e) => {
                  var value = e.currentTarget.value.split("");
                  if(value.length == 0 || (value.length == 1 && value[0] == 0)){
                    value = "0";
                  }
                  else if(value[0] == 0) {
                    value = value.slice(1).join().replaceAll(",", "");
                  } else {
                    value = value.join().replaceAll(",", "");
                  }
                  // handleInputChange(index, e.currentTarget.value);
                  if(parseInt(value) <= 999) {
                    handleInputChange(index, value);
                  }
            },
            onClick: (e) => {
              const input = e.target;
              input.select();
            },
          }}
        />
      </div>
      <div
        className={`w-1/2 text-14 ${isCloseBalance ? 'text-[#FFFFF]' : 'text-[#FFF]'} text-right pr-[min(0.10vw,1px)] ${theme.color} `}
      >
        {numberNoDecimals(countValue)}
      </div>
    </div>
  );
}
