import MenuBtn from "components/fragments/MenuBtn";
import HeaderPos from "components/layouts/HeaderPos";
import React, { useState, useEffect, useRef } from "react";
import Collapse from 'react-bootstrap/Collapse';
import { useNavigate } from "react-router-dom";
import CustomInputSearch from "components/fragments/CustomInputSearch";
import Select from "react-select";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ModalPin from "components/layouts/ModalPinCancleBill";
import ModalSuccess from "components/layouts/ModalSuccessCancleBill";
import ModalCancleBill from "components/layouts/ModalCancleBill";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { orderState } from "state/order";
import { authState, useFetchBranch } from "state/auth";
import { useFetchAllOrder, useUpdateOrder, useUpdateOrderReceiptLog, useFetchAllOrderByDateRange } from "state/order";
import { posState } from "state/pos";
import { tableState, selectedTableState, useFetchAllTable } from "state/table";
import { useReactToPrint } from 'react-to-print';
import { BillPaperToPrint } from "components/fragments/BillPaper";
import { billState, useFetchAllBill } from 'state/bill'
import { appState, useSetRequestLoading } from "state/app";
import { numberWithTwoDecimals } from "utils/numberFormat";
import DefaultProductImg from 'img/product/default.png';
import { socket } from '../../socket';
import LoadingSpinner from 'components/fragments/LoadingSpinner';

function getCurrentDate(separator='',dateb=new Date()){

    let newDate = dateb;
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
}

function getCurrentDateM(separator=''){

    let d = new Date();

    let newDate = new Date(d.setMonth(d.getMonth() - 1)) ;
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
}


export default function BillPage() {
  const { slug, slugBranch, isRequestLoading } = useRecoilValue(appState);
  const setRequestLoading = useSetRequestLoading();
  const [modalPinOpen, setModalPinOpen] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [modalCancleBillOpen, setModalCancleBillOpen] = useState(false);
  const [collapseShow, setCollapseShow] = useState(false);
  
  const [billdatalist, setBilldatalist] = useState([]);
//   console.log("🚀 ~ billdatalist:", billdatalist)
  const [billdata, setBilldata] = useState(false);
//   console.log("🚀 ~ billdata:", billdata)
  const [customer, setCustomer] = useState(false);
  const [billcancledata, setBillcancledata] = useState({
    "billID":"",
    "reason":"",
    "note":""
  });

  const { user, branch } = useRecoilValue(authState);
  const { billPageOrders: orders, selectedOrder } = useRecoilValue(orderState);
//   console.log("🚀 ~ file: BillPage.jsx:60 ~ BillPage ~ orders:", orders)
  const fetchAllOrder = useFetchAllOrder();
  const { currentPOS, currentPOSActivity } = useRecoilValue(posState);
  const setOrderState = useSetRecoilState(orderState);
  const { tables } = useRecoilValue(tableState);
  const selectedTable = useRecoilValue(selectedTableState);
  const setSelectedTable = useSetRecoilState(selectedTableState);
  const fetchAllTable = useFetchAllTable();
  const [selectedDateRange, setSelectedDateRange] = useState({
    // startDate: getCurrentDateM('/'),
    startDate: getCurrentDate('/'),
    endDate: getCurrentDate('/')
  })
  const fetchBranch = useFetchBranch();
  const billInvoiceRef = useRef();
  const handlePrintBillInvoice = useReactToPrint({
    content: () => billInvoiceRef.current,
    onAfterPrint: async () => {
        await setRequestLoading({
            isRequestLoading: false
        });
    }
  });
  const billRef = useRef();
  const handlePrintBill = useReactToPrint({
    content: () => billRef.current,
    onAfterPrint: async () => {
        await setRequestLoading({
            isRequestLoading: false
        });
    }
  });
  const fetchAllBill = useFetchAllBill();
  const { bills } = useRecoilValue(billState);
  const [orderBill, setOrderBill] = useState(null)
  const updateOrder = useUpdateOrder();
  const updateOrderReceiptLog = useUpdateOrderReceiptLog();
  const fetchAllOrderByDateRange = useFetchAllOrderByDateRange();
  
  const CountStatus1 = billdatalist.filter( item => item.billStatus == 1) ;
  const CountStatus2 = billdatalist.filter( item => item.billStatus == 4) ; //Pause
  const CountStatus3 = billdatalist.filter( item => item.billStatus == 2) ; //Success
  const CountStatus4 = billdatalist.filter( item => item.billStatus == 3) ; //Cancel

  const getBillDataList = () => {
    fetch("json/bill.json")
      .then((response) => response.json())
      .then((result) => setBilldatalist(result))
      .catch((error) => console.log("error", error));
  };

//   TODO: Implement Member feature in upcoming phase
//   const getCustomer = (customerID,billID) => {
//     fetch("json/customer.json")
//       .then((response) => response.json())
//       .then((result) => setCustomer(result))
//       .catch((error) => console.log("error", error));
//   };

  useEffect(() => {
    // getBillDataList();
    // fetchAllOrder();
    // realtime order list sync
    // const intervalFetchOrder = setInterval(async () => {
    //     // Fetch all orders
    //     if(user.id && currentPOS.id && currentPOSActivity.operation_date && selectedDateRange.startDate && selectedDateRange.endDate) {
    //         await fetchAllBill();
    //         await fetchAllOrderByDateRange(currentPOS.id, currentPOSActivity.operation_date, selectedDateRange.startDate, selectedDateRange.endDate);
    //     }
    // }, 1500);

    async function onPosOrders(value) {
        if(user.id && currentPOS.id && currentPOSActivity.operation_date && selectedDateRange.startDate && selectedDateRange.endDate) {
            // await fetchAllBill();
            await fetchAllOrderByDateRange(currentPOS.id, currentPOSActivity.operation_date, selectedDateRange.startDate, selectedDateRange.endDate);
        }
    }

    if(user.id && currentPOS.id && currentPOSActivity.operation_date && selectedDateRange.startDate && selectedDateRange.endDate) {
        socket.on(`POS_${user.branchId}_ORDER`, onPosOrders);
        socket.on(`POS_${user.branchId}_ORDER_DETAIL`, onPosOrders);
        socket.on(`POS_${user.branchId}_BILL`, onPosOrders);
    }

    return () => {
        // clearInterval(intervalFetchOrder);
        socket.off(`POS_${user.branchId}_ORDER`);
        socket.off(`POS_${user.branchId}_ORDER_DETAIL`);
        socket.off(`POS_${user.branchId}_BILL`);
    }

  }, [selectedDateRange.startDate, selectedDateRange.endDate]);

//   useEffect(() => {
//     if(user.id) {
//         fetchAllBill();
//     }
//   }, [user.id]);

  useEffect(() => {
    // getBillDataList();
    const fecthData = async () => {
        await setRequestLoading({
            isRequestLoading: true
        });
        // await fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
        await fetchAllOrderByDateRange(currentPOS.id, currentPOSActivity.operation_date, selectedDateRange.startDate, selectedDateRange.endDate);
        await fetchAllTable(user.branchId);
        await fetchBranch(user.branchId);
        setBilldata(false);
        // if(isRequestLoading) {
        //    await setRequestLoading({
        //         isRequestLoading: false
        //     });
        // }
    }

    if(selectedDateRange.startDate && selectedDateRange.endDate && user.employeeId && user.branchId) {
        // fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
        // fetchAllOrderByDateRange(currentPOS.id, currentPOSActivity.operation_date, selectedDateRange.startDate, selectedDateRange.endDate);
        // fetchAllTable(user.branchId);
        // fetchBranch(user.branchId);
        // setBilldata(false);
        fecthData();
    }
  }, [selectedDateRange, user]);

  const [Delivery, setDelivery] = useState('');
  const [BillStatus, setBillStatus] = useState('');
  const [filterText, setFilterText] = useState('');

  const filterStep1 = billdatalist.filter(
      item => ( item.billStatus && item.billStatus == BillStatus)  || BillStatus == ""
  );
  const filterStep2 = filterStep1.filter(
      item => ( item.delivery && item.delivery == Delivery)  || Delivery == ""
  );

  const billfilter = filterStep2.filter(
      item => ( 
            ( item.orderNo && item.orderNo.toLowerCase().includes(filterText.toLowerCase()) ) ||
            ( item.receiptNo && item.receiptNo.toLowerCase().includes(filterText.toLowerCase()) )
        )
  );
  
  const navigate = useNavigate();
  
  const optionsDelivery = [
    { value: '', label: "ทั้งหมด" },
    { value: 1, label: "ทานที่ร้าน" },
    { value: 2, label: "กลับบ้าน" },
    { value: 3, label: "เดลิเวอรี่" },
  ];

  useEffect(() => {
    // if(orders && orders.length > 0) {
    //     console.log("🚀 ~ file: BillPage.jsx:123 ~ useEffect ~ orders:", orders)
        // console.log("currentPOSActivity: ", currentPOSActivity)
        const setOrderBillData = async () => {
            const splitStartDate = selectedDateRange.startDate.split("/");
            const formatStartDate = [splitStartDate[1], splitStartDate[0], splitStartDate[2]].join("-");
            const selectedDateRangeStart = new Date(formatStartDate);
            const splitEndDate = selectedDateRange.endDate.split("/");
            const formatEndDate = [splitEndDate[1], splitEndDate[0], splitEndDate[2]].join("-");
            const selectedDateRangeEnd = new Date(formatEndDate);
    
            let newbilldatalist = [];
            for(let i = 0; i < orders.length; i++) {
                // console.log("🚀 ~ useEffect ~ orders:", orders[i])
                const orderStart = new Date(orders[i].start_time);
                if (
                    // orders[i].operation_date == currentPOSActivity.operation_date &&
                    orderStart.getTime() >= selectedDateRangeStart.getTime() &&
                    orderStart.getTime() <= (selectedDateRangeEnd.getTime() + (24*60*60*1000))
                    // || true
                ) {
                    let netPrice = 0;
                    if(orders[i].bill) {
                        if( orders[i].bill.discount >= orders[i].bill.total) {
                            netPrice = 0;
                        } else {
                            netPrice = orders[i].bill.net;
                        }
                    } else if (orders[i].bill?.net) {
                        netPrice = orders[i].bill.net;
                    } else if (orders[i].sumPrice) {
                        netPrice = orders[i].sumPrice
                    }
                    
                    let parsed = {
                        id: orders[i].id,
                        billID: orders[i].id,
                        receiptID: orders[i].receipt_id ? orders[i].receipt_id : null,
                        receiptNo: orders[i].receipt_no ? orders[i].receipt_no : "-",
                        receiptArchiveLogNo: orders[i].receipt_acrhive_no ? orders[i].receipt_acrhive_no : 0,
                        pos: orders[i].pos.name,
                        pos_id: orders[i].pos_id,
                        // pos: orders[i].pos ? orders[i].pos : null,
                        billStatus: orders[i].status_id,
                        status_id: orders[i].status_id,
                        // staff: `${user.firstNameTH} ${user.lastNameTH}`,
                        staff: orders[i].employee ? `${orders[i].employee.first_name_th} ${orders[i].employee.last_name_th}` : "-",
                        customerID: orders[i].member?.id ?? 1,
                        customerName: orders[i].member?.name ?? "-",  // TODO: update as member
                        orderID: orders[i].id,
                        orderId: orders[i].id,
                        orderNo: orders[i].order_id,
                        // orderDate: new Date(orders[i].operation_date).toLocaleDateString(),
                        orderDate: new Date(orders[i].start_time).toLocaleDateString(),
                        tableID: orders[i].table_activity?.table_id ? orders[i].table_activity.table_id : null,
                        table_activity_id: orders[i].table_activity_id ? orders[i].table_activity_id : null,
                        table_activity: orders[i].table_activity ? orders[i].table_activity : null,
                        tableName: orders[i].table_activity?.table?.no ? orders[i].table_activity.table.no : null,
                        zone: orders[i].table_activity?.table?.zone ? orders[i].table_activity.table.zone.alias : null,
                        delivery: orders[i].type_id,
                        // product: [],
                        product: orders[i].product,
                        orderPrice: {
                            sumprice: orders[i].sumPrice ? orders[i].sumPrice : 0,
                            discount: orders[i].bill?.discount ?? 0,
                            discount_round: orders[i].bill?.discount_round ?? 0,
                            vat: 0,
                            net: netPrice,
                            discount_from_point: orders[i].bill?.discount_from_point ?? 0,
                        },
                        payments: orders[i].billPayments ?? [],
                        member_id: orders[i].member_id ? orders[i].member_id : null,
                        member: 
                            orders[i].member ? 
                                {
                                    ...orders[i].member
                                } 
                                : null,
                        start_time: orders[i].start_time,
                        receivePoint: orders[i].bill?.point_receive ?? 0 ,
                        usagePoint: orders[i].bill?.point_usage ?? 0,
                        memberPrevPoint: orders[i].bill?.member_previous_point ?? 0,
                        memberNetPoint: orders[i].bill?.member_net_point ?? 0,
                        paymentsAndChannels: orders[i].bill?.payments ?? [],
                        paymentsReceipt: orders[i].bill?.paymentsReceipt ?? [],
                        remark: orders[i].remark,
                        delivery_remark: orders[i].delivery_remark,
                        delivery_remark_channel: orders[i].delivery_remark_channel,
                        closureText: orders[i].closureText,
                        employee_id: orders[i].employee_id ?? null,
                        employee: orders[i].employee ?? null,
                        bill: orders[i].bill ?? null,
                    }
                    // if (orders[i].orderDetail.length > 0) {
                        // let products = orders[i].orderDetail.map(od => ({
                        //     id: od.id,
                        //     branchProductID: od.branch_product.id,
                        //     branchProductId: od.branch_product.id,
                        //     productID: od.branch_product.id,
                        //     productImage: "img/product/img3.png",
                        //     productName: od.branch_product.product.name,
                        //     productPrice: od.branch_product.product.price,
                        //     // productSumPrice: od.branch_product.product.price * od.amount,
                        //     productSumPrice: od.sumPrice,
                        //     productQty: od.amount,
                        //     productStatus: od.status_id,
                        //     optionnote: [],
                        //     optionproduct: [],
                        //     optionstandard: [],
                        //     note: ""
                        // }))
                        // parsed.product = products;
                    // }
                    newbilldatalist.push(parsed);
                }
            }
            // console.log("🚀 ~ useEffect ~ newbilldatalist:", newbilldatalist)
            // if(newbilldatalist.length > 0) {
            //     setBilldatalist(newbilldatalist);
            // }
            if(BillStatus == "2") {
                setBilldatalist(newbilldatalist.sort((a,b) => {
                    // parseInt(b.receiptNo.substring(5,12)) - parseInt(a.receiptNo.substring(5,12))
                    var checkA = !parseInt(a.receiptNo.substring(5,12)) ? 0 : parseInt(a.receiptNo.substring(5,12));
                    var checkB = !parseInt(b.receiptNo.substring(5,12)) ? 0 : parseInt(b.receiptNo.substring(5,12));
                    return checkB - checkA
                }));
            } else {
                setBilldatalist(newbilldatalist.sort((a,b) => parseInt(b.orderNo.substring(5)) - parseInt(a.orderNo.substring(5))));
            }
    
            if(isRequestLoading) {
                await setRequestLoading({
                    isRequestLoading: false
                });
            }
        }

        setOrderBillData();
    }, [orders]);

  useEffect(() => {
    if(billdatalist && billdatalist.length > 0) {
        if(billdata) {
            var newData = billdatalist.find(b => b.id == billdata.id);
            setBilldata(newData);
        }
    }
  }, [billdatalist]);

  useEffect(() => {
    // Find order's bill
    if(billdata) {
        // const bill = bills.find(b => b.order_id == billdata.orderNo);
        const order = billdatalist.find(o => o.orderNo == billdata.orderNo || o.id == billdata.id);
        if(order) {
            setOrderBill({
                // ...bill,
                ...order["bill"],
                payments: order.paymentsAndChannels ?? [],
                paymentsReceipt: order.paymentsReceipt ?? [],
            })
        } else {
            setOrderBill(null)
        }

    }
  }, [billdata])

  useEffect(() => {
    if(BillStatus == "2" && billdatalist.length > 0) {
        const newbilldatalist = [...billdatalist];
        setBilldatalist(newbilldatalist.sort((a,b) => {
            // parseInt(b.receiptNo.substring(5,12)) - parseInt(a.receiptNo.substring(5,12))
            var checkA = !parseInt(a.receiptNo.substring(5,12)) ? 0 : parseInt(a.receiptNo.substring(5,12));
            var checkB = !parseInt(b.receiptNo.substring(5,12)) ? 0 : parseInt(b.receiptNo.substring(5,12));
            return checkB - checkA
        }));
    } else if (BillStatus != "" && billdatalist.length > 0) {
        const newbilldatalist = [...billdatalist];
        setBilldatalist(newbilldatalist.sort((a,b) => parseInt(b.orderNo.substring(5)) - parseInt(a.orderNo.substring(5))));
    }
  }, [BillStatus])
  
  const onBillItemClickHandler = async (item) => {
    setBilldata(item);
  }

  const onCallBillBtnHandler = async () => {
    await setRequestLoading({
        isRequestLoading: true
    });
    const newSelectedOrder = {...selectedOrder, ...billdata};
    // console.log("🚀 ~ file: BillPage.jsx:193 ~ onCallBillBtnHandler ~ newSelectedOrder:", newSelectedOrder)
    setOrderState(state => ({ ...state, selectedOrder: { ...state.selectedOrder, ...newSelectedOrder } }));
    window.localStorage.setItem("selectedOrder", JSON.stringify(newSelectedOrder))
    if(tables && tables.length > 0){
        // console.log("🚀 ~ file: BillPage.jsx:197 ~ onCallBillBtnHandler ~ tables:", tables)
        const filtered = tables.filter(t => t.id == billdata.tableID)[0] ? tables.filter(t => t.activity.tableId == billdata.tableID)[0] : null;
        if(filtered) {
            const selected = { ...filtered, value: filtered.id, label: `${filtered.no} (${filtered.zone.alias})` };
            window.localStorage.setItem("selectedTable", JSON.stringify(selected));
            setSelectedTable(state => ({ ...state, ...selected }));
        }
    }
    setTimeout(async () => {
        await setRequestLoading({
            isRequestLoading: false
        });
    }, 500);
    navigate(`/${slug}/${slugBranch}/`);
  }

  const handleDateRangeSelect = async (startDate, endDate) => {
    // console.log("🚀 ~ handleDateRangeSelect ~ startDate:", startDate.format("DD/MM/YYYY"))
    // console.log("🚀 ~ handleDateRangeSelect ~ endDate:", endDate.format("DD/MM/YYYY"))
    await setRequestLoading({
        isRequestLoading: true
    });

    setSelectedDateRange({
        startDate: startDate.format("DD/MM/YYYY"),
        endDate: endDate.format("DD/MM/YYYY")
    })
  }

  const onCancelBillConfirmHandler = async (cancelledOrder) => {
    // console.log("🚀 ~ onCancelBillConfirmHandler ~ cancelledOrder:", cancelledOrder)
    await setRequestLoading({
        isRequestLoading: true
    });
    await updateOrder(cancelledOrder, { status_id: 3 });
    // await fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
    await fetchAllOrderByDateRange(currentPOS.id, currentPOSActivity.operation_date, selectedDateRange.startDate, selectedDateRange.endDate);
    await setRequestLoading({
        isRequestLoading: false
    });
  }

  const onPrintBillBtnHandler = async () => {
    await setRequestLoading({
        isRequestLoading: true
    });
    // If paid bill, display receipt archive
    if(billdata.receiptID && billdata.billStatus == 2) {
        const update = await updateOrderReceiptLog(billdata.receiptID, billdata.receiptArchiveLogNo, billdata.receiptNo);
        setBilldata(prev => ({ ...prev, receiptArchiveLogNo: update.log_id}))
        // await fetchAllOrder(currentPOS.id, currentPOSActivity.operation_date);
        await fetchAllOrderByDateRange(currentPOS.id, currentPOSActivity.operation_date, selectedDateRange.startDate, selectedDateRange.endDate);
        handlePrintBill()
    } else {
        handlePrintBillInvoice()
    }
  }

  return (
    <div className="bg-white">
      <HeaderPos isNotify={true}/>
      <div className="pl-[min(2.15vw,22px)] pt-[min(2.15vw,22px)]">
        <div className="row gx-3">
            <div className="col-4">
                <div style={{width:'100%',borderRadius: '8px',background: 'rgba(244, 244, 244, 0.60)',padding:'5px'}}>
                    <div className="pe-4 pt-2" >
                        <div className="pe-3">
                            <DateRangePicker
                                initialSettings={{ 
                                    // startDate: getCurrentDateM('/'), 
                                    startDate: getCurrentDate('/'), 
                                    endDate: getCurrentDate('/'),
                                    locale: {
                                        'format': 'DD/MM/YYYY'
                                    },
                                    maxDate: getCurrentDate('/'),
                                    minDate: getCurrentDateM('/'),
                                }}
                                onCallback={handleDateRangeSelect}
                            >
                                <input type="text" className="form-control input-calendar mb-2" />
                            </DateRangePicker>
                        </div>
                        <div className="fs-10 fw-light mb-3" style={{color:'rgba(109, 120, 141, 0.80)'}}>ข้อมูลจะแสดงย้อนหลังสูงสุด 1 เดือน</div>
                        <div className="d-flex align-items-center mb-3">
                            <div className="form-select-image-2 me-2">
                            <Select

                                className={ ` selectedimg  select-arrow  selectedimg-${Delivery} `}
                                defaultValue={optionsDelivery[0]}
                                options={optionsDelivery}
                                onChange={(e) => {
                                setDelivery(e.value)
                                }}
                                value={
                                optionsDelivery.filter(
                                        item => item.value == Delivery
                                    )
                                }
                                placeholder=""
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                            />
                            </div>
                            <div className="fs-14 fw-500 text-white px-3 py-1 ms-2" style={{borderRadius: '8px',background: '#FF731D' }}>{currentPOS?.code}</div>
                        </div>
                        <CustomInputSearch
                            inputprops={{
                              type: "text",
                              className: "search-input text-12 w-100 pt-2 mb-3",
                              placeholder: "ค้นหาหมายเลขออเดอร์และใบเสร็จ",
                              min: 0,
                              onChange: (e) => {},
                            }}
                            onKeyUp={async (e) => {
                                await setRequestLoading({
                                    isRequestLoading: true
                                });
                                setFilterText(e.target.value);
                                setTimeout(async () => {
                                    await setRequestLoading({
                                        isRequestLoading: false
                                    });
                                }, 200);
                            }}
                        />
                    </div>
                    
                    <div className="bx-bill pe-2" style={{ height: 'calc(100vh - 285px)' }}>
                    {
                        billfilter && billfilter.map((item, index) => 
                        <>
                        {item.closureText &&
                            <div className="text-center m-1 p-1 text-sm">
                               {item.closureText}
                            </div>
                        }
                        <div 
                            className={ item.billID == billdata?.billID ? "card card-bill mb-2 active"  : "card card-bill mb-2"  } 
                            key={index} 
                            // onClick={() => {
                                // setBilldata(item);
                                // {
                                //     item.customerID ? getCustomer(item.billID,item.customerID) : setCustomer(null);
                                // }
                            // }}
                            onClick={() => onBillItemClickHandler(item)}
                        >
                            <div className="d-flex align-items-center mb-1">
                                <div className="me-auto text-12 text-[#B6BBC6]">{item.orderNo != "NA" ? item.orderNo : "-"}</div>
                                {
                                    item.billStatus == 1 ? ( <div className="bill-status-s2">เปิดบิลอยู่</div> ) : ''
                                }
                                {
                                    item.billStatus == 4 ? ( <div className="bill-status-s1">พักบิล</div> ) : ''
                                }
                                {
                                    item.billStatus == 2 ? ( <div className="bill-status-s3">สำเร็จ</div> ) : ''
                                }
                                {
                                    item.billStatus == 3 ? ( <div className="bill-status-s4">ยกเลิก</div> ) : ''
                                }
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="me-auto text-12 text-[#B6BBC6]">{item.receiptNo}</div>
                            </div>
                            <div className="d-flex align-items-center text-16 text-[#061126] mb-1">
                                <div  className={ item.billStatus == 4 ? "tc-1 me-auto" : "me-auto" }>
                                    {item.customerName != "" ? item.customerName : "-"}
                                </div>
                                <div className={ item.billStatus == 4 ? "tc-1" : ""}>
                                    { item.orderPrice && item.orderPrice.net ? (item.orderPrice.net).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00" }
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="me-2">
                                    {
                                    item.delivery == 1 ? ( <div className="dl1">ทานที่ร้าน</div> ) : ''
                                    }
                                    {
                                    item.delivery == 2 ? ( <div className="dl2">กลับบ้าน</div> ) : ''
                                    }
                                    {
                                    item.delivery == 3 ? ( <div className="dl3">เดลิเวอรี่</div> ) : ''
                                    }
                                </div>
                                {
                                    item.delivery == 1 &&
                                    // <div className="text-[#B6BBC6] fs-10 me-1 pt-[1px]">{item.tableName}</div>
                                    <div className="dl1-text pr-2">{item.tableName} - {item.zone}</div>
                                }
                                {
                                    (item.delivery == 2 && item.remark) &&
                                    <div className="dl2-text pr-2">({item.remark})</div>
                                }
                                {
                                    (item.delivery == 3 && item.delivery_remark) &&
                                    <div className="dl3-text pr-2">{item.delivery_remark_channel}({item.delivery_remark})</div>
                                }
                                <div className="text-[#B6BBC6] fs-10 pt-[1px]">{item.orderDate}</div>
                            </div>
                        </div>
                        </>
                        )
                    }

                    </div>
                </div>
            </div>
            <div className="col">
                <div className=" mb-3">
                    <button type="button" 
                        className="btn btn-bill-status me-2" 
                        style={ BillStatus == '' ? {background: '#2DC76D',color: '#FFF',border:'1px solid #2DC76D'} : {} } 
                        onClick={async () => {
                            await setRequestLoading({
                                isRequestLoading: true
                            });
                            setBillStatus('');
                            setTimeout(async () => {
                                await setRequestLoading({
                                    isRequestLoading: false
                                });
                            }, 500);
                        }}
                    >
                        บิลทั้งหมด ({CountStatus1.length+CountStatus2.length+CountStatus3.length+CountStatus4.length})
                    </button>
                    <button type="button" 
                        className="btn btn-bill-status me-2" 
                        style={ BillStatus == 1 ? {background: '#2DC76D',color: '#FFF',border:'1px solid #2DC76D'} : {} } 
                        onClick={async () => {
                            await setRequestLoading({
                                isRequestLoading: true
                            });
                            setBillStatus(1);
                            setTimeout(async () => {
                                await setRequestLoading({
                                    isRequestLoading: false
                                });
                            }, 500);
                        }}
                    >
                        บิลที่เปิดอยู่ ({CountStatus1.length})
                    </button>
                    <button type="button" 
                        className="btn btn-bill-status me-2" 
                        style={ BillStatus == 2 ? {background: '#2DC76D',color: '#FFF',border:'1px solid #2DC76D'} : {} } 
                        onClick={async () => {
                            await setRequestLoading({
                                isRequestLoading: true
                            });
                            setBillStatus(2);
                            setTimeout(async () => {
                                await setRequestLoading({
                                    isRequestLoading: false
                                });
                            }, 500);
                        }}
                    >
                        บิลสำเร็จ ({CountStatus3.length})
                    </button>
                    <button type="button" 
                        className="btn btn-bill-status me-2" 
                        style={ BillStatus == 4 ? {background: '#2DC76D',color: '#FFF',border:'1px solid #2DC76D'} : {} } 
                        onClick={async () => {
                            await setRequestLoading({
                                isRequestLoading: true
                            });
                            setBillStatus(4);
                            setTimeout(async () => {
                                await setRequestLoading({
                                    isRequestLoading: false
                                });
                            }, 500);
                        }}
                    >
                        บิลที่พัก ({CountStatus2.length})
                    </button>
                    <button type="button" 
                        className="btn btn-bill-status" 
                        style={ BillStatus == 3 ? {background: '#2DC76D',color: '#FFF',border:'1px solid #2DC76D'} : {} } 
                        onClick={async () => {
                            await setRequestLoading({
                                isRequestLoading: true
                            });
                            setBillStatus(3);
                            setTimeout(async () => {
                                await setRequestLoading({
                                    isRequestLoading: false
                                });
                            }, 500);
                        }}
                    >
                        บิลที่ยกเลิก ({CountStatus4.length})
                    </button>
                
                </div>

                {  billdata ? (
                    <div className="row gx-3">
                    
                        <div className="col-6">
                            <div className="p-3 position-relative" style={{height:'calc(100vh - 150px)',borderRadius: '8px',background: '#FFF',boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.14)'}}>
                                <div className="d-flex align-items-center">
                                <div className="me-auto ">
                                    <span className="text-14 text-[#2DC76D] me-2">Order No.</span> <span className="text-14 text-[#2F4858]">
                                    { billdata?.orderNo && billdata?.orderNo != "NA" ? billdata.orderNo : "-"}
                                    </span>
                                </div>
                                { billdata ? 
                                    billdata.billStatus == 1 ?  <div className="bill-status-s2">เปิดบิลอยู่</div> : 
                                    billdata.billStatus == 4 ?  <div className="bill-status-s1">พักบิล</div> : 
                                    billdata.billStatus == 2 ?  <div className="bill-status-s3">สำเร็จ</div>: 
                                    billdata.billStatus == 3 ?  <div className="bill-status-s4">ยกเลิก</div> : ''
                                : "-"
                                }
                                </div>

          

                                <div>
                                <span className="text-14 text-[#2DC76D] me-2">Receipt No.</span>
                                <span className="text-[#2F4858] text-14">{ billdata ? billdata.receiptNo : "-"} </span>
                                </div>
                                <div className="row g-0">
                                <div className="col">
                                    <div>
                                    <span className="text-12 text-[#2DC76D] me-2">วันที่ :</span>
                                    <span className="text-12 text-[#2F4858] me-2">{ billdata ? billdata.orderDate : "-"} </span>
                                    <span className="text-12 text-[#2DC76D] me-2">จุดขาย :</span>
                                    <span className="text-12 text-[#2F4858]">{ billdata ? billdata.pos : "-"}</span>
                                    </div>
                                    <div>
                                    <span className="text-12 text-[#2DC76D] me-2">พนักงาน :</span>
                                    <span className="text-12 text-[#2F4858]">{ billdata ? billdata.staff : "-"}</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="text-14 text-[#2F4858] text-end mb-1">Total</div>
                                    <div className="text-16 fw-500 text-[#2DC76D]">{ billdata && billdata.orderPrice && billdata.orderPrice.net ? billdata.orderPrice.net.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}</div>
                                </div>
                                </div>
                                <div className="my-2">
                                    {
                                    billdata.delivery == 1 ? ( <div className="dl1">ทานที่ร้าน</div> ) : ''
                                    }
                                    {
                                    billdata.delivery == 2 ? ( <div className="dl2">กลับบ้าน</div> ) : ''
                                    }
                                    {
                                    billdata.delivery == 3 ? ( <div className="dl3">เดลิเวอรี่</div> ) : ''
                                    }
                                </div>
                                <div>
                                <div className="">
                                    <div className="row gx-2 tc-17 fs-12">
                                    <div className="col-7">รายการ</div>
                                    <div className="col-2 text-center">จำนวน</div>
                                    <div className="col-3 text-end">ราคา</div>
                                    </div>
                                </div>
                                <hr className="mt-1 mb-0" />
                                <div className="bx-main-order-list " style={{ height:'calc(100vh - 440px)' }}>
                                    <ul className="p-0 m-0  w-100 ">
                                        {
                                        billdata.product && billdata.product.map((item, index) =>
                                            (billdata.billStatus != 3 && item.productStatus == 3) ? null :
                                            <li className="px-2" key={item.id}  >
                                            <div className="row gx-2 align-items-center">
                                                <div className="col-7 d-flex align-items-center">
                                                <img 
                                                    src={item.productImage} 
                                                    className="foodimg" 
                                                    onError={(e) => e.target.src = DefaultProductImg}
                                                />
                                                <div className="fs-12">{item.productName}</div>
                                                </div>
                                                <div className="col-2 fs-14 text-center foodQtyli relative">
                                                    <p className={`${item.productCancel > 0 ? 'text-[var(--color-red)]' : ''} ${billdata.billStatus == 3 ? 'line-through' : ''}`}>{billdata.billStatus != 3 ? item.productRemain : item.productQty}</p>
                                                    { (item.productCancel > 0 && billdata.billStatus != 3) &&
                                                        <span className="absolute line-through top-[calc(min(0.78vw,8px)*-1.5)] right-4">{item.productAmount}</span>
                                                    }
                                                </div>
                                                <div className="col-3 fs-14 text-end "><span className="foodPriceli">{item.productSumPrice.toLocaleString()}</span>.-</div>
                                            </div>
                                            <div className="d-flex flex-wrap bx-Foption ">
                                                {item.optionstandard && item.optionstandard.map((item2, index) => 
                                                <div className="Foption mt-2" key={item2.listID}>{item2.listName} {item2.optionQty > 1 && `x ${item2.optionQty}`}</div>
                                                )}
                                                {item.optionproduct && item.optionproduct.map((item2, index) => 
                                                <div className="Foption mt-2" key={item2.listID}>{item2.listName} {item2.optionQty > 1 && `x ${item2.optionQty}`}</div>
                                                )}
                                            </div>
                        
                                            { item.optionnote && item.optionnote.length > 0 || item.note != "" ?
                                                (
                                                <div className="fNote ">
                                                {item.optionnote && item.optionnote.map((item2, index) => 
                                                    <span key={item2.listID}>
                                                    {item2.listName}{index != item.optionnote.length - 1 && ", "}
                                                    </span>
                                                )}
                        
                                                { item.note }
                                                </div>
                                                ) : ''
                                            }
                                            </li>
                                        )
                                        }
                                    </ul>
                                </div>
                                </div>

                                <div className="absolute p-3 bottom-[0%] w-100 left-[0%]">
                                    <Collapse in={collapseShow}>
                                        <div id="collapse1">
                                            <div className="card card-body border-0 bg-c1 px-3 py-2" >
                                                <div className="d-flex fs-14 tc-10 px-1">
                                                <div className="me-auto">ยอดรวม</div>
                                                <div className="" id="txsum">
                                                    { billdata.orderPrice.sumprice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                                                </div>
                                                </div>
                                                <div className="d-flex fs-12 tc-10 px-1">
                                                <div className="me-auto">ส่วนลด</div>
                                                <div id="txdiscount">
                                                    { 
                                                        billdata?.orderPrice?.discount_round > 0 && billdata?.orderPrice?.discount_from_point > 0 ? 
                                                            (billdata.orderPrice.discount_round + billdata.orderPrice.discount_from_point).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                                                        billdata?.orderPrice?.discount_round > 0 ?
                                                            billdata.orderPrice.discount_round.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                                                        billdata?.orderPrice?.discount_from_point > 0 ?
                                                            billdata.orderPrice.discount_from_point.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                                                        billdata.orderPrice.discount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                    }฿
                                                </div>
                                                </div>
                                                <div className="d-flex fs-12 tc-10 px-1">
                                                <div className="me-auto">ภาษี</div>
                                                <div id="txtax">{ billdata.orderPrice.vat.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }฿</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                    <button
                                        className="btn collapse-total collapsed "
                                        type="button"
                                        onClick={() => setCollapseShow(!collapseShow)}
                                        aria-controls="collapse1"
                                        aria-expanded={collapseShow}
                                        style={{borderRadius: '8px'}}
                                        >
                                        <div className="d-flex align-items-center  ">
                                            <div className="me-auto">ยอดรวมทั้งหมด </div>
                                            <div id="txnet">{ billdata && billdata.orderPrice.net && billdata.orderPrice.net ? billdata.orderPrice.net.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00" }฿</div>
                                        </div>
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div className="col-6">
                            <div className="ps-3 py-2 d-flex flex-column" style={{height:'calc(100vh - 150px)',borderRadius: '8px',background: '#FFF',boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.14)'}}>
                                <div className="fs-18 fw-500 text-[#2DC76D] mb-3">ข้อมูลสมาชิก</div>

                            <div className="pe-3" style={{ height:'calc(100vh - 270px)',overflow:'auto' }}>
                                <div className="d-flex align-items-center mb-3">
                                    <img
                                    className="aspect-square w-[min(6.13vw,62px)] rounded-full object-cover"
                                    src="/img/avatar-female.png"
                                    alt=""
                                    />
                                    <div className="ms-3 fs-14 text-[#2F4858]">
                                        {/* <div> {customer ? customer.customerName : '-'} </div> */}
                                        <div> {billdata.member ? billdata.member.name : '-'} </div>
                                        {/* <div> {customer ? customer.customerPhone : '-'}  </div> */}
                                        <div> {billdata.member ? billdata.member.phone_number : '-'}  </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">แต้มสะสมที่มีอยู่</div>
                                    {/* <div className="text-[#2F4858]">{customer ? customer.point.all : '-'}</div> */}
                                    {/* <div className="text-[#2F4858]">{customer ? customer.point.all : billdata.member ? billdata.member.point : '-'}</div> */}
                                    <div className="text-[#2F4858]">{billdata.billStatus == 2 && billdata.member ? billdata.memberPrevPoint : billdata.member?.current_point ? billdata.member.current_point : '-'}</div>
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">คะแนนสะสมที่ใช้</div>
                                    {/* <div className="text-[#2F4858]">{customer ? customer.point.usage : '-'}</div> */}
                                    <div className="text-[#2F4858]">{billdata.usagePoint ? billdata.usagePoint : '-'}</div>
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">ได้รับคะแนนสะสม</div>
                                    {/* <div className="text-[#2F4858]">{customer ? customer.point.receive : '-'}</div> */}
                                    {/* <div className="text-[#2F4858]">{(customer && billdata.billStatus == 2 && orderBill?.total) ? Math.floor(orderBill.total / 25) : '-'}</div> */}
                                    <div className="text-[#2F4858]">{billdata.receivePoint ? billdata.receivePoint : '-'}</div>
                                    
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">แต้มสะสมที่มีอยู่</div>
                                    {/* <div className="text-[#2F4858]">{customer ?  (customer.point.all + customer.point.receive  ) -  customer.point.usage : '-'}</div> */}
                                    <div className="text-[#2F4858]">{billdata.memberNetPoint ? billdata.memberNetPoint : '-'}</div>
                                </div>
                                <hr />
                                <div className="fs-18 fw-500 text-[#2DC76D] mb-2 mt-2">ส่วนลดและโปรโมชั่นที่ได้รับ</div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">ส่วนลดท้ายบิล{orderBill?.discount_percent > 0 && ` (${orderBill.discount_percent}%)`}</div>
                                    {/* <div className="text-[#2F4858]">{customer ? numberWithTwoDecimals(customer.discount.endBill) : '-'}</div> */}
                                    <div className="text-[#2F4858]">
                                        {  
                                            billdata.orderPrice?.discount_round ? numberWithTwoDecimals(billdata.orderPrice.discount_round) :
                                            billdata.orderPrice?.discount ? numberWithTwoDecimals(billdata.orderPrice.discount) :
                                            '-'
                                        }
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">ส่วนลดโปรโมชั่น</div>
                                    <div className="text-[#2F4858]">{customer ? numberWithTwoDecimals(customer.discount.promotion) : '-'}</div>
                                </div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">ส่วนลดการใช้คะแนน</div>
                                    {/* <div className="text-[#2F4858]">{customer ? numberWithTwoDecimals(customer.discount.point) : '-'}</div> */}
                                    <div className="text-[#2F4858]">
                                        {  
                                            billdata.bill?.is_discount_point && billdata.bill?.discount_from_point ? numberWithTwoDecimals(billdata.bill.discount_from_point) : '-'
                                        }
                                    </div>
                                </div>
                                <hr />
                                <div className="fs-18 fw-500 text-[#2DC76D] mb-2 mt-2">ค่าใช้จ่ายอื่นๆ</div>
                                <div className="d-flex align-items-center mb-1">
                                    <div className="me-auto text-[#2F4858] fs-14 fw-light">ค่าธรรมเนียมบัตรเครดิต</div>
                                    {/* <div className="text-[#2F4858]">{customer ? customer.payment.credit : '-'}</div> */}
                                    <div className="text-[#2F4858]">
                                        {billdata.billStatus == 2 && billdata.payments.fee ? numberWithTwoDecimals(billdata.payments.fee) : '-'}
                                    </div>
                                </div>
                                <hr />
                                <div className={`fs-18 fw-500 text-[#2DC76D] mb-2 mt-2 ${(!billdata?.payments || billdata?.payments.length == 0) && "mb-auto"}`}>ช่องทางการชำระเงิน</div>
                                {/* { (billdata.billStatus == 2 && billdata.payments.cash && billdata.payments.cash > 0) ?
                                    <div className="d-flex align-items-center">
                                        <div className="me-auto text-[#2F4858] fs-14 fw-light">เงินสด</div>
                                        <div className="text-[#2F4858]">{billdata.billStatus == 2 &&  billdata.payments.cash ? billdata.payments.cash : '-'}</div>
                                    </div>
                                    : null
                                } */}
                                { (billdata.billStatus == 2 && billdata.paymentsAndChannels.find(pac => pac.type == "Cash").total > 0) ?
                                    <div className="d-flex align-items-center">
                                        <div className="me-auto text-[#2F4858] fs-14 fw-light">เงินสด</div>
                                        {/* <div className="text-[#2F4858]">{customer ? customer.payment.cash : '-'}</div> */}
                                        <div className="text-[#2F4858]">{billdata.paymentsAndChannels.find(pac => pac.type == "Cash").total ? numberWithTwoDecimals(billdata.paymentsAndChannels.find(pac => pac.type == "Cash").total) : '-'}</div>
                                    </div>
                                    : null
                                }
                                { (billdata.billStatus == 2 && billdata.paymentsAndChannels.find(pac => pac.type == "Transfer").total > 0) ?
                                    <div>
                                        <div className="d-flex align-items-center">
                                            <div className="me-auto text-[#2F4858] fs-14 fw-light">เงินโอน</div>
                                            {/* <div className="text-[#2F4858]">{customer ? customer.payment.tranfers : '-'}</div> */}
                                            {/* <div className="text-[#2F4858]">{billdata.billStatus == 2 && billdata.payments.transfer ? billdata.payments.transfer : '-'}</div> */}
                                        </div>
                                        {billdata.paymentsAndChannels.find(pac => pac.type == "Transfer").channels.map(pm => (
                                            <div className="d-flex align-items-center">
                                                <div className="me-auto text-[#2F4858] fs-14 fw-light pl-4">{pm.name}</div>
                                                <div className="text-[#2F4858]">{numberWithTwoDecimals(pm.total)}</div>
                                            </div>
                                        ))}
                                    </div>
                                    : null
                                }
                                { (billdata.billStatus == 2 && billdata.payments.promptpay && billdata.payments.promptpay > 0) ?
                                    <div className="d-flex align-items-center">
                                        <div className="me-auto text-[#2F4858] fs-14 fw-light">พร้อมเพย์</div>
                                        {/* <div className="text-[#2F4858]">{customer ? customer.payment.promptpay : '-'}</div> */}
                                        <div className="text-[#2F4858]">{billdata.billStatus == 2 && billdata.payments.promptpay ? numberWithTwoDecimals(billdata.payments.promptpay) : '-'}</div>
                                    </div>
                                    : null
                                }
                                { (billdata.billStatus == 2 && billdata.paymentsAndChannels.find(pac => pac.type == "Credit").total > 0) ?
                                    <div>
                                        <div className="d-flex align-items-center">
                                            <div className="me-auto text-[#2F4858] fs-14 fw-light">บัตรเครดิต</div>
                                            {/* <div className="text-[#2F4858]">{customer ? customer.payment.credit : '-'}</div> */}
                                            {/* <div className="text-[#2F4858]">{billdata.billStatus == 2 && billdata.payments.credit ? billdata.payments.credit : '-'}</div> */}
                                        </div>
                                        {billdata.paymentsAndChannels.find(pac => pac.type == "Credit").channels.map(pm => (
                                            <div className="d-flex align-items-center">
                                                <div className="me-auto text-[#2F4858] fs-14 fw-light pl-4">{pm.name}</div>
                                                <div className="text-[#2F4858]">{numberWithTwoDecimals(pm.total)}</div>
                                            </div>
                                        ))}
                                    </div>
                                    : null
                                }
                                {/* { (billdata.billStatus == 2 && billdata.payments.other && billdata.payments.other > 0) ?
                                    <div className="d-flex align-items-center">
                                        <div className="me-auto text-[#2F4858] fs-14 fw-light">อื่นๆ</div>
                                        <div className="text-[#2F4858]">{billdata.billStatus == 2 && billdata.payments.other ? billdata.payments.other : '-'}</div>
                                    </div>
                                    : null
                                } */}
                                { (billdata.billStatus == 2 && billdata.paymentsAndChannels.find(pac => pac.type == "Delivery").total > 0) ?
                                    <div className="d-flex align-items-center">
                                        <div className="me-auto text-[#2F4858] fs-14 fw-light">{billdata.paymentsAndChannels.find(pac => pac.type == "Delivery").name} {billdata.delivery_remark && `(${billdata.delivery_remark})`}</div>
                                        <div className="text-[#2F4858]">{numberWithTwoDecimals(billdata.paymentsAndChannels.find(pac => pac.type == "Delivery").total)}</div>
                                    </div>
                                    : null
                                }
                                { (billdata.billStatus == 2 && billdata.paymentsAndChannels.find(pac => pac.type == "Delivery").channels.length > 0) ?
                                    <div>
                                        <div className="d-flex align-items-center">
                                            <div className="me-auto text-[#2F4858] fs-14 fw-light">{billdata.paymentsAndChannels.find(pac => pac.type == "Delivery").name} {billdata.delivery_remark && `(${billdata.delivery_remark})`}</div>
                                            {/* <div className="text-[#2F4858]">{billdata.billStatus == 2 && billdata.payments.other ? billdata.payments.other : '-'}</div> */}
                                        </div>
                                        {billdata.paymentsAndChannels.find(pac => pac.type == "Delivery").channels.map(pm => (
                                            <div className="d-flex align-items-center">
                                                <div className="me-auto text-[#2F4858] fs-14 fw-light pl-4">{pm.name}</div>
                                                <div className="text-[#2F4858]">{numberWithTwoDecimals(pm.total)}</div>
                                            </div>
                                        ))}
                                    </div>
                                    : null
                                }
                            </div>


                                <div className="mb-auto"></div>
                                <div className="pt-2 pb-2 d-flex justify-content-center">
                                    <button className="btn btn-x1 text-[#6D788D] bg-[#E6E6E6]"
                                        onClick={() => { 
                                            // console.log('billdata: ', billdata)
                                            // setModalCancleBillOpen(true);
                                            setBillcancledata( prev => ({ ...prev, billID: billdata.billID, ...billdata }))
                                            setModalCancleBillOpen(true);
                                        }}
                                        disabled={ 
                                            billdata.billStatus == 4 || billdata.status_id == 4 || //บิลพัก
                                            billdata.billStatus == 3 || billdata.status_id == 3 || //บิลยกเลิก
                                            billdata.billStatus == 2 || billdata.status_id == 2 //บิลสำเร็จ
                                        }
                                    >
                                        ยกเลิก
                                    </button>
                                    <button 
                                        className="btn mx-3 btn-x1 text-[#FFF] bg-[#2DC76D]"
                                        onClick={async () => onCallBillBtnHandler()}
                                        // disabled={ !customer }
                                        disabled={ billdata.billStatus === 2 || billdata.billStatus === 3 }
                                    >
                                        เรียกบิล
                                    </button>
                                    <button 
                                        className="btn btn-x1 btn-x1-print text-[#FFF] "
                                        // disabled={ !customer }
                                        disabled={ billdata.billStatus === 4 }
                                        onClick={async () => { await onPrintBillBtnHandler(); }}
                                    >
                                        พิมพ์
                                    </button>
                                </div>
                            </div>              
                        </div>

                        <div style={{ display: "none" }}>
                            {/* { orderBill && */}
                                <BillPaperToPrint
                                    key={'print-invoice'} 
                                    ref={billInvoiceRef} 
                                    order={billdata}
                                    user={user}
                                    bill={orderBill}
                                    pos={currentPOS}
                                    branch={branch}
                                    type="invoice"
                                    copy={true}
                                />
                            {/* } */}
                        </div>

                        <div style={{ display: "none" }}>
                            {/* { orderBill && */}
                                <BillPaperToPrint 
                                    key={'print-receipt'} 
                                    ref={billRef} 
                                    order={billdata}
                                    user={user}
                                    bill={orderBill}
                                    pos={currentPOS}
                                    branch={branch}
                                    type="receipt"
                                    copy={true}
                                />
                            {/* } */}
                        </div>
                        
                    </div>

                ) : (

                        <div className="d-flex align-items-center justify-content-center" style={{ height:'calc(100vh - 150px)' } }>
                            <div>ไม่พบรายการ</div>
                        </div>
                    )
                }
            </div>
        </div>
      </div>

      <ModalCancleBill
        modalOpenObj={{
          modalOpen: modalCancleBillOpen,
          setModalOpen: setModalCancleBillOpen,
        }}
        modalPinOpenObj={{
          modalOpen: modalPinOpen,
          setModalOpen: setModalPinOpen,
        }}
        billcancleObj={{
          billcancledata,
          setBillcancledata,
        }}
      />

      <ModalPin
        modalOpenObj={{
          modalOpen: modalPinOpen,
          setModalOpen: setModalPinOpen,
        }}
        modalSuccessOpenObj={{
          modalOpen: modalSuccessOpen,
          setModalOpen: setModalSuccessOpen,
        }}
        billcancleObj={{
          billcancledata,
          setBillcancledata,
        }}
        onConfirm={onCancelBillConfirmHandler}
      />

      <ModalSuccess
        modalOpenObj={{
          modalOpen: modalSuccessOpen,
          setModalOpen: setModalSuccessOpen,
        }}
      />

      <LoadingSpinner 
        modalOpenObj={{
          modalOpen: isRequestLoading,
          setModalOpen: null
        }}
      />

    </div>
  );
}
