import { atom, selector, useRecoilState, useSetRecoilState, useResetRecoilState } from 'recoil';
import { getAllPOS, getPOS, updatePOS } from '../services/pos';
import { authPin } from '../services/auth';
import { createPOSActivity, getPOSActivity, updatePOSActivity, getAllPOSActivity, getPOSClosureSummary } from 'services/pos-activity';
import { createStaffLogin } from 'services/staff-log';
import { authState } from './auth';

export const posStatusCheck = () => {
    const isPosStarted = window.localStorage.getItem("isPosStarted") === "true";
    // console.log("🚀 ~ file: pos.js:9 ~ posStatusCheck ~ isPosStarted:", isPosStarted)
    if (isPosStarted) {
        window.localStorage.setItem("isPosStarted", true);
        return true;
    } 
    else {
        window.localStorage.setItem("isPosStarted", false);
        return false;
    }
}

export const kitchenStatusCheck = () => {
    const isKitchenStarted = window.localStorage.getItem("isKitchenStarted") === "true";
    // console.log("🚀 ~ file: pos.js:22 ~ kitchenStatusCheck ~ isKitchenStarted:", isKitchenStarted)
    if (isKitchenStarted) {
        window.localStorage.setItem("isKitchenStarted", true);
        return true;
    } 
    else {
        window.localStorage.setItem("isKitchenStarted", false);
        return false;
    }
}


export const currentPOSCheck = () => {
    return JSON.parse(window.localStorage.getItem("currentPOS"));
}

export const currentPOSActivityCheck = () => {
    return JSON.parse(window.localStorage.getItem("currentPOSActivity"));
}

// State
export const posState = atom({
    key: 'posState',
    default: {
        pos: [],
        isPosStarted: posStatusCheck(),
        isPosFinished: false,
        isKitchenStarted: kitchenStatusCheck(),
        isKitchenFinished: false,
        currentPOS: currentPOSCheck(),
        currentPOSActivity: currentPOSActivityCheck(),
        posActivities: [],
        summary: {
            posId: 0,
            initialAmount: 0,
            totalDeposit: 0,
            totalWithdraw: 0,
            totalSales: 0,
            amount1000: 0,
            amount500: 0,
            amount100: 0,
            amount50: 0,
            amount20: 0,
            amount10: 0,
            amount5: 0,
            amount1: 0,
            amountTotal: 0,
            totalCash: 0,
            totalTransfer: 0,
            totalOther: 0,
        },
        branch: null,
        closure: {
            cashCountAmount: 0,
            diff: 0
        }
    },
});

export const selectedPosSettingState = atom({
    key: 'selectedPosSettingState',
    default: {
        posId: null,
        posName: null,
        startTime: null,
        endTime: null,
        duration: null,
        initialAmount: 0,
        circulation: 0,
        closureCashBalance: 0,
        amount1000: 0,
        amount500: 0,
        amount100: 0,
        amount50: 0,
        amount20: 0,
        amount10: 0,
        amount5: 0,
        amount1: 0,
        amountTotal: 0,
        employeeBranchAccountId: 0,
        employeeId: 0,
        activity: null
    }
});

// Action
export const useFetchAllPos = () => {
    const setState = useSetRecoilState(posState);

    return async (branchId) => {
        try {
            const fetch = await getAllPOS(branchId);
            // Sort POS by its order
            const sorted = [...fetch.pos].sort((a,b) => a.order - b.order)
            setState(state => ({ ...state, pos: sorted }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useFetchPos = () => {
    const setState = useSetRecoilState(posState);

    return async () => {
        try {
            const fetch = await getAllPOS();
            setState(state => ({ ...state, pos: fetch.pos }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useUpdatePos = () => {
    const setState = useSetRecoilState(posState);

    return async (pos) => {
        try {
            const update = await updatePOS(pos);
            const fetch = await getAllPOS();
            window.localStorage.setItem("isPosStarted", false);
            window.localStorage.removeItem("currentPOS");
            window.localStorage.removeItem("currentPOSActivity");
            setState(state => ({ ...state, pos: fetch.pos, isPosStarted: false }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

// Start POS
export const useCreatePosActivity = () => {
    const setState = useSetRecoilState(posState);
    const setAuthState = useSetRecoilState(authState);

    return async (posSetting, logMachine) => {
        // console.log("🚀 ~ file: pos.js:116 ~ return ~ posSetting:", posSetting)
        try {
            const update = await updatePOS(posSetting, 1, true);
            const create = await createPOSActivity({ ...posSetting, activityTypeId: 1 });
            const staffLog = await createStaffLogin({ ...posSetting, activityTypeId: 1 }, logMachine);
            // console.log("🚀 ~ return ~ staffLog:", staffLog)
            const fetch = await getPOSActivity(create.posActivity.id);
            // window.localStorage.setItem("isPosStarted", true);
            window.localStorage.setItem("currentPOS", JSON.stringify(fetch.posActivity.pos));
            window.localStorage.setItem("currentPOSActivity", JSON.stringify(fetch.posActivity));
            if(posSetting.type == "kitchen") {
                // console.log('kitchen na')
                window.localStorage.setItem("isKitchenStarted", true);
                setState(state => ({ ...state, currentPOS: fetch.posActivity.pos, currentPOSActivity: fetch.posActivity, isKitchenStarted: true, isPosStarted: false }));
                // setAuthState(state => ({ ...state, logMachine: staffLog.login_machine }));
            } else {
                // console.log('pos na')
                window.localStorage.setItem("isPosStarted", true);
                setState(state => ({ ...state, currentPOS: fetch.posActivity.pos, currentPOSActivity: fetch.posActivity, isPosStarted: true, isKitchenStarted: false }));
                // setAuthState(state => ({ ...state, logMachine: staffLog.login_machine }));
            }
        } catch (error) {
            console.error('Error create data:', error);
        }
    };
};

// Close POS
export const useClosePOSActivity = () => {
    const setState = useSetRecoilState(posState);
    const resetState = useResetRecoilState(posState)
    const resetAuthState = useResetRecoilState(authState)

    return async (posSetting, operationDate, isKitchen) => {
        // console.log("🚀 ~ return ~ isKitchen:", isKitchen)
        // console.log("🚀 ~ return ~ operationDate:", operationDate)
        // console.log("🚀 ~ return ~ posSetting:", posSetting)
        try {
            const update = await updatePOS(posSetting, 2, false);
            const create = await createPOSActivity(posSetting, operationDate);
            const fetch = await getAllPOS();
            window.localStorage.setItem("isPosStarted", false);
            window.localStorage.setItem("isKitchenStarted", false);
            window.localStorage.removeItem("currentPOS");
            window.localStorage.removeItem("currentPOSActivity");
            // setState(state => ({ ...state, currentPOS: fetch.posActivity.pos, isPosStarted: false, isKitchenStarted: false }));
            if(isKitchen) {
                window.localStorage.removeItem("allDerCafeToken");
                // resetState();
                // resetAuthState();
                // window.localStorage.removeItem("currentPOS");
                // window.localStorage.removeItem("currentPOSActivity");
                window.location.reload(true);
            }
        } catch (error) {
            console.error('Error create data:', error);
        }
    };
};

export const useClearPOS = () => {
    const setState = useSetRecoilState(posState);
    const resetState = useResetRecoilState(posState)
    return async () => {
        try {
            window.localStorage.setItem("isPosStarted", false);
            window.localStorage.setItem("isKitchenStarted", false);
            window.localStorage.removeItem("currentPOS");
            window.localStorage.removeItem("currentPOSActivity");
            resetState();
        } catch (error) {
            console.error('Error create data:', error);
        }
    };
};

export const useFetchAllPosActivity = () => {
    const setState = useSetRecoilState(posState);

    return async () => {
        try {
            const fetch = await getAllPOSActivity();
            // Sort POS by its order
            // const sorted = [...fetch.pos].sort((a,b) => a.order - b.order);
            setState(state => ({ ...state, posActivities: fetch.posActivity }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useDrawerPosActivity = () => {
    const setState = useSetRecoilState(posState);

    return async (posSetting, operationDate) => {
        // console.log("🚀 ~ file: pos.js:169 ~ return ~ posSetting:", posSetting)
        try {
            const create = await createPOSActivity(posSetting, operationDate);
            const fetch = await getAllPOSActivity();
            const sorted = [...fetch.posActivity].sort((a,b) => a.order - b.order)
            setState(state => ({ ...state, posActivity: sorted }));
        } catch (error) {
            console.error('Error create data:', error);
        }
    };
};

export const useCreateStaffLogin = () => {
    const setState = useSetRecoilState(posState);
    const setAuthState = useSetRecoilState(authState);

    return async (posSetting, isSwitch, logMachine) => {
        // console.log("🚀 ~ file: pos.js:189 ~ return ~ posSetting:", posSetting)
        try {
            const staffLogin = await createStaffLogin(posSetting,logMachine);
            // console.log("🚀 ~ return ~ staffLogin:", staffLogin);
            const pos = await getAllPOSActivity();
            // console.log("🚀 ~ file: pos.js:192 ~ return ~ pos:", pos)
            const filter = pos.posActivity.reverse().filter(p => p.pos_id == posSetting.posId && p.activity_type_id == 1)[0];
            // console.log("🚀 ~ file: pos.js:195 ~ return ~ filter:", filter)
            const fetch = await getPOSActivity(filter.id);
            // console.log("🚀 ~ return ~ fetch:", fetch)
            window.localStorage.setItem("isPosStarted", true);
            window.localStorage.setItem("currentPOS", JSON.stringify(fetch.posActivity.pos));
            // window.localStorage.setItem("currentPOSActivity", JSON.stringify(fetch.posActivity));
            window.localStorage.setItem("currentPOSActivity", JSON.stringify(filter));
            setState(state => ({ ...state, currentPOS: fetch.posActivity.pos, isPosStarted: true, currentPOSActivity: filter }));
            // setAuthState(state => ({ ...state, logMachine: staffLogin.login_machine }));

            if(isSwitch) {
                const auth = await authPin(posSetting.employeeId, posSetting.employeePin);
                return true
            }
        } catch (error) {
            console.error('Error create data:', error);
        }
    }
}

// Optimize for reusable function
export const useCreateStaffKitchenLogin = () => {
    const setState = useSetRecoilState(posState);

    return async (posSetting, isSwitch, logMachine) => {
        // console.log("🚀 ~ file: pos.js:189 ~ return ~ posSetting:", posSetting)
        try {
            const staffLogin = await createStaffLogin(posSetting, logMachine);
            const pos = await getAllPOSActivity();
            // console.log("🚀 ~ file: pos.js:192 ~ return ~ pos:", pos)
            const filter = pos.posActivity.reverse().filter(p => p.pos_id == posSetting.posId && p.activity_type_id == 1)[0];
            // console.log("🚀 ~ file: pos.js:195 ~ return ~ filter:", filter)
            const fetch = await getPOSActivity(filter.id);
            // console.log("🚀 ~ return ~ fetch:", fetch)
            window.localStorage.setItem("isKitchenStarted", true);
            window.localStorage.setItem("currentPOS", JSON.stringify(fetch.posActivity.pos));
            // window.localStorage.setItem("currentPOSActivity", JSON.stringify(fetch.posActivity));
            window.localStorage.setItem("currentPOSActivity", JSON.stringify(filter));
            setState(state => ({ ...state, currentPOS: fetch.posActivity.pos, isKitchenStarted: true, currentPOSActivity: filter }));

            if(isSwitch) {
                const auth = await authPin(posSetting.employeeId, posSetting.employeePin);
                return true
            }
        } catch (error) {
            console.error('Error create data:', error);
        }
    }
}

export const useGetPOSClosureSummary = () => {
    const setState = useSetRecoilState(posState);

    return async (posId) => {
        // console.log("🚀 ~ file: pos.js:230 ~ return ~ posId:", posId)
        try {
            const get = await getPOSClosureSummary(posId);
            setState(state => ({ ...state, summary: get.summary }))
        } catch (error) {
            console.error('Error fetch data:', error);
        }
    }
}

export const useSetPosClosure = () => {
    const setState = useSetRecoilState(posState);
    return async (cashCountAmount, diff) => {
        try {
            // const setState = useSetRecoilState(posState);
            const closure = {
                cashCountAmount: cashCountAmount,
                diff: diff
            }
            setState(state => ({ ...state, closure: closure}))
            
        } catch (error) {
            console.error('Error useSetPosClosure:', error);
        }
    }
}