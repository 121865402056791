import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import BillDetail from "components/layouts/BillDetail";
import PayCash from "components/layouts/payment/Cash";
import PayQr from "components/layouts/payment/QrCode";
import PayTranfer from "components/layouts/payment/Tranfers";
import PayDelivery from "components/layouts/payment/Delivery";
import PayCredit from "components/layouts/payment/Credit";
import Staff from "components/layouts/payment/Staff";

import PayPoint from "components/layouts/payment/Point";
import PayOther from "components/layouts/payment/Other";
import PayDiscount from "components/layouts/payment/Discount";

import ModalSuccess from "components/layouts/ModalSuccess";
import ModalDiscountList from "components/layouts/ModalDiscountList";
import { nanoid } from 'nanoid'
import Form from 'react-bootstrap/Form';

import { useCreatePayment } from "state/payment";
import { numberWithTwoDecimals } from "utils/numberFormat";

export default function ModalPayment({
  modalSuccessOpenObj,
  modalOpenObj,
  paymentChannels,
  order,
  bill,
  user,
  currentPos,
  currentBranch,
  onPrintBtn,
  setCurrentPayment,
  member,
  staffList,
  setIsPaymentMode,
  currentPOS,
  updateOrderStaff,
  updateOrderRemark,
  endBillDiscount,
  setEndBillDiscount,
  setRequestLoading,
  printBillFull,
  printBillFullSuccess,
  members,
  ...props
}) {
  // console.log("🚀 ~ paymentChannels:", paymentChannels)
  // console.log("🚀 ~ file: ModalPayment.jsx:31 ~ user:", user)

    const [BtnpaymentDisabled, setBtnpaymentDisabled] = useState(true);
    const [BtnenterDisabled, setBtnenterDisabled] = useState(false);

    const [StaffSelect, setStaffSelect] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    const [modalTextSuccess, setModalTextSuccess] = useState(false);
    const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
    const [modalDiscountListOpen, setModalDiscountListOpen] = useState(false);

    const [moneypay, setMoneypay] = useState('0.00');
    const [PaymentObj, setPaymentObj] = useState([]);
    // console.log("🚀 ~ PaymentObj:", PaymentObj)
    const [PaymentSelect, setPaymentSelect] = useState({"paymentChannelID":"0"});
    // console.log("🚀 ~ PaymentSelect:", PaymentSelect)

    const [discount, setDiscount] = useState([]);

    const [creditFeeType, setCreditFeeType] = useState(0);
    const [creditFeeValue, setCreditFeeValue] = useState(0);

    const [provider, setProvider] = useState(null);
    // console.log("🚀 ~ provider:", provider)
    const [remark, setRemark] = useState(null);
    
    const [deliverySelect, setDeliverySelect] = useState(null)
    const [DeliveryDetail, setDeliveryDetail] = useState({
      "remark":"",
      "paymentChannel": 2
    });

    // const [PaymentDetail, setPaymentDetail] = useState({
    //    "orderNo":"OR202028040007",
    //    "paymentNet":"430.00",
    //    "paymentTotal":'0.00',
    //    "paymentRemaining":'-430.00',
    //    "paymentChange":"0.00",
    // });

    const [PaymentDetail, setPaymentDetail] = useState({
       orderNo: "",
       paymentNet: "0.00" ,
       paymentTotal: "0.00",
       paymentRemaining: "0.00",
       paymentChange: "0.00",
    });
    // console.log("🚀 ~ PaymentDetail:", PaymentDetail)

    // Coupon, Promotion and Other are disable for beta phase.
    const [PaymentChannelList, setPaymentChannelList] = useState(
      [
        {
          "paymentChannelID": 0,
          "paymentChannelName": "รายละเอียด",
          "icon":" detail",
          "disabled": false
        },
        {
          "paymentChannelID": 1,
          "paymentChannelName": "เงินสด",
          "icon": "cash",
          "disabled": true,
          "channels": []
        },
        {
          "paymentChannelID": 2,
          "paymentChannelName": "QR",
          "icon": "qr",
          "disabled": true,
          "channels": []
        },
        {
          "paymentChannelID": 3,
          "paymentChannelName": "เงินโอน",
          "icon": "tranfers",
          "disabled": true,
          "channels": []
        },
        {
          "paymentChannelID": 4,
          "paymentChannelName": "Delivery",
          "icon": "delivery",
          "disabled": true,
          "channels": []
        },
        {
          "paymentChannelID": 5,
          "paymentChannelName": "บัตรเครดิต",
          "icon": "credit",
          "disabled": true,
          "channels": []
        },
        {
          "paymentChannelID": 6,
          "paymentChannelName": "คูปอง/ส่วนลด",
          "icon": "coupon",
          "disabled": false,
          "channels": []
        },
        {
          "paymentChannelID": 7,
          "paymentChannelName": "โปรโมชั่น",
          "icon": "promotion",
          "disabled": true,
          "channels": []
        },
        {
          "paymentChannelID": 8,
          "paymentChannelName": "ช่องทางอื่นๆ",
          "icon": "other",
          "disabled": false,
          "channels": []
        },
      ]
    );
    const [paymentBankList, setPaymentBankList] = useState([]);
    const [paymentDeliveryList, setPaymentDeliveryList] = useState([]);
    const [paymentCreditList, setPaymentCreditList] = useState([]);
    // const [endBillDiscount, setEndBillDiscount] = useState({
    //   id: null,
    //   name: null,
    //   discountTypeId: 1,
    //   isPercent: false,
    //   amount: 0,
    //   value: 0
    // });
    // console.log("endBillDiscount: ", endBillDiscount)

    const createPayment = useCreatePayment();

    // ชำระเงิน หลังจากใส่จำนวน/ช่องทางชำระเรียบร้อยแล้ว
    const handlePayment = async () => {
      // console.log('handlePayment - order: ', order)
      // console.log('handlePayment - PaymentObj: ', PaymentObj)
      // console.log('handlePayment - DeliveryDetail: ', DeliveryDetail)
      // console.log('handlePayment - bill: ', bill)
      // console.log('handlePayment - deliverySelect: ', deliverySelect)
      await setRequestLoading({
        isRequestLoading: true
      })
      let payments = [];
      for (const payment of PaymentObj) {
        if(payment.channel.paymentChannelID == 3 || payment.channel.paymentChannelID == 5) {
          // Transfer, Credit
          payments.push({
            ...payment,
            selectedPaymentChannelID: payment.provider.id,
            isViaDelivery: deliverySelect ? true : false,
            deliveryId: deliverySelect ? deliverySelect.id : 0
          })
        } else {
          //  handler for fisrt normal payment select from Delivery
          if(payment.channel.icon == "delivery") {
            if(payment.channel.paymentChannelID == "1" && payment.channel.paymentChannelName == "ชำระปกติ") {
              const cashChannel = PaymentChannelList.find(pc => pc.paymentChannelName == "เงินสด");
              payments.push({
                ...payment,
                selectedPaymentChannelID: cashChannel.paymentChannelID,
                isViaDelivery: true,
              deliveryId: deliverySelect ? deliverySelect.id : 0
              })
            } else if (payment.channel.paymentChannelID == 4 && payment.channel.paymentChannelName == "Delivery") {
              payments.push({
                ...payment,
                selectedPaymentChannelID: payment.provider.id,
                isViaDelivery: true,
              deliveryId: deliverySelect ? deliverySelect.id : 0
              })
            }
          }
          else {
            // Cash, QR case
            payments.push({
              ...payment,
              selectedPaymentChannelID: payment.channel.id ? payment.channel.id : payment.provider.id,
              isViaDelivery: deliverySelect ? true : false,
              deliveryId: deliverySelect ? deliverySelect.id : 0
            })
          }
        }
      }
      
      // hot fix for staff free meal handle
      if(PaymentObj.length == 0 || payments.length == 0) {
        payments.push({
          selectedPaymentChannelID: 1,
          status: 1,
          amount: 0,
          changeAmount: 0,
          feeAmount: 0,
          feePercent: 0,
          isViaDelivery: false,
          deliveryId: 0,
          channel: {
            paymentChannelID: 1,
            paymentChannelName: "เงินสด",
            icon: "cash",
            disabled: false,
            channels: []
          }
        })
      }
      // console.log("🚀 ~ handlePayment ~ order:", order)
      // console.log("🚀 ~ handlePayment ~ bill:", bill)
      // console.log("🚀 ~ handlePayment ~ payments:", payments)
      // createPayment(order, bill, PaymentObj)
      
      await createPayment(order, bill, payments, currentPOS.id, endBillDiscount)
      await setCurrentPayment(payments)

      // Update delivery order remark
      if (DeliveryDetail.remark && DeliveryDetail.remark != "" && deliverySelect && deliverySelect.delivery_name) {
        await updateOrderRemark({
          id: order.id,
          delivery_remark: DeliveryDetail.remark,
          delivery_remark_channel: deliverySelect.delivery_name
        });
      }

      await setEndBillDiscount({
        discountTypeId: 1,
        isPercent: false,
        amount: 0,
        value: 0,
        id: null,
        name: null,
        valueRound: 0,
        isUsePoint: false,
        pointUsage: 0,
        discountFromPoint: 0
      });

      setDiscount([])

      // await setRequestLoading({
      //   isRequestLoading: false
      // })
      await modalOpenObj.setModalOpen(false);

      setTimeout(async () => {
        await printBillFullSuccess();
      }, 1000);

      // setTimeout(async () => {
      //   modalSuccessOpenObj.setModalOpen(true);
      // }, 2000);

      // modalSuccessOpenObj.setModalOpen(true);
    }

    const handleAddPayment = () => {
      // console.log('PaymentSelect: ', PaymentSelect)
      const item = {
        "id":  nanoid() ,
        "channel":PaymentSelect,
        "amount":moneypay,
        "provider":provider,
        "remark":remark,
        "status":  1
      }

      // setPaymentObj( (prev) => [ ...prev, item ])

      // TODO: recheck
      // if(deliverySelect){
      //   var item = {
      //     "id":  nanoid() ,
      //     "channel":{"paymentChannelID":4,"paymentChannelName":"Delivery","icon":"delivery","disabled":false},
      //     "subchannel":PaymentSelect,
      //     "amount":moneypay,
      //     "provider":provider,
      //     "remark":remark,
      //     "status":  1
      //   }
      // }else{
      //   var item = {
      //     "id":  nanoid() ,
      //     "channel":PaymentSelect,
      //     "subchannel":null,
      //     "amount":moneypay,
      //     "provider":provider,
      //     "remark":remark,
      //     "status":  1
      //   }
      // }
      
      setPaymentObj( (prev) => [ ...prev,item ])
      setMoneypay('0.00')
      if(PaymentSelect.paymentChannelID != 3) {
        setProvider(null)
      }
    }

    const removePaymentItem = (id) => {
      setPaymentObj(prev => prev.filter(dx => dx.id !== id) 
      )
    }
    const handleSumPayment = (item) => {
      // console.log("🚀 ~ handleSumPayment ~ item:", item)
      // console.log("🚀 ~ handleSumPayment ~ order:", order)
      // console.log("🚀 ~ handleSumPayment ~ bill:", bill)
      const sum =  item.length > 0 ? item.map((pd) => parseFloat(pd.amount) ).reduce((prev, curr) => prev + curr) : 0;
      // console.log("🚀 ~ handleSumPayment ~ sum:", sum)
      // console.log("🚀 ~ handleSumPayment ~ PaymentDetail:", PaymentDetail)
      // console.log("🚀 ~ handleSumPayment ~ endBillDiscount:", endBillDiscount)
      setPaymentDetail( (prev) => ({  
        ...prev,
          paymentTotal: sum.toFixed(2),
          // paymentRemaining: sum <= 0 ?  ( sum - prev.paymentNet  ).toFixed(2) : '0.00',
          paymentRemaining: sum <= (bill?.net - endBillDiscount.valueRound) ? (sum - Number(prev.paymentNet) + Number(endBillDiscount.valueRound) + Number(endBillDiscount.discountFromPoint)).toFixed(2) : '0.00',
          paymentChange: sum > 0 ?  ( sum - prev.paymentNet  ).toFixed(2) : '0.00'
        }) 
      )
      if(sum >= PaymentDetail.paymentNet || sum >= (PaymentDetail.paymentNet - endBillDiscount.valueRound - endBillDiscount.discountFromPoint) ){
        setBtnpaymentDisabled(false)
        setBtnenterDisabled(true)
      }else{
        setBtnpaymentDisabled(true)
        setBtnenterDisabled(false)
      }
      
      // Only allow zero-value payment with discount
      if( sum == endBillDiscount.valueRound && 
          endBillDiscount.amount > 0 &&
          (Number(PaymentDetail.paymentNet) - Number(endBillDiscount.valueRound)) == 0 
        ) {
        setBtnpaymentDisabled(false)
        setBtnenterDisabled(true)
      }

      // Prevent unintentional negative value bill payment
      if(PaymentDetail.paymentTotal < 0 || PaymentDetail.paymentNet < 0 || sum < 0) {
        setBtnpaymentDisabled(true)
      }
    }

    useEffect(() => {
      return () => {
        setMoneypay("0.00");
        setStaffSelect(false);
      }
    }, [])

    // // TODO: recheck for multiple discount
    // useEffect(() => {
    //   let sumdiscount =  discount && discount.length > 0  ? discount.map((pd) => parseFloat(pd.discountPrice) ).reduce((prev, curr) => prev + curr) : 0;
    //   setPaymentDetail( (prev) => ({  
    //     ...prev,
    //       paymentNet:(parseFloat(prev.paymentNet) - sumdiscount).toFixed(2),
    //       paymentRemaining:(parseFloat(prev.paymentRemaining) + sumdiscount).toFixed(2),
    //     }) 
    //   )
    // },[discount])

    useEffect(() => {
      handleSumPayment(PaymentObj)
    }, [PaymentObj, bill]);

    useEffect(() => {
      setPaymentObj([])
      setPaymentSelect({"paymentChannelID":"0"})
      setDeliverySelect(null)
    }, [modalOpenObj.modalOpen]);

    useEffect(() => {
      // console.log("🚀 ~ file: ModalPayment.jsx:158 ~ useEffect ~ paymentChannels:", paymentChannels)
      let newPaymentBankList = [];
      let newPaymentDeliveryList = [];
      let newPaymentCreditCardList = [];
      let newPaymentChannelList = [...PaymentChannelList];
      newPaymentChannelList[1].channels = [];
      newPaymentChannelList[2].channels = [];
      newPaymentChannelList[3].channels = [];
      newPaymentChannelList[4].channels = [];
      newPaymentChannelList[5].channels = [];

      for (let i = 0; i < paymentChannels.length; i++) {
        // let newPaymentChannelList = [...PaymentChannelList];
        if(paymentChannels[i].name == "Cash") {
          newPaymentChannelList[1].disabled = false;
          newPaymentChannelList[1].channels.push(paymentChannels[i])
        }
        // TODO: Uncomment this code in the future to enable QR code payment channel
        // if(paymentChannels[i].name == "QR") {
        //   newPaymentChannelList[2].disabled = false;
        //   newPaymentChannelList[2].channels.push(paymentChannels[i])
        // }
        if(paymentChannels[i].name == "Transfer") {
          newPaymentChannelList[3].disabled = false;
          newPaymentBankList.push({
            ...paymentChannels[i],
            // name: paymentChannels[i].bank_title
          });
          newPaymentChannelList[3].channels.push(paymentChannels[i])
        }
        if(paymentChannels[i].name == "Delivery") {
          newPaymentChannelList[4].disabled = false;
          newPaymentDeliveryList.push({
            ...paymentChannels[i],
            // name: paymentChannels[i].bank_title
          });
          newPaymentChannelList[4].channels.push(paymentChannels[i])
        }
        if(paymentChannels[i].name == "Debit") {
          newPaymentChannelList[5].disabled = false;
          newPaymentCreditCardList.push({
            ...paymentChannels[i],
            // name: paymentChannels[i].bank_title
          });
          newPaymentChannelList[5].channels.push(paymentChannels[i])
        }
        setPaymentChannelList(newPaymentChannelList);
      }
      if(newPaymentBankList.length > 0) {
        setPaymentBankList(newPaymentBankList);
      }
      if(newPaymentDeliveryList.length > 0) {
        setPaymentDeliveryList(newPaymentDeliveryList);
      }
      if(newPaymentCreditCardList.length > 0) {
        setPaymentCreditList(newPaymentCreditCardList);
      }
    }, [paymentChannels]);

    useEffect(() => {
      if(order) {
        setPaymentDetail(prevState => ({
          ...prevState,
          orderNo: order.orderNo,
          paymentNet: `${order.orderPrice.sumprice}` ,
          paymentTotal: '0.00',
          paymentRemaining: `-${order.orderPrice.sumprice}`,
          paymentChange: "0.00",
       }));
      }
    }, [order]);
    
    useEffect(() => {
      if (order && order.employee) {
        setUserInfo(prev => ({ 
          ...prev, 
          fullName: `${order.employee.first_name_th} ${order.employee.last_name_th}`,
          code: order.employee.code ? order.employee.code : "",
        }))
      } 
    }, [order])

    useEffect(() => {
      if(endBillDiscount) {
        let remainPayment = Math.abs(PaymentDetail.paymentNet) - endBillDiscount.valueRound;
        setPaymentDetail(prev => ({
          ...prev,
          paymentRemaining: `-${remainPayment}`,
        }))
        handleSumPayment(PaymentObj)
      }
    }, [endBillDiscount])

    useEffect(() => {
      // To ensure that remaining payment amount will be correctly display
      if(
        Number(PaymentDetail.paymentRemaining) == 0 &&
        Number(PaymentDetail.paymentNet) != 0 &&
        Number(PaymentDetail.paymentTotal) != 0 &&
        PaymentObj.length == 0 &&
        (Number(endBillDiscount.amount) == 0 || Number(endBillDiscount.valueRound) == 0)
      )
      {
        setPaymentDetail(prev => ({
          ...prev,
          paymentRemaining: `-${PaymentDetail.paymentTotal}`
        }))
      }
    }, [PaymentDetail])

    const removeEndBillDiscount = async () => {
      setEndBillDiscount(prev => ({
        ...prev,
        discountTypeId: 1,
        isPercent: false,
        amount: 0,
        value: 0,
        id: null,
        name: null,
        valueRound: 0,
        // isUsePoint: false,
        // pointUsage: 0,
        // discountFromPoint: 0
      }));
      setPaymentObj([])
      setPaymentSelect({"paymentChannelID":"0"})
      setDeliverySelect(null)
      setDeliveryDetail({
        "remark":"",
        "paymentChannel": 2
      })
      const newDiscount = discount.filter(d => d.type !=('คูปอง/ส่วนลด'));
      setDiscount(newDiscount);
    };

    const removePointDiscount = async () => {
      setEndBillDiscount(prev => ({
        ...prev,
        isUsePoint: false,
        pointUsage: 0,
        discountFromPoint: 0
      }));
      setPaymentObj([])
      setPaymentSelect({"paymentChannelID":"0"})
      setDeliverySelect(null)
      setDeliveryDetail({
        "remark":"",
        "paymentChannel": 2
      })
      const newDiscount = discount.filter(d => !d.discountName?.includes('คะแนนแลกส่วนลด'));
      setDiscount(newDiscount);
    };
    
    return (
      <div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={modalOpenObj.modalOpen}
            onClose={() => {
              setMoneypay("0.00");
              setIsPaymentMode(false)
              setStaffSelect(false);
              setDeliverySelect(null);
              setDeliveryDetail({
                  "remark":"",
                  "paymentChannel": 2
                }
              )
              setEndBillDiscount({
                discountTypeId: 1,
                isPercent: false,
                amount: 0,
                value: 0,
                id: null,
                name: null,
                valueRound: 0,
                isUsePoint: false,
                pointUsage: 0,
                discountFromPoint: 0
              });
              setDiscount([])
              modalOpenObj.setModalOpen(false)
            }}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
        >
            <Fade in={modalOpenObj.modalOpen}>
            <div className="bg-white focus-visible-none w-[min(90.11vw,905px)] pt-1 p-[min(1.74vw,15px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-16 text-[var(--color-black-2)]" >
                
                <div className="text-end mb-2">
                  <button 
                    className="btn btn-close-st-2" 
                    onClick={() => {
                      setMoneypay("0.00");
                      setIsPaymentMode(false)
                      setStaffSelect(false);
                      setDeliverySelect(null);
                      setDeliveryDetail({
                          "remark":"",
                          "paymentChannel": 2
                        }
                      )
                      setEndBillDiscount({
                        discountTypeId: 1,
                        isPercent: false,
                        amount: 0,
                        value: 0,
                        id: null,
                        name: null,
                        valueRound: 0,
                        isUsePoint: false,
                        pointUsage: 0,
                        discountFromPoint: 0
                      });
                      setDiscount([])
                      modalOpenObj.setModalOpen(false)
                    }}
                  >
                  </button>
                </div>

                <div className="row gx-2">
                  <div className="col-6 px-4">
                    {
                      PaymentSelect.paymentChannelID == '8' || PaymentSelect.paymentChannelID == '9' 
                      ? (
                        <PayOther 
                        PaymentSelect={PaymentSelect}
                        setPaymentSelect={setPaymentSelect}
                        setProvider={setProvider}
                        />
                      ) : (
                        <div className="row mb-0">
                          {
                            PaymentChannelList && PaymentChannelList.map((item, index) => 
                              <div className="col-4" key={index}>
                                <button  
                                  className={`btn btn-select-payment ${item.icon} ${ PaymentSelect.paymentChannelID == item.paymentChannelID ? 'active' : ''  }`} 
                                  onClick={() => { 
                                    if(item.paymentChannelID == 1 || item.paymentChannelID == 2 ) {
                                      setPaymentSelect(
                                        {
                                          ...item,
                                          ...item.channels[0]
                                        }
                                      );
                                    } else {
                                      setPaymentSelect(item);
                                    }
                                    setProvider(null);
                                  }}
                                  disabled={item.disabled}
                                >
                                  <div className="pay-icon"></div><div >{item.paymentChannelName}</div>
                                </button>
                              </div>
                            )
                          }
                        </div>
                      )
                    }


                    <div className="card card-st-1">
                      <div className="card-body py-2 text-[#061126]">
                        <div className="mb-1 d-flex align-items-center">
                          <div className="me-auto fs-14 pt-2">มูลค่าที่ต้องชำระสุทธิ</div>
                          <div className="text-end">
                            <div className="fs-24">{numberWithTwoDecimals(PaymentDetail.paymentNet - endBillDiscount.valueRound - endBillDiscount.discountFromPoint)}</div>
                          </div>
                        </div>
                        <div className="mb-1 d-flex align-items-center">
                          <div className="me-auto">
                            {/* {
                              discount && discount.length > 0 ? (
                                <div className="p-1 bg-[#FFD8DA] fs-12 text-[#F95057] d-inline-block me-3" style={{borderRadius: '2px'}}>
                                 {discount[0].discountName}
                                </div>
                              ) : ''
                            } */}
                            {
                              endBillDiscount.id && endBillDiscount.value > 0 ? (
                                <div className="p-1 bg-[#FFD8DA] fs-12 text-[#F95057] d-block me-5" style={{borderRadius: '2px'}}>
                                  {endBillDiscount.name}<span className="ml-1 px-1 font-bold cursor-pointer text-md" onClick={removeEndBillDiscount}>X</span>
                                </div>
                              ) : !endBillDiscount.id && endBillDiscount.isPercent && endBillDiscount.amount > 0 ?
                                <div className="p-1 bg-[#FFD8DA] fs-12 text-[#F95057] d-block me-5" style={{borderRadius: '2px'}}>
                                  ส่วนลด {endBillDiscount.amount} %<span className="ml-1 px-1 font-bold cursor-pointer text-md" onClick={removeEndBillDiscount}>X</span>
                                </div>
                                : !endBillDiscount.id && !endBillDiscount.isPercent && endBillDiscount.value > 0 ? 
                                <div className="p-1 bg-[#FFD8DA] fs-12 text-[#F95057] d-block me-5" style={{borderRadius: '2px'}}>
                                  ส่วนลด {endBillDiscount.value} บาท<span className="ml-1 px-1 font-bold cursor-pointer text-md" onClick={removeEndBillDiscount}>X</span>
                                </div>
                              :''
                            }
                            {
                              endBillDiscount.discountFromPoint > 0 ?
                                <div className="p-1 bg-[#FFD8DA] fs-12 text-[#F95057] d-block me-5 mt-2" style={{borderRadius: '2px'}}>
                                  ส่วนลดคะแนน {endBillDiscount.discountFromPoint} บาท<span className="ml-1 px-1 font-bold cursor-pointer text-md" onClick={removePointDiscount}>X</span>
                                </div>
                              :''
                            }
                          </div>
                          <div className="">
                            <button 
                            className="btn btn-c2"
                            disabled={discount && discount.length < 2}
                            onClick={() => {
                              setModalDiscountListOpen(true)
                            }}
                            >ดูทั้งหมด</button>
                          </div>
                        </div>
                        <div className="mb-1 d-flex align-items-center">
                          <div className="me-auto fs-14">มูลค่าที่ชำระรวม</div>
                          <div className="fs-24">{numberWithTwoDecimals(PaymentDetail.paymentTotal)}</div>
                        </div>
                        <div className="mb-1 d-flex align-items-center">
                          <div className="me-auto fs-14">คงเหลือที่ต้องชำระ</div>
                          {
                            PaymentDetail.paymentRemaining <= 0 ? (
                              <div className="fs-24 text-[#F00]">{ numberWithTwoDecimals(PaymentDetail.paymentRemaining)  }</div>
                            ) : (
                              <div className="fs-24 ">0.00</div>
                            )
                          }
                          
                        </div>
                      </div>
                      <hr className="my-0" />
                      {
                        PaymentSelect.paymentChannelID == 4 && !provider ? (
                          <div className="px-3 py-3">
                            <div className="mb-4">
                              <div className="fs-16 fw-500 text-[#061126] mb-3">Remark</div>
                              <input 
                                className="form-control border-0" 
                                style={{background: '#FFF',borderRadius:'8px',filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14))'}} 
                                onChange={(e) => {
                                  setDeliveryDetail( (prev) => ({  
                                    ...prev,
                                      remark:e.target.value
                                    }) 
                                  )
                                } }
                                maxLength={10}
                              />
                            </div>
                            <div className="mb-2">
                              <div className="fs-16 fw-500 text-[#061126] mb-3">ช่องทางการชำระ</div>
                              <div>
                                <Form.Check
                                    inline
                                    label="ชำระปกติ"
                                    name="paymentDeliveryChannel"
                                    type="radio"
                                    id="paymentDeliveryChannel1"
                                    className='form-check-st-5 tc-7'
                                    // defaultChecked={true}
                                    defaultValue={1}
                                    onChange={(e) => {
                                      setDeliveryDetail( (prev) => ({  
                                        ...prev,
                                          paymentChannel: 1
                                        }) 
                                      )
                                    }}
                                    checked={DeliveryDetail.paymentChannel == 1}
                                />
                                <Form.Check
                                    inline
                                    label="ชำระผ่านผู้ให้บริการ"
                                    name="paymentDeliveryChannel"
                                    type="radio"
                                    id="paymentDeliveryChannel2"
                                    className='form-check-st-5 tc-7'
                                    defaultValue={2}
                                    onChange={(e) => {
                                      setDeliveryDetail( (prev) => ({  
                                        ...prev,
                                          paymentChannel: 2
                                        }) 
                                      )
                                    }}
                                    checked={DeliveryDetail.paymentChannel == 2}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          PaymentSelect.paymentChannelID == 5 ? (
                            <div className="px-3 py-3">

                              <div className="mb-2">
                                <div className="fs-16 fw-500 text-[#061126] mb-3">รูปแบบค่าธรรมเนียม</div>
                                <div className="mb-2">
                                  <Form.Check
                                      inline
                                      label="ไม่มีค่าธรรมเนียม"
                                      name="creditFeeType"
                                      type="radio"
                                      id="creditFeeType1"
                                      className='form-check-st-5 tc-7'
                                      value={0}
                                      onChange={() => setCreditFeeType(0)}
                                      checked={ creditFeeType == 0 }
      
                                  />
                                </div>
                                <div className="mb-3">
                                  <Form.Check
                                      inline
                                      label="ค่าธรรมเนียมเป็น %"
                                      name="creditFeeType"
                                      type="radio"
                                      id="creditFeeType2"
                                      className='form-check-st-5 tc-7 me-3'
                                      value={1}
                                      onChange={() => setCreditFeeType(1)}
                                      checked={ creditFeeType == 1 }
      
                                  />
                                  <Form.Check
                                      inline
                                      label="ค่าธรรมเนียมเป็น บาท"
                                      name="creditFeeType"
                                      type="radio"
                                      id="creditFeeType3"
                                      className='form-check-st-5 tc-7 me-0'
                                      value={2}
                                      onChange={() => setCreditFeeType(2)}
                                      checked={ creditFeeType == 2 }
                                  />
                                </div>
                                <div className="">
                                  <input className="form-control text-end border-0 fs-20 pe-4 form-st-5" 
                                  type="number"
                                  onClick={
                                    (e) => {
                                      const input = e.target;
                                      input.select();
                                    }
                                  }
                                  style={{background: '#FFF',borderRadius:'8px',filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14))'}} 
                                  disabled={creditFeeType == 0}
                                  defaultValue={0}
                                  onChange={(e) => setCreditFeeValue(e.target.value)}
                                  />
                                  
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="card-body px-2 py-2 text-[#061126]">
                              <div className="bx-paylist pt-2">
                                <ul className="paylist">
                                  {
                                    PaymentObj && PaymentObj.map((item, index) => 
                                        <PaymentItem 
                                          key={item.id}
                                          item={item}
                                          handleClick={() => removePaymentItem(item.id) }
                                        />    
                                    )
                                  }
                                </ul>
                              </div>
                            </div>
                          )
                        )
                      }
                    </div>
                  </div>
                  <div className="col-6">
                      {
                        PaymentSelect.paymentChannelID == 0 && !StaffSelect ? (
                          <BillDetail billID={order.billID} 
                            staffObj={{
                              setStaffSelect,
                              StaffSelect
                            }}
                            selectedUserObj={{
                              selectedUser,
                              setSelectedUser,
                            }}
                            userInfoObj={{
                              userInfo,
                              setUserInfo,
                            }}
                            order={order}
                            pos={currentPos}
                            branch={currentBranch}
                            onPrintBtn={onPrintBtn}
                            endBillDiscount={endBillDiscount}
                          />
                        ) : ''
                      }

                      {
                        StaffSelect ? (
                          <Staff 
                            staffObj={{
                              setStaffSelect,
                              StaffSelect
                            }}
                            selectedUserObj={{
                              selectedUser,
                              setSelectedUser,
                            }}
                            userInfoObj={{
                              userInfo,
                              setUserInfo,
                            }}
                            modalSuccessOpenObj={{
                              modalOpen: modalSuccessOpen,
                              setModalOpen: setModalSuccessOpen,
                              setModalTextSuccess
                            }}
                            staffList={staffList}
                            updateOrderStaff={updateOrderStaff}
                            resetPayment={async () => {
                              setPaymentObj([])
                              setPaymentSelect({"paymentChannelID":"0"})
                              setDeliverySelect(null);
                              setDeliveryDetail({
                                  "remark":"",
                                  "paymentChannel": 2
                                }
                              )
                              setMoneypay("0.00");
                              setEndBillDiscount({
                                discountTypeId: 1,
                                isPercent: false,
                                amount: 0,
                                value: 0,
                                id: null,
                                name: null,
                                valueRound: 0,
                                isUsePoint: false,
                                pointUsage: 0,
                                discountFromPoint: 0
                              });
                            }}
                          />
                        ) : ''
                      }

                      {
                        PaymentSelect.paymentChannelID == 1 && !StaffSelect ? (
                          <PayCash 
                            Payment={{
                              PaymentObj,
                              setPaymentObj,
                              PaymentSelect
                            }}
                            deliveryObj={{
                              deliverySelect,
                              setDeliverySelect
                            }}
                            moneypayObj={{
                              moneypay,
                              setMoneypay
                            }}
                            eObj={{
                              handlePayment,
                              handleAddPayment
                            }}
                            PaymentDetailObj={{
                              PaymentDetail,
                              setPaymentDetail
                            }}
                            btnObj={{
                              BtnenterDisabled,
                              BtnpaymentDisabled
                            }}
                            modalSuccessOpenObj={modalSuccessOpenObj}
                            
                          />
                        ) : ''
                      }

                      {
                        PaymentSelect.paymentChannelID == 2 && !StaffSelect ? (
                          <PayQr 
                            providerObj={{
                              provider,
                              setProvider
                            }}
                            deliveryObj={{
                              deliverySelect,
                              setDeliverySelect
                            }}
                            eObj={{
                              handlePayment,
                              handleAddPayment
                            }}
                            Payment={{
                              PaymentObj,
                              setPaymentObj,
                              PaymentSelect
                            }}
                            PaymentDetailObj={{
                              PaymentDetail,
                              setPaymentDetail
                            }}
                            moneypayObj={{
                              moneypay,
                              setMoneypay
                            }}
                            btnObj={{
                              BtnenterDisabled,
                              BtnpaymentDisabled
                            }}
                            modalSuccessOpenObj={modalSuccessOpenObj}
                          />
                        ) : ''
                      }

                      {
                        PaymentSelect.paymentChannelID == 3 && !StaffSelect ? (
                          <PayTranfer 
                            providerObj={{
                              provider,
                              setProvider
                            }}
                            deliveryObj={{
                              deliverySelect,
                              setDeliverySelect
                            }}
                            eObj={{
                              handlePayment,
                              handleAddPayment
                            }}
                            Payment={{
                              PaymentObj,
                              setPaymentObj,
                              PaymentSelect
                            }}
                            PaymentDetailObj={{
                              PaymentDetail,
                              setPaymentDetail
                            }}
                            moneypayObj={{
                              moneypay,
                              setMoneypay
                            }}
                            btnObj={{
                              BtnenterDisabled,
                              BtnpaymentDisabled
                            }}
                            modalSuccessOpenObj={modalSuccessOpenObj}
                            paymentBankList={paymentBankList}
                          />
                        ) : ''
                      }

                      {
                        PaymentSelect.paymentChannelID == 4 && !StaffSelect ? (
                          <PayDelivery 
                            deliveryObj={{
                              deliverySelect,
                              setDeliverySelect
                            }}
                            Payment={{
                              PaymentObj,
                              setPaymentObj,
                              PaymentSelect,
                              setPaymentSelect
                            }}
                            moneypayObj={{
                              moneypay,
                              setMoneypay
                            }}
                            eObj={{
                              handlePayment,
                              handleAddPayment
                            }}
                            PaymentDetailObj={{
                              PaymentDetail,
                              setPaymentDetail
                            }}
                            btnObj={{
                              BtnenterDisabled,
                              BtnpaymentDisabled
                            }}
                            DeliveryDetailObj={{
                              DeliveryDetail,
                              setDeliveryDetail
                            }}
                            providerObj={{
                              provider,
                              setProvider
                            }}
                            paymentDeliveryList={paymentDeliveryList}
                          />
                        ) : ''
                      }

                      {
                        PaymentSelect.paymentChannelID == 5 && !StaffSelect ? (
                          <PayCredit 
                            Payment={{
                              PaymentObj,
                              setPaymentObj,
                              PaymentSelect,
                              setPaymentSelect
                            }}
                            deliveryObj={{
                              deliverySelect,
                              setDeliverySelect
                            }}
                            moneypayObj={{
                              moneypay,
                              setMoneypay
                            }}
                            eObj={{
                              handlePayment,
                              handleAddPayment
                            }}
                            PaymentDetailObj={{
                              PaymentDetail,
                              setPaymentDetail
                            }}
                            btnObj={{
                              BtnenterDisabled,
                              BtnpaymentDisabled
                            }}
                            providerObj={{
                              provider,
                              setProvider
                            }}
                            creditObj={{
                              creditFeeType,
                              creditFeeValue,
                              setCreditFeeType,
                              setCreditFeeValue,
                            }}
                            modalSuccessOpenObj={modalSuccessOpenObj}
                            paymentCreditList={paymentCreditList}
                          />
                        ) : ''
                      }

                      {
                        PaymentSelect.paymentChannelID == 6 && !StaffSelect ? (
                          <PayDiscount 
                            endBillDiscountObj={{
                              endBillDiscount,
                              setEndBillDiscount,
                            }} 
                            setPaymentSelect={setPaymentSelect}
                            orderPrice={order.orderPrice.sumprice}
                            setPaymentObj={setPaymentObj}
                            discountObj={{
                              discount,
                              setDiscount
                            }}
                            PaymentDetailObj={{
                              PaymentDetail,
                              setPaymentDetail
                            }}
                          />
                        ) : ''
                      }


                      {
                        PaymentSelect.paymentChannelID == 8 && !StaffSelect ? (
                          <PayPoint 
                            Payment={{
                              PaymentObj,
                              setPaymentObj,
                              PaymentSelect,
                              setPaymentSelect
                            }}
                            discountObj={{
                              discount,
                              setDiscount
                            }}
                            PaymentDetailObj={{
                              PaymentDetail,
                              setPaymentDetail
                            }}
                            members={members}
                            member={member}
                            endBillDiscountObj={{
                              endBillDiscount,
                              setEndBillDiscount,
                            }} 
                          />
                        ) : ''
                      }
                  </div>
                </div>

            </div>
            </Fade>
        </Modal>

        <ModalSuccess
            modalOpenObj={{
                modalOpen: modalSuccessOpen,
                setModalOpen: setModalSuccessOpen,
                modalTextSuccess:modalTextSuccess
            }}
        />

        <ModalDiscountList
            modalOpenObj={{
              modalOpen: modalDiscountListOpen,
              setModalOpen: setModalDiscountListOpen,
            }}
            discount={discount}
        />
      </div>

    )
}


const PaymentItem =  ({item,handleClick,...props}) => {

  return (
    <li>
      {
        item.subchannel ? (
        <div className={`me-3 overflow-hidden me-1  `}>
          { item.subchannel.paymentChannelName}
        </div>
        ) : ''
      } 
      <div className={`me-3 overflow-hidden ${ item.provider ? 'me-3' : 'me-auto'}  `} style={{flex:'0 0 60px',width:'60px'}}>
         { item.channel.paymentChannelName}
      </div>
      {
        item.provider ? (
          <div className="me-auto d-flex align-items-center">
            <img src={item.provider.logo} style={{width: '18px',height: '18px',objectFit:'cover',borderRadius:'50%'}} />

            {
              !item.subchannel ? (
                <div className="text-[#061126] fs-14 ms-2">
                  {
                    item.provider.name == "Transfer" || item.provider.name == "Debit" || item.provider.name == "Credit" ?
                      item.provider.bank_title :
                    item.provider.name
                  }
                </div>
              ) : ''
            }
            
          </div>
        ) : ''
      }

      {
        item.status == 1 ? ( 
          <div className="fw-light fs-14 text-[#00BC3A] me-3">สำเร็จ</div>
        ) : (
          item.status == 0 ? (
            <div className="fw-light fs-14 text-[#00BC3A] me-3">
              <img src="/img/spin.png" className="spin-img" />
            </div>
          ) : (
            <div className="fw-light fs-14 text-[#FF0] me-3">ไม่สำเร็จ</div>
          )
        )
      }

      <div className="text-end" style={{flex:'0 0 45px',width:'45px'}}>{item.amount.toLocaleString()}</div>
      <button type="button" className="btn btn-close-st-1 ms-2" onClick={handleClick}></button>
    </li>
  )
}

