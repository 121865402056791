import React, { useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

export default function CustomSelect({
  selectedOptionObj,
  options,
  label,
  inputprops,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const handleSelectChange = (event) => {
    const value = event.target.value;
    const syntheticEvent = {
      target: {
        name: inputprops.name,
        value: value,
      },
    };
    selectedOptionObj.setSelectedOption(syntheticEvent);
  };

  return (
    <FormControl fullWidth>
      <Select
        {...inputprops}
        className={"text-18"}
        sx={{
          "&.Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "min(0.10vw,1px) solid var(--color-red) !important",
            },
          },
          ".MuiInputBase-input": {
            height: "min(4.30vw,44px) !important",
            padding: "0",
            paddingLeft: "min(1.27vw,13px)",
            paddingRight: "min(1.27vw,13px)",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          },
          ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "blue !important", // Change border color in error state
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--color-grey-4)",
            borderRadius: "min(0.78vw,8px)",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--color-green)",
            borderWidth: "min(0.10vw,1px)",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--color-green)",
          },
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        value={selectedOptionObj.selectedOption}
        onChange={handleSelectChange}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return <div className="text-[rgba(47,72,88,0.5)]">{label}</div>;
          }

          return selected;
        }}
        IconComponent={() => (
          <div
            className={`cursor-pointer absolute right-[min(1.23vw,12.59px)] ${
              isOpen ? "rotate-180deg" : ""
            }`}
          >
            <img
              className={`object-contain cursor-pointer aspect-[11.41/7.12] w-[min(1.11vw,11.41px)] h-fit `}
              src="/img/icons/icon-arrow-down-black.svg"
              alt=""
            />
          </div>
        )}
        inputProps={{ "aria-label": "Without label" }}
        MenuProps={{
          sx: {
            "&& .Mui-selected": {
              backgroundColor: "#F4FCF8 !important",
              background: "url(/img/icons/icon-correct-circle.svg) no-repeat",
              backgroundPosition: "calc(100% - min(1.46vw,15px))",
              backgroundSize: "min(2.34vw,24px)",
            },
            "&& .MuiMenuItem-root": {
              height: "min(3.91vw,40px) !important",
              fontSize: "min(1.75vw,18px)",
            },
            ".MuiMenu-paper": {
              boxShadow: "none !important",
              border: "min(0.10vw,1px) solid #B6BBC6",
            },
            ".MuiPaper-rounded": {
              borderRadius: "0 !important",
            },
            ".MuiList-padding": {
              padding: 0,
            },
          },
        }}
      >
        {/* <MenuItem
          sx={{
            borderBottom: "min(0.10vw,1px) solid #B6BBC6",
            padding: "min(0.49vw,5px) min(1.27vw,13px)",
          }}
          value=""
          disabled
        >
          เลือกประเภทลูกค้า
        </MenuItem> */}
        {options.map((name, i) => (
          <MenuItem
            sx={{
              borderBottom:
                options.length - 1 === i
                  ? `0`
                  : `min(0.10vw,1px) solid #B6BBC6`,
              padding: "min(0.49vw,5px) min(1.27vw,13px)",
            }}
            key={name}
            value={name}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
