import { atom, selector, useRecoilState, useSetRecoilState, useResetRecoilState } from 'recoil';
import { authLogin, authRefreshToken, getBranchById } from '../services/auth';
import { jwtDecode } from 'jwt-decode';
import { posState, posStatusCheck } from './pos';
import { getPOS, getPOSActivity } from 'services/pos';
import { getAllPOSActivity } from 'services/pos-activity';
import { getStaffActive, getAllStaffLog } from 'services/staff-log';
import { useNavigate } from "react-router-dom";
import { nanoid } from 'nanoid';
import { appState } from 'state/app';

const defaultUser = {
    id: null,
    accountId: null,
    employeeId: null,
    employeeCode: null,
    firstNameTH: null,
    lastNameTH: null,
    firstNameEN: null,
    lastNameEN: null,
    nickName: null,
    branchId: null,
    employeeBranchAccountId: null,
    employeePermissionId: null,
    employeePin: null
}

export const authState = atom({
    key: 'authState',
    default: {
        user: { ...defaultUser },
        // user: null,
        isLoggedIn: false,
        isTokenChecked: false,
        authErrorStatus: null,
        authErrorMessage: null,
        authErrorDisplayText: null,
        isFromLoginPage: false,
        token: null,
        branch: null,
        logMachine: null
    },
});

export const useClientCheck = () => {
    const setState = useSetRecoilState(authState);

    return async () => {
        // console.log('useClientCheck')
        try {
            const client = window.localStorage.getItem("allDerCafeClient");
            // console.log("🚀 ~ return ~ client:", client)
            if(!client) {
                const newClient = nanoid(128);
                window.localStorage.setItem("allDerCafeClient", newClient);
                setState(state => ({ ...state, logMachine: newClient }))
            } else {
                setState(state => ({ ...state, logMachine: client }))
            }
            // console.log("🚀 ~ return ~ token:", token)
        } catch (error) {
            console.error('Error token check:', error);
        }
    };
};

export const useAuthTokenCheck = () => {
    const setState = useSetRecoilState(authState);
    const setPosState = useSetRecoilState(posState);
    const setAppState = useSetRecoilState(appState);

    return async () => {
        // console.log('useAuthTokenCheck')
        try {
            const token = window.localStorage.getItem("allDerCafeToken");
            const client = window.localStorage.getItem("allDerCafeClient");
            if (token) {
                // Reauth/Refresh Token
                const refreshedToken = await authRefreshToken(token, client);
                if (refreshedToken.token) {
                    const decoded = jwtDecode(refreshedToken.token);
                    // return null

                    const user = {
                        id: decoded.accountId,
                        accountId: decoded.accountId
                    };

                    if (decoded.employee) {
                        user.employeeId = decoded.employee.id;
                        user.firstNameTH = decoded.employee.first_name_th;
                        user.lastNameTH = decoded.employee.last_name_th;
                        user.firstNameEN = decoded.employee.first_name_en;
                        user.lastNameEN = decoded.employee.last_name_en;
                        user.nickName = decoded.employee.nickname;
                        user.branchId = decoded.employee.EmployeePermission[0].branch_id;
                        user.employeeBranchAccountId = decoded.employee.EmployeePermission[0].EmployeeBranchAccount[0].id;
                        user.employeePermissionId = decoded.employee.EmployeePermission[0].id;
                        user.employeeCode = decoded.employee.code;
                        user.branchId = decoded.employee.EmployeePermission[0].branch_id;
                        user.businessId = decoded.employee.business_id;
                        user.employeePin = decoded.employee.EmployeePermission[0].EmployeeBranchAccount[0].pin_code
                    }

                    const isPosStarted = window.localStorage.getItem("isPosStarted") === "true";
                    // console.log("🚀 ~ file: auth.js:66 ~ return ~ isPosStarted:", isPosStarted)
                    const isKitchenStarted = window.localStorage.getItem("isKitchenStarted") === "true";
                    // console.log("🚀 ~ file: auth.js:68 ~ return ~ isKitchenStarted:", isKitchenStarted)
                    if (isPosStarted) {
                        // TODO: Recheck usage for POS
                        const currentPOS = JSON.parse(window.localStorage.getItem("currentPOS"));
                        // console.log("🚀 ~ file: auth.js:80 ~ return ~ currentPOS:", currentPOS)
                        const fetch = await getPOS(currentPOS.id)
                        // const fetch = await getPOS(currentPOS.id ? currentPOS.id : currentPOS.pos.id ? currentPOS.pos.id : null)
                        // console.log("🚀 ~ file: auth.js:82 ~ return ~ fetch:", fetch)
                        window.localStorage.setItem("currentPOS", JSON.stringify(fetch.pos));
                        const fetchAll = await getAllPOSActivity()
                        // console.log("🚀 ~ file: auth.js:79 ~ return ~ fetchAll:", fetchAll)
                        const currentPOSActivity = fetchAll.posActivity.reverse().find(pa => pa.pos_id == currentPOS.id && pa.activity_type_id == 1);
                        // console.log("🚀 ~ file: auth.js:81 ~ return ~ currentPOSActivity:", currentPOSActivity)
                        window.localStorage.setItem("currentPOSActivity", JSON.stringify(currentPOSActivity));
                        setPosState(state => ({ ...state, isPosStarted: isPosStarted, currentPOS: { ...state.currentPOS, ...fetch.pos }, currentPOSActivity: { ...currentPOSActivity } }))
                    } else if (isKitchenStarted) {
                        const currentPOS = JSON.parse(window.localStorage.getItem("currentPOS"));
                        // console.log("🚀 ~ file: auth.js:80 ~ return ~ currentPOS:", currentPOS)
                        const fetch = await getPOS(currentPOS.id);
                        // console.log("🚀 ~ file: auth.js:82 ~ return ~ fetch:", fetch)
                        window.localStorage.setItem("currentPOS", JSON.stringify(fetch.pos));
                        setPosState(state => ({ ...state, isKitchenStarted: isKitchenStarted, currentPOS: { ...state.currentPOS, ...fetch.pos } }))
                    } else {
                        // Newly login or login again from other browser
                        // console.log('decode: ', decoded)
                        if(decoded.log && decoded.log.pos) {
                            const posStarted = decoded.log.pos.type == "pos" ? true : false;
                            const kitchenStarted = decoded.log.pos.type == "kitchen" ? true : false;
                            window.localStorage.setItem("isPosStarted", JSON.stringify(posStarted));
                            window.localStorage.setItem("isKitchenStarted", JSON.stringify(kitchenStarted));
                            window.localStorage.setItem("currentPOS", JSON.stringify(decoded.log.pos));
                            window.localStorage.setItem("currentPOSActivity", JSON.stringify(decoded.log.posActivity));
                            setPosState(state => ({ 
                                ...state, 
                                isPosStarted: posStarted,
                                isKitchenStarted: kitchenStarted,
                                currentPOS: { ...state.currentPOS, ...decoded.log.pos }, 
                                currentPOSActivity: { ...decoded.log.posActivity } 
                            }))
                        }
                    }

                    setAppState(state => ({
                        ...state,
                        slug: decoded.employee.business.businessdetail.pos_slug
                    }))

                    setState(state => (
                        {
                            ...state,
                            user: user,
                            // user: { user, ...decoded.employee},
                            isLoggedIn: true,
                            isTokenChecked: true,
                            token: `Bearer ${refreshedToken}`,
                            // logMachine: decoded.log?.staffLog?.login_machine ?? null
                        }
                    ));
                } else {
                    setState(state => ({ ...state, isLoggedIn: false, isTokenChecked: true }));
                }
            } else {
                setState(state => ({ ...state, isLoggedIn: false, isTokenChecked: true }));
            }
        } catch (error) {
            console.error('Error token check:', error);
            setState(state => ({ ...state, isLoggedIn: false, isTokenChecked: true }));
        }
    };
};

export const useAuthLogin = () => {
    const setState = useSetRecoilState(authState);
    const setPosState = useSetRecoilState(posState);

    return async (auth, slug, slugBranch) => {
        try {
            const login = await authLogin(auth, slug, slugBranch);
            // console.log("🚀 ~ return ~ login:", login)
            if (login.token && login.user) {
                setState(state => (
                    {
                        ...state,
                        user: login.user,
                        isLoggedIn: true,
                        isFromLoginPage: true,
                        token: `Bearer ${login.token}`,
                        authErrorStatus: null,
                        authErrorMessage: null,
                        authErrorDisplayText: null,
                    }
                ));

                window.localStorage.setItem("isPosStarted", false);
                setPosState(state => ({ ...state, isPosStarted: false }))
            } else {
                if (login.response.status === 401) {
                    setState(state => (
                        {
                            ...state,
                            authErrorStatus: login.response.status,
                            authErrorDisplayText: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
                        }
                    ));
                } else {
                    setState(state => (
                        {
                            ...state,
                            authErrorStatus: login.response.status,
                            authErrorDisplayText: "เกิดเหตุผิดพลาด กรุณาลองใหม่อีกครั้ง",
                        }
                    ));
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useAuthLogout = () => {
    const setState = useSetRecoilState(authState);
    const resetState = useResetRecoilState(authState);

    return async () => {
        try {
            window.localStorage.removeItem("allDerCafeToken");
            window.localStorage.removeItem("isPosStarted");
            window.localStorage.removeItem("isKitchenStarted");
            resetState();
        } catch (error) {
            console.error('Error fetching data:', error);
            setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useFetchBranch = () => {
    const setState = useSetRecoilState(authState);
    const setPOSState = useSetRecoilState(posState);

    return async (branchId) => {
        try {
            const fetch = await getBranchById(branchId);
            setState(state => ({ ...state, branch: fetch.branch }))
            setPOSState(state => ({ ...state, branch: fetch.branch }))
        } catch (error) {
            console.error('Error fetching data:', error);
            setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useAuthCheckActive = () => {
    const setState = useSetRecoilState(authState);
    const resetState = useResetRecoilState(authState);
    const resetPOSState = useResetRecoilState(posState);
    const navigate = useNavigate();

    return async (posId, employeeId, operationDate, logMachine, isKitchen, slug, slugBranch) => {
        // console.log("🚀 ~ return ~ operationDate:", operationDate)
        // console.log("🚀 ~ return ~ posId:", posId)
        // console.log("🚀 ~ return ~ employeeId:", employeeId)
        // console.log("🚀 ~ return ~ logMachine:", logMachine)
        // console.log("🚀 ~ return ~ isKitchen:", isKitchen)
        try {
            // const staffLogs = await getAllStaffLog(posId, operationDate);
            const staffActive = await getStaffActive(posId, employeeId, operationDate);
            // console.log("🚀 ~ return ~ staffActive:", staffActive)

            if(staffActive?.code == "ERR_NETWORK") {

            }
            else if (isKitchen) {
                if(!staffActive) {
                    window.localStorage.removeItem("allDerCafeToken");
                    window.localStorage.removeItem("isPosStarted");
                    window.localStorage.removeItem("isKitchenStarted");
                    window.localStorage.removeItem("currentPOS");
                    window.localStorage.removeItem("currentPOSActivity");
                    resetPOSState();
                    resetState();
                    navigate(`/${slug}/${slugBranch}/login`);
                }
            }
            else if(!staffActive) {
                window.localStorage.removeItem("isPosStarted");
                window.localStorage.removeItem("isKitchenStarted");
                window.localStorage.removeItem("currentPOS");
                window.localStorage.removeItem("currentPOSActivity");
                resetPOSState();
                resetState();
                navigate(`/${slug}/${slugBranch}/login`);
            }
            else if(staffActive.login_machine !== logMachine) {
                window.localStorage.removeItem("allDerCafeToken");
                window.localStorage.removeItem("isPosStarted");
                window.localStorage.removeItem("isKitchenStarted");
                window.localStorage.removeItem("currentPOS");
                window.localStorage.removeItem("currentPOSActivity");
                resetPOSState();
                resetState();
                navigate(`/${slug}/${slugBranch}/login`);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};