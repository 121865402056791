import React from "react";

export default function CustomInputPassword({inputprops,iconProps,...props}) {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <div
      {...props}
      className={`custom-input-password custom-input sarabun text-18 relative ${props.className}`}
    >
      <input
        {...inputprops}
        className={`w-full ${
          inputprops && inputprops.className ? inputprops.className : ""
        }  ${inputprops && inputprops.error ? "error" : ""}`}
        type={showPassword ? "text" : "password"}
      />
      <span
        className={`${
          showPassword
            ? "bg-[url(img/icons/eye-slash.svg)]"
            : "bg-[url(img/icons/eye.svg)]"
        } ${iconProps && iconProps.className ? iconProps.className : ""}`}
        onClick={handleClickShowPassword}
      ></span>
    </div>
  );
}
