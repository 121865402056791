import ModalPosDubStartConfirm from "components/layouts/ModalPosDubStartConfirm";
import ModalPosSettingStart from "components/layouts/ModalPosSettingStart";
import ModalPosStartConfirm from "components/layouts/ModalPosStartConfirm";
import ModalPrintError from "components/layouts/ModalPrintError";
import PosAvartarCard from "components/layouts/PosAvartarCard";
import PosSettingCard from "components/layouts/PosSettingCard";
import React, { useState, useEffect } from "react";
import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil';
import { posState, useFetchAllPos, selectedPosSettingState, useCreateStaffLogin, useCreateStaffKitchenLogin } from 'state/pos';
import { authState } from "state/auth";
import { branchState } from "state/branch";
import { useFetchBranch } from "state/branch";
import { useAuthTokenCheck } from "state/auth";
import { useNavigate } from "react-router-dom";

const mockPos = [
  {
    posInfo: {
      posName: "POS 1 PS00141",
    },
    userInfo: {
      fullName: "นายชลกฤษ ฟองสายชล",
      startTime: "12.00 น.",
      endTime: "-",
      duration: "3 ชั่วโมง 14 นาที",
    },
    isStarted: true,
  },
  {
    posInfo: {
      posName: "POS 2 PS00142",
    },
    userInfo: {
      fullName: "นางสาวเบญจวรรณ บัวพิทักษ์",
      startTime: "08.00 น.",
      endTime: "-",
      duration: "1 ชั่วโมง 10 นาที",
    },
    isStarted: true,
  },
  {
    posInfo: {
      posName: "POS 3",
    },
    userInfo: {
      fullName: "-",
      startTime: "-",
      endTime: "-",
      duration: "-",
    },
    isStarted: false,
  },
  {
    posInfo: {
      posName: "Checker",
    },
    userInfo: {
      fullName: "-",
      startTime: "-",
      endTime: "-",
      duration: "-",
    },
    isStarted: false,
  },
  {
    posInfo: {
      posName: "ครัวร้อน",
    },
    userInfo: {
      fullName: "-",
      startTime: "-",
      endTime: "-",
      duration: "-",
    },
    isStarted: false,
    isKitchen: true
  },
  {
    posInfo: {
      posName: "บาร์น้ำ",
    },
    userInfo: {
      fullName: "-",
      startTime: "-",
      endTime: "-",
      duration: "-",
    },
    isStarted: false,
    isKitchen: true
  },
];

export default function PosSettingPage() {

  const navigate = useNavigate();
  const authTokenCheck = useAuthTokenCheck();
  const { isLoggedIn } = useRecoilValue(authState);

  const [modalPosSettingStartOpen, setModalPosSettingStartOpen] =
    useState(false);
  const [modalPosSettingStartConfirmOpen, setModalPosSettingStartConfirmOpen] =
    useState(false);
  const [modalPosDubStartConfirmOpen, setModalPosDubStartConfirmOpen] =
    useState(false);
  const [modalPrintErrorOpen, setModalPrintErrorOpen] =
    useState(false);

  const [startValue, setStartValue] = useState("0.00");

  const [posSettingCardList, setPosSettingCardList] = useState([]);
  const { pos: posList } = useRecoilValue(posState);
  const fetchAllPos = useFetchAllPos();
  const [selectedPosSetting, setSelectedPosSetting] = useRecoilState(selectedPosSettingState);
  // console.log("🚀 ~ PosSettingPage ~ selectedPosSetting:", selectedPosSetting)
  const { user, logMachine } = useRecoilValue(authState);
  const { branch } = useRecoilValue(branchState);
  const fetchBranch = useFetchBranch();
  const createStaffLogin = useCreateStaffLogin();
  const resetSelectedPosSettingState = useResetRecoilState(selectedPosSettingState);
  const createStaffKitchenLogin = useCreateStaffKitchenLogin();

  useEffect(() => {
    if(user.branchId && user.branchId !== 0) {
      fetchBranch(user.branchId);
      fetchAllPos(user.branchId);
    }
  },[user]);

  useEffect(() => {
    if(posList && posList.length > 0) {
      let parsed = posList.map(p => {

        let userName = null;
        let startDateTime = null;
        let startTime = null;
        let duration = null;
        if (p.is_logged && p.activity) {
          userName = p.activity.employee_branch_account.employee_permission.employee.first_name_th + " " + p.activity.employee_branch_account.employee_permission.employee.last_name_th;
          startDateTime = new Date(p.activity.created_at);
          startTime = startDateTime.toLocaleTimeString('th-TH',{ hourCycle: 'h23', hour: '2-digit', minute: '2-digit' }) + " น.";
          let current = new Date();
          let dif = (current.getTime() - startDateTime.getTime()) / (1000 * 60 * 60);
          // Create reusable function
          if (dif > 0) {
            let splitted = dif.toString().split('.');
            let hour = splitted[0];
            let minute = ((Number(splitted[1].substring(0,2)) / 100) * 60).toFixed(0);
            if (hour >= 1) {
              duration = `${hour} ชั่วโมง ${minute} นาที`;
            } else {
              duration = `${minute} นาที`;
            }
          }
        }

        return {
          posInfo: {
            ...p,
            posId: p.id,
            posName: p.name,
            allowedAt: p.allowed_at,
            branchId: p.branch_id,
            code: p.code,
            isActive: p.is_logged,
            isRegisted: p.is_registed,
            order: p.order,
          },
          userInfo: {
            fullName: userName,
            startTime: startTime,
            endTime: null,
            duration: duration,
          },
          isStarted: p.is_logged,
          isKitchen: p.type == "kitchen" ? true : false
        }
      });
      setPosSettingCardList([ ...parsed]);
    }
  },[posList]);

  const onOkayBtnHandler = async () => {
    if(selectedPosSetting.posInfo?.type == "kitchen") {
      createStaffKitchenLogin(
        {
          ...selectedPosSetting,
          employeeId: user.employeeId,
          employeeBranchAccountId: user.employeeBranchAccountId,
          startTime: selectedPosSetting.posInfo?.activity?.operation_date ? selectedPosSetting.posInfo.activity.operation_date : null
        },
        false,
        logMachine
      );
    } else {
      createStaffLogin(
        {
          ...selectedPosSetting,
          employeeId: user.employeeId,
          employeeBranchAccountId: user.employeeBranchAccountId,
          startTime: selectedPosSetting.posInfo?.activity?.operation_date ? selectedPosSetting.posInfo.activity.operation_date : null
        },
        false,
        logMachine
      )
      ;
    }
  }

  const onCancelBtnHandler = async () => {
    resetSelectedPosSettingState()
  }

  return (
    <div className="px-[min(1.95vw,20px)] py-[min(2.73vw,28px)]">
      <PosAvartarCard
        userInfo={{
          fullName: `${user.firstNameTH} ${user.lastNameTH}`,
          code: `${user.employeeCode}`,
          avartar: "/img/avatar-female.png",
        }}
      />
      <div className="text-20 flex gap-[min(1.46vw,15px)] font-[500]">
        <div>
          ชื่อธุรกิจ : <span className="font-[400]">{branch && branch.business ? branch.business.businessdetail.name : null}</span>
        </div>
        <div>
          สาขา : <span className="font-[400]">{branch.name}</span>
        </div>
      </div>
      <div className="w-full h-[min(0.10vw,1px)] bg-[#EDEDED] my-[min(1.86vw,19px)]"></div>
      <div className="flex flex-wrap gap-[min(1.27vw,13px)]">
        {posSettingCardList.map((value, index) => {
          return (
            <PosSettingCard
              key={index}
              posInfo={{
                posId: value.posInfo.posId ? value.posInfo.posId : null,
                posName: value.posInfo.posName,
                allowedAt: value.posInfo.allowedAt,
                branchId: value.posInfo.branchId,
                code: value.posInfo.code,
                isActive: value.posInfo.isActive,
                isRegisted: value.posInfo.isRegisted,
                order: value.posInfo.order,
                ...value
              }}
              userInfo={{
                fullName: value.userInfo.fullName,
                startTime: value.userInfo.startTime,
                endTime: value.userInfo.endTime,
                duration: value.userInfo.duration,
                employeeBranchAccountId: user.employeeBranchAccountId,
                employeeId: user.employeeId
              }}
              modalPosSettingStart={{
                modalPosSettingStartOpen: modalPosSettingStartOpen,
                setModalPosSettingStartOpen: setModalPosSettingStartOpen,
              }}
              modalPosDubStartConfirm={{
                modalOpen: modalPosDubStartConfirmOpen,
                setModalOpen: setModalPosDubStartConfirmOpen,
              }}
              isStarted={value.isStarted}
              isKitchen={value.isKitchen}
              setSelectedPosSetting={setSelectedPosSetting}
            />
          );
        })}
      </div>
      <ModalPosSettingStart
        modalPosSettingStart={{
          modalPosSettingStartOpen: modalPosSettingStartOpen,
          setModalPosSettingStartOpen: setModalPosSettingStartOpen,
        }}
        modalPosStartConfirm={{
          modalOpen: modalPosSettingStartConfirmOpen,
          setModalOpen: setModalPosSettingStartConfirmOpen,
        }}
        startValueObj={{
          startValue: startValue,
          setStartValue: setStartValue,
        }}
        details={{
          userName: `${user.firstNameTH} ${user.lastNameTH}`,
          posName: selectedPosSetting.posName
        }}
      />
      <ModalPosStartConfirm
        modalOpenObj={{
          modalOpen: modalPosSettingStartConfirmOpen,
          setModalOpen: setModalPosSettingStartConfirmOpen,
        }}
        details={{
          userName: `${user.firstNameTH} ${user.lastNameTH}`,
          posName: selectedPosSetting.posName,
          businessName: branch && branch.business ? branch.business.businessdetail.name : null,
          branch: branch.name,
          startTime: selectedPosSetting.startTime ? selectedPosSetting.startTime.toLocaleString('es-ES').replace(',','') : null
        }}
        logMachine={logMachine}
      />
      <ModalPosDubStartConfirm
        modalOpenObj={{
          modalOpen: modalPosDubStartConfirmOpen,
          setModalOpen: setModalPosDubStartConfirmOpen,
        }}
        onOkayBtn={onOkayBtnHandler}
        onCancelBtn={onCancelBtnHandler}
      />
      <ModalPrintError
        modalOpenObj={{
          modalOpen: modalPrintErrorOpen,
          setModalOpen: setModalPrintErrorOpen,
        }}
      />
    </div>
  );
}
