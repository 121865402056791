import React, { useEffect, useState } from "react";

export default function WarningAlert({ time, alertOpenObj, ...props }) {
  const [countdown, setCountdown] = useState(time);

  useEffect(() => {
    if(!time || time <= 0) {
      return;
    }
    let intervalId;
    if (!alertOpenObj.alertOpen) {
      return;
    }

    intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(intervalId);
          alertOpenObj.setAlertOpen(false);
          return setCountdown(time);
        }
        return prevCountdown - 1;
      });
    }, 1000);
    return () => {
      clearInterval(intervalId);
      alertOpenObj.setAlertOpen(false);
      setCountdown(time);
    };
  }, [alertOpenObj.alertOpen]);

  return (
    <div
      className={`absolute rounded-[min(0.78vw,8px)] aspect-[320/52] top-[min(0.78vw,8px)] right-[min(0.59vw,6px)] z-[9999] w-[min(31.25vw,320px)] bg-[#fcf4e4] text-[#e4a324] text-16 px-[min(1.56vw,16px)] py-[min(0.59vw,6px)] flex items-center justify-between ${
        alertOpenObj.alertOpen ? "fade-in" : "fade-out"
      }`}
    >
      <div className="flex items-center">
        <img
          className="object-contain w-[min(2.34vw,24px)] aspect-square mr-[min(1.17vw,12px)]"
          src="/img/icons/icon-warning.svg"
          alt=""
        />
        <div>พิมพ์ไม่สำเร็จ</div>
      </div>
      <img
        onClick={() => alertOpenObj.setAlertOpen(false)}
        className="cursor-pointer object-contain w-[min(1.17vw,12px)] aspect-square"
        src="/img/icons/icon-warning-close.svg"
        alt=""
      />
    </div>
  );
}
