export const numberWithTwoDecimals = (value) => {
  const numberValue = parseFloat(value);

  if (!isNaN(numberValue)) {
    const formattedValue = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numberValue);

    return formattedValue;
  } else {
    return "Invalid Input";
  }
};

export const numberNoDecimals = (value) => {
  const numberValue = parseFloat(value);

  if (!isNaN(numberValue)) {
    const formattedValue = new Intl.NumberFormat("en-US").format(numberValue);

    return formattedValue;
  } else {
    return "Invalid Input";
  }
};
