export function getCurrentDate(separator = '', dateb = new Date()) {

    let newDate = dateb;
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`
}

export function getCurrentDateM(separator = '') {

    let d = new Date();

    let newDate = new Date(d.setMonth(d.getMonth() - 1));
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`
}

export function getTimeDifference(startDate, endDate) {
    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    // Format the result as "hh:mm:ss"
    const formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;

    return formattedTime;
}

// Helper function to pad zeros
function padZero(number) {
    return number < 10 ? `0${number}` : number;
}

export const localeStringDateTimeOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit" 
}


export const localeStringDateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
}