import React, { useEffect, useState } from "react";

export default function TableCard({
  info,
  type,
  isSelected = false,
  isReservedMode = false,
  isMergeTableMode = false,
  isMergeSelected = false,
  isMoveTableMode = false,
  isMoveSelected = false,
  ...props
}) {
  const initTheme = {
    color: "",
    bgColor: "",
    borderColor: "",
    userIcon: "",
    userText: "",
    remarkText: "",
    maxPersonText: "",
    timeIcon: "/img/icons/icon-time.svg",
    timeText: "",
    disabled: "",
    tickIcon: "/img/icons/icon-tick-blue.svg",
  };
  const [theme, setTheme] = useState(initTheme);

  const disabledTheme = () =>
    setTheme({
      ...initTheme,
      color: "text-[var(--color-grey-4)]",
      bgColor: "bg-[--color-light-grey-4]",
      borderColor: "border-[--color-light-grey-4]",
      userIcon: "/img/icons/icon-table-user-grey.svg",
      userText:
        info.currentPerson <= 0
          ? "text-[var(--color-grey-4)]"
          : "text-[var(--color-grey-4)] font-[700]",
      maxPersonText: "text-[var(--color-grey-4)]",
      timeIcon: "/img/icons/icon-time-grey.svg",
      timeText: "text-[var(--color-grey-4)]",
      disabled: "pointer-events-none",
    });

  const activeTheme = () =>
    setTheme({
      ...initTheme,
      color: "text-[var(--color-green)]",
      bgColor: "bg-[#FFF]",
      borderColor: "border-[var(--color-green)]",
      userIcon: "/img/icons/icon-table-user.svg",
      timeIcon: "/img/icons/icon-time.svg",
    });

  const selectTheme = () =>
    setTheme({
      ...initTheme,
      color: "text-[#FFF]",
      bgColor: "bg-[var(--color-blue-2)]",
      borderColor: "border-[var(--color-blue-2)]",
      userIcon: "/img/icons/icon-table-user-white.svg",
      userText:
        info.currentPerson <= 0 ? "text-[#fff]" : "text-[#fff] font-[700]",
      maxPersonText: "text-[#fff]",
      timeIcon: "/img/icons/icon-time-white.svg",
      timeText: "text-[#fff]",
      remarkText: "text-[#fff]",
    });

  const orangeModeTheme = () =>
    setTheme({
      ...initTheme,
      color: "text-[#FFF]",
      bgColor: "bg-[var(--color-orange)]",
      borderColor: "border-[var(--color-orange)]",
      userIcon: "/img/icons/icon-table-user-white.svg",
      userText:
        info.currentPerson <= 0 ? "text-[#fff]" : "text-[#fff] font-[700]",
      maxPersonText: "text-[#fff]",
      timeIcon: "/img/icons/icon-time-white.svg",
      timeText: "text-[#fff]",
      tickIcon: "/img/icons/icon-tick-orange.svg",
      remarkText: "text-[#fff]",
    });

  const caseReserverdMode = () => {
    if (!isSelected && type !== "empty") {
      disabledTheme();
    }
    if (!isSelected && type === "empty") {
      activeTheme();
    }
  };

  const caseMergeMode = () => {
    if (!isSelected && ["using", "overtime"].includes(type)) {
      disabledTheme();
    }
    if (!isSelected && ["reserved", "empty"].includes(type)) {
      activeTheme();
    }
    if (info.mergeList != "") {
      disabledTheme();
    }

    if (isMergeSelected) {
      orangeModeTheme();
    }
  };

  const caseMoveMode = () => {
    if (!isSelected && ["using", "overtime"].includes(type)) {
      disabledTheme();
    }
    if (!isSelected && ["reserved", "empty"].includes(type)) {
      activeTheme();
    }

    if (isMoveSelected) {
      orangeModeTheme();
    }
  };

  useEffect(() => {
    if (isSelected) {
      selectTheme();
      return;
    }

    if (isReservedMode) {
      caseReserverdMode();
      return;
    }

    if (isMergeTableMode) {
      caseMergeMode();
      return;
    }

    if (isMoveTableMode) {
      caseMoveMode();
      return;
    }

    switch (type) {
      case "empty":
        setTheme({
          ...initTheme,
          color: "text-[var(--color-grey-4)]",
          bgColor: "bg-[var(--color-light-grey-4)]",
          borderColor: "border-[var(--color-grey-4)]",
          userIcon:
            info.currentPerson <= 0
              ? "/img/icons/icon-table-user.svg"
              : "/img/icons/icon-table-user-green.svg",
          userText:
            info.currentPerson <= 0
              ? ""
              : "text-[var(--color-green)] font-[700]",
        });
        break;
      case "reserved":
        setTheme({
          ...initTheme,
          color: "text-[var(--color-yellow)]",
          bgColor: "bg-[var(--color-light-yellow)]",
          borderColor: "border-[var(--color-yellow)]",
          remarkText: "text-[var(--color-yellow)]",
          userIcon:
            info.currentPerson <= 0
              ? "/img/icons/icon-table-user.svg"
              : "/img/icons/icon-table-user-green.svg",
          userText:
            info.currentPerson <= 0
              ? ""
              : "text-[var(--color-green)] font-[700]",
        });
        break;
      case "overtime":
        setTheme({
          ...initTheme,
          color: "text-[var(--color-red)]",
          bgColor: "bg-[var(--color-light-red)]",
          borderColor: "border-[var(--color-red)]",
          userIcon:
            info.currentPerson <= 0
              ? "/img/icons/icon-table-user.svg"
              : "/img/icons/icon-table-user-green.svg",
          userText:
            info.currentPerson <= 0
              ? ""
              : "text-[var(--color-green)] font-[700]",
        });
        break;
      case "using":
        setTheme({
          ...initTheme,
          color: "text-[var(--color-green)]",
          bgColor: "bg-[var(--color-light-green)]",
          borderColor: "border-[var(--color-green)]",
          userIcon:
            info.currentPerson <= 0
              ? "/img/icons/icon-table-user.svg"
              : "/img/icons/icon-table-user-green.svg",
          userText:
            info.currentPerson <= 0
              ? ""
              : "text-[var(--color-green)] font-[700]",
          timeText: "text-[var(--color-green)]",
          timeIcon: "/img/icons/icon-time-green.svg",
        });
        break;

      default:
        break;
    }
  }, [
    type,
    isSelected,
    isReservedMode,
    isMergeTableMode,
    isMergeSelected,
    isMoveTableMode,
    isMoveSelected,
  ]);

  return (
    <div
      {...props}
      className={`${theme.disabled} relative flex flex-col justify-between cursor-pointer w-[min(11.04vw,113px)] h-[min(9.47vw,97px)] ${theme.bgColor} border-[min(0.20vw,2px)] ${theme.borderColor} px-[min(0.49vw,5px)] pt-[min(0.78vw,8px)] pb-[min(0.49vw,5px)] rounded-[min(0.78vw,8px)] text-10`}
    >
      <div>
        <div className="flex justify-between text-[#474747] gap-[min(0.98vw,10px)]">
          <div className="flex items-center gap-[min(0.20vw,2px)]">
            <img
              className="aspect-[6/8] w-[min(0.59vw,6px)] h-auto object-contain"
              src={theme.userIcon}
              alt=""
            />
            <div className={`prompt`}>
              <span className={`${theme.userText}`}>{info.currentPerson}</span>
              <span className={`${theme.maxPersonText}`}>/</span>
              <span className={`${theme.maxPersonText}`}>{info.maxPerson}</span>
            </div>
          </div>
          <div className="flex items-center gap-[min(0.20vw,2px)]">
            <img
              className="object-contain aspect-square w-[min(0.98vw,10px)] uppercase"
              src={theme.timeIcon}
              alt=""
            />
            <div className={theme.timeText}>{info.time}</div>
          </div>
        </div>

        {type === "overtime" && (
          <div
            className={`text-right truncate mt-[min(0.29vw,3px)] ${theme.color}`}
          >
            {info.timeUp}
          </div>
        )}
      </div>

      {type === "reserved" && !info.mergeList && !info.moveFrom && (
        <div className={`truncate sarabun ${theme.remarkText}`}>
          {info.remark}
        </div>
      )}

      {info.mergeList && (
        <div className="flex items-center gap-[min(0.49vw,5px)]">
          <div className="rounded-[min(0.20vw,2px)] text-[#FFF] text-10 bg-[var(--color-orange)] min-w-[min(2.64vw,27px)] h-[min(1.27vw,13px)] flex justify-center items-center">
            {info.mergeList.split(",").length > 1 || info.mergeList.split(" ").length > 1? "หลัก" : "รวม" }
          </div>
          <div className={`truncate sarabun ${theme.remarkText}`}>
            {info.mergeList}
          </div>
        </div>
      )}

      {info.moveFrom && (
        <div className="flex items-center gap-[min(0.49vw,5px)]">
          <div className="rounded-[min(0.20vw,2px)] text-[#FFF] text-10 bg-[var(--color-orange)] min-w-[min(4.10vw,42px)] h-[min(1.27vw,13px)] flex justify-center items-center">
            ย้ายจาก
          </div>
          <div className={`truncate sarabun ${theme.remarkText}`}>
            {info.moveFrom}
          </div>
        </div>
      )}

      <div>
        <div
          className={`text-right ${theme.color} uppercase  ${
            type === "reserved" ? "opacity-100" : "opacity-0"
          }`}
        >
          {type}
        </div>
        <div
          className={`text-right ${theme.color} uppercase text-20 font-[700] leading-[0.8]`}
        >
          {info.tableNo}
        </div>
      </div>

      {(isSelected || isMergeSelected) && (
        <div className="bottom-[min(0.49vw,5px)] absolute rounded-full aspect-square w-[min(1.95vw,20px)] bg-[#fff] flex justify-center items-center">
          <img
            className="aspect-[10/8.7] w-[min(0.98vw,10px)] object-contain"
            src={theme.tickIcon}
            alt=""
          />
        </div>
      )}
    </div>
  );
}