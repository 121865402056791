import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomPlainBtn from "components/fragments/CustomPlainBtn";
import CustomSelect from "components/fragments/CustomSelect";
import PersonIdInput from "components/fragments/PersonIdInput";
import CustomInput from "components/fragments/CustomInput";
import moment from "moment";
import CustomDatePicker from "components/fragments/CustomDatePicker";
import { useFormik } from "formik";
import * as yup from "yup";
import { getCustomerTypePrefixAndSuffix } from "utils/helperFunction";

const validationSchema = yup.object().shape({
  customer_type: yup.string().required(),
  first_name: yup
    .string()
    .required()
    .when("customer_type", {
      is: (val) => val !== "บุคคลธรรมดา" && val !== "",
      then: (schema) => {
        return schema.nullable().notRequired();
      },
    }),
  last_name: yup
    .string()
    .required()
    .when("customer_type", {
      is: (val) => val !== "บุคคลธรรมดา" && val !== "",
      then: (schema) => {
        return schema.nullable().notRequired();
      },
    }),
  company_name: yup
    .string()
    .nullable()
    .when("customer_type", {
      is: (val) => val !== "บุคคลธรรมดา" && val !== "",
      then: (schema) => {
        return schema.required();
      },
    }),
  // birthdate: yup
  //   .string()
  //   .required()
  //   .when("customer_type", {
  //     is: (val) => val !== "บุคคลธรรมดา" && val !== "",
  //     then: (schema) => {
  //       return schema.nullable().notRequired();
  //     },
  //   }),
  phone_number: yup
    .string()
    .matches(/^[0-9]+$/, "Phone number must only contain digits")
    .min(10, "Phone number must be exactly 10 digits")
    .max(10, "Phone number must be exactly 10 digits")
    .required(),
  tax_id: yup
    .string()
    .matches(/^[0-9]+$/, "Phone number must only contain digits")
    .min(13, "Phone number must be exactly 10 digits")
    .max(13, "Phone number must be exactly 10 digits"),
});

export default function ModalRegisterMember({ modalOpenObj, modalSuccessOpenObj, onSubmit, memberCode }) {
  const custTypeOptions = [
    "บุคคลธรรมดา",
    "บริษัทจำกัด",
    "บริษัทมหาชนจำกัด",
    "ห้างหุ้นส่วนจำกัด",
    "ร้านค้า",
    "อื่นๆ",
  ];
  const genderOptions = ["ไม่ระบุ","ชาย", "หญิง"];
  // const groupOptions = ["ไม่ระบุ", "กลุ่มที่ 1", "กลุ่มที่ 2", "กลุ่มที่ 3"];
  const groupOptions = ["ไม่ระบุ"];
  const initForm = {
    customer_type: "",
    tax_id: "",
    first_name: "",
    last_name: "",
    prefix: "",
    company_name: "",
    suffix: "",
    birthdate: "",
    phone_number: "",
    gender: "",
    group: "ไม่ระบุ",
  };

  const formik = useFormik({
    initialValues: initForm,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // alert(JSON.stringify(values, null, 2));
      await onSubmit({ ...values, code: memberCode });
      resetForm();
      modalOpenObj.setModalOpen(false);
      modalSuccessOpenObj.setModalOpen(true);
    },
  });

  useEffect(() => {
    const compPrefixAndSuffix = getCustomerTypePrefixAndSuffix(
      formik.values.customer_type
    );
    formik.setValues({
      ...initForm,
      customer_type: formik.values.customer_type,
      tax_id: formik.values.tax_id,
      phone_number: formik.values.phone_number,
      group: formik.values.group,
      prefix: compPrefixAndSuffix.prefix,
      suffix: compPrefixAndSuffix.suffix,
    });
  }, [formik.values.customer_type]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => {
          formik.resetForm();
          return modalOpenObj.setModalOpen(false)
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          <div className="bg-white w-[min(88.38vw,905px)] min-h-[min(59.57vw,610px)] max-h-[100vh] px-[min(4.00vw,41px)] pb-[min(3.71vw,38px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-24 text-[var(--color-black-2)] leading-[1.8] font-[500]">
            <div className="relative">
              <button
                onClick={() => {
                  formik.resetForm();
                  return modalOpenObj.setModalOpen(false);
                }}
                className="absolute top-[min(0.98vw,10px)] right-0 aspect-square w-[min(3.90vw,39.97px)] rounded-full bg-[#F5F5F5] flex justify-center items-center"
              >
                <img
                  className="aspect-square object-contain w-[min(0.98vw,10px)]"
                  src="/img/icons/icon-close.svg"
                  alt=""
                />
              </button>
            </div>
            <form
              className="w-full min-h-[min(59.57vw,610px)] pb-[min(3.71vw,38px)] max-h-[100vh] flex flex-col justify-between"
              onSubmit={formik.handleSubmit}
            >
              <div className="flex flex-col pt-[min(5.66vw,58px)]  font-[400]">
                {/* header */}
                <div className="flex items-center gap-[min(9.08vw,93px)]">
                  <div className="text-24 font-[700] text-[var(--color-green)]">
                    สมัครสมาชิกใหม่
                  </div>
                  <div className="flex items-center gap-[min(1.17vw,12px)]">
                    <div className="text-18">รหัสลูกค้า</div>
                    <div className="text-16 text-[rgba(47,72,88,0.7)] aspect-[187/44] rounded-[min(0.78vw,8px)] min-w-[min(18.26vw,187px)] flex justify-center items-center bg-[#F2F2F2]">
                      {/* CM2011040001 */}
                      {memberCode}
                    </div>
                  </div>
                </div>
                {/* body */}

                <div className="grid grid-cols-2 gap-y-[min(0.98vw,10px)] pr-[min(6.64vw,68px)] mt-[min(2.54vw,26px)]">
                  {/* customer_type */}
                  <div className="text-18 w-fit">
                    <div>
                      ประเภทลูกค้า <span className="text-[#F00]">*</span>
                    </div>
                    <div className="w-[min(32.42vw,332px)]  mt-[min(0.49vw,5px)]">
                      <CustomSelect
                        options={custTypeOptions}
                        label={"เลือกประเภทลูกค้า"}
                        selectedOptionObj={{
                          selectedOption: formik.values.customer_type,
                          setSelectedOption: formik.handleChange,
                        }}
                        inputprops={{
                          name: "customer_type",
                          onChange: formik.handleChange,
                          onBlur: formik.handleBlur,
                          error:
                            formik.touched.customer_type &&
                            formik.errors.customer_type
                              ? "error"
                              : undefined,
                        }}
                      />
                    </div>
                  </div>
                  {/* tax_id */}
                  <div className="text-18">
                    <div>หมายเลขผู้เสียภาษี (13หลัก)</div>
                    <div className="mt-[min(0.78vw,8px)]">
                      <PersonIdInput
                        personIdObj={{
                          value: formik.values.tax_id,
                          setValue: formik.handleChange,
                        }}
                        inputprops={{
                          name: "tax_id",
                          error:
                            formik.touched.tax_id && formik.errors.tax_id
                              ? "error"
                              : undefined,
                        }}
                      />
                    </div>
                  </div>
                  {(formik.values.customer_type === "" ||
                    formik.values.customer_type === "บุคคลธรรมดา") && (
                    <>
                      <div className="text-18 w-fit">
                        <div>
                          ชื่อ <span className="text-[#F00]">*</span>
                        </div>
                        <div className="w-[min(32.42vw,332px)]  mt-[min(0.49vw,5px)]">
                          <CustomInput
                            inputprops={{
                              type: "text",
                              className:
                                "rounded-[min(0.78vw,8px)] h-[min(4.30vw,44px)] px-[min(1.27vw,13px)] py-[min(1.03vw,10.5px)] text-18 leading-[4] placeholder:h-full bg-[#FFF] border-[1px] border-[var(--color-grey-4)]",
                              placeholder: "-",
                              name: "first_name",
                              onChange: formik.handleChange,
                              onBlur: formik.handleBlur,
                              value: formik.values.first_name,
                              error:
                                formik.touched.first_name &&
                                formik.errors.first_name
                                  ? "error"
                                  : undefined,
                            }}
                          />
                        </div>
                      </div>
                      <div className="text-18 w-fit">
                        <div>
                          นามสกุล <span className="text-[#F00]">*</span>
                        </div>
                        <div className="w-[min(32.42vw,332px)]  mt-[min(0.49vw,5px)]">
                          <CustomInput
                            inputprops={{
                              type: "text",
                              className:
                                "rounded-[min(0.78vw,8px)] h-[min(4.30vw,44px)] px-[min(1.27vw,13px)] py-[min(1.03vw,10.5px)] text-18 leading-[4] placeholder:h-full bg-[#FFF] border-[1px] border-[var(--color-grey-4)]",
                              placeholder: "-",
                              name: "last_name",
                              onChange: formik.handleChange,
                              onBlur: formik.handleBlur,
                              value: formik.values.last_name,
                              error:
                                formik.touched.last_name &&
                                formik.errors.last_name
                                  ? "error"
                                  : undefined,
                            }}
                          />
                        </div>
                      </div>
                      <div className="text-18 w-fit">
                        <div>
                          วัน-เดือน-ปี เกิด{" "}
                          {/* <span className="text-[#F00]">*</span> */}
                        </div>
                        <div className="w-[min(32.42vw,332px)]  mt-[min(0.49vw,5px)]">
                          <CustomDatePicker
                            inputprops={{
                              type: "text",
                              value: formik.values.birthdate
                                ? moment(
                                    formik.values.birthdate,
                                    "YYYY-MM-DD"
                                  ).format("DD/MM/YYYY")
                                : "",
                              className:
                                "rounded-[min(0.78vw,8px)] h-[min(4.30vw,44px)] px-[min(1.27vw,13px)] py-[min(1.03vw,10.5px)] text-18 leading-[4] placeholder:h-full bg-[#FFF] border-[1px] border-[var(--color-grey-4)] custom-input-datepicker",
                              placeholder: "-",
                              name: "birthdate",
                              onBlur: formik.handleBlur,
                              error:
                                formik.touched.birthdate &&
                                formik.errors.birthdate
                                  ? "error"
                                  : undefined,
                            }}
                            dateObj={{
                              date: formik.values.birthdate,
                              setDate: formik.handleChange,
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {/* company_name */}
                  {formik.values.customer_type !== "" &&
                    formik.values.customer_type !== "บุคคลธรรมดา" && (
                      <div className="col-span-2 flex gap-[min(1.76vw,18px)] text-18">
                        <div>
                          <div className="opacity-0">
                            prefix <span className="text-[#F00]">*</span>
                          </div>
                          <div className="mt-[min(0.49vw,5px)]">
                            <div className="text-[rgba(47,72,88,0.7)] text-16 font-[400] bg-[#F2F2F2] min-w-[min(9.38vw,96px)] px-[min(0.98vw,10px)] h-[min(4.30vw,44px)] rounded-[min(0.78vw,8px)] flex justify-center items-center">
                              {formik.values.prefix}
                            </div>
                          </div>
                        </div>

                        <div className="flex-grow-1">
                          <div>
                            ชื่อ <span className="text-[#F00]">*</span>
                          </div>
                          <div className="mt-[min(0.49vw,5px)]">
                            <CustomInput
                              inputprops={{
                                type: "text",
                                className:
                                  "rounded-[min(0.78vw,8px)] h-[min(4.30vw,44px)] px-[min(1.27vw,13px)] py-[min(1.03vw,10.5px)] text-18 leading-[4] placeholder:h-full bg-[#FFF] border-[1px] border-[var(--color-grey-4)]",
                                placeholder: "-",
                                name: "company_name",
                                onChange: formik.handleChange,
                                onBlur: formik.handleBlur,
                                value: formik.values.company_name,
                                error:
                                  formik.touched.company_name &&
                                  formik.errors.company_name
                                    ? "error"
                                    : undefined,
                              }}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="opacity-0">
                            suffix <span className="text-[#F00]">*</span>
                          </div>
                          <div className="mt-[min(0.49vw,5px)]">
                            <div className="text-[rgba(47,72,88,0.7)] text-16 font-[400] bg-[#F2F2F2] min-w-[min(9.38vw,96px)] rounded-[min(0.78vw,8px)] h-[min(4.30vw,44px)] flex justify-center items-center">
                              {formik.values.suffix
                                ? formik.values.suffix
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  {/* phone_number */}
                  <div className="text-18 w-fit">
                    <div>
                      เบอร์โทรศัพท์ <span className="text-[#F00]">*</span>
                    </div>
                    <div className="w-[min(32.42vw,332px)]  mt-[min(0.49vw,5px)]">
                      <CustomInput
                        inputprops={{
                          type: "text",
                          className:
                            "rounded-[min(0.78vw,8px)] h-[min(4.30vw,44px)] px-[min(1.27vw,13px)] py-[min(1.03vw,10.5px)] text-18 leading-[4] placeholder:h-full bg-[#FFF] border-[1px] border-[var(--color-grey-4)]",
                          placeholder: "-",
                          name: "phone_number",
                          onChange: (e) => {
                            if (e.currentTarget.value.length > 10) {
                              return;
                            }
                            const syntheticEvent = {
                              target: {
                                name: "phone_number",
                                value: e.currentTarget.value,
                              },
                            };
                            formik.handleChange(syntheticEvent);
                          },
                          onBlur: formik.handleBlur,
                          value: formik.values.phone_number,
                          error:
                            formik.touched.phone_number &&
                            formik.errors.phone_number
                              ? "error"
                              : undefined,
                        }}
                      />
                    </div>
                  </div>
                  {(formik.values.customer_type === "" ||
                    formik.values.customer_type === "บุคคลธรรมดา") && (
                    <>
                      <div className="text-18 w-fit">
                        <div>เพศ</div>
                        <div className="w-[min(32.42vw,332px)]  mt-[min(0.49vw,5px)]">
                          <CustomSelect
                            options={genderOptions}
                            // label={"ไม่ระบุ"}
                            label={genderOptions[0]}
                            selectedOptionObj={{
                              selectedOption: formik.values.gender,
                              setSelectedOption: formik.handleChange,
                            }}
                            inputprops={{
                              name: "gender",
                              onChange: formik.handleChange,
                              onBlur: formik.handleBlur,
                              error:
                                formik.touched.gender && formik.errors.gender
                                  ? "error"
                                  : undefined,
                              defaultValue: genderOptions[0]
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {/* group */}
                  <div className="text-18 w-fit">
                    <div>กลุ่มลูกค้า</div>
                    <div className="w-[min(32.42vw,332px)]  mt-[min(0.49vw,5px)]">
                      <CustomSelect
                        options={groupOptions}
                        // label={"ไม่ระบุ"}
                        selectedOptionObj={{
                          selectedOption: formik.values.group,
                          setSelectedOption: formik.handleChange,
                        }}
                        inputprops={{
                          name: "group",
                          onChange: formik.handleChange,
                          onBlur: formik.handleBlur,
                          error:
                            formik.touched.group && formik.errors.group
                              ? "error"
                              : undefined,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* footer */}
              <div className="flex justify-end mt-[min(2.83vw,29px)] pr-[min(2.93vw,30px)]">
                <div className="flex gap-[min(1.17vw,12px)]">
                  <CustomPlainBtn
                    onClick={() => {
                      formik.setValues({ ...initForm });
                      return modalOpenObj.setModalOpen(false)
                    }}
                    className="aspect-[114/52.8] min-w-[min(11.13vw,114px)] text-20 rounded-[min(0.78vw,8px)] btn-grey-3-active"
                    btntxt={"ยกเลิก"}
                  />
                  <CustomPlainBtn
                    type={"submit"}
                    className="aspect-[114/52.8] min-w-[min(11.13vw,114px)] text-20 rounded-[min(0.78vw,8px)] btn-main-active"
                    btntxt={"ยืนยัน"}
                  />
                </div>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
