import axiosInstance from './axios';

const endPoint = "/summary";

export const getBranchSummaryStat = async ({ branchId, startDate, endDate }) => {
    try {
        const queryParams = {
            branchId: branchId,
            startDate: startDate,
            endDate: endDate,
        };

        const response = await axiosInstance.get('/summary/total-gmv', { params: queryParams });

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            summary: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}