import React, { useEffect, useState } from "react";
import TableBtnDetail from "components/fragments/TableBtnDetail";

export default function ShowTableDetail({
  tableSeletected,
  tableCardList,
  isDetailModeObj,
  tableOrder,
  selectedTable,
  onPrintBtn,
  onCheckBillBtn
}) {
  const tagTestList = Array.from({ length: 5 }, () => "ไม่เผ็ด");
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`bg-[#fff] w-[min(48.93vw,501px)] h-full fixed top-0 right-0 pt-[min(1.66vw,17px)] table-detail ${
        isDetailModeObj.isMode ? "active" : ""
      }`}
    >
      <div className="relative h-full">
        <div className="px-[min(1.95vw,20px)]">
          <div className="flex items-start justify-between">
            <div className="flex items-start gap-[min(1.66vw,17px)]">
              <img
                onClick={() => isDetailModeObj.setIsMode(false)}
                className="cursor-pointer object-contain aspect-[15.07/15.75] w-[min(1.47vw,15.07px)]"
                src="/img/icons/icon-double-arrow-right-red.svg"
                alt=""
              />
              <div className="text-18 leading-[1.3] flex flex-col gap-[min(0.29vw,3px)]">
                <div className="text-[var(--color-grey-2)] font-[500]">
                  Order NO.{" "}
                  <span className="text-[var(--color-green)]">
                    {tableOrder?.orderNo ?? ""}
                  </span>
                </div>
                <div className="flex gap-[min(0.29vw,3px)]">
                  <img
                    className="object-contain aspect-square w-[min(1.76vw,18px)]"
                    src="/img/icons/icon-calendar-green.svg"
                    alt=""
                  />
                  <div className="font-[300] text-[var(--color-grey-2)]">
                    {/* 28/04/2020 */}
                    {tableOrder?.operation_date
                      ? new Date(tableOrder.operation_date).toLocaleDateString(
                          "en-GB",
                          { year: "numeric", month: "2-digit", day: "2-digit" }
                        )
                      : ""}
                  </div>
                </div>
              </div>
            </div>

            <img
              onClick={() => isDetailModeObj.setIsMode(false)}
              className="cursor-pointer object-contain aspect-[15.07/15.75] w-[min(1.47vw,15.07px)] h-fit"
              src="/img/icons/icon-double-arrow-right-red.svg"
              alt=""
            />
          </div>
          {tableOrder?.member && (
            <div className="mt-[min(1.07vw,11px)] flex w-full rounded-[min(0.78vw,8px)] bg-[#F4FCF8] px-[min(1.56vw,16px)] pt-[min(0.59vw,6px)] pb-[min(0.78vw,8px)] ">
              <div className="flex flex-col items-center justify-between w-fit mr-[min(3.47vw,35.5px)]">
                <img
                  className="object-cover rounded-full aspect-square w-[min(3.42vw,35px)] h-fit"
                  src="/img/avatar-male-pink.png"
                  alt=""
                />
                <div className="flex text-10 text-[var(--color-grey-2)] items-center gap-[min(0.39vw,4px)]">
                  <img
                    className="object-contain aspect-[11.51/7.97] w-[min(1.12vw,11.51px)] h-fit"
                    src="/img/star-yellow.png"
                    alt=""
                  />
                  {/* <div>88888</div> */}
                  <div>{tableOrder.member.point}</div>
                </div>
              </div>
              <div className="flex-grow">
                <div className="truncate text-[#4C4E64DE] font-[500] tracking-[0.015em]">
                  {/* คุณเบญจรรณ บัวพิทักษ์ */}
                  {tableOrder.member.name}
                </div>
                <div className="truncate text-[var(--color-grey-2)] tracking-[0.04em]">
                  {/* 066-154-1519 */}
                  {tableOrder.member.phone_number}
                </div>
              </div>
              <div className="flex flex-col items-end justify-between">
                <div className="cursor-pointer flex items-center gap-[min(0.59vw,6px)]">
                  <div className="text-12 text-[var(--color-green)] underline">
                    แก้ไข
                  </div>
                  <img
                    className="object-contain aspect-square w-[min(1.06vw,10.9px)] h-fit"
                    src="/img/icons/icon-edit-green.svg"
                    alt=""
                  />
                </div>
                <div className="rounded-full min-w-[min(8.13vw,83.3px)] h-[min(1.65vw,16.85px)] bg-[var(--color-blue-2)] flex justify-center items-center text-[#FFF] text-12 font-[300] mb-[min(0.29vw,3px)]">
                  {/* SILVER */}
                  {tableOrder.member.member_level}
                </div>
              </div>
            </div>
          )}
          <div className="flex gap-[min(2.44vw,25px)] text-16 mt-[min(1.66vw,17px)]">
            <div className="w-[40%]">
              <div className="flex items-center gap-[min(0.88vw,9px)]">
                <div className="truncate text-[var(--color-green)] font-[700]">
                  จำนวนลูกค้า :
                </div>
                <img
                  className="object-contain rounded-full aspect-[13/15] w-[min(1.27vw,13px)] h-fit"
                  src="/img/icons/icon-table-user-green.svg"
                  alt=""
                />
                <div>
                  <span className="font-[700] text-[var(--color-green)]">
                    {tableOrder?.table_activity?.table.current_guest ?? 0}
                  </span>
                  <span>/</span>
                  <span>{tableOrder?.table_activity?.table.volume ?? 0}</span>
                </div>
              </div>
              <div className="flex items-center gap-[min(0.88vw,9px)]">
                <div className="truncate text-[var(--color-green)] font-[700]">
                  เวลาเปิดโต๊ะ :
                </div>
                {/* <div className="text-[var(--color-green)]">12:03am</div> */}
                {/* <div className="text-[var(--color-green)]">
                  {tableOrder?.operation_date
                    ? new Date(tableOrder.operation_date).toLocaleTimeString(
                        "en-GB",
                        { hour: "2-digit", minute: "2-digit" }
                      )
                    : ""}
                </div> */}
                <div className="text-[var(--color-green)]">
                  {selectedTable?.activity?.updatedAt
                    ? new Date(selectedTable.activity.updatedAt).toLocaleTimeString(
                        "en-GB",
                        { hour: "2-digit", minute: "2-digit" }
                      )
                    : ""}
                </div>
              </div>
            </div>
            <div className="flex-grow items-center text-[var(--color-red)]">
              <div
                className="flex items-baseline
             gap-[min(0.88vw,9px)]"
              >
                <div className="whitespace-nowrap font-[700]">โต๊ะ :</div>
                {/* <div className="whitespace-nowrap">VIP:A03</div> */}
                <div className="whitespace-nowrap">
                  {tableOrder
                    ? `${tableOrder?.table_activity?.table?.no}:${tableOrder?.table_activity?.table.zone?.alias}`
                    : tableCardList[tableSeletected]?.tableNo ?? ""}
                </div>
                {tableSeletected && tableCardList[tableSeletected].moveFrom && (
                  <div className="text-12 text-[#949494]">
                    (ย้ายจาก {tableCardList[tableSeletected].moveFrom} )
                  </div>
                )}
                {tableSeletected &&
                  tableCardList[tableSeletected].mergeList && (
                    <div className="text-12 text-[#949494]">
                      (รวม {tableCardList[tableSeletected].mergeList} )
                    </div>
                  )}
              </div>
              <div className="flex items-center gap-[min(0.88vw,9px)]">
                <div className="font-[700]">เวลากำหนด :</div>
                {/* <div>02:03am</div> */}
                {/* <div>
                  {tableOrder?.end_time
                    ? new Date(tableOrder.end_time).toLocaleTimeString(
                        "en-GB",
                        { hour: "2-digit", minute: "2-digit" }
                      )
                    : ""}
                </div> */}
                <div>
                  {selectedTable?.overTime
                    ? new Date(selectedTable.overTime).toLocaleTimeString(
                        "en-GB",
                        { hour: "2-digit", minute: "2-digit" }
                      )
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>

        {tableSeletected &&
        tableCardList[tableSeletected].type === "reserved" ? (
          <>
            <div className="w-full h-[min(0.10vw,1px)] bg-[rgba(182,187,198,0.3)] mt-[min(2.83vw,29px)]"></div>
            <div className="px-[min(1.95vw,20px)] text-16 text-[var(--color-grey-2)] mt-[min(3.52vw,36px)]">
              <div className="flex justify-between items-center">
                <div className="font-[700]">ชื่อผู้จอง :</div>
                <div>คุณอนุรักษ์ ใจสะอาด</div>
              </div>
              <div className="flex justify-between items-center">
                <div className="font-[700]">เบอร์โทรศัพท์ :</div>
                <div>088-8899999 </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {tableOrder?.product ? (
          <>
            <div className="flex justify-between text-14 text-[#525252] mt-[min(1.76vw,18px)] pl-[min(3.22vw,33px)] pr-[min(2.44vw,25px)]">
              <div>รายการ</div>
              <div className="flex justify-between w-[min(12.50vw,128px)] px-[min(0.29vw,3px)]">
                <div>จำนวน</div>
                <div>ราคา</div>
              </div>
            </div>
            <div className="w-full h-[min(0.10vw,1px)] bg-[rgba(182,187,198,0.3)] mt-[min(0.98vw,10px)]"></div>
            <div
              className={`flex flex-col flex-grow pb-[min(1.37vw,14px)] px-[min(2.44vw,25px)] pt-[min(1.37vw,14px)] text-18 overflow-y-auto gap-[min(1.66vw,17px)] ${
                windowHeight > 767 ? "max-h-[45vh]" : "max-h-[35vh]"
              } `}
            >
              {/* <div>
                <div className="flex justify-between w-full">
                  <div className="flex gap-[min(1.76vw,18px)]">
                    <img
                      className="object-contain aspect-[98/74] w-[min(9.57vw,98px)] h-fit"
                      src="/img/ex-food-1.png"
                      alt=""
                    />
                    <div className="w-[min(13.09vw,134px)] text-justify">
                      Vegan สลัดอกไก่
                    </div>
                  </div>
                  <div className="flex justify-between pl-[min(0.98vw,10px)] w-[min(12.70vw,130px)]">
                    <div>10</div>
                    <div>550.-</div>
                  </div>
                </div>
                <div className="flex gap-[min(0.29vw,3px)] mt-[min(1.46vw,15px)]">
                  {tagTestList.map((v, i) => {
                    return (
                      <div
                        key={i}
                        className="flex justify-center items-center min-w-[min(5.18vw,53px)] h-[min(2.15vw,22px) bg-[rgba(250,204,205,0.30)] text-[var(--color-red)] rounded-[min(0.49vw,5px)] text-12"
                      >
                        {v}
                      </div>
                    );
                  })}
                  <div className="flex justify-center items-center min-w-[min(3.42vw,35px)] h-[min(2.15vw,22px) bg-[var(--color-red)] text-[#FFF] rounded-[min(0.49vw,5px)] prompt text-12">
                    +2
                  </div>
                </div>
              </div> */}
              {/* <div>
                <div className="flex justify-between w-full">
                  <div className="flex gap-[min(1.76vw,18px)]">
                    <img
                      className="object-contain aspect-[98/74] w-[min(9.57vw,98px)] h-fit"
                      src="/img/ex-food-2.png"
                      alt=""
                    />
                    <div className="w-[min(13.09vw,134px)] text-justify">
                      ต้มยำกุ้งน้ำข้น
                    </div>
                  </div>
                  <div className="flex justify-between pl-[min(0.98vw,10px)] w-[min(12.70vw,130px)]">
                    <div>10</div>
                    <div>550.-</div>
                  </div>
                </div>
              </div> */}
              {/* <div>
                <div className="flex justify-between w-full">
                  <div className="flex gap-[min(1.76vw,18px)]">
                    <img
                      className="object-contain aspect-[98/74] w-[min(9.57vw,98px)] h-fit"
                      src="/img/ex-food-3.png"
                      alt=""
                    />
                    <div className="w-[min(13.09vw,134px)] text-justify">
                      Vegan พิซซ่า เป็น พิซซ่าสำหรับคนที่ ทานมัง
                    </div>
                  </div>
                  <div className="flex justify-between pl-[min(0.98vw,10px)] w-[min(12.70vw,130px)]">
                    <div>10</div>
                    <div>550.-</div>
                  </div>
                </div>
              </div> */}
              {tableOrder?.product?.map((product, index) => (
                <div key={`orderProduct${index}`}>
                  <div className="flex justify-between w-full">
                    <div className="flex gap-[min(1.76vw,18px)]">
                      <img
                        className="object-contain aspect-[98/74] w-[min(9.57vw,98px)] h-fit"
                        src={product.productImage}
                        alt=""
                      />
                      <div className="w-[min(13.09vw,134px)] text-justify">
                        {product.productName}
                      </div>
                    </div>
                    <div className="flex justify-between pl-[min(0.98vw,10px)] w-[min(12.70vw,130px)]">
                      <div>{product.productQty}</div>
                      <div>{product.productSumPrice}</div>
                    </div>
                  </div>
                  <div className="flex gap-[min(0.29vw,3px)] mt-[min(1.46vw,15px)]">
                    {product.optionstandard.map((v, i) => {
                      return (
                        <div
                          key={`option${i}`}
                          className="flex justify-center items-center min-w-[min(5.18vw,53px)] h-[min(2.15vw,22px) bg-[rgba(250,204,205,0.30)] text-[var(--color-red)] rounded-[min(0.49vw,5px)] text-12"
                        >
                          {v.listName} {v.optionQty > 1 && `x${v.optionQty}`}
                        </div>
                      );
                    })}
                    {product.optionproduct.map((v, i) => {
                      return (
                        <div
                          key={`option${i}`}
                          className="flex justify-center items-center min-w-[min(5.18vw,53px)] h-[min(2.15vw,22px) bg-[rgba(250,204,205,0.30)] text-[var(--color-red)] rounded-[min(0.49vw,5px)] text-12"
                        >
                          {v.listName} {v.optionQty > 1 && `x${v.optionQty}`}
                        </div>
                      );
                    })}
                    {product.optionnote.map((v, i) => {
                      return (
                        <div
                          key={`option${i}`}
                          className="flex justify-center items-center min-w-[min(5.18vw,53px)] h-[min(2.15vw,22px) bg-[rgba(250,204,205,0.30)] text-[var(--color-red)] rounded-[min(0.49vw,5px)] text-12"
                        >
                          {v.listName}
                        </div>
                      );
                    })}
                    {/* <div className="flex justify-center items-center min-w-[min(3.42vw,35px)] h-[min(2.15vw,22px) bg-[var(--color-red)] text-[#FFF] rounded-[min(0.49vw,5px)] prompt text-12">
                      +2
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          ""
        )}

        <div className="absolute bottom-0 w-full h-fit">
          <div className="bg-[#CAE0FD] min-h-[min(5.27vw,54px)] px-[min(1.95vw,20px)] text-18 font-[500] text-[var(--color-blue-2)] flex items-center cursor-pointer">
            <img
              className="object-contain aspect-[8.79/14.98] w-[min(0.86vw,8.79px)] mr-[min(3.52vw,36px)]"
              src="/img/icons/icon-arrow-up-blue.svg"
              alt=""
            />
            <div>ยอดรวมทั้งหมด</div>
            <div className="flex-grow flex justify-end">
              {tableOrder?.sumPrice
                ? parseInt(tableOrder.sumPrice).toFixed(2)
                : "0.00"}
              ฿
            </div>
          </div>
          <div className="flex justify-center bg-[#F3F3F3] py-[min(1.17vw,12px)] gap-[min(1.17vw,12px)]">
            <TableBtnDetail
              className="flex justify-center text-[#FFF] items-center btn-table-zone-active shadow-table-btn-2 text-14 min-w-[min(13.38vw,137px)] h-[min(3.32vw,34px)] font-[500]"
              content={
                <div className="flex gap-[min(0.59vw,6px)]">
                  <img
                    className="object-contain aspect-[22.5/20.25] w-[min(2.20vw,22.5px)]"
                    src={
                      tableSeletected &&
                      tableCardList[tableSeletected].type !== "reserved"
                        ? "/img/icons/icon-print-white.svg"
                        : "/img/icons/icon-print-grey.svg"
                    }
                    alt=""
                  />
                  <div>พิมพ์รายการ</div>
                </div>
              }
              // isActive={
              //   tableSeletected &&
              //   tableCardList[tableSeletected].type !== "reserved"
              // }
              isActive={true}
              onClickBtn={onPrintBtn}
            />
            {/* <TableBtnDetail
              className="flex justify-center text-[#FFF] items-center btn-orange shadow-table-btn-2 text-14 min-w-[min(10.64vw,109px)] h-[min(3.32vw,34px)] font-[500]"
              content={<div>แยกบิล</div>}
              isActive={
                tableSeletected != null &&
                tableCardList[tableSeletected].type !== "reserved"
              }
            /> */}
            <TableBtnDetail
              className="flex justify-center text-[#FFF] items-center btn-main-active shadow-table-btn-2 text-14 min-w-[min(10.64vw,109px)] h-[min(3.32vw,34px)] font-[500]"
              content={<div>เช็คบิล</div>}
              isActive={
                (tableSeletected != null || tableSeletected != undefined) &&
                tableCardList[tableSeletected].type !== "reserved"
              }
              onClickBtn={onCheckBillBtn}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
