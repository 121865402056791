import { atom, selector, useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { getAllTable, updateTable, createTableActivity, moveTableActivity, mergeTableActivity, createTableBooking, getAllTableZone } from '../services/table';

// State
export const tableState = atom({
    key: 'tableState',
    default: {
        tables: [],
        tableZone: [],
        isTableOrderCheckBill: false
    },
});

export const selectedTableState = atom({
    key: 'selectedTableState',
    default: null,
});

// Action
export const useFetchAllTable = () => {
    const setState = useSetRecoilState(tableState);

    return async (branchId) => {
        try {
            const fetch = await getAllTable(branchId);
            setState(state => ({ ...state, tables: fetch.table }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
};

export const useFetchTable = () => {
    const setState = useSetRecoilState(tableState);

    return async () => {
        try {
            const fetch = await getAllTable();
            setState(state => ({ ...state, tables: fetch.table }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useUpdateTable = () => {
    const setState = useSetRecoilState(tableState);
    const selectedTable = useRecoilValue(selectedTableState);

    return async (updateDetail) => {
        try {
            const update = await updateTable(selectedTable, updateDetail);
            const fetch = await getAllTable();
            setState(state => ({ ...state, tables: fetch.table }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
};

export const useOpenTable = () => {
    const setState = useSetRecoilState(tableState);
    const selectedTable = useRecoilValue(selectedTableState);

    return async (detail, table) => {
        // console.log("🚀 ~ file: table.js:66 ~ return ~ detail:", detail)
        const tableData = table ? table : selectedTable ? selectedTable : null

        if (!tableData) return null

        try {
            const create = await createTableActivity(tableData, detail);
            const fetch = await getAllTable();
            setState(state => ({ ...state, tables: fetch.table }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
};

export const useMoveTable = () => {
    const setState = useSetRecoilState(tableState);
    const selectedTable = useRecoilValue(selectedTableState);

    return async (fromTable,toTable) => {
        try {
            const update = await moveTableActivity(fromTable, toTable);
            const fetch = await getAllTable();
            setState(state => ({ ...state, tables: fetch.table }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
};

export const useMergeTable = () => {
    const setState = useSetRecoilState(tableState);
    const selectedTable = useRecoilValue(selectedTableState);

    return async (mainTable,mergeTables) => {
        // console.log("🚀 ~ file: table.js:96 ~ return ~ mergeTables:", mergeTables)
        try {
            const update = await mergeTableActivity(mainTable, mergeTables);
            const fetch = await getAllTable();
            setState(state => ({ ...state, tables: fetch.table }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
};

export const useBookingTable = () => {
    const setState = useSetRecoilState(tableState);
    const selectedTable = useRecoilValue(selectedTableState);

    return async (bookingDetail) => {
        try {
            const update = await createTableBooking(bookingDetail);
            const fetch = await getAllTable();
            setState(state => ({ ...state, tables: fetch.table }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
};

export const useFetchAllTableZone = () => {
    const setState = useSetRecoilState(tableState);

    return async (branchId) => {
        try {
            const fetch = await getAllTableZone(branchId);
            setState(state => ({ ...state, tableZone: fetch.tableZone }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
};