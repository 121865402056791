import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomBtn from "components/fragments/CustomBtn";
import OTPInput from "react-otp-input";
import CustomInput from "components/fragments/CustomInput";
import { useRecoilValue } from "recoil";
import { authState } from "state/auth";

const pinCount = 4;

export default function ModalPin({
  modalSuccessOpenObj,
  modalOpenObj,
  onPinSubmitHandler,
  ...props
}) {
  const [pin, setPin] = useState("");
  const [isError, setIsError] = useState();
  const { user } = useRecoilValue(authState);

  useEffect(() => {
    setIsError(undefined);
  }, [pin]);

  const handleSubmitPin = async () => {
    // Check pin valid
    // TODO: Implement handler on API for better security
    if (pin.length !== 4 || pin != user.employeePin) {
      setIsError("true");
      return;
    }

    setIsError(undefined);
    setPin("");
    modalOpenObj.setModalOpen(false);
    // modalSuccessOpenObj.setModalOpen(true);
    await onPinSubmitHandler();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => modalOpenObj.setModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          <div className="bg-white w-[min(38.67vw,396px)] p-[min(2.44vw,25px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-18 text-[var(--color-black-2)] text-center leading-[1.8] font-[700]">
            <div className="leading-[2.2]">กรุณาใส่รหัสยืนยัน</div>
            <div className={"flex justify-center mt-[min(1.46vw,15px)]"}>
              <OTPInput
                value={pin}
                onChange={setPin}
                numInputs={pinCount}
                inputStyle={null}
                renderSeparator={<div className="w-[min(1.95vw,20px)]"></div>}
                renderInput={(props, index) => {
                  const { style, ...rest } = props;
                  return (
                    <CustomInput
                      className="w-[min(5.96vw,61px)] aspect-square"
                      inputprops={{
                        ...rest,
                        className:
                          "rounded-[min(0.78vw,8px)] border-[min(0.29vw,3px)] border-[#b6bbc6] font-[400] text-24 text-center no-spinner",
                        type: "number",
                        error: isError,
                      }}
                    />
                  );
                }}
              />
            </div>

            <div className="flex justify-center gap-[min(2.01vw,20.63px)] mt-[min(2.44vw,25px)]">
              <CustomBtn
                onClick={() => {
                  modalOpenObj.setModalOpen(false);
                }}
                className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-[#6D788D] font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-second-2-active`}
                btntxt={"ยกเลิก"}
              />
              <CustomBtn
                onClick={handleSubmitPin}
                className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-white font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-main-active`}
                btntxt={"ยืนยัน"}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
