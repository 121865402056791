import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import { numberNoDecimals } from "utils/numberFormat";
import moment from "moment";

export default function SumLineGraph({ lineData }) {
  const { width, height } = useWindowDimensions();
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        reverse: true,
        grid: {
          display: false,
        },
        ticks: {
          color: "#464255",
          font: {
            size: Math.min(9, width * 0.009),
            weight: 400,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#464255",
          callback: function (value, index, ticks) {
            return value >= 100000
              ? value / 1000 + "k"
              : numberNoDecimals(value);
          },
          font: {
            size: Math.min(9, width * 0.009),
            weight: 400,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        titleAlign: 'center',
        callbacks: {
          title: function (context) {
            return `${context[0].formattedValue} ออเดอร์`;
          },
          label: function (context) {
            return `${rawData[context.dataIndex].date}`;
          },
        },
      },
    },
  };

  // test
  const getPastWeekDates = () => {
    const dates = [];
    const today = new Date();

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(today);
      currentDate.setDate(today.getDate() - i);

      const weekDay = new Intl.DateTimeFormat("th", { weekday: "long" }).format(
        currentDate
      );
      const date = moment(currentDate).format("DD/MM/YYYY");
      const value = parseInt(Math.random() * 1000);

      dates.push({ weekDay, date, value });
    }

    return dates;
  };

  const [rawData, setRawData] = useState(getPastWeekDates());

  const labels = rawData.map((v) => v.weekDay);

  const data = {
    labels,
    datasets: [
      {
        label: "Weekly Sales",
        data: rawData.map((v) => v.value),
        lineTension: 0.1,
        borderRadius: 9999,
        borderColor: "#2dc76d",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "#2DC76D");
          gradient.addColorStop(1, "#FFFFFF");
          return gradient;
        },
        pointBackgroundColor: "#2DC76D",
        pointBorderColor: "#FFF",
        pointRadius: 8,
        pointBorderWidth: 3,
        pointHoverRadius: 8,
        fill: true,
      },
    ],
  };

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if(lineData && lineData.length > 0) {
      const parsedLabels = lineData.map((d,i) => d.target_date);
      const parsedData = {
        labels: parsedLabels,
        datasets: [
          {
            label: "จำนวนบิลขายรายวัน",
            data: lineData.map((v) => v.total),
            lineTension: 0.1,
            borderRadius: 9999,
            borderColor: "#2dc76d",
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, "#2DC76D");
              gradient.addColorStop(1, "#FFFFFF");
              return gradient;
            },
            pointBackgroundColor: "#2DC76D",
            pointBorderColor: "#FFF",
            pointRadius: 8,
            pointBorderWidth: 3,
            pointHoverRadius: 8,
            fill: true,
          },
        ],
      };
      setChartData(parsedData)
    }
  }, [lineData])
  
  return (
    <div className="w-full h-full">
      { chartData &&
        <Line options={options} data={chartData} />
      }
    </div>
  );
}
