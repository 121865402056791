import ReactPaginate from 'react-paginate';
import React, { useLayoutEffect, useState, useEffect } from "react";
import DefaultProductImg from 'img/product/default.png';
import SwipeableViews from 'react-swipeable-views';

export default function ProductPaginate({ items, pageItemOffset, categoryID, ...props }) {

    const [itemPerpage, setItemPerpage] = useState(30);
    const getItemsperpage = () => {
        let el = document.getElementById('productContent');
        let el2 = document.getElementById('content');
        if (el) {
            const bound = el.getBoundingClientRect();
            const bound2 = el2.getBoundingClientRect();
            var item = Math.floor(bound.width / 154) * Math.floor( (bound2.height - 300 ) / 179);
            if(item < 1){
                item = 1
            }
            setItemPerpage(item);
        }
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', getItemsperpage);
        getItemsperpage();
        return () => window.removeEventListener('resize', getItemsperpage);
    }, []);

    useEffect(() => {
        getItemsperpage()
    }, [itemPerpage]);

    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const endOffset = itemOffset + itemPerpage;
    // const currentItems = items.slice(itemOffset, endOffset);
    // const [currentItems, setCurrentItems] = useState(items.slice(itemOffset, endOffset));
    const [currentItems, setCurrentItems] = useState([]);
    const pageCount = Math.ceil(items.length / itemPerpage);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemPerpage) % items.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected + 1)
    };

    useEffect(() => {
        if (items && items.length > 0) {
            setCurrentItems(items.slice(itemOffset, endOffset))
        } else {
            setCurrentItems([])
        }
    }, [items]);

    useEffect(() => {
        if (items && items.length > 0) {
            var theEndOffset = itemOffset + itemPerpage;
            setCurrentItems(items.slice(itemOffset, theEndOffset))
        } else {
            setCurrentItems([])
        }
    }, [itemOffset]);

    useEffect(() => {
        if (categoryID !== undefined) {
            setItemOffset(0)
            setCurrentPage(1)
        }
    }, [categoryID])

    const handleMenuSlide = async (page) => {
        const newOffset = (page * itemPerpage) % items.length;
        setItemOffset(newOffset);
        setCurrentPage(page + 1)
    }

    return (
        <div>
            <div className="mb-auto" id="productContent">
                <SwipeableViews
                    index={currentPage - 1}
                    onChangeIndex={handleMenuSlide}
                    enableMouseEvents
                >
                    {[...Array(pageCount).keys()].map(pi =>
                        <div key={`food-card-${pi}`} className="row gx-2 overflow-y-hidden overflow-x-hidden" style={{ width: "100%", height: ' calc(99vh - 210px)' }}>
                            {items && items.slice(pi * itemPerpage, (pi * itemPerpage) + itemPerpage).map((item, index) => (
                                <div className="col-auto" key={item.productID} >
                                    <button type="button"
                                        className="btn btn-link p-0 text-decoration-none " onClick={() => props.handleoptionShow(item)} >
                                        <div className="card card-food">
                                            <img
                                                src={item.productImage}
                                                className="food-img"
                                                // width={"150px"}
                                                // height={"110px"}
                                                // onError={() => onProductImageInvalidHandler(index)}
                                                // onError={(e) => e.target.src = "img/product/default.png"}
                                                onError={(e) => e.target.src = DefaultProductImg}
                                            />
                                            <div className="card-body ">
                                                <div className="card-name ">{item.productName}</div>
                                                <div className="text-center tc-4 fs-14">
                                                    ฿{item.productPrice.toLocaleString()}.-
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </SwipeableViews>

            </div>

            {
                items && items.length > 0 ?
                    <div className="d-flex justify-content-center position-relative mt-2">
                        <div className="paginationjs-nav J-paginationjs-nav">ทั้งหมด  {items.length} รายการ</div>
                        <div className="position-relative">
                            <div className="position-absolute fs-12 text-center w-100 pt-1">หน้า ที่  {currentPage} / {pageCount}</div>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=""
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={1}
                                pageCount={pageCount}
                                previousLabel=""
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                                forcePage={currentPage - 1}
                            />
                        </div>
                    </div>
                    : null
            }

        </div>
    )

}

