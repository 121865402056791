import axiosInstance from './axios';

const endPoint = "/pos";

export const getAllPOS = async (branchId) => {
    try {
        const queryParams = {
            branch_id: branchId
        };

        const response = await axiosInstance.get('/pos', { params: queryParams });

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            pos: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getPOS = async (id) => {
    try {
        const response = await axiosInstance.get(`/pos/${id}`);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            pos: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getPOSActivity = async (id) => {
    try {
        const response = await axiosInstance.get(`/pos-activities/${id}`);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            pos: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const updatePOS = async (pos, statusId, isActive) => {
    try {
        const id = pos.id ? pos.id : pos.posId ? pos.posId : null;
        const payload = {
            ...pos,
            status_id: statusId,
            is_active: isActive
        }
        const response = await axiosInstance.patch(`/pos/${id}`, payload);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            pos: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}