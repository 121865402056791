
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";

export default function  CategorySlide({categoryObj,categorys,handleClick,...props}) {

    return (
        <>
          <div className="mb-2 pb-1 ">
            <Splide
              hasTrack={false}
              options={{
                autoWidth: true,
                pagination: false,
              }}
            >
              <div className="custom-wrapper ">
                <SplideTrack>
                  {categorys.map((item, index) =>
                      <SplideSlide key={index}>
                        <button
                          className={  item.category_id == categoryObj.categoryID ?  "btn btn-category category btn-c1 active"  : "btn btn-category category btn-c1" }
                          onClick={() => {
                            handleClick(item.category_id);
                          }}
                        >
                          {item.category_name}
                        </button>
                      </SplideSlide>
                  )}
                </SplideTrack>
                <div className="splide__arrows">
                  <button className="splide__arrow btn-scroll-prev splide__arrow--prev"></button>
                  <button className="splide__arrow btn-scroll-next splide__arrow--next"></button>
                </div>
              </div>
            </Splide>
          </div>
        </>
      )

}

