import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomBtn from "components/fragments/CustomBtn";
import { useNavigate } from "react-router-dom";
import WarningAlert from "./WarningAlert";
import { useRecoilValue } from "recoil";
import { appState } from "state/app";

export default function ModalPrintError({
  selectedUserIdObj,
  modalOpenObj,
  ...props
}) {
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const { slug, slugBranch } = useRecoilValue(appState);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => modalOpenObj.setModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          <div className="bg-white w-[min(37.11vw,380px)] p-[min(2.44vw,25px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-18 text-[var(--color-black-2)] text-center leading-[1.8] font-[500]">
            <div className="flex flex-col items-start">
              <div className="font-[700] leading-[2.2] text-[var(--color-red)]">
                เครื่องพิมพ์มีปัญหา
              </div>
              <div className="text-16 text-[var(--color-red)] mt-[min(0.98vw,10px)]">
                กระดาษหมดกรุณาตรวจสอบเครื่องพิมพ์ของคุณ !
              </div>
              <div>ต้องการพิมพ์ใหม่อีกครั้ง ?</div>
              <div className="w-full h-[min(0.10vw,1px)] bg-[#EDEDED] mt-[min(1.46vw,15px)]"></div>
            </div>
            <div className="flex justify-center gap-[min(2.01vw,20.63px)] mt-[min(2.44vw,25px)]">
              <CustomBtn
                onClick={() => {
                  setAlertOpen(true);
                }}
                className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-[#FFF] font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-main-active`}
                btntxt={"พิมพ์"}
              />
              <CustomBtn
                onClick={() => {
                  navigate(`/${slug}/${slugBranch}/`);
                }}
                className={`leading-[0.8] w-fit min-w-[min(10.25vw,105px)] text-18 text-white font-[600] min-h-[min(3.91vw,40px)] rounded-[min(0.78vw,8px)] btn-error-active`}
                btntxt={"ปิด"}
              />
            </div>
          </div>
        </Fade>
      </Modal>
      <WarningAlert
        time={5}
        alertOpenObj={{
          alertOpen: alertOpen,
          setAlertOpen: setAlertOpen,
        }}
      />
    </div>
  );
}
