import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

export default function ModalSuccess({
  selectedUserIdObj,
  modalOpenObj,
  successText,
  discount,
  ...props
}) {

  const [sumDiscount, setSumDiscount] = useState(0);

  useEffect(() => {
    if(discount && discount.length > 0) {
      var total = 0;
      for (const dc of discount) {
        if(dc.discounts && dc.discounts.length > 0) {
          for (const d of dc.discounts) {
            total += d.amount;
          }
        }
      }
      setSumDiscount(total);
    }
  }, [discount]);
  
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => modalOpenObj.setModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          {/* <div className="bg-white w-fit p-[min(2.44vw,25px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-24 text-[var(--color-black-2)] leading-[1.8]"> */}
          <div className="bg-white w-[480px] p-[min(2.44vw,25px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] leading-[1.8]">
            <p className="text-24 font-[500]">รายละเอียดโปรโมชั่นที่ได้รับ</p>
            {/* <div className="flex flex-col items-center ">
              <img className="w-[min(8.11vw,83px)] aspect-square object-contain rounded-full" src="/img/icons/icon-success.png" alt="" />
              <div className="leading-[1.6] font-[500] text-center text-[#5A5A5A] mt-[min(2.44vw,25px)]">
                { successText ? successText : modalOpenObj?.modalTextSuccess ? modalOpenObj?.modalTextSuccess : "เปลี่ยนพนักงานสำเร็จ" }
              </div>
            </div> */}
            {
              discount && discount.length > 0 ?
                discount.map((dc) => 
                  <div className="mt-2">
                    <div className="flex justify-between text-18 font-[500]">
                      <p>{dc.type}</p>
                      <p>มูลค่าส่วนลด</p>
                    </div>
                    {
                      dc.discounts?.length > 0 ? 
                        dc.discounts.map(d => 
                          <div className="flex justify-between mt-2">
                          <div>
                            <p className="bg-[#FFD8DA] text-[#F95057] rounded-2m me-5">{d.campaign}</p>
                            {
                              d.desc ? 
                              <p>- {d.desc}</p>
                              : null
                            }
                            {
                              d.option?.length > 0 ? 
                              d.option.map(op => 
                                <p>- {op}</p>
                                )
                              : null
                            }
                          </div>
                          <p className="text-[#F95057]">-{d.amount.toFixed(2)}</p>
                        </div>
                        )
                      : null
                    }
                  </div>
                )
              :
              null
            }
            <div className="flex justify-between font-[500] text-20 text-center my-4">
              <p></p>
              <p className="text-center">มูลค่ารวม</p>
              <p className="text-[#F95057]">- {sumDiscount.toFixed(2)}</p>
            </div>
            <div className="text-center mt-4">
              <button
                className="btn bg-green-500 text-white font-[500] w-48"
                onClick={() => modalOpenObj.setModalOpen(false)}
              >
                ปิด
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
