import React from "react";

export default function ReceiptPrintPage({
  $isCopy = false,
  $isInvoice = false,
}) {
  return (
    <div className="bg-[#EFEFEF] pt-[4.00vw] pb-[12.79vw]">
      <div className="flex justify-center text-[0.78vw] w-full leading-[1.8]">
        <div
          id={"section-to-print-receipt-page"}
          className="w-[29.49vw] bg-[#FFF] px-[1.70vw] py-[1.31vw] flex flex-col gap-[0.59vw] text-[0.98vw] font-[300]"
        >
          <div className="text-center w-full flex flex-col items-center font-[300]">
            <img
              className="object-cover aspect-square w-[7.71vw] h-fit logo-receipt"
              src="/img/icons/icon-ex-cust-logo.png"
              alt=""
            />
            {!$isInvoice && (
              <>
                <div>โรงคั่วกาแฟพะเยา</div>
                <div className="flex justify-center gap-[1.07vw] w-[90%]">
                  <div className="text-left  w-full">
                    <div className="flex justify-between">
                      บริษัท อินเตอร์ คอฟฟี่เทรด จำกัด
                      {!$isCopy && (
                        <span className="w-[40%]">สำนักงานใหญ่ (00000)</span>
                      )}
                    </div>
                    <div className="flex justify-between">
                      TAX ID : 123456789101
                      {$isCopy && (
                        <span className="text-small text-[0.78vw] font-[500] w-[40%]">
                          (สำเนาครั้งที่ 1)
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="font-[500] mt-[0.51vw]">
                  ใบกำกับภาษีอย่างย่อ / ใบเสร็จรับเงิน
                </div>
              </>
            )}
          </div>

          {!$isInvoice && (
            <>
              <div className="flex flex-col gap-[0.39vw]">
                <div className="flex justify-between gap-[0.98vw] ">
                  <div className="whitespace-nowrap">
                    วันที่ : 28/04/2022 19:36
                  </div>
                  <div className="text-right">POS# : PO145740</div>
                </div>
                <div className="flex justify-between gap-[0.98vw] ">
                  <div className="whitespace-nowrap">เลขที่ : BI2310112540</div>
                  <div className="text-right">พนักงานขาย : โต</div>
                </div>
                <div className="flex justify-between gap-[0.98vw] ">
                  <div className="whitespace-nowrap">RD#EXXXXXXXXXXXX</div>
                  <div className="text-right">โต๊ะ : VIP / A3</div>
                </div>
              </div>
            </>
          )}

          {$isInvoice && (
            <>
              <div className="text-center font-[600]">ใบแจ้งหนี้</div>
              <div className="flex flex-col gap-[0.39vw]">
                <div className="flex justify-between gap-[0.98vw] ">
                  <div className="whitespace-nowrap">
                    วันที่ : 28/04/2022 19:36
                  </div>
                  <div className="text-right">POS# : PO145740</div>
                </div>
                <div className="flex justify-between gap-[0.98vw] ">
                  <div className="text-right">พนักงานขาย : โต</div>
                  <div className="text-right">โต๊ะ : VIP / A3</div>
                </div>
              </div>
            </>
          )}

          <div className="flex flex-col gap-[0.39vw]">
            {/* product list */}
            <table className="mt-[0.78vw]">
              {/* header */}
              <thead>
                <tr className="border-dashed border-t-[0.04vw] border-b-[0.04vw] border-black h-0 mt-[0.78vw]">
                  <th className="w-[3.13vw] th-col-sm text-center py-[0.39vw] font-[400]">
                    จำนวน
                  </th>
                  <th className="text-center py-[0.39vw] font-[400]">รายการ</th>
                  <th className="w-[5.47vw] th-col-md  py-[0.39vw] text-right font-[400]">
                    หน่วยละ
                  </th>
                  <th className="w-[5.47vw] th-col-md  py-[0.39vw] text-right font-[400]">
                    รวมเงิน
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colSpan="2"
                    className="pt-[0.78vw] pb-[0.29vw] font-[500]"
                  >
                    จำนวนสินค้า : 9
                  </td>
                </tr>
                <tr>
                  <td className="pt-[0.29vw] font-[300] text-center align-top">
                    1
                  </td>
                  <td className="pt-[0.29vw] font-[300] pl-[1.27vw] pl-sp-13">
                    <div>แซลม่อนสเต๊ก</div>
                    <div className="pl-[0.78vw]">
                      <div className="text-small text-[0.78vw]">-บราว์ซอส</div>
                      <div className="text-small text-[0.78vw]">
                        -ขนาด (ใหญ่)
                      </div>
                      <div className="text-small text-[0.78vw]">
                        -เพิ่มหอมทอด (ใหญ่)
                      </div>
                    </div>
                  </td>
                  <td className="pt-[0.29vw] font-[300] text-right align-top">
                    <div>500.00</div>
                  </td>
                  <td className="pt-[0.29vw] font-[300] text-right align-top">
                    <div>500.00</div>
                    <div className="pl-[0.78vw]">
                      <div className="text-small text-[0.78vw] opacity-0">
                        null
                      </div>
                      <div className="text-small text-[0.78vw]">+10.00</div>
                      <div className="text-small text-[0.78vw]">+10.00</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="pt-[0.29vw] font-[300] text-center align-top">
                    2
                  </td>
                  <td className="pt-[0.29vw] font-[300] pl-[1.27vw] pl-sp-13">
                    <div>ต้มยำปลาดุก</div>
                  </td>
                  <td className="pt-[0.29vw] font-[300] text-right align-top">
                    <div>240.00</div>
                  </td>
                  <td className="pt-[0.29vw] font-[300] text-right align-top">
                    <div>480.00</div>
                  </td>
                </tr>
                <tr>
                  <td className="pt-[0.29vw] font-[300] text-center align-top">
                    2
                  </td>
                  <td className="pt-[0.29vw] font-[300] pl-[1.27vw] pl-sp-13">
                    <div>ปลาช่อนลุยสวน</div>
                  </td>
                  <td className="pt-[0.29vw] font-[300] text-right align-top">
                    <div>160.00</div>
                  </td>
                  <td className="pt-[0.29vw] font-[300] text-right align-top">
                    <div>320.00</div>
                  </td>
                </tr>
                <tr>
                  <td className="pt-[0.29vw] font-[300] text-center align-top">
                    4
                  </td>
                  <td className="pt-[0.29vw] font-[300] pl-[1.27vw] pl-sp-13">
                    <div>โค๊ก</div>
                  </td>
                  <td className="pt-[0.29vw] font-[300] text-right align-top">
                    <div>30.00</div>
                  </td>
                  <td className="pt-[0.29vw] font-[300] text-right align-top">
                    <div>120.00</div>
                  </td>
                </tr>
                <tr>
                  <td className="pt-[0.29vw] font-[300] text-center align-top">
                    1
                  </td>
                  <td className="pt-[0.29vw] font-[300] pl-[1.27vw] pl-sp-13">
                    <div>ข้าวสวย</div>
                  </td>
                  <td className="pt-[0.29vw] font-[300] text-right align-top">
                    <div>45.00</div>
                  </td>
                  <td className="pt-[0.29vw] font-[300] text-right align-top">
                    <div>45.00</div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className="py-[1.66vw] font-[500]">
                    โปรโมชั่นที่ได้รับ
                  </td>
                </tr>
                <tr>
                  <td className="pt-[0.29vw] font-[300] text-center align-top">
                    2
                  </td>
                  <td className="pt-[0.29vw] font-[300] pl-[1.27vw] pl-sp-13">
                    <div>ซื้อ 1 แถม 1 โค๊ก</div>
                  </td>
                  <td className="pt-[0.29vw] font-[300] text-right align-top">
                    <div>-30.00</div>
                  </td>
                  <td className="pt-[0.29vw] font-[300] text-right align-top">
                    <div>-60.00</div>
                  </td>
                </tr>
              </tbody>
              {/* body */}
            </table>
            {/* line */}
            <div className="border-dashed border-t-[0.04vw] border-black h-0 my-[1.28vw] my-sp-13"></div>
            <div className="flex justify-between w-full">
              <div className="whitespace-nowrap">ราคารวม</div>
              <div className="text-left">1,425.00</div>
            </div>
            <div className="flex justify-between w-full">
              <div className="whitespace-nowrap">ส่วนลด ( 10 % )</div>
              <div className="text-left">-142.50</div>
            </div>
            <div className="flex justify-between w-full">
              <div className="whitespace-nowrap">ส่วนลดคะแนน</div>
              <div className="text-left">0.00</div>
            </div>
            <div className="flex justify-between w-full">
              <div className="whitespace-nowrap">ค่าบริการ ( 10 % )</div>
              <div className="text-left">128.25</div>
            </div>
            <div className="flex justify-between w-full text-large font-[700] text-[1.37vw]">
              <div className="whitespace-nowrap">ราคาสุทธิ</div>
              <div className="text-left">1,540.00</div>
            </div>
            <div className="flex justify-between w-full">
              <div className="whitespace-nowrap">ค่าบริการ ( 10 % )</div>
              <div className="text-left">128.25</div>
            </div>
            <div className="flex justify-between w-full">
              <div className="whitespace-nowrap">ภาษีมูลค่าเพิ่ม 7 %</div>
              <div className="text-left">100.75</div>
            </div>
            {/* line */}
            <div className="border-dashed border-t-[0.04vw] border-black h-0 my-[1.28vw] my-sp-13"></div>
            <div className="text-center">( VAT Included ) </div>
            {!$isInvoice && (
              <>
                <div className="flex justify-between gap-[0.98vw] ">
                  <div className="whitespace-nowrap">เงินสด</div>
                  <div className="text-right">2,000.00</div>
                </div>
                <div className="flex justify-between gap-[0.98vw] ">
                  <div className="whitespace-nowrap">เงินเทอน</div>
                  <div className="text-right">460.000</div>
                </div>
                {/* line star */}
                <div className="line-star mt-[1.28vw] mb-[0.59vw]"></div>
                {/* member */}
                <div className="text-center">
                  Member : MB2302202015468721575
                </div>
                <div className="flex justify-between gap-[0.98vw] mt-[1.66vw]">
                  <div className="whitespace-nowrap">
                    คุณ : บุญเสริม โชคดีมีชัย
                  </div>
                  <div className="text-right">เบอร์ : 088-882-XXXX</div>
                </div>
                <table>
                  <thead>
                    <tr className="mt-[0.78vw]">
                      <th className="w-[7.23vw] th-col-lg text-center py-[0.39vw] font-[400]"></th>
                      <th className="text-right py-[0.39vw] font-[400]">
                        ได้รับ
                      </th>
                      <th className="py-[0.39vw] text-right font-[400]">
                        ใช้ไป
                      </th>
                      <th className="py-[0.39vw] text-right font-[400]">
                        คงเหลือ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>คะแนน</td>
                      <td className="text-right">+154</td>
                      <td className="text-right">0</td>
                      <td className="text-right">3650</td>
                    </tr>
                  </tbody>
                </table>
                {/* line star */}
                <div className="line-star mt-[1.28vw] mb-[0.59vw]"></div>
              </>
            )}

            <div className="text-center">THANK YOU</div>
            <div className="text-center">ขอบคุณที่ใช้บริการ</div>
          </div>
        </div>
      </div>
    </div>
  );
}
