import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomBtn from "components/fragments/CustomBtn";

export default function ModalPosDubStartConfirm({ modalOpenObj, onOkayBtn, onCancelBtn, ...props }) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => modalOpenObj.setModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          <div className="bg-white w-[min(37.11vw,380px)] p-[min(2.44vw,25px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-22 text-[var(--color-black-2)] text-center leading-[1.8] font-[700]">
            <div>พบการใช้งานอุปกรณ์อื่น</div>
            <div>คุณยืนยันที่จะเข้าสู่ระบบหรือไม่</div>
            <div className="leading-[2.2] font-[400] text-18 text-[#2DC76D]">ระบบจะทำการออกจากอุปกรณ์อื่นอัตโนมัติ</div>
            <div className="flex justify-center gap-[min(2.44vw,25px)] mt-[min(0.98vw,10px)]">
              <CustomBtn
                onClick={() => {
                  onCancelBtn()
                  modalOpenObj.setModalOpen(false);
                }}
                className={`leading-[0.8] w-full text-[#6D788D] font-[600] min-h-[min(5.57vw,57px)] rounded-[min(0.78vw,8px)] btn-second-2-active text-18`}
                btntxt={"ยกเลิก"}
              />
              <CustomBtn
                onClick={() => {
                  onOkayBtn();
                  modalOpenObj.setModalOpen(false);
                }}
                className={`leading-[0.8] w-full text-white font-[600] min-h-[min(5.57vw,57px)] rounded-[min(0.78vw,8px)] btn-main-active text-18`}
                btntxt={"ตกลง"}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
