import React, { useEffect, useState } from "react";

export default function SelectStaffCard({
  selected = false,
  userInfo,
  ...props
}) {
  const [theme, setTheme] = useState({
    color: "bg-[#FAFAFA]",
  });
  useEffect(() => {
    if (selected) {
      return setTheme({ ...theme, color: "bg-[#F4FCF8]" });
    }
    setTheme({ ...theme, color: "bg-[#FAFAFA]" });
  }, [selected]);

  return (
    <div
      {...props}
      className={`w-full rounded-[min(1.46vw,15px)] py-[min(0.78vw,8px)] pl-[min(1.56vw,16px)] pr-[min(0.39vw,4px)] flex items-center justify-between ${theme.color} cursor-pointer ${props.className ?? ''}`}
    >
      <div className="flex gap-[min(1.46vw,15px)]">
        <img
          className="aspect-square w-[min(3.91vw,40px)] rounded-full object-contain"
          src="/img/avartar-male.png"
          alt=""
        />
        <div className="text-[#4c4e64] opacity-87 leading-[1.4] text-14 font-[400] tracking-[0.015em]">
          <div>{userInfo.fullName}</div>
          <div className="opacity-60">{userInfo.code}</div>
        </div>
      </div>
      {selected && (
        <img
          className="aspect-square w-[min(2.93vw,30px)] object-contain"
          src="/img/icons/icon-correct-circle.svg"
          alt=""
        />
      )}
    </div>
  );
}
