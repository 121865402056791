export const getCustomerTypePrefixAndSuffix = (customer_type) => {
    const customerTypePrefixAndSuffix = {
      prefix: '',
      suffix: ''
    }
    switch (customer_type) {
      case "ร้านค้า":
        customerTypePrefixAndSuffix.prefix = "ร้านค้า";
        customerTypePrefixAndSuffix.suffix = "";
        break;
      case "บริษัทจำกัด":
        customerTypePrefixAndSuffix.prefix = "บริษัท";
        customerTypePrefixAndSuffix.suffix = "จำกัด";
        break;
      case "ห้างหุ้นส่วนจำกัด":
        customerTypePrefixAndSuffix.prefix = "ห้างหุ้นส่วน";
        customerTypePrefixAndSuffix.suffix = "จำกัด";
        break;
      case "บริษัทมหาชนจำกัด":
        customerTypePrefixAndSuffix.prefix = "บริษัทมหาชน";
        customerTypePrefixAndSuffix.suffix = "จำกัด";
        break;
      default:
        customerTypePrefixAndSuffix.prefix = "อื่นๆ";
        customerTypePrefixAndSuffix.suffix = "";
        break;
    }
    
    return customerTypePrefixAndSuffix;
  }

// TODO: Implement helper function data parsing
export const parsedOrdersToBills = (orders) => {
  return orders.map((order) => ({
    billID: order.id,
    receiptNo: "OT202028040007",
    pos: "POS1",
    billStatus: 1,
    staff: "ชลกฤษ ฟองสายชล",
    customerID: 1,
    customerName: "บุญเสริม โชคดี",
    orderNo: order.order_id,
    orderDate: "19/09/2023 ",
    tableID: 1,
    tableName: "VIP - A01",
    zone: "",
    delivery: 1,
  }));
};