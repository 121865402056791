import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomBtn from "components/fragments/CustomBtn";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

export default function ModalCancleBill({
    modalOpenObj,
    modalPinOpenObj,
    billcancleObj,
    ...props
    }) {
    
    const [reason, setReason] = useState(null);
    const optionsReason = [
        { value: 'มีสิ่งปนเปื้อนในอาหาร', label: "มีสิ่งปนเปื้อนในอาหาร" },
        { value: "รออาหารนานเกินไป", label: "รออาหารนานเกินไป" },
        { value: "พนักงานรับออเดอร์ผิด", label: "พนักงานรับออเดอร์ผิด" },
        { value: "ลูกค้าขอยกเลิก", label: "ลูกค้าขอยกเลิก" },
        { value: "อื่นๆ" , label: "อื่นๆ" },
    ];

    return (
        <div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={modalOpenObj.modalOpen}
            onClose={() => modalOpenObj.setModalOpen(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={modalOpenObj.modalOpen}>
            <div className="bg-white focus-visible-none w-[min(37.11vw,380px)] px-4 py-3 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-16 text-[var(--color-black-2)]">
                
                <div className="mb-4 text-22 text-หะฟพะ font-[700] leading-[2.2]">
                    ยกเลิกบิล
                </div>

                <div className="">
                  <div className="mb-4 select-3" >
                      <Select
                        className="w-100 select-arrow"
                        options={optionsReason}
                        placeholder="เลือกเหตุผล"
                        onChange={(e) => {

                          billcancleObj.setBillcancledata( (prev) => ({  ...prev,reason:e.value}) ) 
                        }}
                        value={
                            optionsReason.filter(
                              item => item.value === billcancleObj.billcancledata.reason
                            )
                        }
                      />
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <div className="me-auto fs-14 tc-15">หมายเหตุ</div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-link p-0 text-danger btn-clear fs-14"
                        onClick={(e) => { billcancleObj.setBillcancledata( (prev) => ({  ...prev,note:''}) )  } }
                      >
                        ล้าง
                      </button>
                    </div>
                  </div>

                  <textarea
                    className="form-control fs-14"
                    name="foodNote"
                    rows="4"
                    onChange={(e) => { billcancleObj.setBillcancledata( (prev) => ({  ...prev,note:e.target.value}) )  } }
                    required
                    value={billcancleObj.billcancledata.note}
                  ></textarea>
                </div>


                <div className=" mt-4 pt-3 mb-3 flex justify-center gap-[min(2.01vw,20.63px)]">
                <CustomBtn
                    onClick={() => {
                    modalOpenObj.setModalOpen(false);
                    }}
                    className={` leading-[0.8] w-fit  text-18 text-[#6D788D] font-[600] px-4 py-3 rounded-[min(0.78vw,8px)] btn-second-2-active shadow-none`}
                    btntxt={"ยกเลิก"}
                />
                <CustomBtn
                    onClick={() => {
                        modalPinOpenObj.setModalOpen(true);
                        modalOpenObj.setModalOpen(false);
                    }}
                    className={` leading-[0.8] w-fit  text-18 text-white font-[600] px-4 py-3 rounded-[min(0.78vw,8px)] btn-main-active shadow-none`}
                    btntxt={"ยืนยัน"}
                />
                </div>
            </div>
            </Fade>
        </Modal>
        </div>

    )
}