import React, { useEffect, useState } from "react";
import { numberWithTwoDecimals } from "utils/numberFormat";

const HIDE_VALUE = "xxxxxxx";
export default function SaleSumCard({
  props,
  info,
  isActive,
  isShowValue = false,
}) {
  const [theme, setTheme] = useState({
    bgColor: "bg-[#FFF]",
    valueColor: "",
    infoColor: "text-[rgba(0,0,0,0.5)]",
  });
  useEffect(() => {
    if (isActive) {
      setTheme({
        ...theme,
        bgColor: "bg-[var(--color-green)]",
        valueColor: "text-[#FFF]",
        infoColor: "text-[#FFF]",
      });
    }
  }, [isActive]);

  return (
    <div
      className={`bg-[#FFF] w-[min(11.43vw,117.05px)] px-[min(0.91vw,9.33px)] pt-[min(1.01vw,10.33px)] pb-[min(1.50vw,15.34px)] shadow-1-8 text-center rounded-[min(0.67vw,6.83px)] ${theme.bgColor}`}
    >
      <div className="relative">
        <div
          className={`absolute top-0 left-0 h-0 ${
            isActive ? "filter-white" : ""
          }`}
        >
          {info.icon}
        </div>

        <div className="flex flex-col gap-[min(0.80vw,8.15px)] pt-[min(3.97vw,40.67px)]">
          <div className={`${theme.valueColor} text-20`}>
            {isShowValue ? numberWithTwoDecimals(info.value) : HIDE_VALUE}
          </div>
          <div className={`${theme.infoColor} text-14 leading-[1.4]`}>
            {info.label}
          </div>
        </div>
      </div>
    </div>
  );
}
