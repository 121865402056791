import axiosInstance from './axios';

const endPoint = "/bill";

export const getAllBill = async () => {
    try {
        const response = await axiosInstance.get('/bills');

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            bill: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getBill = async (id) => {
    try {
        const response = await axiosInstance.get(`/bills/${id}`);
        
        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            bill: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const createBill = async (order, posId, selectedTable) => {
    // console.log("🚀 ~ file: bill.js:46 ~ createBill ~ order:", order)
    // {
    //     "id": 31,
    //     "order_id": "OR231100000031P1",
    //     "batch": 1,
    //     "table_activity_id": 1,
    //     "type_id": 1,
    //     "status_id": 1,
    //     "start_time": "2023-12-18T19:14:55.028Z",
    //     "end_time": null,
    //     "is_combine": false,
    //     "operation_date": "2023-12-18T19:14:55.028Z"
    // }
    const tableActivityId = order.table_activity_id ? order.table_activity_id : selectedTable ? selectedTable.activity.id : 1; // For beta test

    try {
        const payload = {
            tableActivityId: tableActivityId,
            orderId: order.orderNo ? order.orderNo : order.order_id ? order.order_id : null,
            posId: posId,
            employeeBranchAccountId: 1,
            paymentChanelId: 1, // Default of cash
            paymentStatusId: 1, // Default of init bill
            total: order?.sumPrice ? order.sumPrice : order?.orderPrice?.sumprice ? order.orderPrice.sumprice : 0,
            discount: 0,
            tax: 0,
            net: order.sumPrice ? order.sumPrice : order?.orderPrice?.net ? order.orderPrice.net : 0, 
            paidAmount: 0
        }
        // console.log("🚀 ~ file: bill.js:73 ~ createBill ~ payload:", payload)

        const response = await axiosInstance.post(`/bills/`, payload);
        
        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            bill: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const updateBill = async (id, tableActivityId, paidAmount, paymentStatusId, paidPosId, billDiscount) => {
    try {
        const payload = {
            tableActivityId: tableActivityId,
            paidAmount: paidAmount,
            paymentStatusId: paymentStatusId,
            paidPosId: paidPosId,
            discount: Number(billDiscount.value) ?? 0,
            // discount_id: billDiscount.id ?? 0, // TODO: support for backend DB 
            discount_percent: (billDiscount.discountTypeId == 2 || billDiscount.isPercent) ? Number(billDiscount.amount) : 0,
            discount_round: Number(billDiscount.valueRound) ?? 0,
            is_discount_point: billDiscount.isUsePoint ?? false,
            point_discount_usage: Number(billDiscount.pointUsage) ?? 0,
            discount_from_point: billDiscount.discountFromPoint ?? 0,
        }
        // console.log("🚀 ~ file: bill.js:102 ~ updateBill ~ payload:", payload)
        const response = await axiosInstance.patch(`/bills/${id}`, payload);
        
        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            bill: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}