import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomBtn from "components/fragments/CustomBtn";
import { useNavigate } from "react-router-dom";

export default function ModalTableEventMode({
  info,
  selectedUserIdObj,
  modalOpenObj,
  onConfirm,
  ...props
}) {

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpenObj.modalOpen}
        onClose={() => modalOpenObj.setModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpenObj.modalOpen}>
          <div className="bg-white w-[min(38.09vw,390px)] p-[min(2.44vw,25px)] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)] text-18 text-[var(--color-black-2)] text-center">
            <div className="text-22 font-[700]">{info.headText}</div>
            <div className="flex justify-center mt-[min(1.95vw,20px)] items-start gap-[min(1.27vw,13px)] text-[var(--color-green)] text-24 tracking-[0.04em]">
              <div>{info.selectTableNo}</div>
              <div>{info.split}</div>
              <div className="break-all">{info.targetList}</div>  
            </div>
            <div className="flex justify-center gap-[min(2.44vw,25px)] mt-[min(1.46vw,15px)]">
              <CustomBtn
                onClick={() => modalOpenObj.setModalOpen(false)}
                className={`leading-[0.8] w-full text-[#6D788D] font-[600] min-h-[min(5.57vw,57px)] rounded-[min(0.78vw,8px)] btn-second-2-active text-18`}
                btntxt={"ยกเลิก"}
              />
              <CustomBtn
                onClick={onConfirm}
                className={`leading-[0.8] w-full text-white font-[600] min-h-[min(5.57vw,57px)] rounded-[min(0.78vw,8px)] btn-main-active text-18`}
                btntxt={"ตกลง"}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
