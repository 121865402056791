import React, { useEffect, useState } from "react";
import CustomInput from "components/fragments/CustomInput";
export default function  PayPoint({
    discountObj,
    Payment,
    PaymentDetailObj,
    member,
    members,
    endBillDiscountObj,
    ...props
    }) {
    // console.log("🚀 ~ PaymentDetailObj:", PaymentDetailObj)
    // console.log("🚀 ~ Payment:", Payment)
    // console.log("🚀 ~ members:", members)
    // console.log("🚀 ~ member:", member)


    const [customerSelect, setCustomerSelect] = useState(null);
    const [customerList, setCustomerList] = useState([
        // { id: 1, fullName: "มุนิน มุตา",code:'xxx', tel: "0000000000","point":2000,"pointExpire":'31/12/2566' },
        // { id: 2, fullName: "มุนิน มุตา",code:'xxx', tel: "1111111111","point":3000,"pointExpire":'31/12/2566' },
        // { id: 3, fullName: "มุนิน มุตา",code:'xxx', tel: "22222222222","point":4000,"pointExpire":'31/12/2566' }
    ]);
    const [isError, setIsError] = useState();
    const [filteredCustomerList, setFilteredCustomerList] = useState([]);
    const [keyword, setKeyword] = useState("");
    console.log("🚀 ~ keyword:", keyword)

    const [usePoint, setUsePoint] = useState(0);
    const [discount, setDiscount] = useState(null);
    const [pointRateExchange, setPointRateExchange] = useState({
        "name":"เงื่อนไข คะแนนที่ใช้ 10 ได้รับส่วนลด 5.-",
        "rate": 5/10
    }); 

    const handleAddDiscount = async () => {
        var newDiscounts = [...discountObj.discount];
        // console.log("🚀 ~ handleAddDiscount ~ newDiscounts:", newDiscounts)
        var exist = newDiscounts.findIndex(dc => dc.type == 'โปรโมชั่น');
        // console.log("🚀 ~ handleAddDiscount ~ exist:", exist)
        if(exist >= 0) {
            newDiscounts[exist] = {
                discountName: "คะแนนแลกส่วนลด "+discount+" บาท",
                discountPrice: discount,
                type: 'โปรโมชั่น',
                discounts: [
                    {
                    campaign: `ส่วนลดแลกคะแนน ${discount} บาท`,
                    desc: 'นำคะแนนมาแลกเป็นส่วนลด',
                    option: [
                        'ทุกๆ 10 คะแนน แลกเป็นส่วนลด 5 บาท'
                    ],
                    amount: discount
                    }
                ]
            }
            discountObj.setDiscount( (prev) => [ ...newDiscounts ])
        } else {
            let item = {
                discountName: "คะแนนแลกส่วนลด "+discount+" บาท",
                discountPrice: discount,
                type: 'โปรโมชั่น',
                discounts: [
                    {
                    campaign: `ส่วนลดแลกคะแนน ${discount} บาท`,
                    desc: 'นำคะแนนมาแลกเป็นส่วนลด',
                    option: [
                        'ทุกๆ 10 คะแนน แลกเป็นส่วนลด 5 บาท'
                    ],
                    amount: discount
                    }
                ]
            }
            discountObj.setDiscount( (prev) => [ ...prev,item ])
        }
        endBillDiscountObj.setEndBillDiscount( (prev) => ({
            ...prev,
            isUsePoint: true,
            pointUsage: usePoint,
            // value: discount,
            // valueRound: Math.ceil(discount),
            // amount: discount,
            discountFromPoint: discount,
            // name: "คะแนนแลกส่วนลด"
        }));
        Payment.setPaymentSelect({"paymentChannelID": 0})
    }
    
    useEffect(() => {
        if(members && members.length > 0) {
            const parsed = members.map(member => ({
                id: member.id,
                fullName: member.name,
                tel: member.phone_number ?? "-",
                point: member.MemberPoint ? member.MemberPoint[0].point : 0
            }));
            setCustomerList(parsed);
        }
    }, [members])

    useEffect(() => {
        if (!keyword) {
          return setFilteredCustomerList([]);
        }
        const filteredList = customerList.filter(
          (user) =>
            user.fullName.toLowerCase().includes(keyword.toLowerCase()) 
        );
        setFilteredCustomerList(filteredList);
    }, [customerList, keyword]);


    const getDiscount = (usePoint) => {
        let discount = usePoint*pointRateExchange.rate
        setDiscount(discount)
    }

    useEffect(() => {
        getDiscount(usePoint)  
    },[usePoint])

    useEffect(() => {
        if(member && member.id) {
            setCustomerSelect({
                id: member.id,
                fullName: member.name,
                tel: member.phone_number ?? "-",
                point: member.MemberPoint ? member.MemberPoint[0].point : 0,
                pointExpire: "-",
            })
        }
    }, [member])

    // useEffect(() => {
    //     if(endBillDiscountObj?.endBillDiscount) {
    //         if(endBillDiscountObj.endBillDiscount.isUsePoint) {
    //             setUsePoint(endBillDiscountObj.endBillDiscount.pointUsage)
    //         }
    //     }
    // }, [endBillDiscountObj])

    const addPoint = async (point) => {
        // console.log("🚀 ~ addPoint ~ point:", point)
        if(customerSelect && customerSelect.point) {
            if(customerSelect.point == 0) {
                return null
            } else {
                var newUsePoint = usePoint + point;
                if(newUsePoint > customerSelect.point) {
                    newUsePoint = customerSelect.point;
                }
                // console.log("🚀 ~ addPoint ~ newUsePoint:", newUsePoint)
                if(newUsePoint < 0) {
                    setUsePoint(0);
                } else {
                    if(point > 0) {
                        var total = newUsePoint * pointRateExchange.rate;
                        // console.log("🚀 ~ addPoint ~ total:", total)
                        if(total >= Math.abs(PaymentDetailObj?.PaymentDetail?.paymentRemaining)) {
                            setUsePoint( Math.floor((Math.abs(PaymentDetailObj.PaymentDetail.paymentRemaining) * (1 / pointRateExchange.rate)) / 10) * 10);
                        }
                        else if(total <= Math.abs(PaymentDetailObj?.PaymentDetail?.paymentRemaining)) {
                            setUsePoint(newUsePoint);
                        }
                    } else {
                        var total = newUsePoint * pointRateExchange.rate;
                        // console.log("🚀 ~ addPoint ~ total:", total)
                        if(total <= Math.abs(PaymentDetailObj?.PaymentDetail?.paymentRemaining)) {
                            setUsePoint(newUsePoint);
                        }
                    }
                }
            }
        }
    };
    
    return (
        <div className="h-100 d-flex flex-column">
            <div className="d-flex align-items-center text-[#061126] mb-3">
                <div className="me-auto">
                <span className="fs-18 fw-500">Order NO.</span>
                <span className="fs-14 fw-500 ms-2">{PaymentDetailObj.PaymentDetail.orderNo}</span>
                </div>
                <div className="fs-18 fw-bold text-[#2DC76D]">คะแนนแลกส่วนลด</div>
            </div>
            
            {/* <div className="fs-16 fw-500 mb-2 text-[#2DC76D]">กรุณาเลือกรายชื่อลูกค้า</div>
            <div className=" mb-3 position-relative">
                <CustomInput
                className=" w-75  "
                inputprops={{
                    className:
                    "rounded-[min(0.78vw,8px)] icon-search border-[min(0.10vw,1px)] border-[#b6bbc6] py-[min(1.03vw,10.5px)] px-[min(1.46vw,15px)] font-[400] text-16",
                    placeholder: "พิมพ์ชื่อ/รหัสพนักงาน",
                    onChange: async (e) => { 
                        setKeyword(e.currentTarget.value) 
                    },
                    value: keyword,
                    error: isError,
                }}
                />
                {
                    keyword != "" && filteredCustomerList ? (
                        <div className="mt-[min(1.46vw,15px)] mb-[min(1.46vw,15px)] flex flex-col w-75  custom-dropdown" >
                            {filteredCustomerList.map((value, index) => 
                                <div 
                                className="py-2 px-3 d-flex gap-[min(1.46vw,15px)] align-items-center" 
                                key={index} 
                                style={{borderBottom:'1px solid #e3e3e3',cursor:'pointer'}}
                                onClick={() => { 
                                    setCustomerSelect(value)
                                    setKeyword("") 
                                }}

                                >
                                    <img
                                    className="aspect-square w-[min(3.91vw,40px)] rounded-full object-contain"
                                    src="/img/avartar-male.png"
                                    alt=""
                                    />
                                    <div className="text-[#4c4e64] opacity-87 leading-[1.4] text-14 font-[400] tracking-[0.015em]">
                                    <div>{value.fullName}</div>
                                    <div className="opacity-60">{value.tel}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        ''
                    )
                }
                
            </div> */}

            <div className="p-4 text-[#2F4858] mb-4" style={{borderRadius: '15px',background: 'rgba(223, 247, 233, 0.15)'}}>
                <div className="d-flex align-items-center mb-4">
                    <div className="me-auto ">
                        <i className="icon-card me-2 "></i> 
                        <span className="py-3">ชื่อลูกค้า</span>
                    </div>
                    <div>{ customerSelect ? customerSelect.fullName : '-' }</div>
                </div>
                <div className="d-flex align-items-center mb-4">
                    <div className="me-auto ">
                        <i className="icon-tel me-2"></i>
                        <span className="py-3">เบอร์โทรศัพท์</span>
                    </div>
                    <div>{ customerSelect ? customerSelect.tel : '-' }</div>
                </div>
                <div className="d-flex align-items-center ">
                    <div className="me-auto ">
                        <i className="icon-gift me-2"></i> 
                        <span className="py-3">คะแนนคงเหลือ</span>
                    </div>
                    <div className="text-[#FF731D]"> <i className="icon-star"></i> { customerSelect ? customerSelect.point : '-' }</div>
                </div>
            </div>

            <div className="p-3 text-[#2F4858] mb-2" style={{background: '#FFF',filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08))'}}>
                <div className="row gx-0 align-items-center">
                    <div className="col-4">
                        <div className="text-center text-[#061126] fs-12 fw-500 mb-2 mr-2">
                            คะแนนของคุณ
                        </div>
                        <div className="text-center d-flex align-items-center justify-content-center mr-2">
                            <div className="icon-star" style={{width:'24px',height:'24px'}}></div>
                            <span className="text-[#FF731D] fs-26 fw-bold">{ customerSelect ? customerSelect.point : '-' }</span>
                        </div>
                        <div className="text-center fs-8 text-[#6D788D] mt-1 mb-3 mr-2">
                            หมดอายุ { customerSelect ? customerSelect.pointExpire : '-' }
                        </div>

                        <div className="row">
                            <div className="col-6 px-1">
                                <div className="text-center fs-10 mb-1">คะแนนคงเหลือ</div>
                                <div className="text-center fs-18 fw-500 text-[#2DC76D]">
                                { customerSelect ? customerSelect.point - usePoint : '-' }
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="text-center fs-10 mb-1">ส่วนลด</div>
                                <div className="text-center fs-18 fw-500 text-[#F95057]">
                                  { discount ? "-"+discount : 0}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="py-2 px-2 text-[#2F4858] text-center " style={{borderRadius: '8px',border: '1px solid #F4F4F4'}}>
                            <div 
                                className=" py-2 mb-3 text-center mx-auto text-[#474747]" 
                                style={{fontSize: '8px',background:'rgba(137, 130, 130, 0.08)',borderRadius: '8px'}}
                            >
                               {pointRateExchange.name}
                            </div>
                            <div className="text-center fw-500 fs-10 mb-3">จำนวนคะแนนที่ต้องการใช้</div>
                            <div>
                                <input 
                                    type="number" 
                                    className="border-0 form-st-6 text-center cursor-not-allowed"
                                    style={{background:'rgba(137, 130, 130, 0.08)'}}
                                    defaultValue={0}
                                    value={usePoint}
                                    onChange={(e) => {
                                        if(e.target.value >= 100) {
                                            setUsePoint(e.target.value)
                                        } else {
                                            setUsePoint(0)
                                        }
                                    }}
                                    disabled={true}
                                    max={ customerSelect ? customerSelect.point : 0 }
                                />
                            </div>
                            <div className="text-center fw-300 fs-10 mt-3">ระบุจำนวนคะแนนเช่น 100,500,1000</div>
                            <div className="flex mt-2 gap-2">
                                <button 
                                    className="btn bg-red-500 hover:bg-red-700 text-white fs-14 px-1 py-2 w-1/4"
                                    // disabled={customerSelect?.point == 0 || usePoint == 0}
                                    onClick={() => addPoint(-100)}
                                >
                                    - 100
                                </button>
                                <button
                                    className="btn bg-red-500 hover:bg-red-700 text-white fs-14 px-1 py-2 w-1/4"
                                    // disabled={customerSelect?.point == 0 || usePoint == 0}
                                    onClick={() => addPoint(-10)}
                                >
                                    - 10
                                </button>
                                <button
                                    className="btn bg-blue-500 hover:bg-blue-700 text-white fs-14 px-1 py-2 w-1/4"
                                    // disabled={customerSelect?.point ? (customerSelect.point - usePoint) + 100 > customerSelect.point : false }
                                    onClick={() => addPoint(10)}
                                >
                                    + 10
                                </button>
                                <button
                                    className="btn bg-blue-500 hover:bg-blue-700 text-white fs-14 px-1 py-2 w-1/4"
                                    // disabled={customerSelect?.point ? (customerSelect.point - usePoint) + 1000 > customerSelect.point : false }
                                    onClick={() => addPoint(100)}
                                >
                                    + 100
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="d-flex  pt-2 mt-auto">
                <button className="ms-auto btn btn-st-q1 px-5"
                onClick={handleAddDiscount}
                disabled={
                    !usePoint || 
                    !customerSelect ||
                    PaymentDetailObj?.PaymentDetail?.paymentRemaining && Math.abs(PaymentDetailObj?.PaymentDetail?.paymentRemaining) == 0
                }
                >ยืนยัน
                </button>
            </div>
        </div>
    )

}

