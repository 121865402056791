import React from "react";

export default function PosStartPrintPage() {
  return (
    <div className="bg-[#EFEFEF] min-h-[100vh] pt-[4.00vw] pb-[12.79vw]">
      <div className="flex justify-center text-[0.78vw] w-full">
        <div
          id={"section-to-print"}
          className="w-[21.48vw] bg-[#FFF] px-[1.70vw] py-[1.31vw] flex flex-col gap-[0.59vw] "
        >
          <div className="text-center font-[600]">
            <div>โรงคั่วกาแฟพะเยา</div>
            <div>สาขา 1</div>
            <div className="border-dashed border-t-[0.04vw] border-black h-0 mt-[1.28vw]"></div>
          </div>
          <div className="flex flex-col gap-[0.59vw]">
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">POS :</div>
              <div className="text-right">POS 1</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">วันที่ :</div>
              <div className="text-right">05/19/2020 12:20</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">พนักงาน :</div>
              <div className="text-right">วัสมนต์ จิตรานุพงศ</div>
            </div>
            <div className="flex justify-between gap-[0.98vw] ">
              <div className="whitespace-nowrap">ยอดเงินสดตั้งต้น :</div>
              <div className="text-right font-[700]">2,500.00</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
