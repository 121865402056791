import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { TailSpin } from "react-loader-spinner";

export default function LoadingSpinner({ modalOpenObj }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpenObj.modalOpen}
      // onClose={() => modalOpenObj.setModalOpen(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={modalOpenObj.modalOpen}>
        <div
          className="bg-white focus-visible-none px-4 py-3 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[min(1.46vw,15px)]"
        >
          <div className="text-center">
            <TailSpin
              visible={true}
              height="80"
              width="80"
              color="#2DC76D"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
            />
            <p className="mt-3">รอสักครู่...</p>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
