import axiosInstance from './axios';
import checkImage from 'utils/checkImage';

const endPoint = "/order";
// const baseAPI = "http://localhost:3000/";
const baseAPI = "https://uat-api.allder.co/";

export const getAllOrder = async (posId, operationDate) => {
    try {

        const queryParams = {
            pos_id: posId ?? null,
            operation_date: operationDate ?? null
        }

        const response = await axiosInstance.get('/orders', { params: queryParams });
        // console.log("🚀 ~ file: order.js:8 ~ getAllOrder ~ response.data:", response.data)

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        const orders = [];

        for (let i = 0; i < response.data.length; i++) {
            const orderProducts = [];
            if (response.data[i].orderDetail && response.data[i].orderDetail.length > 0) {
                for (const orderDetail of response.data[i].orderDetail) {
                    const options = [];
                    if (orderDetail.OrderDetailoptionBinding && orderDetail.OrderDetailoptionBinding.length > 0) {
                        for (const option of orderDetail.OrderDetailoptionBinding) {
                            options.push({
                                listID: option.branch_product_option_detail_binding.id,
                                listName: option.branch_product_option_detail_binding.product_option_detail.name,
                                listPrice: option.branch_product_option_detail_binding.price,
                                optionType: option.branch_product_option_detail_binding.product_option_detail.product_option.name,
                                optionTypeId: option.branch_product_option_detail_binding.product_option_detail.product_option.option_type_id,
                                // isMultiple: response.data[i].product_options[j].can_select_multi ? true : false,
                                // optionDetailBindingId: response.data[i].product_options[j].branch_product_option_detail_binding[k].id,
                                optionDetailBindingId: option.branch_product_option_detail_binding_id,
                                optionQty: option.amount
                            })
                        }
                    }

                    var optionPrice = 0;
                    optionPrice = options.reduce((a,b) => a + (b.listPrice * b.optionQty), 0);
                    var productSumPrice = 0;
                    productSumPrice = (orderDetail.branch_product.price + optionPrice) * orderDetail.remain_amount;
                    if(response.data[i].status_id == 3 || orderDetail.status_id == 3) {
                        productSumPrice = (orderDetail.branch_product.price + optionPrice) * orderDetail.amount;
                    }

                    orderProducts.push({
                        id: orderDetail.id,
                        productID: orderDetail.branch_product.id,
                        branchProductId: orderDetail.branch_product_id ? orderDetail.branch_product_id : orderDetail.branch_product.id,
                        // productImage: baseAPI + "cdn-cgi/image/fit=scale-down,width=52,quality=40/" + orderDetail.branch_product.product.img_url,
                        productImage: checkImage.checkURL(orderDetail.branch_product.product.img_url),
                        productName: orderDetail.branch_product.product.name,
                        productPrice: orderDetail.branch_product.price,
                        // productSumPrice: orderDetail.sumPrice ?? 0,
                        productSumPrice: productSumPrice,
                        productQty: response.data[i].status_id == 3 ? orderDetail.amount : orderDetail.remain_amount,
                        // productStatus: response.data[i].status_id == 4 ? 0 : orderDetail.status_id,
                        productStatus: response.data[i].status_id == 4 ? 0 : (response.data[i].status_id == 3 || orderDetail.status_id == 3) ? 3 : (response.data[i].status_id == 2 || orderDetail.status_id == 2) ? 2 : 1,
                        // optionnote: [
                            // {
                            //     "listID": 1,
                            //     "listName": "น้ำแข็ง 2ก้อน"
                            // }
                        // ],
                        optionnote: options.filter(option => option.optionTypeId == 3),
                        // optionproduct: [
                            // {
                            //     "listID": 3,
                            //     "listName": "ไส้กรอก",
                            //     "listPrice": "100.00",
                            //     "optionQty": 1
                            // }
                        // ],
                        optionproduct: options.filter(option => option.optionTypeId == 2),
                        optionstandard: options.filter(option => option.optionTypeId == 1),
                        note: orderDetail.note ?? "",
                        productAmount: orderDetail.amount,
                        productCancel: orderDetail.cancel_amount,
                        productRemain: orderDetail.remain_amount,
                        orderID: orderDetail.order_id,
                        orderNo: orderDetail.main_order_id,
                    })
                }
            }
            // console.log("🚀 ~ file: order.js:23 ~ getAllOrder ~ orderProducts:", orderProducts)

            var orderSumPrice = 0;
            orderSumPrice = orderProducts.filter(odp => odp.productStatus != 3).reduce((a,b) => a + b.productSumPrice, 0);

            orders.push({
                ...response.data[i],
                orderId: response.data[i].id,
                id: response.data[i].id,
                delivery: response.data[i].type_id,
                orderNo: response.data[i].order_id ?? "",
                order_id: response.data[i].order_id ?? "",
                orderDate: null,
                tableID: response.data[i].table_activity?.table_id ?? "",
                zone: response.data[i].table_activity?.table?.zone ?? "",
                delivery: response.data[i].type_id ?? 1,
                product: orderProducts,
                orderPrice: {
                    // sumprice: response.data[i].sumPrice,
                    sumprice: orderSumPrice,
                    discount: 0.0,
                    vat: 0.0,
                    // net: response.data[i].sumPrice,
                    net: orderSumPrice,
                },
                payment: [],
                sumPrice: orderSumPrice,
                remark: response.data[i].remark,
                delivery_remark: response.data[i].delivery_remark,
                delivery_remark_channel: response.data[i].delivery_remark_channel,
            })
        }

        // console.log("orders: ", orders)

        return {
            orders: orders
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getOrder = async (id) => {
    try {
        const response = await axiosInstance.get(`/orders/${id}`);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            order: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const createOrder = async (orderID, orderNo, orderMenu, orderType, tableActivityId, operationDate, customer, employeeId, posId, statusId, orderRemark) => {
    // console.log("🚀 ~ createOrder ~ customer:", customer)
    // console.log("🚀 ~ file: order.js:107 ~ createOrder ~ orderMenu:", orderMenu)
    try {
        const orderDetails = [];
        if (orderMenu.length > 0) {
            for (let i = 0; i < orderMenu.length; i++) {
                const options = []
                if (orderMenu[i].optionstandard && orderMenu[i].optionstandard.length > 0) {
                    for (const option of orderMenu[i].optionstandard) {
                        options.push({
                            // id: 0,
                            // order_detail_id: 0,
                            branch_product_option_detail_binding_id: option.optionDetailBindingId,
                            amount: option.optionQty
                        });   
                    }
                }
                if (orderMenu[i].optionproduct && orderMenu[i].optionproduct.length > 0) {
                    for (const option of orderMenu[i].optionproduct) {
                        options.push({
                            // id: 0,
                            // order_detail_id: 0,
                            branch_product_option_detail_binding_id: option.optionDetailBindingId,
                            amount: option.optionQty
                        });   
                    }
                }
                if (orderMenu[i].optionnote && orderMenu[i].optionnote.length > 0) {
                    for (const option of orderMenu[i].optionnote) {
                        options.push({
                            // id: 0,
                            // order_detail_id: 0,
                            branch_product_option_detail_binding_id: option.optionDetailBindingId,
                            amount: option.optionQty ? option.optionQty : 1
                        });   
                    }
                }
                const detail = {
                    id: orderMenu[i].id,
                    order_id: orderNo,
                    branchProductId: orderMenu[i].branchProductId,
                    amount: orderMenu[i].productQty,
                    statusId: orderMenu[i].productStatus,
                    orderDetailOptionBindings: options,
                    note: orderMenu[i].note ?? "",
                };

                orderDetails.push(detail)
            }
        }
        // console.log("🚀 ~ file: order.js:48 ~ createOrder ~ orderDetails:", orderDetails)

        const payload = {
            batch: 1,
            id: orderID,
            orderId: orderNo,
            tableActivityId: tableActivityId,
            typeId: orderType,
            statusId: statusId ? statusId : 1,
            startTime: new Date().toISOString(),
            isCombine: false,
            // operationDate: new Date().toISOString(),
            operationDate: operationDate,
            orderDetails: orderDetails,
            memberId: customer?.id ?? null,
            employeeId: employeeId ? employeeId : null,
            posId: posId ? posId : null,
            remark: orderRemark ?? null
        };
        // console.log("🚀 ~ createOrder ~ payload:", payload)

        const response = await axiosInstance.post(`/orders/`, payload);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            order: response.data
            // order: []
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const addOrder = async (orderMenu) => {
    // console.log("🚀 ~ file: order.js:66 ~ addOrder ~ orderMenu:", orderMenu)
    try {
        const payload = [];

        const response = await axiosInstance.post(`/order-details/`, orderMenu);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            order: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const updateOrder = async (id, updateData, member) => {
    try {
        const payload = {
            id: id,
            statusId: updateData.status_id,
            memberId: updateData.member_id ? updateData.member_id : updateData.member?.id ? updateData.member.id : member?.id ? member.id : null,
        }

        const response = await axiosInstance.patch(`/orders/${id}`, payload);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            order: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const updateOrderEmployee = async ({ orderId, employeeId}) => {
    try {
        const payload = {
            id: orderId,
            employeeId: employeeId ?? null,
        }

        const response = await axiosInstance.patch(`/orders/${orderId}`, payload);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            order: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getLatestOrderNo = async (posId) => {
    try {
        const response = await axiosInstance.get(`/util/gen-order-id/${posId}`);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            orderNo: response.data.order_id
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const updateOrderDetail = async (orderDetail) => {
    try {
        const payload = {
            id: orderDetail.id,
            orderId: String(orderDetail.order_id),
            main_order_id: orderDetail.orderNo ? orderDetail.orderNo : orderDetail.main_order_id,
            branchProductId: orderDetail.branch_product_id,
            statusId: orderDetail.status_id ? orderDetail.status_id : 2,
            billId: null,
            amount: orderDetail.amount,
            cancel_amount: orderDetail.cancel_amount ?? null,
            remain_amount: orderDetail.remain_amount ?? null,
            is_from_kitchen: orderDetail.is_from_kitchen ?? null
        }

        const response = await axiosInstance.patch(`/order-details/${orderDetail.id}`, payload);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            orderDetail: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const updateMultipleOrderDetail = async (orderDetailIdList) => {
    try {
        const payload = orderDetailIdList.map(od => ({ id: od }))

        const response = await axiosInstance.patch(`/multiple-order-details`, payload);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            orderDetail: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const deleteOrder = async (orderId) => {
    // console.log("🚀 ~ file: order.js:66 ~ addOrder ~ orderMenu:", orderMenu)
    try {

        const response = await axiosInstance.delete(`/orders/${orderId}`);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            order: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const kitchenClearOrder = async (orderId, orderBatch) => {
    try {
        const payload = {
            id: orderId,
            orderBatch: orderBatch,
            isKitchenClear: true
        }

        const response = await axiosInstance.patch(`/orders/${orderId}`, payload);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            order: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const updateOrderReceiptLog = async (receiptId, receiptLogCount, receiptNo) => {
    try {
        const payload = {
            log_id: receiptLogCount + 1,
            receipt_no: receiptNo
        }

        const response = await axiosInstance.patch(`/reciept-archive-log/${receiptId}`, payload);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            order: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getAllOrderByDateRange = async (posId, operationDate, startDate, endDate) => {
    try {

        const queryParams = {
            pos_id: posId ?? null,
            operation_date: operationDate ?? null,
            start_date: startDate,
            end_date: endDate
        }

        const response = await axiosInstance.get('/orders/by-date', { params: queryParams });
        // console.log("🚀 ~ file: order.js:8 ~ getAllOrder ~ response.data:", response.data)

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        const orders = [];

        for (let i = 0; i < response.data.length; i++) {
            const orderProducts = [];
            if (response.data[i].orderDetail && response.data[i].orderDetail.length > 0) {
                for (const orderDetail of response.data[i].orderDetail) {
                    const options = [];
                    if (orderDetail.OrderDetailoptionBinding && orderDetail.OrderDetailoptionBinding.length > 0) {
                        for (const option of orderDetail.OrderDetailoptionBinding) {
                            options.push({
                                listID: option.branch_product_option_detail_binding.id,
                                listName: option.branch_product_option_detail_binding.product_option_detail.name,
                                listPrice: option.branch_product_option_detail_binding.price,
                                optionType: option.branch_product_option_detail_binding.product_option_detail.product_option.name,
                                optionTypeId: option.branch_product_option_detail_binding.product_option_detail.product_option.option_type_id,
                                // isMultiple: response.data[i].product_options[j].can_select_multi ? true : false,
                                // optionDetailBindingId: response.data[i].product_options[j].branch_product_option_detail_binding[k].id,
                                optionDetailBindingId: option.branch_product_option_detail_binding_id,
                                optionQty: option.amount
                            })
                        }
                    }

                    var optionPrice = 0;
                    optionPrice = options.reduce((a,b) => a + (b.listPrice * b.optionQty), 0);
                    var productSumPrice = 0;
                    productSumPrice = (orderDetail.branch_product.price + optionPrice) * orderDetail.remain_amount;
                    if(response.data[i].status_id == 3 || orderDetail.status_id == 3) {
                        productSumPrice = (orderDetail.branch_product.price + optionPrice) * orderDetail.amount;
                    }

                    orderProducts.push({
                        id: orderDetail.id,
                        productID: orderDetail.branch_product.id,
                        branchProductId: orderDetail.branch_product_id ? orderDetail.branch_product_id : orderDetail.branch_product.id,
                        // productImage: "img/product/img1.png",
                        // productImage: baseAPI + "cdn-cgi/image/fit=scale-down,width=52,quality=40/" + orderDetail.branch_product.product.img_url,
                        productImage: checkImage.checkURL(orderDetail.branch_product.product.img_url),
                        productName: orderDetail.branch_product.product.name,
                        productPrice: orderDetail.branch_product.price,
                        // productSumPrice: orderDetail.sumPrice ?? 0,
                        productSumPrice: productSumPrice,
                        productQty: response.data[i].status_id == 3 ? orderDetail.amount : orderDetail.remain_amount,
                        // productStatus: response.data[i].status_id == 4 ? 0 : orderDetail.status_id,
                        productStatus: response.data[i].status_id == 4 ? 0 : (response.data[i].status_id == 3 || orderDetail.status_id == 3) ? 3 : (response.data[i].status_id == 2 || orderDetail.status_id == 2) ? 2 : 1,
                        // optionnote: [
                            // {
                            //     "listID": 1,
                            //     "listName": "น้ำแข็ง 2ก้อน"
                            // }
                        // ],
                        optionnote: options.filter(option => option.optionTypeId == 3),
                        // optionproduct: [
                            // {
                            //     "listID": 3,
                            //     "listName": "ไส้กรอก",
                            //     "listPrice": "100.00",
                            //     "optionQty": 1
                            // }
                        // ],
                        optionproduct: options.filter(option => option.optionTypeId == 2),
                        optionstandard: options.filter(option => option.optionTypeId == 1),
                        note: orderDetail.note ?? "",
                        productAmount: orderDetail.amount,
                        productCancel: orderDetail.cancel_amount,
                        productRemain: orderDetail.remain_amount,
                        orderID: orderDetail.order_id,
                        orderNo: orderDetail.main_order_id,
                    })
                }
            }
            // console.log("🚀 ~ file: order.js:23 ~ getAllOrder ~ orderProducts:", orderProducts)

            var orderSumPrice = 0;
            orderSumPrice = orderProducts.filter(odp => odp.productStatus != 3).reduce((a,b) => a + b.productSumPrice, 0);

            orders.push({
                ...response.data[i],
                orderId: response.data[i].id,
                id: response.data[i].id,
                delivery: response.data[i].type_id,
                orderNo: response.data[i].order_id ?? "",
                order_id: response.data[i].order_id ?? "",
                orderDate: null,
                tableID: response.data[i].table_activity?.table_id ?? "",
                zone: response.data[i].table_activity?.table?.zone ?? "",
                delivery: response.data[i].type_id ?? 1,
                product: orderProducts,
                orderPrice: {
                    // sumprice: response.data[i].sumPrice,
                    sumprice: orderSumPrice,
                    discount: 0.0,
                    vat: 0.0,
                    // net: response.data[i].sumPrice,
                    net: orderSumPrice,
                },
                payment: [],
                sumPrice: orderSumPrice,
                remark: response.data[i].remark,
                delivery_remark: response.data[i].delivery_remark,
                delivery_remark_channel: response.data[i].delivery_remark_channel,
                closureText: response.data[i].closureText ?? null
            })
        }

        // console.log("orders: ", orders)

        return {
            orders: orders
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const updateOrderRemark = async ({id, remark, delivery_remark, delivery_remark_channel}) => {
    try {
        const payload = {
            id: id,
            remark: remark ?? null,
            delivery_remark: delivery_remark ?? null,
            delivery_remark_channel: delivery_remark_channel ?? null,
        }

        const response = await axiosInstance.patch(`/orders/${id}`, payload);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            order: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const updateOrderMember = async ({orderId, memberId}) => {
    try {
        const payload = {
            id: orderId,
            memberId: memberId ?? null,
        }

        const response = await axiosInstance.patch(`/orders/${orderId}`, payload);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            order: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}