import DefaultProductImg from 'img/product/default.png';

export default function ProductOrderDetail({ products, handleClick, ...props }) {
  // console.log("🚀 ~ ProductOrderDetail ~ products:", products)
  return (
    <div>
      {products && products.map((item, index) => {
        let productStatus = item.productStatus;
        if(item.productStatus == 2 && item.productCancel > 0) {
          productStatus = 4;
        }
        return (
          // (item.productStatus == 3 || (item.productRemain == 0 && item.productCancel == item.productAmount)) ? null :
          <li className="px-2"
            // data-status={item.productStatus}
            data-status={productStatus}
            key={item.id ? `prod-${item.id}` : `prod-${index}`}
            onClick={() => handleClick(item)}
          >
            <div className="row gx-2 align-items-center">
              <div className="col-7 d-flex align-items-center">
                <img 
                  src={item.productImage} 
                  className="foodimg"
                  onError={(e) => e.target.src = DefaultProductImg}
                />
                <div className={`fs-12 ${item.productStatus == 3 && 'text-[var(--color-red)] line-through'}`}>{item.productName}</div>
              </div>
              <div className={`col-2 fs-14 text-center foodQtyli relative`}>
                <p className={`${item.productCancel > 0 ? 'text-[var(--color-red)]' : ''} ${item.productStatus == 3 && 'line-through'}`}>{item.productStatus == 3 ? item.productAmount : item.productQty}</p>
                {(item.productCancel > 0 && item.productStatus != 3) &&
                  <span className="absolute line-through top-[calc(min(0.78vw,8px)*-1.5)] right-2">{item.productAmount}</span>
                }
              </div>
              {/* <div
              className={`mr-[min(1.76vw,18px)] text-18 font-[500] ${item.productCancel > 0 ? "line-through" : ""
                } ${item.productCancel > 0 ? 'text-[var(--color-red)]' : ''} relative`}
            >
              {item.productQty}
              {item.productCancel > 0 && (
                <div className="absolute line-through top-[calc(min(0.78vw,8px)*-1)] right-[calc(min(0.59vw,6px)*-1)] text-12 text-black">{item.productAmount} x</div>
              )}
            </div>
            <div className="col-3 fs-14 text-end "><span className="foodPriceli">{item.productSumPrice}</span>.-</div> */}
              <div className="col-3 fs-14 text-end ">
                <span className={`foodPriceli ${item.productStatus == 3 && 'text-[var(--color-red)] line-through'}`}>{item.productSumPrice.toLocaleString()}.-</span>
              </div>
            </div>
            <div className="d-flex flex-wrap bx-Foption ">
              {item.optionstandard && item.optionstandard.map((item2, index) =>
                <div className="Foption mb-0  mt-2" key={`op-sd-${item2.listID}`}>{item2.listName} {item2.optionQty > 1 && `x${item2.optionQty}`}</div>
              )}
              {item.optionproduct && item.optionproduct.map((item2, index) =>
                <div className="Foption mb-0 mt-2" key={`op-p-${item2.listID}`}>{item2.listName} {item2.optionQty > 1 && `x${item2.optionQty}`}</div>
              )}
            </div>

            {item.optionnote && item.optionnote.length > 0 || item.note != "" ?
              (
                <div className="fNote mt-2">
                  {item.optionnote && item.optionnote.map((item2, index) =>
                    <span key={`op-note-${item2.listID}`}>
                      {item2.listName}{index != item.optionnote.length - 1 && ", "}
                    </span>
                  )}

                  {item.note}
                </div>
              ) : ''
            }
          </li>
        )
      })
      }
    </div>
  )

}

